import axios from 'axios';
import { SERVER_SUFFIX } from '../../../const/server';
import {
  ApiResponse,
  IClassDeptCodeArr,
  IClasses,
  IRelationDeletePayload,
  IRelationPayload,
  IRelations,
  IStudentRelationPayload,
  ITeacher,
} from '../../../interfaces/api/I-api-class';
import { axiosEduInstance } from '../../axiosEduInstance';
import { axiosErrorHandler } from '../../axiosInstance';

/*
 *
 *  학급 api
 *
 */

//학급 조회
export const classList = async () => {
  try {
    const tenant = sessionStorage.getItem('tenantid');
    const response = await axiosEduInstance.get<ApiResponse<IClasses>>(
      `https://${tenant}${SERVER_SUFFIX}/api/hrs/class`,
    );
    return response;
  } catch (error) {
    console.log(error);
    axiosErrorHandler(error);
  }
};

//학급 등록
export const registClass = async (props: IClassDeptCodeArr) => {
  try {
    const tenant = sessionStorage.getItem('tenantid');
    const response = await axiosEduInstance.post<ApiResponse<string>>(
      `https://${tenant}${SERVER_SUFFIX}/api/hrs/class`,
      props,
    );
    return response;
  } catch (error) {
    console.log(error);
    axiosErrorHandler(error);
  }
};

//학급 삭제
export const removeClass = async (props: { deptcode: string }) => {
  try {
    const tenant = sessionStorage.getItem('tenantid');
    const response = await axiosEduInstance.delete<any>(`https://${tenant}${SERVER_SUFFIX}/api/hrs/class`, {
      data: props,
    });
    return response;
  } catch (error) {
    console.log(error);
    axiosErrorHandler(error);
  }
};

/*
 *
 *  선생님 api
 *
 */

//선생님 조회
export const teacherList = async (classcode: string) => {
  try {
    const tenant = sessionStorage.getItem('tenantid');
    const response = await axiosEduInstance.get<ApiResponse<IClasses>>(
      `https://${tenant}${SERVER_SUFFIX}/api/hrs/class/${classcode}/teachers`,
    );
    return response;
  } catch (error) {
    console.log(error);
    axiosErrorHandler(error);
  }
};

//선생님 등록
export const teacherAddClass = async (props: ITeacher) => {
  try {
    const tenant = sessionStorage.getItem('tenantid');
    const response = await axiosEduInstance.post<ApiResponse<any>>(
      `https://${tenant}${SERVER_SUFFIX}/api/hrs/class/teachers`,
      props,
    );
    return response;
  } catch (error) {
    console.log(error);
    axiosErrorHandler(error);
  }
};

//선생님 삭제
export const teacherRemoveClass = async (props: ITeacher) => {
  try {
    const tenant = sessionStorage.getItem('tenantid');
    const response = await axiosEduInstance.delete<any>(`https://${tenant}${SERVER_SUFFIX}/api/hrs/class/teachers`, {
      data: props,
    });
    return response;
  } catch (error) {
    console.log(error);
    axiosErrorHandler(error);
  }
};

/*
 *
 *  학생 api
 *
 */
//학생 조회
export const studentList = async (classcode: string) => {
  try {
    const tenant = sessionStorage.getItem('tenantid');
    const response = await axiosEduInstance.get<ApiResponse<{ students: string[] }>>(
      `https://${tenant}${SERVER_SUFFIX}/api/hrs/class/${classcode}/students`,
    );
    return response;
  } catch (error) {
    console.log(error);
    axiosErrorHandler(error);
  }
};

//학생 등록
export const studentAddClass = async (props: IStudentRelationPayload) => {
  try {
    const tenant = sessionStorage.getItem('tenantid');
    const response = await axiosEduInstance.post<ApiResponse<any>>(
      `https://${tenant}${SERVER_SUFFIX}/api/hrs/class/students`,
      props,
    );
    return response;
  } catch (error) {
    console.log(error);
    axiosErrorHandler(error);
  }
};

//학생 삭제
export const studentRemoveClass = async (props: IStudentRelationPayload) => {
  try {
    const tenant = sessionStorage.getItem('tenantid');
    const response = await axiosEduInstance.delete<any>(`https://${tenant}${SERVER_SUFFIX}/api/hrs/class/students`, {
      data: props,
    });
    return response;
  } catch (error) {
    console.log(error);
    axiosErrorHandler(error);
  }
};

/*
 *
 *  학부모 api
 *
 */
//학부모 조회
export const parentList = async (props: { studentIds: string[] }) => {
  try {
    const tenant = sessionStorage.getItem('tenantid');
    const response = await axiosEduInstance.post<ApiResponse<IRelations>>(
      `https://${tenant}${SERVER_SUFFIX}/api/hrs/relation/search`,
      props,
    );
    return response;
  } catch (error) {
    console.log(error);
    axiosErrorHandler(error);
  }
};

//학부모(학생) 등록
export const parentAddClass = async (props: IRelationPayload) => {
  try {
    const tenant = sessionStorage.getItem('tenantid');
    const response = await axiosEduInstance.post<ApiResponse<any>>(
      `https://${tenant}${SERVER_SUFFIX}/api/hrs/relation/parents`,
      props,
    );
    return response;
  } catch (error) {
    console.log(error);
    axiosErrorHandler(error);
  }
};

//학부모 삭제
export const parentRemoveClass = async (props: IRelationDeletePayload) => {
  try {
    const tenant = sessionStorage.getItem('tenantid');
    const response = await axiosEduInstance.delete<any>(`https://${tenant}${SERVER_SUFFIX}/api/hrs/relation/parents`, {
      data: props,
    });
    return response;
  } catch (error) {
    console.log(error);
    axiosErrorHandler(error);
  }
};
