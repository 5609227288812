import { IpluralAxiosResponse } from './../../../interfaces/api/I-axios';
import { axiosInstance } from '../../axiosInstance';
import {
  IAdminCounselCategoryReq,
  IAdminCounselCounselorReq,
} from '../../../interfaces/api/counsel/I-api-admin-counsel-category';
import {
  IAdminCounselCounselorRes,
  IAdminCounselCouselorReq,
} from '../../../interfaces/api/counsel/I-api-admin-counsel-counselor';

const adminCounselCounselorInfo = {
  url: {
    list: 'api/v1/admin-mg-tp/counsel/Counselor/list',
    add: 'api/v1/admin-mg-tp/counsel/Counselor/add',
    modify: 'api/v1/admin-mg-tp/counsel/Counselor/modify',
    remove: 'api/v1/admin-mg-tp/counsel/Counselor/remove',
  },
};

// list
export const counselCounselorListApi = (channelid: string) => {
  const req: IAdminCounselCouselorReq = {
    cmd: '1509',
    orgcode: 'ORG01',
    channelid: channelid,
    fields: 'FULL',
  };

  const { url } = adminCounselCounselorInfo;
  return axiosInstance.post<IpluralAxiosResponse<IAdminCounselCounselorRes>>(url.list, req);
};

// add
export const counselCounselorAddApi = (data: IAdminCounselCounselorReq) => {
  const { url } = adminCounselCounselorInfo;
  return axiosInstance.post(url.add, data);
};

// remove
export const counselCounselorRemoveApi = (data: IAdminCounselCounselorReq) => {
  const { url } = adminCounselCounselorInfo;
  return axiosInstance.post(url.remove, data);
};

// // modify
// export const counselCounselorModifyApi = (data: IAdminCounselCounselorReq) => {
//   const { url } = adminCounselCounselorInfo;
//   return axiosInstance.post(url.modify, data);
// };

// export const CategoryList = () => {
//   const body = {
//     cmd:'1501',
//     orgCode : "ORG01"
//   };

//   dispatch(modifyDept(body));
// };

// export const addCategory = (categoryName: string) => {
//     if (!categoryName) return;
//     const body = {
//       cmd:'1502',
//       orgCode : "ORG01",
//       name : categoryName,
//     };

//     return modifyDept(body);
//   };

//   export const modifyCategory = (categoryName: string, id:string) => {
//     if (!categoryName || !id) return;
//     const body = {
//       cmd:'1503',
//       orgCode : "ORG01",
//       name : categoryName,
//       id : id
//     };

//     dispatch(modifyDept(body));
//   };

//   export const deleteCategory = (id: string) => {
//     if (!id) return;
//     const body = {
//       cmd:'1504',
//       orgCode : "ORG01"
//       id : id
//     };

//     dispatch(modifyDept(body));
//   };
