import { title } from 'process';
import { ReactElement } from 'react';
import hint from '../assets/image/etc/hint.png';
import { Tooltip } from 'react-tooltip';
import 'react-tooltip/dist/react-tooltip.css';
import { t } from 'i18next';

interface ICustomCell {
  title: string;
  content: ReactElement;
  required?: boolean;
  isDate?: boolean;
  isToolTip?: boolean;
  toolTipContent?: string | React.ReactNode;
  toolTipId?: string;
}
export const CustomCell = ({ title, required, isDate, content, isToolTip, toolTipContent, toolTipId }: ICustomCell) => {
  return (
    <div
      className="w-full"
      style={{
        zIndex: isDate ? 99 : '',
      }}
    >
      {/* 제목 */}
      <div className="w-full font-bold text-modal-subTitle">
        {title}
        {required && <span className="text-[#d21011]">*</span>}
        {isToolTip && toolTipContent && (
          <img
            data-tooltip-id={toolTipId}
            className={`ml-1 w-4.5 cursor-pointer inline-block`}
            style={{ marginTop: '-1px' }}
            src={hint}
          />
        )}
        {isToolTip && toolTipContent && (
          <Tooltip
            id={toolTipId}
            style={{
              backgroundColor: '#0055ab',
              color: '#19231b',
              zIndex: 1,
            }}
          >
            <div className="block mt-4 mb-4 z-1 w-30">
              <div className="block text-sm mb-1 text-white">{toolTipContent}</div>
            </div>
          </Tooltip>
        )}
      </div>
      {/* INPUT */}
      <div className="w-full">{content}</div>
    </div>
  );
};
