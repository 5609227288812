import { IpluralAxiosResponse } from './../../../interfaces/api/I-axios';
import { getMyTenantId, getOrgCode } from '../../../helpers/h-userInfo';
import {
  IadminRuleUser,
  IadminRuleUserAddApiBody,
  IadminRuleUserInfoApiBody,
  IadminRuleUserListApiBody,
  IadminRuleUserModifyApiBody,
  IadminRuleUserRemoveApiBody,
} from '../../../interfaces/api/rule/I-api-admin-rule-user';
import { axiosErrorHandler, axiosInstance } from '../../axiosInstance';

const adminRuleUserInfo = {
  url: {
    list: '/api/v1/admin-mg-tp/rule/user/list',
    add: '/api/v1/admin-mg-tp/rule/user/add',
    info: '/api/v1/admin-mg-tp/rule/user/info',
    modify: '/api/v1/admin-mg-tp/rule/user/modify',
    remove: '/api/v1/admin-mg-tp/rule/user/remove',
  },
};

// list
export const adminRuleUserListApi = (props?: IadminRuleUserListApiBody) => {
  const { url } = adminRuleUserInfo;
  return axiosInstance.post<IpluralAxiosResponse<IadminRuleUser>>(url.list, {
    tenantid: getMyTenantId(),
    orgcode: getOrgCode(),
    pagesize: 50,
    page: 1,
    ...props,
  });
};

// add
export const adminRuleUserAddApi = (props: IadminRuleUserAddApiBody[]) => {
  const { url } = adminRuleUserInfo;
  return axiosInstance.post(url.add, {
    tenantid: getMyTenantId(),
    orgcode: getOrgCode(),
    rulelist: [...props],
  });
};

// remove
export const adminRuleUserRemoveApi = (
  props: IadminRuleUserRemoveApiBody[],
) => {
  const { url } = adminRuleUserInfo;
  return axiosInstance.post(url.remove, {
    tenantid: getMyTenantId(),
    orgcode: getOrgCode(),
    rulelist: [...props],
  });
};

// modify
export const adminRuleUserModifyApi = (props: IadminRuleUserModifyApiBody) => {
  const { url } = adminRuleUserInfo;
  return axiosInstance.post(url.modify, props);
};

// info
export const adminRuleUserInfoApi = async (
  props: IadminRuleUserInfoApiBody,
) => {
  const { url } = adminRuleUserInfo;
  return axiosInstance.post(url.info, props);
};
