// style
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import '../../../styles/ag-grid.css';

import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { CellClickedEvent, ColDef } from 'ag-grid-community';
import { Func_BtnGroupHubList_Table_Column } from './columns';
import { AgGridReact } from 'ag-grid-react';
import { adminButtonGroupHubListApi } from '../../../api/admin/button/api-admin-btn-group-hub';
import { IAdminButtonGroupHub } from '../../../interfaces/api/button/I-api-admin-btn-group-hub';
import { IAdminButtonFunc } from '../../../interfaces/api/button/I-api-admin-btn-func';
import { setSelectedFuncBtnGroupsBtn } from '../../../redux/slice/funcButtonManagementSlice';
import { adminButtonFuncInfoApi } from '../../../api/admin/button/api-admin-btn-func';
import axios from 'axios';

const FuncBtnGroupHubList_Table = () => {
  const dispatch = useAppDispatch();
  // STATE
  const { selectedFuncBtnGroup, funcBtnGroupsBtnStatus, funcBtnStatus } =
    useAppSelector((state) => state.funcButtonManagement);
  const [selectedBtnGroupsBtnCodeList, setSelectedBtnGroupsBtnCodeList] =
    useState<IAdminButtonGroupHub[]>([]);

  // AG_GRID
  const [columnDefs, setColumnDefs] = useState<ColDef[]>(
    Func_BtnGroupHubList_Table_Column,
  );
  const [rowData, setRowData] = useState<IAdminButtonFunc[]>([]);
  const defaultColDef = useMemo<ColDef>(() => {
    return {
      flex: 1,
      resizable: true,
      minWidth: 100,
    };
  }, []);

  // 버튼그룹의 버튼 선택시..
  const cellClickedListener = useCallback((event: CellClickedEvent<any>) => {
    const buttonInfo: IAdminButtonFunc = event.data;
    dispatch(setSelectedFuncBtnGroupsBtn(buttonInfo));
    // console.log('버튼 그룹의 버튼 정보', buttonInfo);
  }, []);

  // buttoncode 리스트로 버튼 상세 정보 배열 구하기 --> AG_ROWDATA 설정
  const fetchFuncBtnInfo = async (list: IAdminButtonGroupHub[]) => {
    const funcbuttonsInfoList: IAdminButtonFunc[] = [];
    try {
      for (const btn of list) {
        const res = await adminButtonFuncInfoApi({ btncode: btn.btncode });
        if (!res) return;

        const btnInfo = res.data.data.result;
        funcbuttonsInfoList.push(btnInfo);
      }
      setRowData(funcbuttonsInfoList);
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.log('error: ', error);
        return error.message;
      } else {
        console.log('unexpected error: ', error);
        return 'An unexpected error occurred';
      }
    }
  };

  // 선택된 버튼 그룹의 btncode 리스트 구하기.
  const getBtnGroupsCodeList = async () => {
    try {
      const res = await adminButtonGroupHubListApi({
        groupcode: selectedFuncBtnGroup.groupcode,
        btnkind: 'F',
      });
      if (!res) return;
      const groupsBtnCodeList = res?.data.data.result;
      return groupsBtnCodeList;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.log('error: ', error);
      } else {
        console.log('unexpected error: ', error);
      }
    }
  };

  // AG_ROWDATA 구하기 전 조건 설정
  useEffect(() => {
    if (selectedBtnGroupsBtnCodeList.length < 1) {
      setRowData([]);
      return;
    }
    fetchFuncBtnInfo(selectedBtnGroupsBtnCodeList);
  }, [selectedBtnGroupsBtnCodeList]);

  // 선택한 버튼 그룹의 버튼코드 리스트 구하기.
  useEffect(() => {
    if (selectedFuncBtnGroup.groupcode.length !== 32) return;
    getBtnGroupsCodeList()
      .then((groupsBtnCodeList) => {
        if (!groupsBtnCodeList || groupsBtnCodeList.length === 0) {
          setSelectedBtnGroupsBtnCodeList([]);
        } else {
          setSelectedBtnGroupsBtnCodeList(groupsBtnCodeList);
        }
      })
      .catch((err) => console.log(err));
  }, [selectedFuncBtnGroup]);

  // 옵저버
  useEffect(() => {
    if (funcBtnGroupsBtnStatus !== 'success' && funcBtnStatus !== 'success')
      return;

    getBtnGroupsCodeList()
      .then((groupsBtnCodeList) => {
        if (!groupsBtnCodeList || groupsBtnCodeList.length === 0) {
          setSelectedBtnGroupsBtnCodeList([]);
        } else {
          setSelectedBtnGroupsBtnCodeList(groupsBtnCodeList);
        }
      })
      .catch((err) => console.log(err));
  }, [funcBtnGroupsBtnStatus, funcBtnStatus]);

  return (
    <div className="w-full grow flex pb-2 flex-col overflow-hidden">
      <div className="ag-theme-alpine ag-theme-mycustomtheme w-full grow">
        <AgGridReact
          rowData={rowData}
          columnDefs={columnDefs}
          defaultColDef={defaultColDef}
          rowSelection={'single'}
          onCellClicked={cellClickedListener}
          overlayNoRowsTemplate={'\xa0'}
        ></AgGridReact>
      </div>
    </div>
  );
};

export default FuncBtnGroupHubList_Table;
