import { t } from 'i18next';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../redux/store';
import ClassSectionHeader from './ClassSectionHeader';
import ClassDetailsBtn from './ClassDetailsBtn';
import { teacherList } from '../../../api/admin/class/api-class';
import { hrsUserListApi } from '../../../api/hrs/api-hrs-users';
import { useAppSelector } from '../../../redux/hooks';
import { getMyTenantId } from '../../../helpers/h-userInfo';
import { AgGridReact } from 'ag-grid-react';
import { GridApi, SelectionChangedEvent } from 'ag-grid-community';
import { IhrsUserListApiResponseFull } from '../../../interfaces/api/I-api-hrs-users';
import { AgHeaderTextCenter } from '../../common/AgCustomHeader';
import { setTeacherReloadStatus } from '../../../redux/slice/classManagementSlice';
import '../../../styles/ag-grid.css';

const ClassDetailTeacher = () => {
  const dispatch = useDispatch();
  const tenantid = getMyTenantId();
  const { usersid } = useAppSelector((state) => state.user);
  const gridRef = useRef<AgGridReact<IhrsUserListApiResponseFull>>(null);
  const [gridApi, setGridApi] = useState<GridApi<IhrsUserListApiResponseFull>>();
  const [selectedTeachers, setSelectedTeachers] = useState<Array<IhrsUserListApiResponseFull>>([]);
  const [data, setData] = useState<Array<IhrsUserListApiResponseFull>>([]);
  const selectedClassCode = useSelector((state: RootState) => state.classManagement.selectedClassCode);
  const teacherReloadStatus = useSelector((state: RootState) => state.classManagement.teacherReloadStatus);

  useEffect(() => {
    if (!selectedClassCode) return;
    fetchData(selectedClassCode);
    dispatch(setTeacherReloadStatus(false));
    setSelectedTeachers([]);
  }, [teacherReloadStatus]);

  useEffect(() => {
    if (!selectedClassCode) return;
    fetchData(selectedClassCode);
  }, [selectedClassCode]);

  const fetchData = async (selectedClassCode: string) => {
    const eduRs = await teacherList(selectedClassCode);
    if (!eduRs?.data.result) return;

    const teacherArray = eduRs?.data.result;
    if (!teacherArray || !Array.isArray(teacherArray)) {
      console.error('No teachers found');
      return;
    }

    if (teacherArray.length === 0) {
      setData([]);
      return;
    }
    const who = teacherArray.map((teacher: string) => ({
      bid: `${tenantid}.${teacher}`,
    }));

    const commonRs = await hrsUserListApi({
      cmd: '1101',
      sid: usersid ? usersid : '',
      who,
      language: 'ko',
      fields: 'FULL',
    });

    setData(commonRs.data.data.result);
  };

  const columnDefs = useMemo(
    () => [
      {
        headerName: ' ',
        field: 'checkbox',
        checkboxSelection: true,
        maxWidth: 50,
        resizable: false,
      },
      {
        headerName: t('ClassManagement.classDetailName') as string,
        field: 'username',
        editable: false,
        headerComponent: AgHeaderTextCenter,
        cellStyle: { textAlign: 'center' },
      },
      {
        headerName: t('ClassManagement.classDetailId') as string,
        field: 'userid',
        editable: false,
        headerComponent: AgHeaderTextCenter,
        cellStyle: { textAlign: 'center' },
      },
      {
        headerName: t('ClassManagement.classDetailKind') as string,
        field: 'userfield5',
        editable: false,
        headerComponent: AgHeaderTextCenter,
        valueGetter: (params: any) =>
          params.data.userfield5 === 't' ? t('ClassManagement.classDetailTeacher') : params.data.userfield5,
        cellStyle: { textAlign: 'center' },
      },
      {
        headerName: t('ClassManagement.classDetailPosition') as string,
        field: 'position',
        editable: false,
        headerComponent: AgHeaderTextCenter,
        cellStyle: { textAlign: 'center' },
      },
    ],
    [],
  );

  const defaultColDef = useMemo(
    () => ({
      flex: 1,
      resizable: false,
      sortable: false,
    }),
    [],
  );

  const onGridReady = useCallback(
    (params: { api: GridApi<IhrsUserListApiResponseFull> }) => {
      setGridApi(params.api);
      gridRef.current?.api.setRowData(data);
    },
    [data],
  );

  const onSelectionChanged = useCallback((event: SelectionChangedEvent<IhrsUserListApiResponseFull>) => {
    const selectedRows = event.api.getSelectedRows();
    setSelectedTeachers(selectedRows);
  }, []);

  return (
    <div className="p-2 pt-0 category-list-container h-[35%]">
      <ClassSectionHeader title={t('ClassManagement.classDetailTan')} hide={true} className={`p-0 border-none`} />
      <ClassDetailsBtn isTeacherMode={true} selectedTeachers={selectedTeachers} />
      <div className="ag-theme-alpine mt-2" style={{ height: 140, width: 'auto' }}>
        <AgGridReact
          rowHeight={38}
          headerHeight={38}
          ref={gridRef}
          rowData={data}
          columnDefs={columnDefs}
          defaultColDef={defaultColDef}
          rowMultiSelectWithClick
          rowSelection={'multiple'}
          onGridReady={onGridReady}
          onSelectionChanged={onSelectionChanged}
          overlayNoRowsTemplate={`<span style="margin-top:40px;">${t('ClassManagement.classDetailSel2')}</span>`}
        />
      </div>
    </div>
  );
};

export default ClassDetailTeacher;
