import Button from '../../common/buttons/Button';
import InputText from '../../common/inputs/InputText';

const UpperTopBar = () => {
  return (
    <div className="flex justify-between gap-2 py-defaultY">
      <div>
        <InputText placeholder="사용자 검색" />
      </div>
      <div className="flex gap-1">
        <Button label="사용자 로그아웃(초기화)" kind="cancel" />
        <Button label="액셀 다운로드" kind="cancel" />
      </div>
    </div>
  );
};

export default UpperTopBar;
