import '../../styles/treeView-default.css';
import { useEffect, useRef, useState } from 'react';
import Tree from 'rc-tree';
import { t } from 'i18next';
// API
import { hrsOrgsListApi } from '../../api/hrs/api-hrs-orgs';
// REDUX
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
// COMP
import { IhrsOrgDeptFull, IhrsOrgUserAll } from '../../interfaces/api/I-api-hrs-orgs';
import { EventDataNode, Key } from 'rc-tree/lib/interface';
import { getChildDept } from '../../helpers/h-treeView';
import { getMyOrgCode, getMyRootDeptCode, getMyTenantId } from '../../helpers/h-userInfo';
import _ from 'lodash';
import 'rc-tree/assets/index.css';
import { customTitle, dropIndicatorRender, switcherIconHandler } from '../../helpers/h-treeView-ui';
import { OnExpand, OnSelect } from '../../interfaces/I-treeView';
import { useResizeDetector } from 'react-resize-detector';
import Loader from '../common/Loader';
import Button from '../common/buttons/Button';
import { closeClassDepartmentModal } from '../../helpers/h-react-modals';
import MainPageTitleHeader from '../common/MainPageTitleHeader';
import MainPageTitleHeaderDesc from '../common/MainPageTitleHeaderDesc';
import { classList, registClass } from '../../api/admin/class/api-class';
import { IClass, IClassDeptCodeArr } from '../../interfaces/api/I-api-class';
import { setClassReloadStatus } from '../../redux/slice/classManagementSlice';

export interface TreeData {
  title: string;
  key: string;
  isLeaf?: boolean;
  children?: TreeData[];
  data: IhrsOrgDeptFull | IhrsOrgUserAll;
}

interface Props {
  width: number | undefined;
}

const prefixCls = 'ucTenantTree';

interface IdpObj {
  [key: number]: string;
  0: string;
  1: string;
}
const dpObj: IdpObj = {
  0: 'drop to content',
  1: 'drop to gap',
};

const initContextInfo = {
  show: false,
  points: {
    x: 0,
    y: 0,
  },
};

const initUserContextInfo = {
  show: false,
  points: {
    x: 0,
    y: 0,
  },
};

// MAIN
const TreeViewV7 = ({ width }: Props) => {
  const { deptStatus } = useAppSelector(
    (state: { organizationUserManagement: { orgInfo: any } }) => state.organizationUserManagement.orgInfo,
  );
  const { userModifyStatus, userAddStatus, userRemoveStatus } = useAppSelector(
    (state: { organizationUserManagement: { userInfo: any } }) => state.organizationUserManagement.userInfo,
  );
  const { usersid } = useAppSelector((state) => state.user);

  const dispatch = useAppDispatch();

  // context-menu-states
  const [contextInfo, setContextInfo] = useState(initContextInfo);
  const [userContextInfo, setUserContextInfo] = useState(initUserContextInfo);
  // tree-view-states
  const treeRef = useRef<Tree<TreeData> | null>(null);
  const { width: treeWrapperWidth, height: treeWrapperHeight, ref: treeWrapperRef } = useResizeDetector();
  const treeTitleRef = useRef<HTMLElement | null>(null);

  const [treeData, setTreeData] = useState<TreeData[] | null>(null);
  const [selectedKeys, setSelectedKeys] = useState<Key[]>([]);
  const [expandedKeys, setExpandedKeys] = useState<Key[]>([]);
  const [autoExpandParent, setAutoExpandParent] = useState(true);
  const [didSearch, setDidSearch] = useState(false);

  // 스크론 테스트 스테이트
  const [_keyToScroll, _setKeyToScroll] = useState<string | null>(null);

  const handleRegistClass = async () => {
    const classDeptCodes = selectedKeys.map((key) => {
      const stringKey = key.toString();
      const splitKey = stringKey.split(':');
      return splitKey[splitKey.length - 1];
    });

    const eduRs = await classList();
    const filteredClassDeptCodes = classDeptCodes.filter(
      (deptCode) => !eduRs?.data.result.classes.some((classItem: IClass) => classItem.deptcode === deptCode),
    );

    const props: IClassDeptCodeArr = {
      deptcode: filteredClassDeptCodes,
    };

    console.log(props);

    const res = await registClass(props);
    if (res?.data.code === 200) {
      dispatch(setClassReloadStatus(true));
      closeClassDepartmentModal();
    }
  };

  // 초기 ROOT 부서 불러오기
  useEffect(() => {
    const fetchData = async () => {
      const tenantid = getMyTenantId();
      const orgcode = getMyOrgCode();
      const rootDept = getMyRootDeptCode();

      if (!(tenantid && orgcode && rootDept)) return alert('error from treeView.fetchData');

      const res = await hrsOrgsListApi({
        cmd: '1103',
        sid: usersid ? usersid : '',
        what: [{ gid: `${tenantid}.${orgcode}.${rootDept}` }],
        fields: 'FULL',
        include: 'DEPT',
        kind: 'DEPT',
        extra: 'ALL',
      });
      if (!res) return;
      if (!res.data.data.result.dept || res.data.data.result.dept.data.length < 1) return;

      const deptData = res.data.data.result.dept.data[0];
      const deptCode: string = res?.data.data.result.dept.data[0].deptcode;

      setTreeData([
        {
          title: deptData.deptname,
          key: deptCode,
          data: {
            ...deptData,
          },
        },
      ]);
    };

    setTimeout(() => {
      fetchData();
    }, 30);
  }, []);

  const onSelect: OnSelect = (selectedKeys, info) => {
    setSelectedKeys(selectedKeys);
  };

  const onExpand: OnExpand = (expandedKeys, info) => {
    if (info.nativeEvent.type === 'dragenter') {
      return;
    }
    setAutoExpandParent(false);
    setExpandedKeys(expandedKeys);
  };

  const onLoadData = async (selectedNode: EventDataNode<TreeData>) => {
    if (didSearch) return;
    getChildDept({ selectedNode, treeData, setTreeData });
  };

  return (
    <div className="h-full w-full flex flex-col pl-4 pr-4">
      <MainPageTitleHeader text={t('ClassManagement.classTreeSpecifying')} textOnly />
      <MainPageTitleHeaderDesc text={<>{t('ClassManagement.classTreeDesc')}</>} />
      <div
        id="treeView"
        ref={treeWrapperRef}
        className="grow flex flex-col h-[200px] border border-gray-300 mt-5 pt-1 pl-3 w-[95%] mx-auto"
      >
        {treeData && deptStatus !== 'pending' && userModifyStatus !== 'pending' ? (
          <Tree
            ref={treeRef}
            prefixCls={prefixCls}
            className="CUSTOM_TREE bg-white h-full"
            autoExpandParent={autoExpandParent}
            expandedKeys={expandedKeys}
            selectedKeys={selectedKeys}
            multiple={true}
            draggable
            loadData={onLoadData}
            onSelect={onSelect}
            onExpand={onExpand}
            height={treeWrapperHeight}
            treeData={treeData}
            switcherIcon={switcherIconHandler}
            dropIndicatorRender={dropIndicatorRender}
            titleRender={customTitle}
          />
        ) : (
          <Loader color="#1f62b9" isLoading={true} />
        )}
      </div>
      {/* 취소 / 저장 버튼 */}
      <div className="min-h-[5rem] w-full  jcac">
        <div className="flex gap-2">
          <Button kind="cancel" label={t('ClassManagement.classCancel')} onClick={closeClassDepartmentModal} />
          <Button kind="primary" label={t('ClassManagement.classConfirm')} onClick={handleRegistClass} />
        </div>
      </div>
    </div>
  );
};

export default TreeViewV7;
