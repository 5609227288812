import React, { useEffect, useState } from 'react';
import { Route, Routes, useNavigate } from 'react-router-dom';
import { ToastContainer, Slide } from 'react-toastify';
import SpLoginPage from '../login/SpLoginPage';
import SpMainPage from '../main/SpMainPage';
import { user } from './sp_conf';

const SpApp = () => {
  const navigate = useNavigate();

  const [spAuth, setSpAuth] = useState(false);

  useEffect(() => {
    if (sessionStorage.getItem('spAuthKey') !== user.key) {
      navigate('/sp/login');
    }
  }, [spAuth]);

  return (
    <div className="flex flex-col h-screen">
      <Routes>
        <Route
          path="/login"
          element={<SpLoginPage spAuth={spAuth} setSpAuth={setSpAuth} />}
        />
        <Route path="/*" element={<SpMainPage setSpAuth={setSpAuth} />} />
      </Routes>
      <ToastContainer
        position="top-center"
        autoClose={800}
        hideProgressBar
        closeButton={false}
        newestOnTop
        closeOnClick
        draggable={false}
        transition={Slide}
        theme="colored"
      />
    </div>
  );
};

export default SpApp;
