import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  showModal: true, // 모달은 기본적으로 표시
  runJoyride: false, // ReactJoyride는 기본적으로 실행되지 않음
};

export const welcomeModalSlice = createSlice({
  name: 'welcomeModal',
  initialState,
  reducers: {
    setShowModal: (state, action) => {
      state.showModal = action.payload;
    },
    toggleModal: (state) => {
      state.showModal = !state.showModal;
    },
    setRunJoyride: (state, action) => {
      state.runJoyride = action.payload; // ReactJoyride 실행 여부를 제어
    },
  },
});

export const { toggleModal, setShowModal, setRunJoyride } = welcomeModalSlice.actions;

export default welcomeModalSlice.reducer;