import { createSlice } from '@reduxjs/toolkit';

interface InitialState {
  spAuth: boolean;
}

const initialState: InitialState = {
  spAuth: false,
};

export const spAuthSlice = createSlice({
  name: 'spAuth',
  initialState,
  reducers: {
    initSpAuthSlice: () => initialState,
    setSpAuth: (state, action) => {
      state.spAuth = action.payload;
    },
  },
});

export const { initSpAuthSlice, setSpAuth } = spAuthSlice.actions;

export default spAuthSlice.reducer;
