import { t } from 'i18next';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
// 라이브러리
import { SplitPane } from 'react-multi-split-pane';
import { useResizeDetector } from 'react-resize-detector';

// 컴포넌트

import {
  handleViewPortSize,
  PaneProps,
  splitPaneViewportCase,
} from '../../../helpers/h-splitPane';
import { IspTenantListReturnValue_desc_F } from '../../../interfaces/api/service-provider/I-api-admin-serviceProvider-fed';
import TenantMornitoring_tenantList_Table from '../../tables/sp/tenantMornitoring/TenantMornitoring_tenantList_Table';
import {
  adminGraphListApi,
  IadminGraph,
} from '../../../api/admin/api-admin-graph';
import MainPageTitleHeader from '../../common/MainPageTitleHeader';
import axios from 'axios';

interface DownPane extends PaneProps {
  selectedTenant: IspTenantListReturnValue_desc_F | null;
}

interface UpperPane extends PaneProps {
  setSelectedTenant: Dispatch<
    SetStateAction<IspTenantListReturnValue_desc_F | null>
  >;
}

// 테넌트 관리
const TenantMornitor = () => {
  const [splitPaneViewportKey, setSplitPaneViewportKey] = useState(
    splitPaneViewportCase.default,
  );
  const [selectedTenant, setSelectedTenant] =
    useState<IspTenantListReturnValue_desc_F | null>(null);

  return (
    <div className="h-full w-full">
      <SplitPane
        className="custom"
        split="horizontal"
        defaultSizes={[3, 5]}
        minSize={[50, 50]}
      >
        <Upper
          setSplitPaneViewportKey={setSplitPaneViewportKey}
          splitPaneUpdatedState={splitPaneViewportKey}
          setSelectedTenant={setSelectedTenant}
        />
        <Down
          setSplitPaneViewportKey={setSplitPaneViewportKey}
          splitPaneUpdatedState={splitPaneViewportKey}
          selectedTenant={selectedTenant}
        />
      </SplitPane>
    </div>
  );
};

const Upper = ({
  setSplitPaneViewportKey,
  splitPaneUpdatedState,
  setSelectedTenant,
}: UpperPane) => {
  const { width, ref } = useResizeDetector();

  useEffect(() => {
    ref.current.parentElement.style.flex = splitPaneUpdatedState.upper;
  }, [splitPaneUpdatedState]);

  return (
    <div
      ref={ref}
      className="w-full flex flex-col px-6 border border-solid bg-white border-[#dfe2e8] rounded"
    >
      <MainPageTitleHeader
        onClick={() =>
          handleViewPortSize([setSplitPaneViewportKey, 'clickedUpper'])
        }
        text={t('TenantMornitor.tenantList')}
        direction="up"
      />
      <TenantMornitoring_tenantList_Table
        setSelectedTenant={setSelectedTenant}
      />
      {/* <div className="grow pb-2 w-full"></div> */}
    </div>
  );
};

const Down = ({
  setSplitPaneViewportKey,
  splitPaneUpdatedState,
  selectedTenant,
}: DownPane) => {
  const { width, ref } = useResizeDetector();
  const [graphData, setGraphData] = useState<IadminGraph[] | null>(null);

  useEffect(() => {
    ref.current.parentElement.style.flex = splitPaneUpdatedState.down;
  }, [splitPaneUpdatedState]);

  useEffect(() => {
    if (!selectedTenant) return;
    const fetchData = async () => {
      try {
        const res = await adminGraphListApi({
          tenantid: selectedTenant.tenantid,
          graphtype: 0,
          refresh: 10,
          lasthour: 1,
          theme: 'light',
        });
        const graphList = res.data.data.result;

        if (!graphList || graphList.length < 1) return setGraphData(null);
        setGraphData(graphList);
      } catch (error) {
        if (axios.isAxiosError(error)) {
          console.log('error: ', error);
        } else {
          console.log('unexpected error: ', error);
        }
      }
    };
    fetchData();
  }, [selectedTenant]);

  return (
    <div
      ref={ref}
      className="w-full flex flex-col px-6 border border-solid bg-white border-[#dfe2e8] rounded overflow-scroll"
    >
      <MainPageTitleHeader
        onClick={() =>
          handleViewPortSize([setSplitPaneViewportKey, 'clickedDown'])
        }
        text={t('TenantMornitor.graphList')}
        direction="down"
      />
      <GraphListWrapper graphData={graphData} />
    </div>
  );
};

interface IGraphListWrapper {
  graphData: IadminGraph[] | null;
}
export const GraphBox = ({ graphData }: { graphData: IadminGraph }) => {
  return (
    <div className="w-96 h-96 flex flex-col m-auto py-defaultY">
      <iframe
        src={graphData.graphdata}
        frameBorder="0"
        width={'100%'}
        height={'100%'}
      ></iframe>
    </div>
  );
};
export const GraphListWrapper = ({ graphData }: IGraphListWrapper) => {
  return (
    <div className="grow pb-2 w-full flex flex-wrap justify-between">
      {graphData &&
        graphData.map((g) => <GraphBox graphData={g} key={g.graphid} />)}
    </div>
  );
};

export default TenantMornitor;
