export const dummyData = [
  {
    groupCode: 'ORG001',
    groupName: 'UCWARE',
    groupCaption: 'UCWARE',
    domain: 'domain here',
  },
  {
    groupCode: 'ORG001',
    groupName: 'UCWARE',
    groupCaption: 'UCWARE',
    domain: 'domain here',
  },
  {
    groupCode: 'ORG001',
    groupName: 'UCWARE',
    groupCaption: 'UCWARE',
    domain: 'domain here',
  },
  {
    groupCode: 'ORG001',
    groupName: 'UCWARE',
    groupCaption: 'UCWARE',
    domain: 'domain here',
  },
  {
    groupCode: 'ORG001',
    groupName: 'UCWARE',
    groupCaption: 'UCWARE',
    domain: 'domain here',
  },
  {
    groupCode: 'ORG001',
    groupName: 'UCWARE',
    groupCaption: 'UCWARE',
    domain: 'domain here',
  },
  {
    groupCode: 'ORG001',
    groupName: 'UCWARE',
    groupCaption: 'UCWARE',
    domain: 'domain here',
  },
  {
    groupCode: 'ORG001',
    groupName: 'UCWARE',
    groupCaption: 'UCWARE',
    domain: 'domain here',
  },
];
