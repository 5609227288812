import { IpluralAxiosResponse } from './../../../interfaces/api/I-axios';
import { axiosInstance } from '../../axiosInstance';
import {
  IAdminCounselChannelReq,
  IChannel,
  IChannelv2,
} from '../../../interfaces/api/counsel/I-api-admin-counsel-category';
import { IChannelDTO } from '../../../interfaces/api/counsel/I-api-admin-counsel-channel';

const adminCounselChannelInfo = {
  url: {
    list: 'api/v1/admin-mg-tp/counsel/channel/list',
    add: 'api/v1/admin-mg-tp/counsel/channel/add',
    modify: 'api/v1/admin-mg-tp/counsel/channel/modify',
    remove: 'api/v1/admin-mg-tp/counsel/channel/remove',
  },
};

// list
export const counselChannelListApi = (categoryid: string) => {
  const req: IChannelv2 = {
    cmd: '1505',
    orgcode: 'ORG01',
    categoryid: categoryid,
  };

  const { url } = adminCounselChannelInfo;
  return axiosInstance.post<IpluralAxiosResponse<IChannelDTO>>(url.list, req);
};

// add
export const counselChannelAddApi = (data: IChannelv2) => {
  const { url } = adminCounselChannelInfo;
  return axiosInstance.post(url.add, data);
};

// remove
export const counselChannelRemoveApi = (data: IChannel) => {
  const { url } = adminCounselChannelInfo;
  return axiosInstance.post(url.remove, data);
};

// modify
export const counselChannelModifyApi = (data: IAdminCounselChannelReq) => {
  const { url } = adminCounselChannelInfo;
  return axiosInstance.post(url.modify, data);
};

// export const CategoryList = () => {
//   const body = {
//     cmd:'1501',
//     orgCode : "ORG01"
//   };

//   dispatch(modifyDept(body));
// };

// export const addCategory = (categoryName: string) => {
//     if (!categoryName) return;
//     const body = {
//       cmd:'1502',
//       orgCode : "ORG01",
//       name : categoryName,
//     };

//     return modifyDept(body);
//   };

//   export const modifyCategory = (categoryName: string, id:string) => {
//     if (!categoryName || !id) return;
//     const body = {
//       cmd:'1503',
//       orgCode : "ORG01",
//       name : categoryName,
//       id : id
//     };

//     dispatch(modifyDept(body));
//   };

//   export const deleteCategory = (id: string) => {
//     if (!id) return;
//     const body = {
//       cmd:'1504',
//       orgCode : "ORG01"
//       id : id
//     };

//     dispatch(modifyDept(body));
//   };
