import React from 'react';
import ListTitle from '../../common/ListTitle';

const dummyData = new Array(30).fill(null).map(() => ({
  date: 'Int64',
  time: 'sql',
  login: '유씨웨어',
  logout: '유씨웨어',
  chattingUsage: '3036',
  messageUsage: '유씨웨어',
  fileUpload: '유씨웨어',
  fileUploadMB: '215631',
  fileDownload: '...',
  fileDownloadMB: '...',
  // chattingUser: '...',
  // messageSender: '...',
  // fileSender: '...',
}));

const UpperListContainer = () => {
  return (
    <div className="h-full">
      <ListTitle
        titles={[
          '날짜',
          '시간',
          '로그인',
          '로그아웃',
          '채팅 사용',
          '쪽지 사용',
          '파일 업로드',
          '파일 업로드(MB)',
          '파일 다운로드',
          '파일 다운로드(MB)',
          // '채팅 사용자',
          // '쪽지 전송자',
          // '파일 전송자',
        ]}
      />
      <div className="overflow-auto h-full">
        {dummyData.map((data, i) => (
          <List data={data} key={i} index={i} />
        ))}
      </div>
    </div>
  );
};

interface Data {
  date: string;
  time: string;
  login: string;
  logout: string;
  chattingUsage: string;
  messageUsage: string;
  fileUpload: string;
  fileUploadMB: string;
  fileDownload: string;
  fileDownloadMB: string;
  // chattingUser: string;
  // messageSender: string;
  // fileSender: string;
}

type Props = {
  data: Data;
  index: number;
};

const List: React.FC<Props> = ({ data, index }) => {
  // const dataLength = Object.keys(data).length;
  const dataValue = Object.values(data);
  // const gridCols = `grid-cols-${dataLength}`;
  // console.log(
  //   `dataLength: ${dataLength}, dataValue: ${typeof dataValue}, grid: ${gridCols}`,
  // );

  return (
    <div
      className={`grid grid-cols-10 border-b border-solid py-[0.813rem] border-[#dfe2e8] ${
        index < 1 ? 'bg-[#f1f3f6]' : ''
      }`}
    >
      {dataValue.map((data, i) => (
        <div key={i} className="text-center">
          {data}
        </div>
      ))}
      {/* <div className="text-center">{data.date}</div>
      <div className="text-center">{data.time}</div>
      <div className="text-center">{data.login}</div>
      <div className="text-center">{data.logout}</div>
      <div className="text-center">{data.chattingUsage}</div>
      <div className="text-center">{data.messageUsage}</div>
      <div className="text-center">{data.fileUpload}</div>
      <div className="text-center">{data.fileUploadMB}</div>
      <div className="text-center">{data.fileDownload}</div>
      <div className="text-center">{data.fileDownloadMB}</div>
      <div className="text-center">{data.chattingUser}</div>
      <div className="text-center">{data.messageSender}</div>
      <div className="text-center">{data.fileSender}</div> */}
    </div>
  );
};

export default UpperListContainer;
