import { useRef } from 'react';
import Button from '../common/buttons/Button';
import Modal from 'react-modal';
import { t } from 'i18next';

const customStyles = {
  overlay: {
    zIndex: 9999,
  },
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    minWidth: '21.875rem',
    padding: 0,
    // minHeight: '12.25rem',
  },
};

interface Props {
  label: string;
  isOpen: boolean;
  onConfirm: () => void;
  onClose: () => void;
}

const BasicReactModal = ({
  label,
  isOpen = false,
  onConfirm,
  onClose,
}: Props) => {
  const subTitle = useRef<HTMLHeadingElement | null>(null);

  function afterOpenModal() {
    if (subTitle.current === null) return;
    // references are now sync'd and can be accessed.
    // console.log(subTitle);
    subTitle.current.style.color = 'red';
    // subTitle.current.style.color = '#f00';
  }

  return (
    <div>
      <Modal
        isOpen={isOpen}
        onAfterOpen={afterOpenModal}
        onRequestClose={onClose}
        style={customStyles}
        contentLabel="Example Modal"
      >
        <div
          className="flex flex-col h-full px-3
            relative border border-modal-subTitle bg-white z-50 shadow-listbox-options
            rounded
          "
        >
          {/* HEADER */}
          <div className="min-h-[3.5rem] w-full flex flex-col relative">
            {/* <div className="grow font-bold w-full flex items-center truncate">
                페더레이션 신청
              </div> */}
          </div>
          {/* BODY */}
          <div className="grow w-full jcac gap-3 overflow-y-auto text-sm">
            {label}
          </div>
          {/* FOOTER */}
          <div className="min-h-[6rem] w-full jcac gap-2">
            <Button kind="cancel" label={t('ContextMenu.treeContextMenu.cancel')} onClick={onClose} />
            <Button kind="primary" label={t('ContextMenu.treeContextMenu.ok')} onClick={onConfirm} />
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default BasicReactModal;
