import React from 'react';

const TfaSms = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="60"
      height="60"
      viewBox="0 0 60 60"
    >
      <path style={{ fill: 'none' }} d="M0 0h60v60H0z" />
      <g data-name="ico_sms">
        <path
          data-name="합치기 16"
          d="m10.152 31.142-.993-6.568H2a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2h28a2 2 0 0 1 2 2v20.574a2 2 0 0 1-2 2H16.687l-4.745 6.991a.961.961 0 0 1-.805.435.99.99 0 0 1-.985-.858z"
          transform="translate(14 14)"
          style={{
            stroke: '#1f62b9',
            strokeLinejoin: 'round',
            strokeWidth: '2px',
            fill: 'none',
          }}
        />
        <text
          transform="translate(30.344 30.826)"
          style={{
            fill: '#1f62b9',
            fontSize: '11px',
            fontFamily: 'Roboto-Bold,Roboto',
            fontWeight: '700',
          }}
        >
          <tspan x="-11.58" y="0">
            SMS
          </tspan>
        </text>
      </g>
    </svg>
  );
};

export default TfaSms;
