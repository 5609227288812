import {
  IAdminOrgAddApiBody,
  IAdminOrgModifyApiBody,
  IAdminOrgRemoveApiBody,
  IAdminOrgsAddApiBody,
} from '../../../interfaces/api/org/i-api-admin-org';
import { axiosInstance } from '../../axiosInstance';

const adminOrgInfo = {
  url: {
    add: 'api/v1/admin-mg-tp/org/add',
    import: 'api/v1/admin-mg-tp/org/import',
    remove: 'api/v1/admin-mg-tp/org/remove',
    modify: 'api/v1/admin-mg-tp/org/modify',
  },
};

// add
export const adminOrgAddApi = (
  props: IAdminOrgAddApiBody | IAdminOrgsAddApiBody,
) => {
  const { url } = adminOrgInfo;
  return axiosInstance.post(url.add, props);
};

// import
export const adminOrgImportApi = (
  props: IAdminOrgAddApiBody | IAdminOrgsAddApiBody,
) => {
  const { url } = adminOrgInfo;
  return axiosInstance.post(url.import, props);
};

// remove
export const adminOrgRemoveApi = (props: IAdminOrgRemoveApiBody) => {
  const { url } = adminOrgInfo;
  return axiosInstance.post(url.remove, props);
};

// modify
export const adminOrgModifyApi = (props: IAdminOrgModifyApiBody) => {
  const { url } = adminOrgInfo;
  return axiosInstance.post(url.modify, props);
};
