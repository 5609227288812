import { ColDef } from 'ag-grid-community';
import { dateValueGetter } from '../../../helpers/h-agGrid';
import { billingHistory } from '../../../interfaces/I-ag-column';
import { formatToDateTime } from '../../../utils/dateTimeHandler';
import { AgCellTextCenter } from '../../common/AgCustomCell';

//export const WorkspaceBillingHistory_column: ColDef<billingHistory>[] = [
export const WorkspaceBillingHistory_column  = (t: (key: string) => string) => {
  return [
    {
      field: 'licenseType',
      headerName: t('ServiceMornitoring.h-rightLicenseType'),
      // headerComponent: AgHeaderTextCenter,
      cellRenderer: AgCellTextCenter<billingHistory>,
    },
    {
      field: 'price',
      headerName: t('ServiceMornitoring.h-rightPrice'),
      // headerComponent: AgHeaderTextCenter,
      cellRenderer: AgCellTextCenter<billingHistory>,
    },
    // {
    //   field: 'licenseCount',
    //   headerName: t('ServiceMornitoring.h-rightLicenseCount'),
    //   // headerComponent: AgHeaderTextCenter,
    //   cellRenderer: AgCellTextCenter<billingHistory>,
    // },
    // {
    //   field: 'orderCycle',
    //   headerName: t('ServiceMornitoring.h-rightOrderCycle'),
    //   // headerComponent: AgHeaderTextCenter,
    //   cellRenderer: AgCellTextCenter<billingHistory>,
    // },
    {
      field: 'orderDate',
      headerName: t('ServiceMornitoring.h-rightOrderDate'),
      // headerComponent: AgHeaderTextCenter,
      cellRenderer: AgCellTextCenter<billingHistory>,
      valueGetter: (params: { data: { orderDate: string | any[]; }; }) => {
        if (!params.data) return;
        if (!params.data.orderDate) return;
        const year = params.data.orderDate.slice(0, 4);
        const month = params.data.orderDate.slice(5, 7);
        const day = params.data.orderDate.slice(8, 10);
        const hour = params.data.orderDate.slice(11, 13);
        const min = params.data.orderDate.slice(14, 16);
        const sec = params.data.orderDate.slice(17, 19);
        const dateFormatted = `${year}-${month}-${day} ${hour}:${min}:${sec}`;
        return dateFormatted;
      },
    },
  ];
};
