import MainPageTitleHeader from '../../common/MainPageTitleHeader';
import MainPageTitleHeaderDesc from '../../common/MainPageTitleHeaderDesc';
import CounselingMain from './CounselingMain';
import { t } from 'i18next';

const CounselingManagement = () => {
  return (
    <div className="w-full h-full px-[1.5rem] flex flex-col border border-solid border-[#dfe2e8] bg-white  rounded">
      <MainPageTitleHeader text={t('CounselingManagement.counselingTitle') as string} textOnly />
      <MainPageTitleHeaderDesc
        text={
          <>
            {t('CounselingManagement.counselingDesc1') as string} <br />
            {t('CounselingManagement.counselingDesc2') as string}
          </>
        }
      />
      <CounselingMain />
    </div>
  );
};

export default CounselingManagement;
