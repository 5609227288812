import React, { ChangeEvent, useEffect, useRef, useState } from 'react';
import Modal from 'react-modal';
import { closeAdminIdMngUserList_Table } from '../../../helpers/h-react-modals';
import { useAppSelector } from '../../../redux/hooks';
import LeftTopBar from '../../mainPage/admin_id_management/LeftTopBar';
import { AgGridReact } from 'ag-grid-react';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import { useCallback, useMemo } from 'react';
import { IUserInfo } from '../../../interfaces/api/I-api-hrs-users';
import { ColDef, GridApi, GridReadyEvent, IDatasource, SelectionChangedEvent } from 'ag-grid-community';
import { adminUserListApi } from '../../../api/admin/api-admin-user';
import { USER_LIST_TALBLE_COLUMN } from '../../tables/adminIdMng/columns';

const customStyles = {
  overlay: {
    zIndex: 99,
    //backgroundColor: 'transparent'
  },
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    width: '1000px',
    height: '600px',
    padding: 0,
  },
};

interface Props {
  isOpen: boolean;
}

const userListReactModal = ({ isOpen = false }: Props) => {
  // const {
  //   orgInfo: { contextDeptInfo },
  // } = useAppSelector((state) => state.organizationUserManagement);

  const { adminRoleStatus } = useAppSelector((state) => state.adminIdManagement);

  const [selectedUsers, setSelectedUsers] = useState<IUserInfo[]>([]);

  // AG_GRID
  const [gridApi, setGridApi] = useState<GridApi<IUserInfo[]>>();
  const gridRef = useRef<any>();

  const { t } = useTranslation();
  const [columnDefs, setColumnDefs] = useState<ColDef[]>([]);
  const defaultColDef = useMemo<ColDef>(() => {
    return {
      flex: 1,
      resizable: true,
      minWidth: 100,
    };
  }, []);
  const onSelectionChanged = useCallback((props: SelectionChangedEvent<IUserInfo>) => {
    // const selectedRows = gridRef.current.api.getSelectedRows();
    const selectedRows = props.api.getSelectedRows();
    setSelectedUsers(selectedRows);
  }, []);

  // 테이블 인피니트 스크롤용
  let page = 1;
  const dataSource: IDatasource = {
    rowCount: undefined,
    getRows: (params) => {
      adminUserListApi({ page })
        .then((resp) => ({
          userList: resp?.data.data.result,
          totpage: resp?.data.data.totpage,
          totcount: resp?.data.data.totcount,
        }))
        .then((data) => {
          if (!data) return;
          if (!data.totcount || !data.totpage || !data.userList) return;
          const lastPage = page < data.totpage ? 50 * page + 1 : parseInt(data.totcount);
          params.successCallback(data.userList, lastPage);
          page++;
        })
        .catch((error) => {
          if (axios.isAxiosError(error)) {
            console.log('error: ', error);
          } else {
            console.log('unexpected error: ', error);
          }
        });
    },
  };

  const onGridReady = (params: GridReadyEvent<IUserInfo[]>) => {
    setGridApi(params.api);
    params.api.setDatasource(dataSource);
  };

  useEffect(() => {
    if (gridApi) {
      gridApi.deselectAll();
      gridApi.setDatasource(dataSource);
    }
  }, [gridApi]);

  useEffect(() => {
    setColumnDefs(USER_LIST_TALBLE_COLUMN(t));
  }, [t]);

  useEffect(() => {
    if (adminRoleStatus === 'success' && gridApi) {
      gridApi.deselectAll();
      gridApi.setDatasource(dataSource);
      closeAdminIdMngUserList_Table();
    }
  }, [adminRoleStatus, gridApi]);

  return (
    <Modal
      isOpen={isOpen}
      //onAfterOpen={afterOpenModal}
      onRequestClose={closeAdminIdMngUserList_Table}
      style={customStyles}
      contentLabel="Example Modal"
    >
      <div className="w-950px grow flex flex-col overflow-hidden mx-auto">
        <LeftTopBar gridApi={gridApi} selectedUsers={selectedUsers} setSelectedUsers={setSelectedUsers} />
        <div className="ag-theme-alpine ag-theme-mycustomtheme grow w-full h-480">
          <AgGridReact
            ref={gridRef}
            columnDefs={columnDefs}
            defaultColDef={defaultColDef}
            rowModelType={'infinite'}
            cacheBlockSize={50}
            onGridReady={onGridReady}
            rowMultiSelectWithClick
            rowSelection={'multiple'}
            onSelectionChanged={onSelectionChanged}
            //overlayNoRowsTemplate={'\xa0'}
            //overlayNoRowsTemplate="<div class='ag-overlay-loading-center'>데이터가 없습니다.</div>"
          ></AgGridReact>
        </div>
      </div>
    </Modal>
  );
};

export default userListReactModal;
