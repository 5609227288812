import { t } from 'i18next';
import { modifyUserHandlerV2 } from '../../helpers/h-orgUserMng';
import { useAppSelector } from '../../redux/hooks';

interface Props {
  x: number;
  y: number;
  isRoot?: boolean;
}
const TreeUserContextMenu = ({ x = 0, y = 0 }: Props) => {
  const liStyles =
    'bg-white hover:bg-[#f1f3f7] py-2 m-1 flex justify-center items-center cursor-pointer border-b border-black border-solid';
  const { contextUserInfo } = useAppSelector((state) => state.organizationUserManagement.orgInfo);

  //부서 할당 제거
  const removeAffiliatedDepartment = async () => {
    if (!contextUserInfo) return;
    //여기 수정
    //userorder는 어떻게 되는지?
    // add/modify로 유저 추가 혹은 소속 부서 변경시
    // userorder는 제일 하위로 들어가야함
    // console.log(contextUserInfo);
    const user = {
      // ...contextUserInfo,
      // usernameKo: contextUserInfo.username,
      // usernameEn: contextUserInfo.username,
      // userpwd: '',
      // userpwdhashtype: 'SHA256',
      // deptcode: '',
      // deptname: '',
      bid: contextUserInfo.bid,
      deptcode: contextUserInfo.deptcode,
    };

    modifyUserHandlerV2(user);
  };

  return (
    <div
      className="py-3 px-1 min-w-[150px] my-1 border border-black border-solid bg-white rounded text-sm z-50"
      style={{
        position: 'fixed',
        left: x,
        top: y,
      }}
    >
      <ul className="m-0 p-1">
        <li className={`${liStyles} border-none mb-0`} onClick={removeAffiliatedDepartment}>
          {t('UserContextMenu.treeContextMenu.delete') as string}
        </li>
      </ul>
    </div>
  );
};

export default TreeUserContextMenu;
