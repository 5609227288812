import { ErrorMessage, Field, Form, Formik, FormikProps } from 'formik';
import { useState } from 'react';
import Draggable from 'react-draggable';
import Button from '../../common/buttons/Button';
import InputTextFormik from '../../common/inputs/InputTextFormik';
import SelectListboxFormik from '../../common/selectList/SelectListboxFormik';
import {
  patchFileDevices,
  patchFileProduct,
} from '../../../const/sp/upgradeManagement';
import { CustomCell } from '../../../helpers/h-formik';
//import { clientPatchUploadSchema } from '../../../helpers/h-formSchema';
import { SelectListValue } from '../../../interfaces/TypeSelectListbox';
import { useAppDispatch } from '../../../redux/hooks';
import { clientPatchUpload } from '../../../redux/slice/sp/upgradeManagementSlice';
import FormikError from '../../common/FormikError';

interface Props {
  show: boolean;
  setShow: () => void;
  title: string;
  tenantid: string;
}
const initSelectedOption = {
  id: '',
  name: '',
};
const UpgradeMng_patchUpload_modal = ({
  show,
  setShow,
  title,
  tenantid,
}: Props) => {
  const dispatch = useAppDispatch();
  const [productOptions, setProductOptions] = useState<SelectListValue[]>(
    Object.entries(patchFileProduct).map(([k, v]) => ({
      id: k,
      name: v,
    })),
  );
  const [selectedProduct, setSelectedProduct] =
    useState<SelectListValue>(initSelectedOption);
  const [deviceOptions, setDeviceOptions] = useState<SelectListValue[]>(
    Object.entries(patchFileDevices).map(([k, v]) => ({
      id: v,
      name: k,
    })),
  );
  const [selectedDevice, setSelectedDevice] =
    useState<SelectListValue>(initSelectedOption);

  const clearOptions = () => {
    setSelectedProduct(initSelectedOption);
    setSelectedDevice(initSelectedOption);
  };

  if (show) {
    return (
      <Draggable handle="#handle" positionOffset={{ x: '-50%', y: '-50%' }}>
        <div className="z-[999] min-w-[500px] min-h-[550px] fixed bg-white left-[50%] top-[50%] flex flex-col shadow-listbox-options rounded border border-solid border-borderLight">
          {/* HEADER */}
          <div
            id="handle"
            className="cursor-move py-defaultY px-defaultX font-bold"
          >
            <div className="py-defaultY">{title}</div>
          </div>
          {/* BODY */}
          <Formik
            initialValues={{
              ver: '',
              product: '',
              dev: '',
              subdev: '',
              envFile: null,
            }}
            //validationSchema={clientPatchUploadSchema}
            onSubmit={async (values) => {
              // alert(JSON.stringify(values, null, 2));
              // console.log(values);
              if (values.envFile) {
                const file = new FormData();
                file.append('envFile', values.envFile);
                const BODY = {
                  tenantid,
                  ver: values.ver,
                  product: values.product,
                  dev: values.dev,
                  subdev: values.subdev,
                  envFile: file,
                };
                dispatch(clientPatchUpload(BODY));
                clearOptions();
                setShow();
              }
            }}
          >
            {(formik: FormikProps<any>) => {
              return (
                <Form className="grow px-defaultX w-full flex flex-col justify-between overflow-y-auto">
                  {/* 텍스트 */}
                  <div className="grow w-full flex flex-col gap-3 overflow-y-auto">
                    <CustomCell
                      title="버전"
                      required
                      content={
                        <Field
                          component={InputTextFormik}
                          name={`ver`}
                          value={`ver`}
                          handleClear={() => {
                            formik.setFieldValue(`ver`, '');
                          }}
                        />
                      }
                    />
                    {/* 셀렉트 */}
                    <CustomCell
                      required
                      title="제품 코드"
                      content={
                        <Field
                          component={SelectListboxFormik}
                          options={productOptions}
                          selectedOption={selectedProduct}
                          name="product"
                          value="product"
                          optionsHeight={12}
                          width={'100%'}
                          onChange={(value: SelectListValue) => {
                            setSelectedProduct(value);
                            formik.setFieldValue(`product`, value.id);
                          }}
                        />
                      }
                    />
                    <CustomCell
                      required
                      title="기기 종류"
                      content={
                        <Field
                          component={SelectListboxFormik}
                          options={deviceOptions}
                          selectedOption={selectedDevice}
                          name="dev"
                          value="dev"
                          optionsHeight={12}
                          width={'100%'}
                          onChange={(value: SelectListValue) => {
                            setSelectedDevice(value);
                            const dev = value.id.split('_')[0];
                            const subdev = value.id.split('_')[1];
                            formik.setFieldValue(`dev`, dev);
                            formik.setFieldValue(`subdev`, subdev);
                          }}
                        />
                      }
                    />
                    <Field name="envFile">
                      {({
                        field: { name, value, onChange, onBlur }, // { name, value, onChange, onBlur }
                        form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
                        meta,
                      }: any) => (
                        <div>
                          <input
                            type="file"
                            name={name}
                            onChange={(event) => {
                              console.log(event);
                              if (event.currentTarget.files) {
                                formik.setFieldValue(
                                  'envFile',
                                  event.currentTarget.files[0],
                                );
                              }
                            }}
                          />
                          <ErrorMessage
                            {...{ name, value, onChange, onBlur }}
                            component={FormikError}
                          />
                        </div>
                      )}
                    </Field>
                  </div>
                  {/* 취소 / 저장 버튼 */}
                  <div className="min-h-[6rem] w-full  jcac">
                    <div className="flex gap-5">
                      <Button
                        onClick={() => {
                          clearOptions();
                          setShow();
                        }}
                        kind="cancel"
                        label="취소"
                      />
                      <Button
                        onClick={() => {
                          formik.handleSubmit();
                          // setShow();
                        }}
                        kind="primary"
                        label="저장"
                      />
                    </div>
                  </div>
                </Form>
              );
            }}
          </Formik>
          {/* 버튼 */}
          {/* <div className="jcac py-defaultY">
            <Button label="확인" onClick={setShow} kind="primary" />
          </div> */}
        </div>
      </Draggable>
    );
  }

  return null;
};

export default UpgradeMng_patchUpload_modal;
