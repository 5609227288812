import { IpluralAxiosResponse } from './../../../interfaces/api/I-axios';
import { getMyTenantId, getOrgCode } from '../../../helpers/h-userInfo';
import {
  IadminRuleCode,
  IadminRuleCodeAddApiBody,
  IadminRuleCodeInfoApiBody,
  IadminRuleCodeListApiBody,
  IadminRuleCodeModifyApiBody,
  IadminRuleCodeRemoveApiBody,
} from '../../../interfaces/api/rule/I-api-admin-rule-code';
import { axiosInstance } from '../../axiosInstance';

const adminRuleCodeInfo = {
  url: {
    list: '/api/v1/admin-mg-tp/rule/code/list',
    add: '/api/v1/admin-mg-tp/rule/code/add',
    info: '/api/v1/admin-mg-tp/rule/code/info',
    modify: '/api/v1/admin-mg-tp/rule/code/modify',
    remove: '/api/v1/admin-mg-tp/rule/code/remove',
  },
};

// list
export const adminRuleCodeListApi = (props?: IadminRuleCodeListApiBody) => {
  const { url } = adminRuleCodeInfo;
  return axiosInstance.post<IpluralAxiosResponse<IadminRuleCode>>(url.list, {
    tenantid: getMyTenantId(),
    orgcode: getOrgCode(),
    pagesize: 50,
    page: 1,
    ...props,
  });
};

// add
export const adminRuleCodeAddApi = (props: IadminRuleCodeAddApiBody) => {
  const { url } = adminRuleCodeInfo;
  return axiosInstance.post(url.add, {
    tenantid: getMyTenantId(),
    orgcode: getOrgCode(),
    ...props,
  });
};

// remove
export const adminRuleCodeRemoveApi = (props: IadminRuleCodeRemoveApiBody) => {
  const { url } = adminRuleCodeInfo;
  return axiosInstance.post(url.remove, {
    tenantid: getMyTenantId(),
    orgcode: getOrgCode(),
    ...props,
  });
};

// modify
export const adminRuleCodeModifyApi = (props: IadminRuleCodeModifyApiBody) => {
  const { url } = adminRuleCodeInfo;
  return axiosInstance.post(url.modify, props);
};

// info
export const adminRuleCodeInfoApi = async (
  props: IadminRuleCodeInfoApiBody,
) => {
  const { url } = adminRuleCodeInfo;
  return axiosInstance.post(url.info, props);
};
