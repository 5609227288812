import { toast } from 'react-toastify';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
  adminRuleCodeAddApi,
  adminRuleCodeModifyApi,
  adminRuleCodeRemoveApi,
} from '../../api/admin/rule/api-admin-rule-code';
import {
  adminRuleFuncAddApi,
  adminRuleFuncModifyApi,
  adminRuleFuncRemoveApi,
} from '../../api/admin/rule/api-admin-rule-func';
import {
  adminRuleFuncHubAddtApi,
  adminRuleFuncHubModifyApi,
  adminRuleFuncHubRemovetApi,
} from '../../api/admin/rule/api-admin-rule-func-hub';
import {
  adminRuleUserAddApi,
  adminRuleUserRemoveApi,
} from '../../api/admin/rule/api-admin-rule-user';
import {
  IadminRuleCode,
  IadminRuleCodeAddApiBody,
  IadminRuleCodeModifyApiBody,
  IadminRuleCodeRemoveApiBody,
} from '../../interfaces/api/rule/I-api-admin-rule-code';
import {
  IadminRuleFunc,
  IadminRuleFuncAddApiBody,
  IadminRuleFuncHunListInfo,
  IadminRuleFuncModifyApiBody,
  IadminRuleFuncRemoveApiBody,
} from '../../interfaces/api/rule/I-api-admin-rule-func';
import {
  IadminRulefuncHub,
  IadminRuleFuncHubAddApiBody,
  IadminRuleFuncHubModifyApiBody,
  IadminRuleFuncHubRemoveApiBody,
  IruleFuncHubOption,
} from '../../interfaces/api/rule/I-api-admin-rule-func-hub';
import {
  IadminRuleUserAddApiBody,
  IadminRuleUserRemoveApiBody,
} from '../../interfaces/api/rule/I-api-admin-rule-user';
import axios from 'axios';

interface InitialState {
  // rule/code (규칙)
  ruleCodeList: IadminRuleCode[];
  selectedRuleCode: IadminRuleCode;
  selectedRuleCodeModal: IadminRuleCode | null;
  // rule/func (기능)
  ruleFuncList: IadminRuleFunc[];
  selectedRuleFunc: IadminRuleFunc;
  // rule/func/hub
  ruleFuncHubList: IadminRulefuncHub[];
  selectedRuleFuncHub: IadminRuleFuncHubModifyApiBody;
  //rule/user
  addedUsersBids: string[] | null;
  // rule/func/hub/options
  selectedRuleFuncHubOption: IadminRuleFuncHunListInfo;
  // status
  //    ruleCode
  ruleCodeStatus: 'idle' | 'pending' | 'success' | 'fail';
  ruleCodeAddStatus: 'idle' | 'pending' | 'success' | 'fail';
  ruleCodeModifyStatus: 'idle' | 'pending' | 'success' | 'fail';
  ruleCodeRemoveStatus: 'idle' | 'pending' | 'success' | 'fail';
  //    ruleFunc
  ruleFuncStatus: 'idle' | 'pending' | 'success' | 'fail';
  ruleFuncAddStatus: 'idle' | 'pending' | 'success' | 'fail';
  ruleFuncModifyStatus: 'idle' | 'pending' | 'success' | 'fail';
  ruleFuncRemoveStatus: 'idle' | 'pending' | 'success' | 'fail';
  //    ruleFuncHub
  ruleFuncHubStatus: 'idle' | 'pending' | 'success' | 'fail';
  ruleFuncHubAddStatus: 'idle' | 'pending' | 'success' | 'fail';
  ruleFuncHubModifyStatus: 'idle' | 'pending' | 'success' | 'fail';
  ruleFuncHubRemoveStatus: 'idle' | 'pending' | 'success' | 'fail';
  //    ruleUser
  ruleUserStatus: 'idle' | 'pending' | 'success' | 'fail';
  ruleUserAddStatus: 'idle' | 'pending' | 'success' | 'fail';
  ruleUserRemoveStatus: 'idle' | 'pending' | 'success' | 'fail';
}

const initialState: InitialState = {
  // rule/code
  ruleCodeList: [],
  selectedRuleCode: {
    tenantid: '',
    orgcode: '',
    rulecode: '',
    rulename: '',
    ruleorder: 0,
    updatetime: '',
  },
  selectedRuleCodeModal: null,
  // rule/func
  ruleFuncList: [],
  selectedRuleFunc: {
    tenantid: '',
    orgcode: '',
    funccode: '',
    funckind: '',
    funcname: '',
    allowdup: 0,
    funcfield1: '',
    funcfield2: '',
    defaultvalue: '',
    updatetime: '',
  },
  // rule/func/hub
  ruleFuncHubList: [],
  selectedRuleFuncHub: {
    rulefunckey: '',
    rulecode: '',
    funccode: '',
    funcvalue1: ' ',
    funcvalue2: ' ',
    funcorder: 0,
  },
  // rule/user
  addedUsersBids: null,
  // rule/func/hub/option
  selectedRuleFuncHubOption: {
    funckind: '',
    funcname: '',
    funcfield1: '',
    funcfield2: '',
    defaultvalue: '',
    rulefunckey: '',
    funcvalue1: '',
    funcvalue2: '',
    rulecode: '',
    funccode: '',
    funcorder: 0,
  },
  // status
  //    ruleCode
  ruleCodeStatus: 'idle',
  ruleCodeAddStatus: 'idle',
  ruleCodeModifyStatus: 'idle',
  ruleCodeRemoveStatus: 'idle',
  //    ruleFunc
  ruleFuncStatus: 'idle',
  ruleFuncAddStatus: 'idle',
  ruleFuncModifyStatus: 'idle',
  ruleFuncRemoveStatus: 'idle',
  //    ruleFuncHub
  ruleFuncHubStatus: 'idle',
  ruleFuncHubAddStatus: 'idle',
  ruleFuncHubModifyStatus: 'idle',
  ruleFuncHubRemoveStatus: 'idle',
  //    ruleUser
  ruleUserStatus: 'idle',
  ruleUserAddStatus: 'idle',
  ruleUserRemoveStatus: 'idle',
};

// rule/code (규칙)
// add
export const addRuleCode = createAsyncThunk(
  'ruleCode/add',
  async (rule: IadminRuleCodeAddApiBody, { rejectWithValue }) => {
    try {
      const response = await adminRuleCodeAddApi(rule);
      toast.success('규칙 추가 성공');
      return response?.data;
    } catch (error) {
      toast.error('규칙 추가 실패');
      if (axios.isAxiosError(error)) {
        console.log('error: ', error);
        return rejectWithValue(error.message);
      } else {
        console.log('unexpected error: ', error);
        return rejectWithValue('unexpected error');
      }
    }
  },
);
// remove
export const removeRuleCode = createAsyncThunk(
  'ruleCode/remove',
  async (rule: IadminRuleCodeRemoveApiBody, { rejectWithValue }) => {
    try {
      const response = await adminRuleCodeRemoveApi(rule);
      toast.success('규칙 삭제 성공');
      return response?.data;
    } catch (error) {
      toast.error('규칙 삭제 실페');
      if (axios.isAxiosError(error)) {
        console.log('error: ', error);
        return rejectWithValue(error.message);
      } else {
        console.log('unexpected error: ', error);
        return rejectWithValue('unexpected error');
      }
    }
  },
);
// modify
export const modifyRuleCode = createAsyncThunk(
  'ruleCode/modify',
  async (rule: IadminRuleCodeModifyApiBody, { rejectWithValue }) => {
    try {
      const response = await adminRuleCodeModifyApi(rule);
      toast.success('규칙 수정 성공');
      return response?.data;
    } catch (error) {
      toast.error('규칙 수정 실패');
      if (axios.isAxiosError(error)) {
        console.log('error: ', error);
        return rejectWithValue(error.message);
      } else {
        console.log('unexpected error: ', error);
        return rejectWithValue('unexpected error');
      }
    }
  },
);

// rule/func (기능)
// add
export const addRuleFunc = createAsyncThunk(
  'ruleFunc/add',
  async (rule: IadminRuleFuncAddApiBody, { rejectWithValue }) => {
    try {
      const response = await adminRuleFuncAddApi(rule);
      return response?.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.log('error: ', error);
        return rejectWithValue(error.message);
      } else {
        console.log('unexpected error: ', error);
        return rejectWithValue('unexpected error');
      }
    }
  },
);
// remove
export const removeRuleFunc = createAsyncThunk(
  'ruleFunc/remove',
  async (rule: IadminRuleFuncRemoveApiBody, { rejectWithValue }) => {
    try {
      const response = await adminRuleFuncRemoveApi(rule);
      return response?.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.log('error: ', error);
        return rejectWithValue(error.message);
      } else {
        console.log('unexpected error: ', error);
        return rejectWithValue('unexpected error');
      }
    }
  },
);
// modify
export const modifyRuleFunc = createAsyncThunk(
  'ruleFunc/modify',
  async (rule: IadminRuleFuncModifyApiBody, { rejectWithValue }) => {
    try {
      const response = await adminRuleFuncModifyApi(rule);
      return response?.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.log('error: ', error);
        return rejectWithValue(error.message);
      } else {
        console.log('unexpected error: ', error);
        return rejectWithValue('unexpected error');
      }
    }
  },
);

// rule/func/hub (규칙에 기능 추가/삭제/수정)
// add
export const addRuleFuncHub = createAsyncThunk(
  'ruleFuncHub/add',
  async (rule: IadminRuleFuncHubAddApiBody, { rejectWithValue }) => {
    try {
      const response = await adminRuleFuncHubAddtApi(rule);
      return response?.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.log('error: ', error);
        return rejectWithValue(error.message);
      } else {
        console.log('unexpected error: ', error);
        return rejectWithValue('unexpected error');
      }
    }
  },
);
// remove
export const removeRuleFuncHub = createAsyncThunk(
  'ruleFuncHub/remove',
  async (rule: IadminRuleFuncHubRemoveApiBody, { rejectWithValue }) => {
    try {
      const response = await adminRuleFuncHubRemovetApi(rule);
      return response?.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.log('error: ', error);
        return rejectWithValue(error.message);
      } else {
        console.log('unexpected error: ', error);
        return rejectWithValue('unexpected error');
      }
    }
  },
);
// modify
export const modifyRuleFuncHub = createAsyncThunk(
  'ruleFuncHub/modify',
  async (rule: IadminRuleFuncHubModifyApiBody, { rejectWithValue }) => {
    try {
      const response = await adminRuleFuncHubModifyApi(rule);
      return response?.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.log('error: ', error);
        return rejectWithValue(error.message);
      } else {
        console.log('unexpected error: ', error);
        return rejectWithValue('unexpected error');
      }
    }
  },
);

// rule/user ( 규칙에 사용자 추가/삭제)
// add
export const addRuleUser = createAsyncThunk(
  'ruleUser/add',
  async (body: IadminRuleUserAddApiBody[], { rejectWithValue }) => {
    try {
      const response = await adminRuleUserAddApi(body);
      return response?.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.log('error: ', error);
        return rejectWithValue(error.message);
      } else {
        console.log('unexpected error: ', error);
        return rejectWithValue('unexpected error');
      }
    }
  },
);
// remove
export const removeRuleUser = createAsyncThunk(
  'ruleUser/remove',
  async (body: IadminRuleUserRemoveApiBody[], { rejectWithValue }) => {
    try {
      const response = await adminRuleUserRemoveApi(body);
      return response?.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.log('error: ', error);
        return rejectWithValue(error.message);
      } else {
        console.log('unexpected error: ', error);
        return rejectWithValue('unexpected error');
      }
    }
  },
);

export const ruleFunctionManagementSlice = createSlice({
  name: 'ruleFunctionManagement',
  initialState,
  reducers: {
    initRuleFuncMngSlice: () => initialState,
    initRuleFuncMngStatus: (state) => {
      (state.ruleCodeStatus = 'idle'),
        (state.ruleCodeAddStatus = 'idle'),
        (state.ruleCodeModifyStatus = 'idle'),
        (state.ruleCodeRemoveStatus = 'idle'),
        //    ruleFunc
        (state.ruleFuncStatus = 'idle'),
        (state.ruleFuncAddStatus = 'idle'),
        (state.ruleFuncModifyStatus = 'idle'),
        (state.ruleFuncRemoveStatus = 'idle'),
        //    ruleFuncHub
        (state.ruleFuncHubStatus = 'idle'),
        (state.ruleFuncHubAddStatus = 'idle'),
        (state.ruleFuncHubModifyStatus = 'idle'),
        (state.ruleFuncHubRemoveStatus = 'idle'),
        //    ruleUser
        (state.ruleUserStatus = 'idle'),
        (state.ruleUserAddStatus = 'idle'),
        (state.ruleUserRemoveStatus = 'idle');
    },
    // rule/code
    setRuleCodeList: (
      state,
      { payload: ruleCodeList }: { payload: IadminRuleCode[] },
    ) => {
      state.ruleCodeList = ruleCodeList;
    },
    setSelectedRuleCode: (
      state,
      { payload: ruleCode }: { payload: IadminRuleCode },
    ) => {
      state.selectedRuleCode = ruleCode;
    },
    initSelectedRuleCode: (state) => {
      state.selectedRuleCode = initialState.selectedRuleCode;
    },
    setSelectedRuleCodeModal: (
      state,
      { payload: ruleCode }: { payload: IadminRuleCode },
    ) => {
      state.selectedRuleCodeModal = ruleCode;
    },
    initSelectedRuleCodeModal: (state) => {
      state.selectedRuleCodeModal = initialState.selectedRuleCodeModal;
    },
    // rule/func
    setRuleFuncList: (
      state,
      { payload: ruleFuncList }: { payload: IadminRuleFunc[] },
    ) => {
      state.ruleFuncList = ruleFuncList;
    },
    setSelectedRuleFunc: (
      state,
      { payload: ruleFunc }: { payload: IadminRuleFunc },
    ) => {
      state.selectedRuleFunc = ruleFunc;
    },
    initSelectedRuleFunc: (state) => {
      state.selectedRuleFunc = initialState.selectedRuleFunc;
    },
    // rule/func/hub
    setRuleFuncHubList: (
      state,
      { payload: ruleFuncHubList }: { payload: IadminRulefuncHub[] },
    ) => {
      state.ruleFuncHubList = ruleFuncHubList;
    },
    initRuleFuncHubList: (state) => {
      state.ruleFuncHubList = initialState.ruleFuncHubList;
    },
    setSelectedRuleFuncHub: (
      state,
      { payload: ruleFuncHub }: { payload: IadminRuleFuncHubModifyApiBody },
    ) => {
      state.selectedRuleFuncHub = ruleFuncHub;
    },
    initSelectedRuleFuncHub: (state) => {
      state.selectedRuleFuncHub = initialState.selectedRuleFuncHub;
    },
    // rule/user
    setAddedUsersBids: (state, { payload: bids }: { payload: string[] }) => {
      state.addedUsersBids = bids;
    },
    initAddedUsersBid: (state) => {
      state.addedUsersBids = initialState.addedUsersBids;
    },
    // rule/func/hub/option
    setRuleFuncHubOption: (
      state,
      { payload: rulFuncHubOption }: { payload: IruleFuncHubOption },
    ) => {
      state.selectedRuleFuncHubOption = rulFuncHubOption;
    },
    changeRuleFuncHubOption: (state, action) => {
      state.selectedRuleFuncHubOption = {
        ...state.selectedRuleFuncHubOption,
        [action.payload.name]: action.payload.value,
      };
    },
    initRuleFuncHubOption: (state) => {
      state.selectedRuleFuncHubOption = initialState.selectedRuleFuncHubOption;
    },
  },
  extraReducers(builder) {
    builder
      //rule/code
      // add
      .addCase(addRuleCode.pending, (state) => {
        state.ruleCodeStatus = 'pending';
        state.ruleCodeAddStatus = 'pending';
      })
      .addCase(addRuleCode.fulfilled, (state) => {
        state.ruleCodeStatus = 'success';
        state.ruleCodeAddStatus = 'success';
      })
      .addCase(addRuleCode.rejected, (state) => {
        state.ruleCodeStatus = 'fail';
        state.ruleCodeAddStatus = 'fail';
      })
      // remove
      .addCase(removeRuleCode.pending, (state) => {
        state.ruleCodeStatus = 'pending';
        state.ruleCodeRemoveStatus = 'pending';
      })
      .addCase(removeRuleCode.fulfilled, (state) => {
        state.ruleCodeStatus = 'success';
        state.ruleCodeRemoveStatus = 'success';
      })
      .addCase(removeRuleCode.rejected, (state) => {
        state.ruleCodeStatus = 'fail';
        state.ruleCodeRemoveStatus = 'fail';
      })
      // modify
      .addCase(modifyRuleCode.pending, (state) => {
        state.ruleCodeStatus = 'pending';
        state.ruleCodeModifyStatus = 'pending';
      })
      .addCase(modifyRuleCode.fulfilled, (state) => {
        state.ruleCodeStatus = 'success';
        state.ruleCodeModifyStatus = 'success';
      })
      .addCase(modifyRuleCode.rejected, (state) => {
        state.ruleCodeStatus = 'fail';
        state.ruleCodeModifyStatus = 'fail';
      })
      // rule/func
      // add
      .addCase(addRuleFunc.pending, (state) => {
        state.ruleFuncStatus = 'pending';
        state.ruleFuncAddStatus = 'pending';
      })
      .addCase(addRuleFunc.fulfilled, (state) => {
        state.ruleFuncStatus = 'success';
        state.ruleFuncAddStatus = 'success';
      })
      .addCase(addRuleFunc.rejected, (state) => {
        state.ruleFuncStatus = 'fail';
        state.ruleFuncAddStatus = 'fail';
      })
      // remove
      .addCase(removeRuleFunc.pending, (state) => {
        state.ruleFuncStatus = 'pending';
        state.ruleFuncRemoveStatus = 'pending';
      })
      .addCase(removeRuleFunc.fulfilled, (state) => {
        state.ruleFuncStatus = 'success';
        state.ruleFuncRemoveStatus = 'success';
      })
      .addCase(removeRuleFunc.rejected, (state) => {
        state.ruleFuncStatus = 'fail';
        state.ruleFuncRemoveStatus = 'fail';
      })
      // modify
      .addCase(modifyRuleFunc.pending, (state) => {
        state.ruleFuncStatus = 'pending';
        state.ruleFuncModifyStatus = 'pending';
      })
      .addCase(modifyRuleFunc.fulfilled, (state) => {
        state.ruleFuncStatus = 'success';
        state.ruleFuncModifyStatus = 'success';
      })
      .addCase(modifyRuleFunc.rejected, (state) => {
        state.ruleFuncStatus = 'fail';
        state.ruleFuncModifyStatus = 'fail';
      })
      // rule/func/hub
      // add
      .addCase(addRuleFuncHub.pending, (state) => {
        state.ruleFuncHubStatus = 'pending';
        state.ruleFuncHubAddStatus = 'pending';
      })
      .addCase(addRuleFuncHub.fulfilled, (state) => {
        state.ruleFuncHubStatus = 'success';
        state.ruleFuncHubAddStatus = 'success';
      })
      .addCase(addRuleFuncHub.rejected, (state) => {
        state.ruleFuncHubStatus = 'fail';
        state.ruleFuncHubAddStatus = 'fail';
      })
      // remove
      .addCase(removeRuleFuncHub.pending, (state) => {
        state.ruleFuncHubStatus = 'pending';
        state.ruleFuncHubRemoveStatus = 'pending';
      })
      .addCase(removeRuleFuncHub.fulfilled, (state) => {
        state.ruleFuncHubStatus = 'success';
        state.ruleFuncHubRemoveStatus = 'success';
      })
      .addCase(removeRuleFuncHub.rejected, (state) => {
        state.ruleFuncHubStatus = 'fail';
        state.ruleFuncHubRemoveStatus = 'fail';
      })
      // modify
      .addCase(modifyRuleFuncHub.pending, (state) => {
        state.ruleFuncHubStatus = 'pending';
        state.ruleFuncHubModifyStatus = 'pending';
      })
      .addCase(modifyRuleFuncHub.fulfilled, (state) => {
        state.ruleFuncHubStatus = 'success';
        state.ruleFuncHubModifyStatus = 'success';
      })
      .addCase(modifyRuleFuncHub.rejected, (state) => {
        state.ruleFuncHubStatus = 'fail';
        state.ruleFuncHubModifyStatus = 'fail';
      })
      // rule/user
      // add
      .addCase(addRuleUser.pending, (state) => {
        state.ruleUserStatus = 'pending';
        state.ruleUserAddStatus = 'pending';
      })
      .addCase(addRuleUser.fulfilled, (state) => {
        state.ruleUserStatus = 'success';
        state.ruleUserAddStatus = 'success';
      })
      .addCase(addRuleUser.rejected, (state) => {
        state.ruleUserStatus = 'fail';
        state.ruleUserAddStatus = 'fail';
      })
      // remove
      .addCase(removeRuleUser.pending, (state) => {
        state.ruleUserStatus = 'pending';
        state.ruleUserRemoveStatus = 'pending';
      })
      .addCase(removeRuleUser.fulfilled, (state) => {
        state.ruleUserStatus = 'success';
        state.ruleUserRemoveStatus = 'success';
      })
      .addCase(removeRuleUser.rejected, (state) => {
        state.ruleUserStatus = 'fail';
        state.ruleUserRemoveStatus = 'fail';
      });
  },
});

export const {
  initRuleFuncMngSlice,
  initRuleFuncMngStatus,
  // rule/code
  setRuleCodeList,
  setSelectedRuleCode,
  initSelectedRuleCode,
  setSelectedRuleCodeModal,
  initSelectedRuleCodeModal,
  // rule/func
  setRuleFuncList,
  setSelectedRuleFunc,
  initSelectedRuleFunc,
  // rule/func/hub
  setRuleFuncHubList,
  initRuleFuncHubList,
  initSelectedRuleFuncHub,
  setSelectedRuleFuncHub,
  // rule/user
  setAddedUsersBids,
  initAddedUsersBid,
  // rule/func/hub/option
  setRuleFuncHubOption,
  changeRuleFuncHubOption,
  initRuleFuncHubOption,
} = ruleFunctionManagementSlice.actions;

export default ruleFunctionManagementSlice.reducer;
