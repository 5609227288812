import React, { useState, useEffect, useImperativeHandle, forwardRef } from 'react';
import { ICellEditorParams } from 'ag-grid-community';

const NumericCellEditor = forwardRef((props: ICellEditorParams, ref) => {
  const [value, setValue] = useState(props.value);

  // ref를 사용하여 ag-Grid에서 필요한 메소드를 외부로 노출합니다.
  useImperativeHandle(ref, () => ({
    // 셀 에디터가 종료될 때 그리드에 반환할 값을 제공하는 함수
    getValue: () => {
      return value;
    }
  }));

  useEffect(() => {
    // 자동 포커스 로직 등
  }, []);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value;
    if (newValue === '' || /^[0-9]+$/.test(newValue)) {
      setValue(newValue);
    }
  };

  return (
    <input
      type="text"
      value={value}
      onChange={handleChange}
      style={{ width: '100%' }}
    />
  );
});

export default NumericCellEditor;