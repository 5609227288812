const SpLogo = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="289.605"
      height="32"
      viewBox="0 0 289.605 32"
    >
      <defs>
        <clipPath id="kw12fafqva">
          <path
            data-name="사각형 1967"
            style={{ fill: 'none' }}
            d="M0 0h123.418v12.925H0z"
          />
        </clipPath>
      </defs>
      <g data-name="그룹 402">
        <path
          data-name="패스 388"
          d="M497.561 129.537a2.221 2.221 0 0 0-.319-.776 2.306 2.306 0 0 0-.422-.5l-2.267-1.979a12.563 12.563 0 0 0-.3-10.807 12.234 12.234 0 0 0-4.529-4.9 16.226 16.226 0 0 1 .127 13.761A15.745 15.745 0 0 1 479.8 133a11.918 11.918 0 0 0 9.283-.821 12.175 12.175 0 0 0 2.531-1.787l5.592-.041c.3-.045.436-.366.353-.819"
          transform="translate(-457.885 -101.589)"
          style={{ fill: 'currentcolor' }}
        />
        <path
          data-name="패스 389"
          d="M459.2 121.648a12.5 12.5 0 0 1 5.173-16.7 11.959 11.959 0 0 1 11.872.366 15.782 15.782 0 0 0-7.489-7.491 15.585 15.585 0 0 0-20.865 7.755 16.234 16.234 0 0 0 .227 13.964l-2.813 2.687a2.985 2.985 0 0 0-.517.664 2.908 2.908 0 0 0-.367 1.019c-.083.59.115 1 .5 1.037l7.217-.275a15.689 15.689 0 0 0 3.366 2.158 15.377 15.377 0 0 0 10.813.91 12.18 12.18 0 0 1-7.118-6.089"
          transform="translate(-444.396 -96.322)"
          style={{ fill: 'currentcolor' }}
        />
        <path
          data-name="패스 390"
          d="M475.9 125.532a1.815 1.815 0 1 1-1.815-1.849 1.831 1.831 0 0 1 1.815 1.849"
          transform="translate(-455.015 -106.432)"
          style={{ fill: 'currentcolor' }}
        />
        <path
          data-name="패스 391"
          d="M490.416 125.532a1.815 1.815 0 1 1-1.815-1.849 1.831 1.831 0 0 1 1.815 1.849"
          transform="translate(-460.546 -106.432)"
          style={{ fill: 'currentcolor' }}
        />
      </g>
      <g data-name="그룹 405">
        <g data-name="그룹 403">
          <path
            data-name="패스 392"
            d="M523.118 116.879v-9.735h3.468v10.046c0 3.674 1.265 4.914 3.28 4.914 2.061 0 3.374-1.24 3.374-4.914v-10.046h3.327v9.735c0 5.87-2.46 8.28-6.7 8.28-4.218 0-6.749-2.41-6.749-8.28z"
            transform="translate(-474.387 -100.073)"
            style={{ fill: 'currentcolor' }}
          />
          <path
            data-name="패스 393"
            d="M543.806 115.987c0-5.822 3.677-9.235 8.059-9.235a7.016 7.016 0 0 1 5.083 2.267l-1.8 2.243a4.531 4.531 0 0 0-3.209-1.479c-2.624 0-4.592 2.291-4.592 6.085 0 3.841 1.78 6.155 4.522 6.155a4.748 4.748 0 0 0 3.583-1.741l1.828 2.2a6.969 6.969 0 0 1-5.53 2.6c-4.383-.005-7.944-3.202-7.944-9.095z"
            transform="translate(-479.001 -99.991)"
            style={{ fill: 'currentcolor' }}
          />
          <path
            data-name="패스 394"
            d="M561.446 107.144h3.515l1.382 8.638c.258 1.861.538 3.722.8 5.631h.095a301.62 301.62 0 0 1 1.1-5.631l2.014-8.638h2.975l1.991 8.638c.375 1.813.727 3.722 1.1 5.631h.117c.258-1.909.515-3.794.773-5.631l1.382-8.638h3.28l-3.209 17.681h-4.311l-1.945-8.733a90.746 90.746 0 0 1-.75-4.1h-.093c-.235 1.36-.469 2.721-.75 4.1l-1.9 8.733h-4.24z"
            transform="translate(-482.935 -100.073)"
            style={{ fill: 'currentcolor' }}
          />
          <path
            data-name="패스 395"
            d="M589.1 115.843c0-5.75 3.162-9.091 7.753-9.091s7.755 3.364 7.755 9.091-3.163 9.235-7.755 9.235-7.753-3.508-7.753-9.235zm11.971 0c0-3.794-1.64-6.06-4.218-6.06s-4.216 2.267-4.216 6.06c0 3.771 1.64 6.18 4.216 6.18s4.218-2.41 4.218-6.18z"
            transform="translate(-489.102 -99.991)"
            style={{ fill: 'currentcolor' }}
          />
          <path
            data-name="패스 396"
            d="M612.56 107.144h6.04c3.632 0 6.513 1.312 6.513 5.369a5.054 5.054 0 0 1-3.3 5.082l4.053 7.23H622l-3.561-6.681h-2.413v6.681h-3.466zm5.716 8.208c2.225 0 3.467-.954 3.467-2.839 0-1.909-1.242-2.553-3.467-2.553h-2.249v5.392z"
            transform="translate(-494.334 -100.073)"
            style={{ fill: 'currentcolor' }}
          />
          <path
            data-name="패스 397"
            d="M632.251 107.144h3.468v7.684h.071l5.739-7.684h3.819l-5.294 6.967 6.231 10.714h-3.795l-4.5-7.946-2.273 2.983v4.964h-3.468z"
            transform="translate(-498.726 -100.073)"
            style={{ fill: 'currentcolor' }}
          />
          <path
            data-name="패스 398"
            d="m649.983 122.5 1.991-2.434a6.759 6.759 0 0 0 4.4 1.956c1.8 0 2.765-.787 2.765-2.028 0-1.312-1.054-1.718-2.6-2.386l-2.32-1a5.1 5.1 0 0 1-3.513-4.748c0-2.887 2.482-5.106 6-5.106a7.625 7.625 0 0 1 5.435 2.267l-1.758 2.22a5.47 5.47 0 0 0-3.678-1.456c-1.523 0-2.484.692-2.484 1.861 0 1.265 1.218 1.718 2.695 2.315l2.272.978c2.109.883 3.491 2.243 3.491 4.8 0 2.887-2.367 5.345-6.372 5.345a9.15 9.15 0 0 1-6.324-2.584z"
            transform="translate(-502.68 -99.991)"
            style={{ fill: 'currentcolor' }}
          />
        </g>
      </g>
      <g
        data-name="그룹 2519"
        style={{ clipPath: 'url(#kw12fafqva)' }}
        transform="translate(166.187 12.161)"
      >
        <path
          data-name="패스 1610"
          d="M6.51 9.521q.174-.987-.971-1.511l-1.6-.638q-2.792-1.245-2.65-3.428a3.136 3.136 0 0 1 .664-1.793A3.984 3.984 0 0 1 3.655.926 6.024 6.024 0 0 1 6.02.5a4.1 4.1 0 0 1 2.831 1.041 3.468 3.468 0 0 1 1.062 2.7H7.489a1.727 1.727 0 0 0-.361-1.28 1.625 1.625 0 0 0-1.249-.472 2.517 2.517 0 0 0-1.444.378A1.549 1.549 0 0 0 3.7 3.951q-.158.906 1.212 1.435l1.1.416.631.291Q9.091 7.3 8.934 9.5a3.276 3.276 0 0 1-.685 1.845 3.861 3.861 0 0 1-1.66 1.192 5.9 5.9 0 0 1-2.312.389 5.158 5.158 0 0 1-2.291-.539 3.548 3.548 0 0 1-1.527-1.456A3.749 3.749 0 0 1 0 8.939l2.432.009q-.115 1.968 1.934 2a2.531 2.531 0 0 0 1.461-.391 1.513 1.513 0 0 0 .683-1.036"
          style={{ fill: 'currentcolor' }}
        />
        <path
          data-name="패스 1611"
          d="M14.2 12.916a3.907 3.907 0 0 1-2.087-.577 3.5 3.5 0 0 1-1.358-1.561 4.346 4.346 0 0 1-.355-2.186l.025-.332a5.815 5.815 0 0 1 .764-2.409 4.6 4.6 0 0 1 1.644-1.688 4.063 4.063 0 0 1 2.226-.563 3.111 3.111 0 0 1 2.628 1.277 4.375 4.375 0 0 1 .676 3.146l-.133 1.025h-5.5a1.927 1.927 0 0 0 .423 1.394 1.613 1.613 0 0 0 1.245.54 2.873 2.873 0 0 0 2.241-1l1.071 1.328a3.331 3.331 0 0 1-1.461 1.191 4.777 4.777 0 0 1-2.05.411m.747-7.379a1.66 1.66 0 0 0-1.15.406 3.574 3.574 0 0 0-.9 1.469h3.22l.05-.215a2.234 2.234 0 0 0 .009-.631 1.21 1.21 0 0 0-.407-.752 1.277 1.277 0 0 0-.822-.277"
          style={{ fill: 'currentcolor' }}
        />
        <path
          data-name="패스 1612"
          d="M25.079 6.018a4.854 4.854 0 0 0-.789-.082 2.268 2.268 0 0 0-1.983.937l-1.038 5.877h-2.341l1.561-8.981 2.2-.009-.215 1.1a2.47 2.47 0 0 1 2.091-1.27 3.394 3.394 0 0 1 .814.117z"
          style={{ fill: 'currentcolor' }}
        />
        <path
          data-name="패스 1613"
          d="m28.955 9.787 2.557-6.019h2.523l-4.341 8.982h-2.241l-1.536-8.982h2.374z"
          style={{ fill: 'currentcolor' }}
        />
        <path
          data-name="패스 1614"
          d="M36.069 12.75h-2.357l1.56-8.982h2.357zm-.523-11.264a1.171 1.171 0 0 1 .365-.922 1.406 1.406 0 0 1 1.843-.041 1.164 1.164 0 0 1 .407.879 1.2 1.2 0 0 1-.344.9 1.3 1.3 0 0 1-.943.4 1.309 1.309 0 0 1-.917-.315 1.157 1.157 0 0 1-.411-.9"
          style={{ fill: 'currentcolor' }}
        />
        <path
          data-name="패스 1615"
          d="M41.987 10.99a1.532 1.532 0 0 0 1.079-.353 1.735 1.735 0 0 0 .565-.983l2.2-.009a3.218 3.218 0 0 1-.593 1.7 3.6 3.6 0 0 1-1.428 1.178 4.071 4.071 0 0 1-1.88.4 3.317 3.317 0 0 1-2.59-1.129 4.214 4.214 0 0 1-.921-2.938l.025-.457.008-.092a5.613 5.613 0 0 1 1.477-3.473 4 4 0 0 1 3.1-1.233 3.242 3.242 0 0 1 2.411.992 3.5 3.5 0 0 1 .876 2.528h-2.187a1.836 1.836 0 0 0-.316-1.138 1.2 1.2 0 0 0-.946-.447q-1.677-.049-2.034 2.448a8.278 8.278 0 0 0-.091 1.527q.083 1.453 1.245 1.478"
          style={{ fill: 'currentcolor' }}
        />
        <path
          data-name="패스 1616"
          d="M51 12.916a3.907 3.907 0 0 1-2.087-.577 3.5 3.5 0 0 1-1.358-1.561 4.346 4.346 0 0 1-.349-2.186l.025-.332A5.815 5.815 0 0 1 48 5.851a4.6 4.6 0 0 1 1.644-1.688 4.063 4.063 0 0 1 2.221-.563 3.111 3.111 0 0 1 2.628 1.277 4.375 4.375 0 0 1 .676 3.146l-.133 1.023h-5.5a1.927 1.927 0 0 0 .423 1.394 1.613 1.613 0 0 0 1.245.54 2.873 2.873 0 0 0 2.241-1l1.071 1.328a3.331 3.331 0 0 1-1.461 1.191 4.777 4.777 0 0 1-2.05.411m.747-7.379a1.66 1.66 0 0 0-1.15.406 3.574 3.574 0 0 0-.9 1.469h3.22l.05-.215a2.234 2.234 0 0 0 .009-.631 1.21 1.21 0 0 0-.407-.752 1.277 1.277 0 0 0-.822-.277"
          style={{ fill: 'currentcolor' }}
        />
        <path
          data-name="패스 1617"
          d="m63.254 8.493-.747 4.257h-2.433L62.175.664l4.233.008a4.242 4.242 0 0 1 3.08 1.088 3.372 3.372 0 0 1 .979 2.84 3.811 3.811 0 0 1-1.482 2.839 5.392 5.392 0 0 1-3.44 1.061zm.34-2.018 2.025.017a2.557 2.557 0 0 0 1.627-.507 2.035 2.035 0 0 0 .772-1.369 1.745 1.745 0 0 0-.278-1.378 1.5 1.5 0 0 0-1.191-.548l-2.291-.008z"
          style={{ fill: 'currentcolor' }}
        />
        <path
          data-name="패스 1618"
          d="M76.793 6.018A4.854 4.854 0 0 0 76 5.936a2.268 2.268 0 0 0-1.983.937l-1.034 5.877h-2.341L72.2 3.769l2.2-.009-.215 1.1a2.47 2.47 0 0 1 2.091-1.27 3.394 3.394 0 0 1 .814.117z"
          style={{ fill: 'currentcolor' }}
        />
        <path
          data-name="패스 1619"
          d="M81.582 3.6a3.593 3.593 0 0 1 2.046.61 3.424 3.424 0 0 1 1.261 1.635 5.044 5.044 0 0 1 .287 2.312 5.462 5.462 0 0 1-1.49 3.465 4.206 4.206 0 0 1-3.208 1.291 3.592 3.592 0 0 1-2.026-.6 3.425 3.425 0 0 1-1.261-1.623A4.941 4.941 0 0 1 76.9 8.4a5.627 5.627 0 0 1 1.511-3.536A4.117 4.117 0 0 1 81.582 3.6m-2.366 5.65a2.074 2.074 0 0 0 .374 1.256 1.24 1.24 0 0 0 1 .476 1.819 1.819 0 0 0 1.693-.991A4.924 4.924 0 0 0 82.86 7.3a2.253 2.253 0 0 0-.386-1.272 1.219 1.219 0 0 0-1.008-.493 1.822 1.822 0 0 0-1.673.982 4.884 4.884 0 0 0-.577 2.731"
          style={{ fill: 'currentcolor' }}
        />
        <path
          data-name="패스 1620"
          d="m89.5 9.787 2.557-6.019h2.523l-4.34 8.982H88l-1.537-8.982h2.374z"
          style={{ fill: 'currentcolor' }}
        />
        <path
          data-name="패스 1621"
          d="M96.615 12.75h-2.357l1.56-8.982h2.357zm-.523-11.264a1.171 1.171 0 0 1 .365-.922A1.406 1.406 0 0 1 98.3.523a1.164 1.164 0 0 1 .407.879 1.2 1.2 0 0 1-.344.9 1.3 1.3 0 0 1-.943.4 1.309 1.309 0 0 1-.917-.315 1.157 1.157 0 0 1-.411-.9"
          style={{ fill: 'currentcolor' }}
        />
        <path
          data-name="패스 1622"
          d="M103.057 3.594a2.555 2.555 0 0 1 2.025 1l.88-4.6h2.349L106.1 12.75h-2.092l.133-.963a2.9 2.9 0 0 1-2.341 1.137 2.577 2.577 0 0 1-1.469-.439 2.779 2.779 0 0 1-.976-1.242 5 5 0 0 1-.377-1.805 6.768 6.768 0 0 1 .025-1.012l.05-.374a7.169 7.169 0 0 1 .767-2.424 4.011 4.011 0 0 1 1.4-1.552 3.3 3.3 0 0 1 1.839-.482m-1.7 4.832a6.826 6.826 0 0 0-.042 1.062q.084 1.461 1.179 1.486a2 2 0 0 0 1.718-.93l.64-3.569a1.326 1.326 0 0 0-1.295-.93 1.707 1.707 0 0 0-1.424.66 4.434 4.434 0 0 0-.776 2.221"
          style={{ fill: 'currentcolor' }}
        />
        <path
          data-name="패스 1623"
          d="M112.237 12.916a3.907 3.907 0 0 1-2.087-.577 3.5 3.5 0 0 1-1.358-1.561 4.346 4.346 0 0 1-.349-2.186l.025-.332a5.815 5.815 0 0 1 .764-2.409 4.6 4.6 0 0 1 1.644-1.688A4.063 4.063 0 0 1 113.1 3.6a3.111 3.111 0 0 1 2.628 1.277 4.375 4.375 0 0 1 .676 3.146l-.133 1.023h-5.5a1.927 1.927 0 0 0 .423 1.394 1.613 1.613 0 0 0 1.245.54 2.873 2.873 0 0 0 2.241-1l1.071 1.328a3.331 3.331 0 0 1-1.461 1.191 4.777 4.777 0 0 1-2.05.411m.747-7.379a1.66 1.66 0 0 0-1.15.406 3.574 3.574 0 0 0-.9 1.469h3.22l.05-.215a2.234 2.234 0 0 0 .009-.631 1.21 1.21 0 0 0-.407-.752 1.277 1.277 0 0 0-.822-.277"
          style={{ fill: 'currentcolor' }}
        />
        <path
          data-name="패스 1624"
          d="M123.119 6.018a4.854 4.854 0 0 0-.789-.082 2.268 2.268 0 0 0-1.983.937l-1.038 5.877h-2.341l1.561-8.981 2.2-.009-.215 1.1a2.47 2.47 0 0 1 2.091-1.27 3.394 3.394 0 0 1 .814.117z"
          style={{ fill: 'currentcolor' }}
        />
      </g>
    </svg>
  );
};

export default SpLogo;
