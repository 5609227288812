import '../../../styles/react-resizable.css';
import React, { ChangeEvent, ReactElement, useEffect, useState } from 'react';
import * as yup from 'yup';
import { t } from 'i18next';
import { useTranslation } from 'react-i18next';
import hint from '../../../assets/image/etc/hint.png';
import { Tooltip } from 'react-tooltip';
import 'react-tooltip/dist/react-tooltip.css';

// 컴포넌트
import Button from '../../common/buttons/Button';
// 라이브러리
import Draggable from 'react-draggable';
import { Resizable } from 'react-resizable';
// API
// 리덕스
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { initUserModifyStatus, setTempDeptList } from '../../../redux/slice/organizationUserManagementSlice';
// 인터페이스
import { ResizeCallbackData } from '../../../interfaces/I-modals';
import { SelectListValue } from '../../../interfaces/TypeSelectListbox';
//formik
import { Formik, Form, Field, FormikProps, useFormikContext } from 'formik';
import InputTextFormik from '../../common/inputs/InputTextFormik';
import { IUserFormData } from '../../../interfaces/api/I-api-admin-users';
import { modifyUserHandler, selectedUserToForm } from '../../../helpers/h-orgUserMng';
import InputRadioCheckbox from '../../common/inputs/InputRadioCheckbox';
import { toast } from 'react-toastify';
import { modifyFormSchema } from '../../../helpers/h-formSchema';
import NoImageUserAvatar from '../../../assets/image/profile_photo.png';
import { openAddDepartmentModal, openProfileImg } from '../../../helpers/h-react-modals';
import { setProfileUrl } from '../../../redux/slice/profileSlice';

interface Props {
  onClickClose: () => void;
}
const ModifyUserModal: React.FC<Props> = ({ onClickClose }) => {
  const { t, ready } = useTranslation();
  const [formSchema, setFormSchema] = useState<yup.ObjectSchema<any> | null>(null);
  const dispatch = useAppDispatch();
  const { selectedUser } = useAppSelector((state) => state.organizationUserManagement.userInfo);
  const { deptlist } = useAppSelector((state) => state.organizationUserManagement.orgInfo);
  if (selectedUser === null) return null;
  const userType = useAppSelector((state) => state.user.type);
  const { deptNameAndCode } = useAppSelector((state) => state.organizationUserManagement.orgInfo);
  let isFirst = true;

  const [deptListOptions, setDeptListOptions] = useState<SelectListValue[]>([]);
  const [selectedDept, setSelectedDept] = useState<SelectListValue>({
    id: '',
    name: '',
  });

  const [size, setSize] = useState({
    width: 400,
    height: 600,
  });

  const [profilePicUrl, setProfilePicUrl] = useState(NoImageUserAvatar);
  const selectedDepartments = useAppSelector((state) => state.organizationUserManagement.orgInfo.deptlist);
  const onResize = (e: React.SyntheticEvent<Element, Event>, data: ResizeCallbackData) => {
    setSize({
      width: data.size.width,
      height: data.size.height,
    });
  };

  function handleFileChange(event: ChangeEvent<HTMLInputElement>, formik: FormikProps<IUserFormData>) {
    const file = event.target.files && event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        // 결과가 'https://thumbor'로 시작하지 않으면 빈 문자열을 설정
        if (typeof reader.result === 'string' && reader.result.startsWith('https://thumbor')) {
          formik.setFieldValue('picurl', reader.result);
        } else {
          formik.setFieldValue('picurl', '');
        }
        setProfilePicUrl(reader.result as string);
      };
      reader.readAsDataURL(file);
    }
  }

  const openProfileImgFn = (imageUrl: string) => {
    dispatch(setProfileUrl(imageUrl));
    dispatch(openProfileImg());
  };

  useEffect(() => {
    if (deptListOptions.length < 1) return;
    if (isFirst) {
      // selectedUser?.deptcode가 없는 경우 '' 값을 설정
      const selectedDeptOption = selectedUser?.deptcode
        ? deptListOptions.find((v) => v.id === selectedUser?.deptcode)
        : '';

      if (!selectedDeptOption) return;
      setSelectedDept(selectedDeptOption);
      isFirst = false;
    }
  }, [deptListOptions, selectedUser?.deptcode]);

  // 셀렉트 리스트 형식 맞추기
  useEffect(() => {
    if (!deptNameAndCode) return;
    setDeptListOptions(
      deptNameAndCode.map((v: { deptcode: string; deptname: string }) => ({
        id: v.deptcode,
        name: v.deptname,
      })),
    );
  }, [deptNameAndCode]);

  // 초기화 함수
  useEffect(() => {
    return () => {
      dispatch(initUserModifyStatus());
    };
  }, []);

  //다국어 처리
  useEffect(() => {
    if (ready) {
      setFormSchema(modifyFormSchema(t));
    }
  }, [t, ready]);

  return (
    <Draggable handle="#handle">
      <Resizable
        handle={
          <div
            className="resizable-custom-handler absolute"
            style={{
              left: size.width - 19,
              top: size.height - 19,
            }}
          ></div>
        }
        height={size.height}
        width={size.width}
        onResize={onResize}
      >
        <div className="modal-wrapper">
          {/* WRAPPER */}
          <div
            className="box drag-box  flex flex-col px-[1.25rem]
            relative border border-modal-subTitle bg-white z-50 shadow-listbox-options
            rounded
          "
            style={{ width: `${size.width}px`, height: `${size.height}px` }}
          >
            {/* HEADER */}
            <div id="handle" className="min-h-[3.5rem] cursor-move w-full flex flex-col relative">
              <div className="grow font-bold w-full flex items-center truncate">
                {t('OrganizationUserManagement.userInfo') as string}
              </div>
            </div>
            {/* BODY */}
            {selectedUser && (
              <Formik
                initialValues={{
                  ...selectedUserToForm(selectedUser),
                }}
                validationSchema={formSchema}
                onSubmit={(values) => {
                  modifyUserHandler(values);
                }}
              >
                {(formik: FormikProps<IUserFormData>) => {
                  useEffect(() => {
                    if (selectedDepartments.length > 0) {
                      formik.setFieldValue('deptlist', selectedDepartments);
                    }
                  }, [selectedDepartments]);

                  useEffect(() => {
                    dispatch(setTempDeptList(formik.values.deptlist));
                  }, [formik.values.deptlist]);

                  return (
                    <Form className="grow w-full flex flex-col justify-between overflow-y-auto">
                      <div className="grow w-full flex flex-col gap-3">
                        {/* 프로필 사진 */}
                        <div className="relative m-auto w-36 h-36">
                          <img
                            key={formik.values.picurl ? applyThumbor(formik.values.picurl) : profilePicUrl}
                            src={formik.values.picurl ? applyThumbor(formik.values.picurl) : profilePicUrl}
                            alt="Profile"
                            className="w-full h-full rounded-full"
                            onClick={() =>
                              openProfileImgFn(
                                formik.values.picurl ? applyThumborV2(formik.values.picurl) : profilePicUrl,
                              )
                            }
                          />
                          <label
                            htmlFor="file-upload"
                            className="absolute bottom-0 right-0 mb-0 flex items-center justify-center bg-C-1f62b9 text-white rounded-full w-12 h-12 cursor-pointer hover:bg-blue-500"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="currentColor"
                              viewBox="0 0 24 24"
                              className="w-6 h-6"
                            >
                              <path
                                fillRule="evenodd"
                                d="M12 3a1 1 0 011 1v7h7a1 1 0 110 2h-7v7a1 1 0 11-2 0v-7H4a1 1 0 110-2h7V4a1 1 0 011-1z"
                                clipRule="evenodd"
                              />
                            </svg>
                            <input
                              type="file"
                              id="file-upload"
                              className="hidden"
                              accept="image/*"
                              onChange={(event) => handleFileChange(event, formik)}
                            />
                          </label>
                        </div>
                        <CustomCell
                          title={t('OrganizationUserManagement.userId')}
                          required
                          content={
                            <Field
                              component={InputTextFormik}
                              disabled
                              name={`userid`}
                              value={`userid`}
                              handleClear={() => {
                                formik.setFieldValue(`userid`, '');
                              }}
                            />
                          }
                        />
                        <CustomCell
                          title={t('OrganizationUserManagement.password')}
                          required
                          content={
                            <Field
                              component={InputTextFormik}
                              name={`userpwd`}
                              value={`userpwd`}
                              handleClear={() => {
                                formik.setFieldValue(`userpwd`, '');
                              }}
                            />
                          }
                        />
                        <CustomCell
                          title={t('OrganizationUserManagement.name')}
                          required
                          content={
                            <Field
                              component={InputTextFormik}
                              name={`usernameKo`}
                              value={`usernameKo`}
                              handleClear={() => {
                                formik.setFieldValue(`usernameKo`, '');
                              }}
                            />
                          }
                        />
                        <CustomCell
                          toolTipId="usernameEn-tooltip"
                          isToolTip={true}
                          toolTipContent={
                            <>
                              {t('OrganizationUserManagement.usernameEnTooltip') as string}
                              <br />
                              {t('OrganizationUserManagement.usernameEnTooltip2') as string}
                            </>
                          }
                          title={t('OrganizationUserManagement.nameEn')}
                          content={
                            <Field
                              component={InputTextFormik}
                              placeholder={t('OrganizationUserManagement.h-placeholder')}
                              name={`usernameEn`}
                              value={`usernameEn`}
                              handleClear={() => {
                                formik.setFieldValue(`usernameEn`, '');
                              }}
                            />
                          }
                        />
                        <CustomCell
                          toolTipId="department-tooltip"
                          isToolTip={true}
                          toolTipContent={
                            <>
                              {t('OrganizationUserManagement.departmentTooltip') as string}
                              {/* <br />
                              {t('OrganizationUserManagement.departmentTooltip2') as string} */}
                            </>
                          }
                          title={t('OrganizationUserManagement.department')}
                          content={
                            <div className="flex flex-col gap-2 w-full">
                              {(formik.values.deptlist.length > 0
                                ? formik.values.deptlist
                                : [{ deptname: '', deptcode: '' }]
                              ).map((dept, index) => (
                                <div key={dept.deptcode || index} className="flex items-center gap-2">
                                  <Field
                                    component={InputTextFormik}
                                    isReadOnly={true}
                                    grow={true}
                                    placeholder={t('OrganizationUserManagement.h-placeholder2')}
                                    name={`deptlist[${index}].deptname`}
                                    value={formik.values.deptlist[index]?.deptname || ''}
                                    className="flex-grow w-full"
                                    handleClear={() => {
                                      const updatedDeptlist = [...formik.values.deptlist];

                                      if (index === 0 && updatedDeptlist.length === 1) {
                                        formik.setFieldValue('deptlist', []);
                                      } else {
                                        updatedDeptlist.splice(index, 1);
                                        formik.setFieldValue('deptlist', updatedDeptlist);
                                      }
                                    }}
                                  />
                                  {index === 0 && (
                                    <Button
                                      kind="standard"
                                      label={t('OrganizationUserManagement.departmentDesignation')}
                                      onClick={openAddDepartmentModal}
                                      className="w-24"
                                    />
                                  )}
                                </div>
                              ))}
                            </div>
                          }
                        />
                        {/* 
                        <CustomCell
                          required
                          title={t('OrganizationUserManagement.department')}
                          content={
                            <Field
                              component={SelectListboxFormik}
                              options={deptListOptions}
                              selectedOption={selectedDept}
                              name="deptcode"
                              value="deptcode"
                              optionsHeight={12}
                              width={'100%'}
                              onChange={(value: SelectListValue) => {
                                setSelectedDept(value);
                                formik.setFieldValue(`deptcode`, value.id);
                                formik.setFieldValue(`deptname`, value.name);
                              }}
                            />
                          }
                        /> */}
                        <CustomCell
                          title={t('OrganizationUserManagement.position')}
                          content={
                            <Field
                              component={InputTextFormik}
                              name={`position`}
                              value={`position`}
                              handleClear={() => {
                                formik.setFieldValue(`position`, '');
                              }}
                            />
                          }
                        />
                        <CustomCell
                          title={t('OrganizationUserManagement.duty')}
                          content={
                            <Field
                              component={InputTextFormik}
                              name={`duty`}
                              value={`duty`}
                              handleClear={() => {
                                formik.setFieldValue(`duty`, '');
                              }}
                            />
                          }
                        />
                        <CustomCell
                          title={t('OrganizationUserManagement.grade')}
                          content={
                            <Field
                              component={InputTextFormik}
                              name={`grade`}
                              value={`grade`}
                              handleClear={() => {
                                formik.setFieldValue(`grade`, '');
                              }}
                            />
                          }
                        />
                        <CustomCell
                          title={t('OrganizationUserManagement.mobilePhone')}
                          content={
                            <Field
                              component={InputTextFormik}
                              name={`telmobile`}
                              value={`telmobile`}
                              handleClear={() => {
                                formik.setFieldValue(`telmobile`, '');
                              }}
                            />
                          }
                        />
                        <CustomCell
                          title={t('OrganizationUserManagement.telOffice')}
                          content={
                            <Field
                              component={InputTextFormik}
                              name={`teloffice`}
                              value={`teloffice`}
                              handleClear={() => {
                                formik.setFieldValue(`teloffice`, '');
                              }}
                            />
                          }
                        />
                        <CustomCell
                          title={t('OrganizationUserManagement.extensionNo')}
                          content={
                            <Field
                              component={InputTextFormik}
                              name={`telextension`}
                              value={`telextension`}
                              handleClear={() => {
                                formik.setFieldValue(`telextension`, '');
                              }}
                            />
                          }
                        />
                        <CustomCell
                          title={t('OrganizationUserManagement.email')}
                          content={
                            <Field
                              component={InputTextFormik}
                              name={`email`}
                              value={`email`}
                              handleClear={() => {
                                formik.setFieldValue(`email`, '');
                              }}
                            />
                          }
                        />
                        {/* 신분(학생/학부모/선생) */}
                        {userType === 'edu' && (
                          <CustomCell
                            title={t('OrganizationUserManagement.userField5')}
                            content={
                              <div className="w-full h-9 flex gap-6 mb-1">
                                <div className="gap-3 jcac">
                                  <Field
                                    component={InputRadioCheckbox}
                                    kind="radio"
                                    name="userField5"
                                    id="userFieldTeacher"
                                    value={`t`}
                                    checked={formik.values.userfield5 === 't'}
                                    onChange={() => {
                                      formik.setFieldValue('userfield5', 't');
                                    }}
                                  />
                                  <label htmlFor="userFieldTeacher" className="truncate">
                                    {t('OrganizationUserManagement.teacher')}
                                  </label>
                                </div>
                                <div className="gap-3 jcac">
                                  <Field
                                    component={InputRadioCheckbox}
                                    kind="radio"
                                    name="userField5"
                                    id="userFieldStudent"
                                    value={`s`}
                                    checked={formik.values.userfield5 === 's'}
                                    onChange={() => {
                                      formik.setFieldValue('userfield5', 's');
                                    }}
                                  />
                                  <label htmlFor="userFieldStudent" className="truncate">
                                    {t('OrganizationUserManagement.student')}
                                  </label>
                                </div>
                                <div className="gap-3 jcac">
                                  <Field
                                    component={InputRadioCheckbox}
                                    kind="radio"
                                    name="userField5"
                                    id="userFieldParent"
                                    value={`p`}
                                    checked={formik.values.userfield5 === 'p'}
                                    onChange={() => {
                                      formik.setFieldValue('userfield5', 'p');
                                    }}
                                  />
                                  <label htmlFor="userFieldParent" className="truncate">
                                    {t('OrganizationUserManagement.parent')}
                                  </label>
                                </div>
                              </div>
                            }
                          />
                        )}
                        {/* 자원 관리자 지정 */}
                        <CustomCell
                          title={t('OrganizationUserManagement.isreservationoperator')}
                          content={
                            <div className="w-full h-9 flex gap-6 mb-1">
                              <div className="gap-3 jcac">
                                <Field
                                  component={InputRadioCheckbox}
                                  kind="radio"
                                  name="isreservationoperator"
                                  id="isreservationoperatorTrue"
                                  value={1}
                                  checked={formik.values.isreservationoperator === 1}
                                  onChange={() => {
                                    formik.setFieldValue('isreservationoperator', 1);
                                  }}
                                />
                                <label htmlFor="isreservationoperatorTrue" className="truncate">
                                  {t('OrganizationUserManagement.enable')}
                                </label>
                              </div>
                              <div className="gap-3 jcac">
                                <Field
                                  component={InputRadioCheckbox}
                                  kind="radio"
                                  name="isreservationoperator"
                                  id="isreservationoperatorFalse"
                                  value={0}
                                  checked={formik.values.isreservationoperator === 0}
                                  onChange={() => {
                                    formik.setFieldValue('isreservationoperator', 0);
                                  }}
                                />
                                <label htmlFor="isreservationoperatorFalse" className="truncate">
                                  {t('OrganizationUserManagement.disable')}
                                </label>
                              </div>
                            </div>
                          }
                        />
                        {/* 프로젝트 관리자 지정 */}
                        <CustomCell
                          title={t('OrganizationUserManagement.projectAdmin')}
                          content={
                            <div className="w-full h-9 flex gap-6 mb-1">
                              <div className="gap-3 jcac">
                                <Field
                                  component={InputRadioCheckbox}
                                  kind="radio"
                                  name="isprojectadmin"
                                  id="isProjectAdminTrue"
                                  value={1}
                                  checked={formik.values.isprojectadmin === 1}
                                  onChange={() => {
                                    formik.setFieldValue('isprojectadmin', 1);
                                  }}
                                />
                                <label htmlFor="isProjectAdminTrue" className="truncate">
                                  {t('OrganizationUserManagement.enable')}
                                </label>
                              </div>
                              <div className="gap-3 jcac">
                                <Field
                                  component={InputRadioCheckbox}
                                  kind="radio"
                                  name="isprojectadmin"
                                  id="isProjectAdminFalse"
                                  value={0}
                                  checked={formik.values.isprojectadmin === 0}
                                  onChange={() => {
                                    formik.setFieldValue('isprojectadmin', 0);
                                  }}
                                />
                                <label htmlFor="isProjectAdminFalse" className="truncate">
                                  {t('OrganizationUserManagement.disable')}
                                </label>
                              </div>
                            </div>
                          }
                        />
                      </div>
                      {/* 취소 / 저장 버튼 */}
                      <div className="min-h-[6rem] w-full  jcac">
                        <div className="flex gap-2">
                          <Button onClick={onClickClose} kind="cancel" label={t('OrganizationUserManagement.cancel')} />
                          <Button
                            onClick={() => {
                              if (formik.values.usernameEn === '') {
                                formik.setFieldValue('usernameEn', formik.values.usernameKo);
                              }
                              formik.handleSubmit();
                            }}
                            kind="primary"
                            label={t('OrganizationUserManagement.save')}
                          />
                        </div>
                      </div>
                      <AutoSubmitToken onClickClose={onClickClose} />
                    </Form>
                  );
                }}
              </Formik>
            )}
          </div>
        </div>
      </Resizable>
    </Draggable>
  );
};

interface ICustomCell {
  title: string;
  content: ReactElement;
  required?: boolean;
  isDate?: boolean;
  last?: boolean;
  isToolTip?: boolean;
  toolTipContent?: string | React.ReactNode;
  toolTipId?: string;
}
const CustomCell = ({ title, required, isDate, content, last, isToolTip, toolTipContent, toolTipId }: ICustomCell) => {
  return (
    <div
      className="w-full"
      style={{
        zIndex: isDate ? 99 : '',
      }}
    >
      {/* 제목 */}
      <div className="w-full font-bold text-modal-subTitle">
        {title}
        {required && <span className="text-[#d21011]">*</span>}
        {isToolTip && toolTipContent && (
          <img
            data-tooltip-id={toolTipId}
            className={`ml-1 w-4.5 cursor-pointer inline-block`}
            style={{ marginTop: '-1px' }}
            src={hint}
          />
        )}
        {isToolTip && toolTipContent && (
          <Tooltip
            id={toolTipId}
            style={{
              backgroundColor: '#0055ab',
              color: '#19231b',
              zIndex: 1,
            }}
          >
            <div className="block mt-4 mb-4 z-1 w-30">
              <div className="block text-sm mb-1 text-white">{toolTipContent}</div>
            </div>
          </Tooltip>
        )}
      </div>
      {/* INPUT */}
      <div className="w-full">{content}</div>
    </div>
  );
};

const applyThumbor = (imageUrl: string) => {
  const thumborServerUrl = 'https://thumbor.ucworks-dev.net/unsafe/';
  const size = '144x144';
  return `${thumborServerUrl}${size}/${encodeURIComponent(imageUrl)}`;
};

const applyThumborV2 = (imageUrl: string) => {
  const thumborServerUrl = 'https://thumbor.ucworks-dev.net/unsafe/';
  return `${thumborServerUrl}${encodeURIComponent(imageUrl)}`;
};

const AutoSubmitToken = ({ onClickClose }: { onClickClose: () => void }) => {
  const { userModifyStatus } = useAppSelector((state) => state.organizationUserManagement.userInfo);
  // Grab values and submitForm from context
  const event = useFormikContext<IUserFormData>();

  useEffect(() => {
    if (userModifyStatus === 'idle') return;
    if (userModifyStatus === 'fail') {
      toast.error(String(t('OrganizationUserManagement.toastFail')));
      return;
    }
    if (userModifyStatus === 'success') {
      toast.success(String(t('OrganizationUserManagement.toastModifySuccess')));
      onClickClose();
    }
  }, [userModifyStatus]);

  return null;
};

export default ModifyUserModal;
