import { getMyTenantId, getOrgCode } from '../../../helpers/h-userInfo';
import {
  IAdminButtonCntAddBtn,
  IAdminButtonCntInfoApiBody,
  IAdminButtonCntListApiBody,
  IAdminButtonCntListApiResult,
} from '../../../interfaces/api/button/I-api-admin-btn-cnt';
import { IpluralAxiosResponse } from '../../../interfaces/api/I-axios';
import { axiosInstance } from '../../axiosInstance';

const adminButtonCntInfo = {
  url: {
    list: 'api/v1/admin-mg-tp/button/cnt/list',
    add: 'api/v1/admin-mg-tp/button/cnt/add',
    info: 'api/v1/admin-mg-tp/button/cnt/info',
    modify: 'api/v1/admin-mg-tp/button/cnt/modify',
    remove: 'api/v1/admin-mg-tp/button/cnt/remove',
  },
};

// list
export const adminButtonCntListApi = (props: IAdminButtonCntListApiBody) => {
  const { url } = adminButtonCntInfo;
  return axiosInstance.post<IpluralAxiosResponse<IAdminButtonCntListApiResult>>(
    url.list,
    props,
  );
};

// add
export const adminButtonCntAddApi = (btnlist: IAdminButtonCntAddBtn[]) => {
  const { url } = adminButtonCntInfo;
  return axiosInstance.post(url.add, {
    tenantid: getMyTenantId(),
    orgcode: getOrgCode(),
    btnlist,
  });
};

// info
export const adminButtonCntInfoApi = ({
  btncode,
}: IAdminButtonCntInfoApiBody) => {
  const { url } = adminButtonCntInfo;
  return axiosInstance.post(url.info, {
    btncode,
  });
};

// modify
export const adminButtonCntModifyApi = (btnObj: IAdminButtonCntAddBtn) => {
  const { url } = adminButtonCntInfo;
  return axiosInstance.post(url.modify, btnObj);
};

// remove
export const adminButtonCntRemoveApi = async (
  btnlist: { btncode: string }[],
) => {
  const { url } = adminButtonCntInfo;
  return axiosInstance.post(url.remove, { btnlist });
};
