import {
  servicepProvider_fed_tenantInfo_api,
  servicepProvider_fed_tenantList_api,
  serviceProvider_fed_list_api,
} from './../api/admin/service-provider/api-admin-serviceProvider-fed';
import { store } from '../redux/store';
import {
  IfedListItem,
  IfedTenantListItem,
} from '../interfaces/api/service-provider/I-api-admin-serviceProvider-fed';
import {
  acceptFed,
  cancelFed,
  initFederatinManagementSlice,
  manageFed,
  rejectFed,
  requestFed,
  setConnectedFedList,
  setOtherFedList,
  setTenantList,
} from '../redux/slice/federatinManagementSlice';
import { setMyTenantInfo } from '../redux/slice/authSlice';
import axios from 'axios';
const dispatch = store.dispatch;

// list 페더레이션 status 리스트
export const getAndSetFedList = async () => {
  const {
    user: { userInfo },
  } = store.getState();

  if (!userInfo) return alert('check getAndSetFedList in h-federationMng');

  try {
    const res = await serviceProvider_fed_list_api({
      tenantid: userInfo.bid.split('.')[0],
      pagesize: 100,
      page: 1,
    });
    const fedList = res?.data.data.result;

    if (!fedList) return;

    // 리스트가 있으면...
    if (fedList.length < 1) {
      console.log('fedList.length < 1 ?');
      dispatch(setConnectedFedList([]));
      dispatch(setOtherFedList([]));
      return;
    } else {
      // 리스트가 없으면...
      const connectedFedList = fedList.filter((fed) => fed.status === 8);
      const otherFedList = fedList.filter((fed) => fed.status !== 8);
      dispatch(setConnectedFedList(connectedFedList));
      dispatch(setOtherFedList(otherFedList));
    }
  } catch (error) {
    if (axios.isAxiosError(error)) {
      console.log('error: ', error);
    } else {
      console.log('unexpected error: ', error);
    }
  }
};

// accept 테넌트 연결 요청/해제 수락
export const fedAcceptHandler = async (body: {
  srctenantid: string;
  desttenantid: string;
  kind: 'CONNECT' | 'RELEASE';
}) => {
  const {
    user: { userInfo },
  } = store.getState();

  if (!userInfo) return;

  const BODY = {
    ...body,
    bid: userInfo.bid,
  };
  dispatch(acceptFed(BODY));
};
// manage 페더레이션 신청 허용/허용 안함
export const fedManageHandler = async (body: {
  tenantid: string;
  isdisable: boolean;
}) => {
  const {
    user: { userInfo },
  } = store.getState();

  if (!userInfo) return;

  const BODY = {
    ...body,
    bid: userInfo.bid,
  };

  dispatch(manageFed(BODY));
};
// reject 요청 거부
export const fedRejectHandler = async (body: {
  srctenantid: string;
  desttenantid: string;
}) => {
  const {
    user: { userInfo },
  } = store.getState();

  if (!userInfo) return;

  const BODY = {
    ...body,
    bid: userInfo.bid,
  };
  dispatch(rejectFed(BODY));
};
// request 테넌트 연결/연결 해제 요청
export const fedRequestHandler = async (body: {
  srctenantid: string;
  desttenantid: string;
  kind: 'CONNECT' | 'RELEASE';
}) => {
  const {
    user: { userInfo },
  } = store.getState();

  if (!userInfo) return;

  // console.log('bid from fedRequestHandler : ', userInfo.bid);

  const BODY = {
    ...body,
    bid: userInfo.bid,
  };

  dispatch(requestFed(BODY));
};
// cancel 페더레이션 연결 신청중 취소
export const fedCancelHandler = async (body: {
  srctenantid: string;
  desttenantid: string;
}) => {
  const {
    user: { userInfo },
  } = store.getState();

  if (!userInfo) return;

  const BODY = {
    ...body,
    bid: userInfo.bid,
  };

  dispatch(cancelFed(BODY));
};

// tenantList
export const getAndSetFedTenantList = async () => {
  const {
    user: { userInfo },
  } = store.getState();

  if (!userInfo)
    return alert('check getAndSetFedTenantList in h-federationMng.ts');

  try {
    const res = await servicepProvider_fed_tenantList_api({
      tenantid: userInfo.bid.split('.')[0],
      pagesize: 100,
      page: 1,
    });
    if (!res) return;

    const fedTenantList = res.data.data.result;

    if (fedTenantList.length < 1) {
      dispatch(setTenantList([]));
      return;
    } else {
      dispatch(setTenantList(fedTenantList));
    }
  } catch (error) {
    if (axios.isAxiosError(error)) {
      console.log('error: ', error);
    } else {
      console.log('unexpected error: ', error);
    }
  }
};

// tenant-info
export const getAndSetTenantInfo = async (tenantid: string) => {
  try {
    const res = await servicepProvider_fed_tenantInfo_api({ tenantid });

    if (!res?.data.data.result)
      return alert('check h-federation getAndSetTenantInfo');

    const tenantInfo: IfedTenantListItem = res.data.data.result;
    dispatch(setMyTenantInfo(tenantInfo));
  } catch (error) {
    if (axios.isAxiosError(error)) {
      console.log('error: ', error);
    } else {
      console.log('unexpected error: ', error);
    }
  }
};

// INIT
export const initFedSlice = () => {
  dispatch(initFederatinManagementSlice);
};
