import { t } from 'i18next';
import '../../../styles/counselingGrid.css';
import { ChangeEvent, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Modal from 'react-modal';
import InputText from '../../common/inputs/InputText';
import { closeCounselingChannelModal, openCounselingCounselorModal } from '../../../helpers/h-react-modals';
import Button from '../../common/buttons/Button';
import NoImageUserAvatar from '../../../assets/image/profile_photo.png';
import { RootState } from '../../../redux/store';
import InputRadioCheckbox from '../../common/inputs/InputRadioCheckbox';
import { GridApi } from 'ag-grid-community';
import { AgGridReact } from 'ag-grid-react';
import { ICounselorInfo } from '../../../interfaces/api/I-api-hrs-users';
import { AgHeaderTextCenter } from '../../common/AgCustomHeader';
import { toast } from 'react-toastify';
import {
  setChannelReloadStatus,
  setSelectedUsers,
  setSelectedChannel,
} from '../../../redux/slice/counselingManagementSlice';
import {
  IAdminCounselCounselorReq,
  IChannel,
  IChannelv2,
} from '../../../interfaces/api/counsel/I-api-admin-counsel-category';
import { reqAddChannel, reqAddCounselor, reqModifyChannel, reqRemoveCounselor } from '../../../helpers/h-counselingMng';
import { counselCounselorListApi } from '../../../api/admin/counseling/api-admin-counsel-counselor';
import { IAdminCounselCounselorRes } from '../../../interfaces/api/counsel/I-api-admin-counsel-counselor';

const customStyles = {
  overlay: {
    zIndex: 99,
  },
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    minWidth: '21.875rem',
    padding: 0,
  },
};

interface Props {
  isOpen: boolean;
}

const CounselingChannelModal = ({ isOpen }: Props) => {
  const dispatch = useDispatch();
  const subTitle = useRef<HTMLHeadingElement | null>(null);
  const { selectedCategoryId, selectedCategoryName, selectedChannel } = useSelector(
    (state: RootState) => state.counselingManagement,
  );
  const [channelName, setChannelName] = useState('');
  const [introduceDesc, setIntroduceDesc] = useState('');
  const [fixedStatus, setFixedStatus] = useState<number>(0);
  const [gridApi, setGridApi] = useState<GridApi<ICounselorInfo>>();
  const [profilePicUrl, setProfilePicUrl] = useState(NoImageUserAvatar);

  const gridRef = useRef<AgGridReact<ICounselorInfo>>(null);
  const { selectedUsers } = useSelector((state: RootState) => state.counselingManagement);
  const [localUserData, setLocalUserData] = useState<Array<ICounselorInfo>>(selectedUsers.map((user) => ({ ...user })));

  const inputHandler = (event: ChangeEvent<HTMLInputElement>) => {
    setChannelName(event.target.value);
  };

  const inputIntroduceHandler = (event: ChangeEvent<HTMLInputElement>) => {
    setIntroduceDesc(event.target.value);
  };

  const handleClear = () => {
    setChannelName('');
  };

  const handleIntroduceClear = () => {
    setIntroduceDesc('');
  };

  const handleDelete = () => {
    if (!gridApi) return;
    const selectedRows = gridApi.getSelectedRows();

    if (selectedRows.length === 0) {
      toast.error(t('CounselingManagement.channelModalDelMsg') as string);
      return;
    }

    const updatedUserData = localUserData.filter(
      (user) => !selectedRows.some((selectedUser) => selectedUser.userid === user.userid),
    );

    setLocalUserData(updatedUserData);
    dispatch(setSelectedUsers(updatedUserData));
    gridApi.setRowData(updatedUserData);
  };

  const columnDefs = useMemo(
    () => [
      {
        headerName: ' ',
        field: 'checkbox',
        checkboxSelection: true,
        maxWidth: 50,
        resizable: false,
      },
      {
        headerName: t('CounselingManagement.channelHeaderName') as string,
        field: 'username',
        editable: false,
        headerComponent: AgHeaderTextCenter,
        cellStyle: { textAlign: 'center' },
      },
      {
        headerName: t('CounselingManagement.channelHeaderNickname') as string,
        field: 'nickname',
        editable: true,
        cellEditor: 'agTextCellEditor',
        cellStyle: { textAlign: 'center' },
        onCellValueChanged: (params: any) => {
          const updatedData = localUserData.map((user) =>
            user.userid === params.data.userid ? { ...user, nickname: params.newValue } : { ...user },
          );
          setLocalUserData(updatedData);
          dispatch(setSelectedUsers(updatedData));
        },
      },
    ],
    [localUserData],
  );

  const defaultColDef = useMemo(
    () => ({
      flex: 1,
      resizable: false,
      sortable: false,
    }),
    [],
  );

  const onGridReady = useCallback(
    (params: { api: GridApi<ICounselorInfo> }) => {
      setGridApi(params.api);
      gridRef.current?.api.setRowData(localUserData);
    },
    [localUserData],
  );

  useEffect(() => {
    const initialData = selectedUsers.map((user) => ({ ...user }));
    setLocalUserData(initialData);
    if (gridApi) {
      gridApi.setRowData(initialData);
    }
  }, [selectedUsers, gridApi]);

  useEffect(() => {
    if (isOpen) {
      if (selectedChannel) {
        setChannelName(selectedChannel.name);
        setIntroduceDesc(selectedChannel.description);
        setFixedStatus(selectedChannel.fixed);

        // API 요청
        const fetchCounselorData = async () => {
          try {
            const res = await counselCounselorListApi(selectedChannel.id);
            const counselorData = res.data.data.result.map((counselor: IAdminCounselCounselorRes) => ({
              bid: counselor.bid,
              userid: counselor.bid.split('.')[1],
              nickname: counselor.nickname || '',
              username: counselor.multiname[0].name,
            }));
            console.log(counselorData);

            setLocalUserData(counselorData);
            if (gridApi) {
              gridApi.setRowData(counselorData);
            }
          } catch (error) {
            console.error('Failed to fetch counselor data:', error);
          }
        };

        fetchCounselorData();
      } else {
        setChannelName('');
        setIntroduceDesc('');
        setFixedStatus(0);
        setLocalUserData([]);
      }
    }
  }, [isOpen, selectedChannel, gridApi]);

  const onConfirm = async () => {
    console.log(localUserData);
    if (!selectedCategoryId) return toast.error(t('CounselingManagement.channelToast1') as string);
    if (!channelName) return toast.error(t('CounselingManagement.channelToast2') as string);
    if (!introduceDesc) return toast.error(t('CounselingManagement.channelToast3') as string);
    if (localUserData.length === 0) return toast.error(t('CounselingManagement.channelToast9') as string);

    const data: IChannelv2 = {
      categoryid: selectedCategoryId,
      name: channelName,
      description: introduceDesc,
      fixed: fixedStatus,
    };

    try {
      const res = await reqAddChannel(data);
      console.log('res: ', res);
      if (res.data.data.result === true) {
        const counselorData: IAdminCounselCounselorReq = {
          who: localUserData.map((user) => ({
            bid: user.bid,
            nickname: user.nickname,
            channelid: res.data.data.channelid,
          })),
        };

        const res2 = await reqAddCounselor(counselorData);
        console.log('res2: ', res2);
        if (res2.data.data.result === true) {
          toast.success(t('CounselingManagement.channelToast4') as string);
          dispatch(setSelectedUsers([]));
          dispatch(setChannelReloadStatus(true));
        } else {
          toast.error(t('CounselingManagement.channelToast5') as string);
        }
      }

      setChannelName('');
      setIntroduceDesc('');
      dispatch(setSelectedChannel(null));
      closeCounselingChannelModal();
    } catch (err) {
      console.error('Failed to save the channel: ', err);
      toast.error(t('CounselingManagement.channelToast6') as string);
    }
  };

  const onEdit = async () => {
    console.log(localUserData);
    if (!selectedCategoryId) return toast.error(t('CounselingManagement.channelToast1') as string);
    if (!channelName) return toast.error(t('CounselingManagement.channelToast2') as string);
    if (!introduceDesc) return toast.error(t('CounselingManagement.channelToast3') as string);
    if (localUserData.length === 0) return toast.error(t('CounselingManagement.channelToast9') as string);

    const data: IChannel[] = [
      {
        categoryid: selectedCategoryId,
        name: channelName,
        description: introduceDesc,
        fixed: fixedStatus,
        id: selectedChannel!.id,
      },
    ];

    const formattedData = {
      what: data.map((channel) => ({
        categoryid: channel.categoryid,
        name: channel.name,
        description: channel.description,
        fixed: channel.fixed,
        id: channel.id,
      })),
    };
    console.log('채널 수정: ', data);
    try {
      const res = await reqModifyChannel(formattedData);
      console.log('modiRs: ', res);
      if (res.data.data.result === true) {
        const csList = await counselCounselorListApi(selectedChannel!.id);

        const originCounselorData: IAdminCounselCounselorReq = {
          who: csList.data.data.result.map((user) => ({
            bid: user.bid,
            channelid: selectedChannel!.id,
          })),
        };
        console.log(originCounselorData);
        const res2 = await reqRemoveCounselor(originCounselorData);
        console.log('remRs: ', res2);

        const counselorData: IAdminCounselCounselorReq = {
          who: localUserData.map((user) => ({
            bid: user.bid,
            nickname: user.nickname,
            channelid: selectedChannel!.id,
          })),
        };

        const res3 = await reqAddCounselor(counselorData);
        console.log('addRs: ', res3);
        if (res3.data.data.result === true) {
          toast.success(t('CounselingManagement.channelToast7') as string);
          dispatch(setSelectedUsers([]));
          dispatch(setChannelReloadStatus(true));
        } else {
          toast.error(t('CounselingManagement.channelToast8') as string);
        }
      }

      setChannelName('');
      setIntroduceDesc('');
      dispatch(setSelectedChannel(null));
      closeCounselingChannelModal();
    } catch (err) {
      console.error('Failed to save the channel: ', err);
      toast.error(t('CounselingManagement.channelToast6') as string);
    }
  };

  const applyThumbor = (imageUrl: string) => {
    console.log(imageUrl);
    const thumborServerUrl = 'https://thumbor.ucworks-dev.net/unsafe/';
    const size = '144x144';
    return `${thumborServerUrl}${size}/${encodeURIComponent(imageUrl)}`;
  };

  const applyThumborV2 = (imageUrl: string) => {
    const thumborServerUrl = 'https://thumbor.ucworks-dev.net/unsafe/';
    return `${thumborServerUrl}${encodeURIComponent(imageUrl)}`;
  };

  const onCancel = () => {
    dispatch(setSelectedUsers([]));
    dispatch(setSelectedChannel(null));
    closeCounselingChannelModal();
    setChannelName('');
    setIntroduceDesc('');
    setLocalUserData([]);
  };

  const radioOnChangeHandler = (event: ChangeEvent<HTMLInputElement>) => {
    setFixedStatus(Number(event.target.value));
  };

  return (
    <div>
      <Modal isOpen={isOpen} onRequestClose={onCancel} style={customStyles} contentLabel="Example Modal">
        <div
          className="flex flex-col h-full px-3
            relative border border-modal-subTitle bg-white z-50 shadow-listbox-options
            rounded
          "
        >
          <div className="min-h-[3.5rem] w-full flex flex-col relative text-center font-extrabold mt-5">
            {selectedChannel
              ? (t('CounselingManagement.channelMod') as string)
              : (t('CounselingManagement.channelAdd') as string)}
          </div>

          {/* 프로필 사진 */}
          <div className="relative m-auto w-14 h-14">
            <img
              key={profilePicUrl}
              src={profilePicUrl}
              // key={formik.values.picurl ? applyThumbor(formik.values.picurl) : profilePicUrl}
              // src={formik.values.picurl ? applyThumbor(formik.values.picurl) : profilePicUrl}
              alt="Profile"
              className="w-full h-full rounded-full"
              // onClick={() =>
              //   openProfileImgFn(formik.values.picurl ? applyThumborV2(formik.values.picurl) : profilePicUrl)
              // }
            />
            <label
              htmlFor="file-upload"
              className="absolute bottom-0 right-0 mb-0 flex items-center justify-center bg-C-1f62b9 text-white rounded-full w-7 h-7 cursor-pointer hover:bg-blue-500"
            >
              <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 24 24" className="w-5 h-5">
                <path
                  fillRule="evenodd"
                  d="M12 3a1 1 0 011 1v7h7a1 1 0 110 2h-7v7a1 1 0 11-2 0v-7H4a1 1 0 110-2h7V4a1 1 0 011-1z"
                  clipRule="evenodd"
                />
              </svg>
              <input
                type="file"
                id="file-upload"
                className="hidden"
                accept="image/*"
                // onChange={(event) => handleFileChange(event, formik)}
              />
            </label>
          </div>

          <div className=" w-full font-bold text-left text-[#9D9EA2] text-sm mt-3 mb-2">
            {t('CounselingManagement.channelName') as string}
            <span className="text-red-700">*</span>
          </div>

          <InputText
            value={channelName}
            handleChangeInput={inputHandler}
            handleClear={handleClear}
            placeholder={t('CounselingManagement.channelToast2') as string}
          />

          <div className=" w-full font-bold text-left text-[#9D9EA2] text-sm mt-6 mb-2">
            {t('CounselingManagement.channelIntro') as string}
            <span className="text-red-700">*</span>
          </div>
          <InputText
            value={introduceDesc}
            handleChangeInput={inputIntroduceHandler}
            handleClear={handleIntroduceClear}
            placeholder={t('CounselingManagement.channelToast3') as string}
          />

          <div className=" w-full font-bold text-left text-[#9D9EA2] text-sm mt-6 mb-2">
            {t('CounselingManagement.channelCounselor') as string}
            <span className="text-red-700">*</span>
          </div>
          <div className=" w-full font-normal text-left  text-[#9D9EA2] text-sm mb-2">
            {t('CounselingManagement.channelDesc') as string}
          </div>

          <div className="mt-1 mb-2">
            <button
              className="p-1.5 text-sm active:bg-C-b8d3fe text-C-1f62b9 border border-C-1f62b9 border-solid hover:bg-C-d9e5f9 w-14 rounded-sm mr-2"
              onClick={openCounselingCounselorModal}
            >
              {t('CounselingManagement.contextMenuAdd') as string}
            </button>
            <button
              className="p-1.5 text-sm active:bg-C-b8d3fe text-C-1f62b9 border border-C-1f62b9 border-solid hover:bg-C-d9e5f9 w-14 rounded-sm"
              onClick={handleDelete}
            >
              {t('CounselingManagement.contextMenuDel2') as string}
            </button>
          </div>

          <div className="ag-theme-alpine" style={{ height: 200, width: '100%' }}>
            <AgGridReact
              ref={gridRef}
              rowData={JSON.parse(JSON.stringify(localUserData))}
              columnDefs={columnDefs}
              defaultColDef={defaultColDef}
              rowMultiSelectWithClick
              rowSelection={'multiple'}
              onGridReady={onGridReady}
              overlayNoRowsTemplate={'\xa0'}
            />
          </div>

          <div className=" w-full font-bold text-left text-[#9D9EA2] text-sm mt-6 mb-2">
            {t('CounselingManagement.channelFixed') as string}
            <span className="text-red-700"></span>
          </div>
          <div className="flex items-center gap-2 shrink-0">
            <InputRadioCheckbox
              kind="radio"
              id="accept"
              value={'1'}
              name="federation"
              onChange={radioOnChangeHandler}
              checked={fixedStatus === 1}
            />
            <label className={`mr-5`} htmlFor={'accept'}>
              {t('CounselingManagement.channelFixed') as string}
            </label>
            <InputRadioCheckbox
              kind="radio"
              id="deny"
              value={'0'}
              name="federation"
              onChange={radioOnChangeHandler}
              checked={fixedStatus === 0}
            />
            <label htmlFor={'deny'}>{t('CounselingManagement.channelNotFixed') as string}</label>
          </div>
          <div className="min-h-[6rem] w-full jcac gap-2">
            <Button kind="cancel" label={t('ContextMenu.treeContextMenu.cancel')} onClick={onCancel} />
            <Button
              kind="primary"
              label={
                selectedChannel
                  ? (t('CounselingManagement.contextMenuMod') as string)
                  : (t('CounselingManagement.contextMenuAdd') as string)
              }
              onClick={() => {
                if (selectedChannel) {
                  onEdit();
                } else {
                  onConfirm();
                }
              }}
            />
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default CounselingChannelModal;
