import { IsidebarListsData } from '../../interfaces/mainPage/I-sidebar';

export const sidebarListsDataSp: IsidebarListsData[] = [
  {
    text: '테넌트 관리',
    link: 'tenant-management',
    svgFile: function () {
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="18"
          height="18"
          viewBox="0 0 18 18"
        >
          <defs>
            <clipPath id="rrpbvsri6a">
              <circle
                data-name="타원 421"
                cx="8"
                cy="8"
                r="8"
                transform="translate(-.492 -.492)"
                style={{ fill: '#fff' }}
              />
            </clipPath>
          </defs>
          <path style={{ fill: 'none' }} d="M0 0h18v18H0z" />
          <g
            data-name="마스크 그룹 2"
            style={{ clipPath: 'url(#rrpbvsri6a)' }}
            transform="translate(1.492 1.492)"
          >
            <g data-name="그룹 2513">
              <path
                data-name="빼기 2"
                d="M21094.537 6326.511h-4.031v-16h16v16h-4.031v-3.041a3.97 3.97 0 1 0-7.939 0v3.041zm3.973-12.751a2.384 2.384 0 1 0 2.383 2.384 2.386 2.386 0 0 0-2.383-2.384z"
                transform="translate(-21091 -6311)"
                style={{ fill: 'currentcolor' }}
              />
            </g>
          </g>
        </svg>
      );
    },
  },
  {
    text: '테넌트 모니터링',
    link: 'tenant-mornitor',
    svgFile: function () {
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="18"
          height="18"
          viewBox="0 0 18 18"
        >
          <path style={{ fill: 'none' }} d="M0 0h18v18H0z" />
          <g transform="translate(75.795 -331.354)">
            <g
              data-name="사각형 1962"
              transform="translate(-74.795 334.354)"
              style={{ stroke: '#555', strokeWidth: '2px', fill: 'none' }}
            >
              <rect width="16" height="11" rx="1" style={{ stroke: 'none' }} />
              <path style={{ fill: 'none' }} d="M1 1h14v9H1z" />
            </g>
            <rect
              data-name="사각형 1963"
              width="8"
              height="5"
              rx="1"
              transform="translate(-70.795 343.354)"
              style={{ fill: 'currentcolor' }}
            />
            <rect
              data-name="사각형 1964"
              width="10"
              height="1"
              rx=".5"
              transform="translate(-71.795 347.354)"
              style={{ fill: 'currentcolor' }}
            />
          </g>
        </svg>
      );
    },
  },
  {
    text: '업그레이드 관리',
    link: 'upgrade-management',
    svgFile: function () {
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="18"
          height="18"
          viewBox="0 0 18 18"
        >
          <path
            transform="translate(2)"
            style={{ fill: 'none' }}
            d="M0 0h15.178v18H0z"
          />
          <path
            data-name="패스 1579"
            d="m-519.758-357.693-.027-.024-.023-.021-1.55-1.822-1.262-1.484-2.266-2.662-1.384-1.626a.338.338 0 0 0-.247-.16c-.109 0-.177.08-.247.164l-6.539 7.673a.272.272 0 0 0-.05.247.218.218 0 0 0 .165.164h.019a.415.415 0 0 0 .093.012h4.099a16.337 16.337 0 0 1-.207 2.841 7.578 7.578 0 0 1-.84 2.479 3.887 3.887 0 0 1-1.479 1.509 3.867 3.867 0 0 1-1.887.475 6.09 6.09 0 0 1-1.86-.3 9.4 9.4 0 0 1-1.939-.873 2.498 2.498 0 0 0-.122-.066l-.052-.028a.188.188 0 0 0-.252.078l-.021.034a.275.275 0 0 0-.036.183.249.249 0 0 0 .086.158l.029.023.022.017a11.256 11.256 0 0 0 2.736 2.121 8.5 8.5 0 0 0 3.189 1.039 7.593 7.593 0 0 0 .818.045 6.064 6.064 0 0 0 1.992-.323 5.977 5.977 0 0 0 2.56-1.713 8.44 8.44 0 0 0 1.749-3.307 14.916 14.916 0 0 0 .529-4.393h3.814c.065 0 .129 0 .19-.009l.08-.005a.228.228 0 0 0 .2-.242v-.023a.259.259 0 0 0-.08-.181z"
            transform="translate(537.68 365.492)"
            style={{ fill: 'currentcolor' }}
          />
        </svg>
      );
    },
  },
  {
    text: 'Auto Scale 관리',
    link: 'auto-scale-management',
    svgFile: function () {
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="18.001"
          height="18.001"
          viewBox="0 0 18.001 18.001"
        >
          <path
            transform="translate(.001 .001)"
            style={{ fill: 'none' }}
            d="M0 0h18v18H0z"
          />
          <path
            data-name="패스 1584"
            d="M359.975-671.029a.907.907 0 0 0 .188-.02l.915-.2v2.163a.342.342 0 0 0 .045.118.307.307 0 0 0 .06.081l1.776 1.568a.363.363 0 0 0 .245.09.362.362 0 0 0 .243-.089l1.78-1.571a.334.334 0 0 0 .064-.088.856.856 0 0 0 .031-.14v-2.131l.915.2a.924.924 0 0 0 .19.02.818.818 0 0 0 .546-.2.64.64 0 0 0 .227-.482.645.645 0 0 0-.227-.484l-3.228-2.851a.831.831 0 0 0-.546-.186.832.832 0 0 0-.547.186l-3.228 2.851a.644.644 0 0 0-.226.483.641.641 0 0 0 .226.483.819.819 0 0 0 .551.199z"
            transform="translate(-354.202 675.231)"
            style={{ fill: 'currentcolor' }}
          />
          <path
            data-name="패스 1585"
            d="m369.16-667.853-3.16 3.108a.765.765 0 0 0 0 1.094l3.29 3.233a.792.792 0 0 0 .557.226.79.79 0 0 0 .557-.226.764.764 0 0 0 .207-.737l-.232-.915h2.414a.969.969 0 0 0 .976-.959v-2.337a.969.969 0 0 0-.976-.959h-2.415l.233-.916a.763.763 0 0 0-.207-.737.793.793 0 0 0-.557-.213.8.8 0 0 0-.557.213l-.119.117v.495-.369h-.007z"
            transform="translate(-355.767 673.191)"
            style={{ fill: 'currentcolor' }}
          />
          <path
            data-name="패스 1586"
            d="M357.066-667.978a.793.793 0 0 0-.557-.213.792.792 0 0 0-.556.213.759.759 0 0 0-.207.737l.232.916h-2.415a.968.968 0 0 0-.976.959v2.337a.968.968 0 0 0 .976.959h2.415l-.232.917a.758.758 0 0 0 .207.737.786.786 0 0 0 .556.226.786.786 0 0 0 .557-.226l3.29-3.233a.766.766 0 0 0 0-1.1z"
            transform="translate(-352.587 673.191)"
            style={{ fill: 'currentcolor' }}
          />
          <path
            data-name="패스 1587"
            d="M366.994-658.547a.83.83 0 0 0-.548-.2.869.869 0 0 0-.19.021l-.917.2v-2.09a.939.939 0 0 0-.048-.2.38.38 0 0 0-.054-.066l-1.782-1.569a.372.372 0 0 0-.245-.088.373.373 0 0 0-.246.088l-1.784 1.57a.338.338 0 0 0-.058.083.282.282 0 0 0-.041.132v2.14l-.916-.2a.868.868 0 0 0-.19-.021.818.818 0 0 0-.615.266.682.682 0 0 0 .138.96l3.164 2.784a.82.82 0 0 0 .548.2.827.827 0 0 0 .548-.2l3.234-2.846a.73.73 0 0 0 .128-.149v-.39h.008v.379a.609.609 0 0 0 .092-.323.64.64 0 0 0-.226-.481z"
            transform="translate(-354.22 672.538)"
            style={{ fill: 'currentcolor' }}
          />
        </svg>
      );
    },
  },
  {
    text: '로그아웃',
    link: '/logout',
    svgFile: function () {
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="18"
          height="18"
          viewBox="0 0 18 18"
        >
          <path style={{ fill: 'none' }} d="M0 0h18v18H0z" />
          <path
            data-name="패스 1344"
            d="M824.587 242.729h-9a.9.9 0 0 1-.9-.9v-16.2a.9.9 0 0 1 .9-.9h8.588a.9.9 0 1 1 0 1.8h-7.688v14.4h8.1a.9.9 0 0 1 0 1.8z"
            style={{ fill: 'currentcolor' }}
            transform="translate(-813.687 -224.729)"
          />
          <path
            data-name="패스 1345"
            d="m831.223 233.593-3.6-3.6a.9.9 0 0 0-1.273 1.273l2.064 2.064h-6.828a.9.9 0 0 0 0 1.8h6.828l-2.064 2.064a.9.9 0 1 0 1.273 1.273l3.6-3.6a.9.9 0 0 0 0-1.274z"
            transform="translate(-814.487 -225.229)"
            style={{ fill: 'currentcolor' }}
          />
        </svg>
      );
    },
  },
  {
    text: '테스트',
    link: '/test-page',
    svgFile: function () {
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="18"
          height="18"
          viewBox="0 0 18 18"
        >
          <path style={{ fill: 'none' }} d="M0 0h18v18H0z" />
          <path
            data-name="패스 1344"
            d="M824.587 242.729h-9a.9.9 0 0 1-.9-.9v-16.2a.9.9 0 0 1 .9-.9h8.588a.9.9 0 1 1 0 1.8h-7.688v14.4h8.1a.9.9 0 0 1 0 1.8z"
            style={{ fill: 'currentcolor' }}
            transform="translate(-813.687 -224.729)"
          />
          <path
            data-name="패스 1345"
            d="m831.223 233.593-3.6-3.6a.9.9 0 0 0-1.273 1.273l2.064 2.064h-6.828a.9.9 0 0 0 0 1.8h6.828l-2.064 2.064a.9.9 0 1 0 1.273 1.273l3.6-3.6a.9.9 0 0 0 0-1.274z"
            transform="translate(-814.487 -225.229)"
            style={{ fill: 'currentcolor' }}
          />
        </svg>
      );
    },
  },
];
