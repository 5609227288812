import { t } from 'i18next';
import { useEffect, useState } from 'react';
import { SplitPane } from 'react-multi-split-pane';
import { useResizeDetector } from 'react-resize-detector';
import {
  handleViewPortSize,
  PaneProps,
  splitPaneViewportCase,
} from '../../../helpers/h-splitPane';
import { IspTenantListReturnValue_desc_T } from '../../../interfaces/api/service-provider/I-api-admin-serviceProvider-fed';
import {
  adminGraphListApi,
  IadminGraph,
} from '../../../api/admin/api-admin-graph';
import { getMyTenantId } from '../../../helpers/h-userInfo';
import { sp_tenantListApi_desc_T } from '../../../api/admin/service-provider/api-admin-serviceProvider-fed';
import TenantManagement_serviceOperate_Table from '../../tables/sp/tenantManagement/TenantManagement_serviceOperate_Table';
import MainPageTitleHeader from '../../common/MainPageTitleHeader';
import WorkspaceBillingHistory_Table from '../../tables/portal/WorkspaceBillingHistory_Table';
import TenantManagement_workSpaceAdminInfo_Table from '../../tables/sp/tenantManagement/TenantManagement_workSpaceAdminInfo_Table';
import axios from 'axios';

interface NestedPane extends PaneProps {
  selectedTenant: IspTenantListReturnValue_desc_T | null;
}

// 테넌트 관리
const ServiceMornitoring = () => {
  const [splitPaneViewportKey, setSplitPaneViewportKey] = useState(
    splitPaneViewportCase.default,
  );
  const [selectedTenant, setSelectedTenant] =
    useState<IspTenantListReturnValue_desc_T | null>(null);

  useEffect(() => {
    const tenantid = getMyTenantId();
    if (!tenantid) {
      setSelectedTenant(null);
      return;
    }

    const fetchData = async () => {
      try {
        const spTenantRes = await sp_tenantListApi_desc_T({
          tenantid: tenantid,
        });

        if (!spTenantRes) return;
        const TenantWorkSpace = spTenantRes.data.data.result;
        if (TenantWorkSpace.length < 1) return;
        const myTenantWorkSpace = TenantWorkSpace[0];

        setSelectedTenant(myTenantWorkSpace);
      } catch (error) {
        if (axios.isAxiosError(error)) {
          console.log('error: ', error);
        } else {
          console.log('unexpected error: ', error);
        }
      }
    };
    
    fetchData();
  }, []);

  return (
    <div className="h-full w-full flex flex-col gap-3">
      <SplitPane
        className="custom"
        split="horizontal"
        defaultSizes={[1.3, 4]}
        minSize={[50, 50]}
      >
        <div
          className="w-full border border-solid
          flex flex-col border-[#dfe2e8] rounded overflow-visible"
        >
          <SplitPane
            className="custom"
            split="vertical"
            defaultSizes={[2, 1]}
            minSize={[50, 50]}
          >
            <Left
              setSplitPaneViewportKey={setSplitPaneViewportKey}
              splitPaneUpdatedState={splitPaneViewportKey}
              selectedTenant={selectedTenant}
            />
            <Right
              setSplitPaneViewportKey={setSplitPaneViewportKey}
              splitPaneUpdatedState={splitPaneViewportKey}
              selectedTenant={selectedTenant}
            />
          </SplitPane>
        </div>
        <div className="splitPane-Wrapper grow w-full">
          <SplitPane
            className="custom"
            split="horizontal"
            defaultSizes={[3, 5]}
            minSize={[50, 50]}
          >
            <Upper
              setSplitPaneViewportKey={setSplitPaneViewportKey}
              splitPaneUpdatedState={splitPaneViewportKey}
              selectedTenant={selectedTenant}
            />
            <Down
              setSplitPaneViewportKey={setSplitPaneViewportKey}
              splitPaneUpdatedState={splitPaneViewportKey}
              selectedTenant={selectedTenant}
            />
          </SplitPane>
        </div>
      </SplitPane>
    </div>
  );
};

const Left = ({
  setSplitPaneViewportKey,
  splitPaneUpdatedState,
  selectedTenant,
}: NestedPane) => {
  const { ref } = useResizeDetector();

  useEffect(() => {
    ref.current.parentElement.style.flex = splitPaneUpdatedState.left;
  }, [splitPaneUpdatedState]);

  return (
    <div
      ref={ref}
      className="w-full h-full flex flex-col px-6 border border-solid bg-white border-[#dfe2e8] rounded"
    >
      <MainPageTitleHeader
        textOnly={true}
        text={t('ServiceMornitoring.productInfo')}
        onClick={() =>
          handleViewPortSize([setSplitPaneViewportKey, 'clickedLeft'])
        }
        direction="left"
      />
      <TenantManagement_workSpaceAdminInfo_Table
        selectedTenant={selectedTenant}
        fromPortal={true}
      />
    </div>
  );
};

const Right = ({
  setSplitPaneViewportKey,
  splitPaneUpdatedState,
  selectedTenant,
}: NestedPane) => {
  const { ref } = useResizeDetector();

  useEffect(() => {
    ref.current.parentElement.style.flex = splitPaneUpdatedState.right;
  }, [splitPaneUpdatedState]);

  return (
    <div
      ref={ref}
      className="w-full h-full flex flex-col px-6 border border-solid border-[#dfe2e8] rounded bg-white"
    >
      <MainPageTitleHeader
        textOnly={true}
        onClick={() =>
          handleViewPortSize([setSplitPaneViewportKey, 'clickedRight'])
        }
        direction="right"
        text={t('ServiceMornitoring.paymentHistory')}
      />
      <WorkspaceBillingHistory_Table
        selectedTenant={selectedTenant}
        fromPortal={true}
      />
    </div>
  );
};

const Upper = ({
  setSplitPaneViewportKey,
  splitPaneUpdatedState,
  selectedTenant,
}: NestedPane) => {
  const { width, ref } = useResizeDetector();

  useEffect(() => {
    ref.current.parentElement.style.flex = splitPaneUpdatedState.upper;
  }, [splitPaneUpdatedState]);

  return (
    <div
      ref={ref}
      className="w-full flex flex-col px-6 border border-solid bg-white border-[#dfe2e8] rounded"
    >
      <MainPageTitleHeader
        textOnly={true}
        onClick={() =>
          handleViewPortSize([setSplitPaneViewportKey, 'clickedUpper'])
        }
        text={t('ServiceMornitoring.serviceOpInfo')}
        direction="up"
      />
      {/* 서비스 운영정보 테이블 */}
      <TenantManagement_serviceOperate_Table selectedTenant={selectedTenant} />
      {/* <div className="grow pb-2 w-full"></div> */}
    </div>
  );
};

const Down = ({
  setSplitPaneViewportKey,
  splitPaneUpdatedState,
  selectedTenant,
}: NestedPane) => {
  const { width, ref } = useResizeDetector();
  const [graphData, setGraphData] = useState<IadminGraph[] | null>(null);

  useEffect(() => {
    ref.current.parentElement.style.flex = splitPaneUpdatedState.down;
  }, [splitPaneUpdatedState]);

  useEffect(() => {
    if (!selectedTenant) return;
    const fetchData = async () => {
      try {
        const res = await adminGraphListApi({
          tenantid: selectedTenant.tenantid,
          graphtype: 0,
          refresh: 10,
          lasthour: 1,
          theme: 'light',
        });

        const graphList = res.data.data.result;
        if (!graphList || graphList.length < 1) return setGraphData(null);
        setGraphData(graphList);

        // const currentLang = localStorage.getItem('i18nextLng');
        // // currentLang 값에 따라 menuname을 업데이트하는 함수
        // const updateNameByLang = (item: any) => {
        //   switch (currentLang) {
        //     case 'ko':
        //       return item;
        //     case 'ja':
        //       return {
        //         ...item,
        //         graphname: item.graphnameja || item.graphname,
        //       };
        //     case 'en':
        //       return {
        //         ...item,
        //         graphname: item.graphnameen || item.graphname,
        //       };
        //     default:
        //       return item;
        //   }
        // };
        //const updateMenuByLang = graphList.map(updateNameByLang);
        // if (!updateMenuByLang || updateMenuByLang.length < 1) return setGraphData(null);
        // setGraphData(updateMenuByLang);
      } catch (error) {
        if (axios.isAxiosError(error)) {
          console.log('error: ', error);
        } else {
          console.log('unexpected error: ', error);
        }
      }
    };
    fetchData();
  }, [selectedTenant]);

  return (
    <div
      ref={ref}
      className="w-full flex flex-col px-6 border border-solid bg-white border-[#dfe2e8] rounded"
    >
      <MainPageTitleHeader
        textOnly={true}
        onClick={() =>
          handleViewPortSize([setSplitPaneViewportKey, 'clickedDown'])
        }
        text={t('ServiceMornitoring.graphList')}
        direction="down"
      />
      <GraphListWrapper graphData={graphData} />
    </div>
  );
};

interface IGraphListWrapper {
  graphData: IadminGraph[] | null;
}
export const GraphBox = ({ graphData }: { graphData: IadminGraph }) => {
  //console.log(graphData)
  //console.log(graphData.graphdata)
  return (
    <div className="w-96 h-96 flex flex-col m-auto py-defaultY">
      <iframe
        src={graphData.graphdata}
        frameBorder="0"
        width={'100%'}
        height={'100%'}
      ></iframe>
    </div>
  );
};

export const GraphListWrapper = ({ graphData }: IGraphListWrapper) => {
  return (
    <div className="grow pb-2 w-full flex flex-wrap justify-between overflow-auto">
      {graphData &&
        graphData.map((g) => <GraphBox graphData={g} key={g.graphid} />)}
    </div>
  );
};

export default ServiceMornitoring;
