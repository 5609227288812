import DownButtonsContainer from './DownButtonsContainer';
import InputText from '../../common/inputs/InputText';
import Button from '../../common/buttons/Button';
import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from 'react';
import _, { divide } from 'lodash';
import BtnMore from '../../../assets/svg/icons/BtnMore';
import InputWithSearch from '../../common/InputWithSearch';

interface Props {
  width?: number;
}

const buttons = [
  {
    label: '서버 설정 다시 읽기',
    onClick() {
      // console.log('서버 설정 다시 읽기');
    },
  },
  {
    label: '선택 시작',
    onClick() {
      // console.log('선택 시작');
    },
  },
  {
    label: '선택 중지',
    onClick() {
      // console.log('선택 중지');
    },
  },
  {
    label: '선택 강제중지',
    onClick() {
      // console.log('선택 강제중지');
    },
  },
  {
    label: '전체 시작',
    onClick() {
      // console.log('전체 시작');
    },
  },
  {
    label: '전체 중지',
    onClick() {
      // console.log('전체 중지');
    },
  },
  {
    label: '전체 강제중지',
    onClick() {
      // console.log('전체 강제중지');
    },
  },
];

const ButtonDropDown = ({
  width,
  setShow,
}: {
  width: number;
  setShow: Dispatch<SetStateAction<boolean>>;
}) => {
  let btnList = buttons;
  if (width <= 1010 && width > 890) {
    btnList = buttons.slice(-1);
  } else if (width <= 890 && width > 770) {
    btnList = buttons.slice(-2);
  } else if (width <= 770 && width > 650) {
    btnList = buttons.slice(-3);
  } else if (width <= 650 && width > 530) {
    btnList = buttons.slice(-4);
  } else if (width <= 530 && width > 410) {
    btnList = buttons.slice(-5);
  } else if (width <= 410 && width > 360) {
    btnList = buttons.slice(-6);
  } else if (width <= 360) {
    btnList = buttons.slice(-7);
  }

  const onClickHandler = (buttonFunc: () => void) => {
    buttonFunc();
    setShow(false);
  };

  useEffect(() => {
    const closeList = () => setShow(false);
    setTimeout(() => {
      window.addEventListener('click', closeList);
    }, 50);
    return () => {
      window.removeEventListener('click', closeList);
    };
  }, []);

  return (
    <ul className="text-[#555] text-sm px-[12px] py-2 absolute bg-white top-10 right-0 truncate shadow-listbox-options rounded">
      {btnList.map((b, i) => (
        <li
          key={i}
          onClick={(e) => {
            onClickHandler(b.onClick);
          }}
          className="hover:bg-[#f1f3f7] py-1 cursor-pointer"
        >
          {b.label}
        </li>
      ))}
    </ul>
  );
};

const Buttons = ({ width }: { width: number }) => {
  let btnList = buttons;
  if (width <= 1010 && width > 890) {
    btnList = buttons.slice(0, -1);
  } else if (width <= 890 && width > 770) {
    btnList = buttons.slice(0, -2);
  } else if (width <= 770 && width > 650) {
    btnList = buttons.slice(0, -3);
  } else if (width <= 650 && width > 530) {
    btnList = buttons.slice(0, -4);
  } else if (width <= 530 && width > 410) {
    btnList = buttons.slice(0, -5);
  } else if (width <= 410 && width > 360) {
    btnList = buttons.slice(0, -6);
  } else if (width <= 360) {
    btnList = buttons.slice(0, -7);
  }

  return (
    <div className="flex gap-1">
      {btnList.map((button, i) => (
        <Button key={i} kind="cancel" label={button.label} />
      ))}
    </div>
  );
};

const DownTopBar = ({ width }: Props) => {
  const [showList, setShowList] = useState(false);
  const [userNameInputValue, setUserNameInputValue] = useState('');

  const onClickMore = () => {
    setShowList(!showList);
  };

  const onClickSearchUser = () => {
    // console.log('onClickSearchUser');
  };

  return (
    <div className="py-[0.563rem] flex justify-between">
      <InputWithSearch
        value={userNameInputValue}
        placeholder="검색..."
        onClickSearch={onClickSearchUser}
        handleChangeInput={(e) => setUserNameInputValue(e.target.value)}
        handleClear={() => setUserNameInputValue('')}
      />
      {/* <DownButtonsContainer width/> */}
      <div className="flex gap-1">
        {width && <Buttons width={width} />}
        {width && width <= 1010 && (
          <div className="jcac relative ">
            <div
              className="jcac relative cursor-pointer hover:text-primary-default active:text-primary-default"
              onClick={onClickMore}
              style={{
                color: showList ? '#1f62b9' : '',
              }}
            >
              <BtnMore />
            </div>
            {showList && <ButtonDropDown width={width} setShow={setShowList} />}
          </div>
        )}
      </div>
    </div>
  );
};

export default DownTopBar;

/**
 *  <div className="flex">
        <div className="mr-6 flex gap-1">
          <Button kind="cancel" label="서버 설정 다시 읽기" />
          <Button kind="cancel" label="선택 시작" />
          <Button kind="cancel" label="선택 중지" />
          <Button kind="cancel" label="선택 강제중지" />
        </div>
        <div className="flex gap-1">
          <Button kind="cancel" label="전체 시작" />
          <Button kind="cancel" label="전체 중지" />
          <Button kind="cancel" label="전체 강제중지" />
        </div>
      </div>
 */
