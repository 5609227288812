import './index.css';
import './styles/toast.css';
import ReactDOM from 'react-dom/client';
// 컴포넌트
import App from './App';
// 리덕스
import { store, persistor } from './redux/store';
import { Provider } from 'react-redux';
// redux-persist
import { PersistGate } from 'redux-persist/integration/react';
// 라이브러리
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { setUser } from './redux/slice/authSlice';
import { setAxiosBaseUrl, setAxiosDefaultAuth } from './api/axiosInstance';
import SpApp from './pages/sp/SpApp';

// 서비스 프로바이더 생기면서 하드코딩 로직 추가
if (!window.location.pathname.includes('/sp')) {
  setAxiosBaseUrl();
}

// 새로고침시에 로그아웃 되는 현상 방지.
const setInitialUser = () => {
  if (!sessionStorage.getItem('Auth')) return;

  store.dispatch(setUser(true));
};
setInitialUser();

// 화면 초기 렌더링시 인증을 마친 상태면 axios header에 jwt토큰 설정.
if (sessionStorage.getItem('Auth')) {
  setAxiosDefaultAuth();
}

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);

root.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <BrowserRouter>
        <Routes>
          <Route path="/*" element={<App />} />
          <Route path="/sp/*" element={<SpApp />} />
        </Routes>
      </BrowserRouter>
    </PersistGate>
  </Provider>,
);
