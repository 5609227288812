import { t } from 'i18next';
import { useEffect, useState } from 'react';
import ClassSectionHeader from './ClassSectionHeader';
import { openClassDepartmentModal } from '../../../helpers/h-react-modals';
import { useSelector } from 'react-redux';
import { RootState } from '../../../redux/store';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import ClassList from './ClassList';
import { classList } from '../../../api/admin/class/api-class';
import { hrsOrgsListApi } from '../../../api/hrs/api-hrs-orgs';
import { getMyOrgCode, getMyTenantId } from '../../../helpers/h-userInfo';
import { IClass } from '../../../interfaces/api/I-api-class';
import { setClassReloadStatus } from '../../../redux/slice/classManagementSlice';

const ClassLeft = () => {
  const dispatch = useAppDispatch();
  const { usersid } = useAppSelector((state) => state.user);
  const [classes, setClasses] = useState<IClass[]>([]);
  const classReloadStatus = useSelector((state: RootState) => state.classManagement.classReloadStatus);

  const fetchData = async () => {
    try {
      const eduRs = await classList();
      const classes = eduRs?.data.result.classes as IClass[];
      const tenantid = getMyTenantId();
      const orgcode = getMyOrgCode();

      const what = classes.map((classItem: IClass) => ({
        gid: `${tenantid}.${orgcode}.${classItem.deptcode}`,
      }));

      // TODO: 0102 여기 조회해바야함
      const commonRs = await hrsOrgsListApi({
        cmd: '1103',
        sid: usersid ? usersid : '',
        what,
        fields: 'FULL',
        include: 'DEPT',
        kind: 'DEPT',
        extra: 'ALL',
      });

      if (!commonRs || !commonRs.data.data.result.dept || commonRs.data.data.result.dept.data.length < 1) return;

      // deptpath를 문자열 기준으로 정렬
      const sortedClasses = commonRs.data.data.result.dept.data.sort((a: any, b: any) => {
        return a.deptpath.localeCompare(b.deptpath);
      });

      // deptpath를 숫자 기준으로 정렬
      // const sortedClasses = commonRs.data.data.result.dept.data.sort((a: any, b: any) => {
      //   const pathA = a.deptpath.split(':').map(Number);
      //   const pathB = b.deptpath.split(':').map(Number);

      //   for (let i = 0; i < Math.max(pathA.length, pathB.length); i++) {
      //     if (pathA[i] !== pathB[i]) {
      //       return (pathA[i] || 0) - (pathB[i] || 0);
      //     }
      //   }
      //   return 0;
      // });

      // console.log(commonRs);
      const fetchedClasses = sortedClasses.map((dept: any) => ({
        deptcode: dept.deptcode,
        deptname: dept.deptname,
      }));

      setClasses(fetchedClasses);
    } catch (error) {
      console.error('An error occurred while fetching categories:', error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    fetchData();
    dispatch(setClassReloadStatus(false));
  }, [classReloadStatus]);

  const handleAddClass = () => {
    openClassDepartmentModal();
  };

  return (
    <div className="flex-[1]">
      <div className="w-full h-full border border-solid border-[#dfe2e8] bg-white border-r-0">
        <ClassSectionHeader title={t('ClassManagement.class')} onButtonClick={handleAddClass} />
        <ClassList classes={classes} />
      </div>
    </div>
  );
};

export default ClassLeft;
