import Button from '../../common/buttons/Button';
import {
  addRuleFuncHubHandler,
  removeRuleFuncHandler,
} from '../../../helpers/h-ruleFuncMng';
import { openRuleFuncFuncModal } from '../../../helpers/h-modals';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { toast } from 'react-toastify';
import { initSelectedRuleFunc } from '../../../redux/slice/ruleFunctionManagementSlice';

const UpperLeftTopBar = () => {
  const dispatch = useAppDispatch();
  const { selectedRuleFunc, selectedRuleCode } = useAppSelector(
    (state) => state.ruleFunctionManagement,
  );

  const handleRemoveRuleFunc = () => {
    if (selectedRuleFunc.funccode.length < 1)
      return toast.error('기능을 선택해주세요');

    removeRuleFuncHandler(selectedRuleFunc.funccode);

    dispatch(initSelectedRuleFunc());
  };

  return (
    <div className="py-[0.563rem] gap-1 flex justify-between text-fontColor-default overflow-hidden">
      <div className="flex gap-1">
        <Button
          kind="cancel"
          label="기능 추가"
          onClick={openRuleFuncFuncModal}
        />
        <Button
          kind="cancel"
          label="기능 삭제"
          onClick={handleRemoveRuleFunc}
        />
        {/* <SelectListbox
          options={selectValues}
          value={selected}
          optionsHeight={12}
          width={'9.62rem'}
          onChange={(value) => {
            setSelected(value);
          }}
        /> */}
        {/* <InputWithSearch
          value={testInputValue}
          placeholder="검색..."
          onClickSearch={() => console.log('search!!')}
          handleChangeInput={(e) => setTestInputValue(e.target.value)}
          handleClear={(e) => setTestInputValue('')}
        /> */}
      </div>
      <div>
        <Button
          kind="cancel"
          label="rule에 추가"
          onClick={() => {
            // console.log(selectedRuleFunc?.funccode, selectedRuleCode.rulecode);
            addRuleFuncHubHandler(selectedRuleFunc, selectedRuleCode);
          }}
        />
      </div>
    </div>
  );
};

export default UpperLeftTopBar;
