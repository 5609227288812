import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { fedRequestHandler } from '../../../helpers/h-federationMng';
import { closeFederation_disConnectApply_Modal } from '../../../helpers/h-modals';
import { IserviceProviderFedRequestApiBody } from '../../../interfaces/api/service-provider/I-api-admin-serviceProvider-fed';
import { useAppSelector } from '../../../redux/hooks';
import FederationMng_ask_common from './FederationMng_ask_common';

type RELEASE_BODY = {
  srctenantid: string;
  desttenantid: string;
  kind: 'RELEASE';
};

const FederationMng_disConnect_Modal = () => {
  const { selectedConnectedFederation } = useAppSelector(
    (state) => state.federatinManagement,
  );

  const handleReleaseRequest = () => {
    if (!selectedConnectedFederation)
      return toast.error('Please select item first');

    if (selectedConnectedFederation.status !== 8)
      return alert('check function handleReleaseRequest');

    const body: RELEASE_BODY = {
      srctenantid: selectedConnectedFederation.srctenantid,
      desttenantid: selectedConnectedFederation.desttenantid,
      kind: 'RELEASE',
    };

    // console.log('body from handleReleaseRequest : ', body);

    fedRequestHandler(body);
  };
  // 클리어 함수
  useEffect(() => {
    // console.log('dd');
  }, []);

  return (
    <FederationMng_ask_common
      label="선택된 항목을 연결 해제 신청하시겠습니까?"
      onClickClose={closeFederation_disConnectApply_Modal}
      onClickHandler={() => {
        handleReleaseRequest();
        closeFederation_disConnectApply_Modal();
      }}
    />
  );
};

export default FederationMng_disConnect_Modal;
