import { IpluralAxiosResponse, IsingularAxiosResponse } from './../../../interfaces/api/I-axios';
import { getMyTenantId, getOrgCode } from '../../../helpers/h-userInfo';
import {
  IAdminButtonFunc,
  IAdminButtonFuncAddApiBody,
  IAdminButtonFuncInfoApiBody,
  IAdminButtonFuncListApiBody,
  IAdminButtonFuncModifyApiBody,
  IAdminButtonFuncRemoveApi,
} from '../../../interfaces/api/button/I-api-admin-btn-func';
import { axiosErrorHandler, axiosInstance } from '../../axiosInstance';

const adminButtonCntInfo = {
  url: {
    list: 'api/v1/admin-mg-tp/button/func/list',
    add: 'api/v1/admin-mg-tp/button/func/add',
    info: 'api/v1/admin-mg-tp/button/func/info',
    modify: 'api/v1/admin-mg-tp/button/func/modify',
    remove: 'api/v1/admin-mg-tp/button/func/remove',
  },
};

// list
export const adminButtonFuncListApi = (props: IAdminButtonFuncListApiBody) => {
  const { url } = adminButtonCntInfo;
  return axiosInstance.post<IpluralAxiosResponse<IAdminButtonFunc>>(url.list, props);
};

// add
export const adminButtonFuncAddApi = (btn: IAdminButtonFuncAddApiBody) => {
  const { url } = adminButtonCntInfo;
  return axiosInstance.post(url.add, {
    tenantid: getMyTenantId(),
    orgcode: getOrgCode(),
    btnlist: [btn],
  });
};

// info
export const adminButtonFuncInfoApi = ({ btncode }: IAdminButtonFuncInfoApiBody) => {
  const { url } = adminButtonCntInfo;
  return axiosInstance.post<IsingularAxiosResponse<IAdminButtonFunc>>(url.info, { btncode });
};

// modify
export const adminButtonFuncModifyApi = (btn: IAdminButtonFuncModifyApiBody) => {
  const { url } = adminButtonCntInfo;
  return axiosInstance.post(url.modify, btn);
};

// remove
export const adminButtonFuncRemoveApi = ({ btncode }: IAdminButtonFuncRemoveApi) => {
  const { url } = adminButtonCntInfo;
  return axiosInstance.post(url.remove, {
    btnlist: [{ btncode }],
  });
};
