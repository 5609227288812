import React, { useState } from 'react';
import { t } from 'i18next';
import hint from '../../../assets/image/etc/hint.png';
import download from '../../../assets/image/etc/download.png';
import { Tooltip } from 'react-tooltip'
import { Button } from '../../common/buttons/Button';
import InputText from '../../common/inputs/InputText';
import * as XLSX from 'xlsx';
import i18n from '../../../translations/i18n';
import 'react-tooltip/dist/react-tooltip.css'
import { toast } from 'react-toastify';
import { UserInfoList_Table_Import_User_Column } from '../../tables/orgUserMng/columns';
import { useTranslation } from 'react-i18next';
import { fieldLabelsForEN, fieldLabelsForKO } from '../../../const/excelFields';

interface Props {
  width?: number | undefined;
  gridApi?: any;
  updateRowData ?: any;
  updateUserRowData ?: any
}

const RightTopBarImport: React.FC<Props> = ({ width, gridApi, updateRowData, updateUserRowData }) => {
  const [fileName, setFileName] = useState<string>('');
  const { t } = useTranslation();

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files ? event.target.files[0] : null;
    if (!file) return;
  
    setFileName(file.name);
    const reader = new FileReader();
    reader.onload = (e: ProgressEvent<FileReader>) => {
      const data = e.target?.result;
      const workbook = XLSX.read(data, { type: 'binary' });
      const firstSheetName = workbook.SheetNames[0];
      const firstWorksheet = workbook.Sheets[firstSheetName];
      let jsonFirstSheet: { [key: string]: any; }[] = XLSX.utils.sheet_to_json(firstWorksheet, { defval: "" }) as { [key: string]: any; }[];
      console.log(JSON.stringify(jsonFirstSheet));

      if(jsonFirstSheet.length < 1) {
        toast.error(t('OrganizationUserManagement.toastWarn5') as string);
        return;
      }
      console.log(jsonFirstSheet);
      const expectedKeys = getExpectedKeys();

      const isValid = jsonFirstSheet.every(row =>
        expectedKeys.every(key => Object.hasOwnProperty.call(row, key))
      );
  
      if (!isValid) {
        toast.error(t('OrganizationUserManagement.toastWarn7') as string);
        return;
      }

      if(jsonFirstSheet.length > 3000) {
        toast.error(t('OrganizationUserManagement.toastWarn6') as string);
        return;
      }

      const originalColumnKeys = UserInfoList_Table_Import_User_Column(t).slice(1).map(data => data.field);
      jsonFirstSheet = transformKeys(jsonFirstSheet, expectedKeys, originalColumnKeys);
      console.log(JSON.stringify(jsonFirstSheet));
      if(updateUserRowData) {
        updateUserRowData(jsonFirstSheet);
      }
    };
    event.target.value = '';
    reader.readAsBinaryString(file);
  };

  const handleDownloadExcel = () => {
    const currentLanguage = i18n.language;

    let filePath = '/assets/xlsx/import/user_template_en.xlsx';

    if(currentLanguage.startsWith('ko')){
      filePath = '/assets/xlsx/import/user_template_ko.xlsx'; 
    
    }else if(currentLanguage.startsWith('ja')){
      filePath = '/assets/xlsx/import/user_template_ja.xlsx'; 
    }

    window.location.href = filePath;
  };

  const getExpectedKeys = () => {
    const currentLanguage = i18n.language.slice(0,2);
    switch(currentLanguage) {
      case 'ko':
        return Object.values(fieldLabelsForKO);
      case 'ja':
        return ['組織コード', 'ユーザID*', 'パスワード', '名前*', '名前(English)', '職位', "職務", "職級", "携帯電話", "電話(会社)", "内線番号","メール"];
      case 'en':
      default:
        return Object.values(fieldLabelsForEN);
    }
  };

  const transformKeys = (
    jsonArray: Array<{ [key: string]: any }>, // jsonArray는 어떤 키를 가진 객체의 배열
    originalKeys: string[], // 원본 키 배열
    expectedKeys: string[] // 변경될 키 배열
  ): Array<{ [key: string]: any }> => { // 반환 타입도 객체의 배열
    return jsonArray.map(item => {
      const newItem: { [key: string]: any } = {}; // 새 객체 초기화
      expectedKeys.forEach((key, index) => {
        // null이나 undefined인 경우에만 기본값을 할당
        const originalValue = item[originalKeys[index]];
        newItem[key] = originalValue !== null && originalValue !== undefined ? originalValue : '';
      });
      return newItem;
    });
  };

  return (
    <>
      <div className={`flex gap-1 py-defaultY z-20 w-full `}>
        {/* 사용자 검색 */}
        <InputText
          value={fileName}
          placeholder={t('OrganizationUserManagement.xlsPlaceholder')}
          className='w-[19.5rem]'
          disabled={true}
        />
        {/* 파일 업로드 라벨 및 버튼 */}
        <label htmlFor="file-upload" className="text-center leading-9 inline-block cursor-pointer min-w-[6.25rem] h-9 rounded text-sm px-3 truncate bg-C-1f62b9 hover:bg-C-599aee active:bg-C-32418e disabled:bg-C-ebedf1 text-white disabled:text-C-b4b5b8 disabled:border disabled:border-C-b4b5b8 disabled:border-solid">
          {t('OrganizationUserManagement.registFile') as string}
        </label>
        <input
          id="file-upload"
          type="file"
          style={{ display: 'none' }}
          accept=".xlsx"
          onChange={handleFileChange}
        />
        {/* 양식 다운로드 버튼 */}
        <button
          className="min-w-[6.25rem] h-9 rounded text-sm px-3 truncate bg-white hover:bg-C-d9e5f9 active:bg-C-b8d3fe text-C-1f62b9 border border-C-1f62b9 border-solid flex items-center justify-center space-x-1"
          onClick={handleDownloadExcel}>
          <img src={download} alt="Down" className="w-4 h-4" />
          <span>{t('OrganizationUserManagement.downloadExcel') as string}</span>
        </button>
        <input type='button' />
      </div>
      <div className={`flex gap-1 text-xs ml-2 mb-4 text-neutral-500 z-30 w-full `}>
        {t('OrganizationUserManagement.downloadExcelDesc') as string}
        <img data-tooltip-id="my-tooltip" 
             className={`mb-1 w-4.5 cursor-pointer inline-block`} 
             style={{marginTop:"-1px"}} 
             src={hint} />
        <Tooltip id="my-tooltip" style={{ backgroundColor: "#0055ab", color: "#19231b" }} >
          <div className={`block mt-4 mb-4 z-30 `}>
            <div className={`block text-sm mb-1 text-white`}>{ t('OrganizationUserManagement.importUserDesc1') as string }</div>
            <div className={`block text-sm mb-1 text-white`}>{ t('OrganizationUserManagement.importUserDesc2') as string }</div>
            <div className={`block text-sm mb-1 text-white`}>{ t('OrganizationUserManagement.importUserDesc3') as string }</div>
            <div className={`block text-sm mb-1 text-white`}>{ t('OrganizationUserManagement.importUserDesc4') as string }</div>
            <div className={`block text-sm mb-1 text-white`}>{ t('OrganizationUserManagement.importUserDesc5') as string }</div>
            <div className={`block text-sm mb-1 text-white`}>{ t('OrganizationUserManagement.importUserDesc6') as string }</div>
          </div>
        </Tooltip>
      </div>
    </>
  );
};

export default RightTopBarImport;
