import InputText from '../../common/inputs/InputText';
import { useState } from 'react';
import { SelectListValue } from '../../../interfaces/TypeSelectListbox';
import SelectListbox from '../../common/selectList/SelectListbox';

const selectValues: SelectListValue[] = [
  { id: '1', name: '유씨웨어1' },
  { id: '2', name: '유씨웨어2' },
  { id: '3', name: '유씨웨어3' },
];

const DownFormContainer = () => {
  const [selected, setSelected] = useState(selectValues[0]);

  return (
    <div className="h-full w-full flex flex-col gap-1.5 overflow-auto">
      <div className="flex w-full">
        <div className="min-w-[8rem] self-center align-middle">DB 종류</div>
        <div className="h-9 z-10">
          <SelectListbox
            options={selectValues}
            value={selected}
            optionsHeight={12}
            width={'19.5rem'}
            onChange={(value) => {
              setSelected(value);
            }}
          />
        </div>
      </div>
      <div className="flex">
        <div className="min-w-[8rem] self-center">버튼 이미지 크기</div>
        <div className="w-full">
          <InputText placeholder="placeholder" />
        </div>
      </div>
      <div className="flex">
        <div className="min-w-[8rem] self-center">데이터베이스</div>
        <div className="w-full">
          <InputText placeholder="placeholder" />
        </div>
      </div>
      <div className="flex">
        <div className="min-w-[8rem] self-center">사용자명</div>
        <div className="w-full">
          <InputText placeholder="placeholder" />
        </div>
      </div>
      <div className="flex">
        <div className="min-w-[8rem] self-center">패스워드</div>
        <div className="w-full">
          <InputText placeholder="placeholder" />
        </div>
      </div>
      <div className="flex">
        <div className="min-w-[8rem] self-center">DB 호스트명</div>
        <div className="w-full">
          <InputText placeholder="placeholder" />
        </div>
      </div>
      <div className="flex">
        <div className="min-w-[8rem] self-center">DB 포트</div>
        <div className="w-full">
          <InputText placeholder="placeholder" />
        </div>
      </div>
      <div className="flex">
        <div className="min-w-[8rem] self-center">설명</div>
        <div className="w-full">
          <InputText placeholder="placeholder" />
        </div>
      </div>
      <div className="flex">
        <div className="min-w-[8rem] self-center">DB 속성</div>
        <div className="w-full">
          <InputText placeholder="placeholder" />
        </div>
      </div>
    </div>
  );
};

export default DownFormContainer;
