import { t } from 'i18next';
import { toast } from 'react-toastify';
import { adminRoleAddApi, adminRoleModifyApi, adminRoleRemoveApi } from './../../api/admin/api-amin-role';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { IroleAddApiBody, IroleModifyApiBody, IroleRemoveApiBody } from '../../interfaces/api/I-api-admin-role';
import axios from 'axios';

interface InitialState {
  // status
  adminRoleStatus: 'idle' | 'pending' | 'success' | 'fail';
  adminRoleAddStatus: 'idle' | 'pending' | 'success' | 'fail';
  adminRoleRemoveStatus: 'idle' | 'pending' | 'success' | 'fail';
}

const initialState: InitialState = {
  adminRoleStatus: 'idle',
  adminRoleAddStatus: 'idle',
  adminRoleRemoveStatus: 'idle',
};

// add
export const addRole = createAsyncThunk('role/add', async (rule: IroleAddApiBody, { rejectWithValue }) => {
  try {
    const response = await adminRoleAddApi(rule);

    if (response?.status === 200) {
      toast.success(String(t('AdminIdManagement.toast3')));
    }

    return response?.data.data.result;
  } catch (error) {
    toast.error(String(t('AdminIdManagement.toast4')));
    if (axios.isAxiosError(error)) {
      console.log('error: ', error);
      return rejectWithValue(error.message);
    } else {
      console.log('unexpected error: ', error);
      return rejectWithValue('unexpected error');
    }
  }
});

// modify
export const modifyRole = createAsyncThunk('role/modify', async (rule: IroleModifyApiBody, { rejectWithValue }) => {
  try {
    const response = await adminRoleModifyApi(rule);

    if (response?.status === 200) {
      toast.success('수정되었습니다.');
    }

    return response?.data.data.result;
  } catch (error) {
    toast.error(String(t('AdminIdManagement.toast6')));
    if (axios.isAxiosError(error)) {
      console.log('error: ', error);
      return rejectWithValue(error.message);
    } else {
      console.log('unexpected error: ', error);
      return rejectWithValue('unexpected error');
    }
  }
});

// remove
export const removeRole = createAsyncThunk('role/remove', async (rule: IroleRemoveApiBody, { rejectWithValue }) => {
  try {
    const response = await adminRoleRemoveApi(rule);

    if (response?.status === 200) {
      toast.success(String(t('AdminIdManagement.toast7')));
    }
    return response?.data.data.result;
  } catch (error) {
    toast.error(String(t('AdminIdManagement.toast8')));
    if (axios.isAxiosError(error)) {
      console.log('error: ', error);
      return rejectWithValue(error.message);
    } else {
      console.log('unexpected error: ', error);
      return rejectWithValue('unexpected error');
    }
  }
});

export const adminIdManagementSlice = createSlice({
  name: 'adminIdManagement',
  initialState,
  reducers: {
    initAdminIdManagementSlice: () => initialState,
  },
  extraReducers(builder) {
    builder
      // role
      // delete
      .addCase(removeRole.fulfilled, (state) => {
        state.adminRoleStatus = 'pending';
        state.adminRoleRemoveStatus = 'pending';
      })
      .addCase(removeRole.pending, (state) => {
        state.adminRoleStatus = 'success';
        state.adminRoleRemoveStatus = 'success';
      })
      .addCase(removeRole.rejected, (state) => {
        state.adminRoleStatus = 'fail';
        state.adminRoleRemoveStatus = 'fail';
      })

      // add
      .addCase(addRole.fulfilled, (state) => {
        state.adminRoleStatus = 'pending';
        state.adminRoleAddStatus = 'pending';
      })
      .addCase(addRole.pending, (state) => {
        state.adminRoleStatus = 'success';
        state.adminRoleAddStatus = 'success';
      })
      .addCase(addRole.rejected, (state) => {
        state.adminRoleStatus = 'fail';
        state.adminRoleAddStatus = 'fail';
      });
  },
});

export const { initAdminIdManagementSlice } = adminIdManagementSlice.actions;

export default adminIdManagementSlice.reducer;
