import { useEffect, useState } from 'react';
// 라이브러라
import { SplitPane } from 'react-multi-split-pane';
import { useResizeDetector } from 'react-resize-detector';

// 컴포넌트
import DownFormContainer from './DownFormContainer';
import DownTopBar from './DownTopBar';
import UpperListContainer from './UpperListContainer';
import UpperTopBar from './UpperTopBar';

import {
  handleViewPortSize,
  PaneProps,
  splitPaneViewportCase,
} from '../../../helpers/h-splitPane';
import MainPageTitleHeader from '../../common/MainPageTitleHeader';

const LogViewer = () => {
  const [splitPaneViewportKey, setSplitPaneViewportKey] = useState(
    splitPaneViewportCase.default,
  );

  return (
    <div className="h-full w-full">
      <SplitPane
        className="custom"
        split="horizontal"
        minSize={[100, 200]}
        defaultSizes={[348, 628]}
      >
        <Upper
          setSplitPaneViewportKey={setSplitPaneViewportKey}
          splitPaneUpdatedState={splitPaneViewportKey}
        />
        <Down
          setSplitPaneViewportKey={setSplitPaneViewportKey}
          splitPaneUpdatedState={splitPaneViewportKey}
        />
      </SplitPane>
    </div>
  );
};

const Upper = ({
  setSplitPaneViewportKey,
  splitPaneUpdatedState,
}: PaneProps) => {
  const { ref } = useResizeDetector();

  useEffect(() => {
    ref.current.parentElement.style.flex = splitPaneUpdatedState.upper;
  }, [splitPaneUpdatedState]);

  return (
    <div
      ref={ref}
      className="w-full h-full px-[1.5rem] flex flex-col border border-solid border-[#dfe2e8] bg-white rounded"
    >
      <MainPageTitleHeader
        text="로그 파일 목록"
        onClick={() =>
          handleViewPortSize([setSplitPaneViewportKey, 'clickedUpper'])
        }
      />
      <UpperTopBar />
      <UpperListContainer />
    </div>
  );
};

const Down = ({
  setSplitPaneViewportKey,
  splitPaneUpdatedState,
}: PaneProps) => {
  const { ref } = useResizeDetector();

  useEffect(() => {
    ref.current.parentElement.style.flex = splitPaneUpdatedState.down;
  }, [splitPaneUpdatedState]);

  return (
    <div
      ref={ref}
      className="w-full h-full px-[1.5rem] py-defaultY border border-solid border-[#dfe2e8] rounded bg-white"
    >
      {/* <MainPageTitleHeader text="실시간 접속 사용자 수" /> */}
      <DownTopBar
        onClick={() =>
          handleViewPortSize([setSplitPaneViewportKey, 'clickedDown'])
        }
      />
      <DownFormContainer />
    </div>
  );
};

export default LogViewer;
