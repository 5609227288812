import React, { useState } from 'react';
import { SelectListValue } from '../../../interfaces/TypeSelectListbox';
import DoubleLeftArrow from '../../../assets/svg/arrows/DoubleLeftArrow';
import DoubleRightArrow from '../../../assets/svg/arrows/DoubleRightArrow';
import LeftArrow from '../../../assets/svg/arrows/LeftArrow';
import RightArrow from '../../../assets/svg/arrows/RightArrow';
import SelectListbox from '../../common/selectList/SelectListbox';

const selectValues: SelectListValue[] = [
  { id: '1', name: '25' },
  { id: '2', name: '60' },
  { id: '3', name: '180' },
];

const NavBar = () => {
  const [selected, setSelected] = useState(selectValues[0]);

  return (
    <div className="flex justify-end gap-5 pb-defaultY">
      <div className="flex gap-3 justify-centet items-center">
        <div className="flex gap-1">
          <DoubleLeftArrow />
          <LeftArrow />
          <RightArrow />
          <DoubleRightArrow />
        </div>
        <div>4/18</div>
      </div>
      <div className="flex gap-3">
        <span className="flex justify-centet items-center">열 개수</span>
        <div className="w-16 h-9">
          <SelectListbox
            options={selectValues}
            value={selected}
            optionsHeight={12}
            // width={'5rem'}
            onChange={(value) => {
              setSelected(value);
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default NavBar;
