import React from 'react';
import SearchIcon from './icons/SearchIcon';
import InputText from './inputs/InputText';

interface Props {
  value: string;
  placeholder?: string;
  onClickSearch: () => void;
  handleChangeInput: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleClear: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  className?: string;
}

const InputWithSearch = (props: Props) => {
  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        props.onClickSearch();
      }}
      className={props.className}
      style={{
        position: 'relative',
      }}
    >
      <button type="submit" className="absolute left-2 top-0 bottom-0 z-10">
        <SearchIcon />
      </button>
      <InputText
        className="pl-8"
        placeholder={props.placeholder ? props.placeholder : ''}
        value={props.value}
        handleChangeInput={props.handleChangeInput}
        handleClear={props.handleClear}
      />
    </form>
  );
};

export default InputWithSearch;
