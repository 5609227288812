import { useState, useEffect } from 'react';
import { hrsOrgsListApi } from '../api/hrs/api-hrs-orgs';
import { IHrsOrgsListApiResponse } from '../interfaces/api/I-api-hrs-orgs';
import base64 from 'base-64';
import { useAppDispatch, useAppSelector } from '../redux/hooks';
import { useDeptContext } from '../providers/DeptProvider';

type DeptInfo = {
  // Define the structure of your response here
};

export default function useDeptInfoList() {
  const [data, setData] = useState<IHrsOrgsListApiResponse | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const user = useAppSelector((state) => state.user);
  const {deptMap, setDeptMap} = useDeptContext();
  const dispatch = useAppDispatch();

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      setError(null);
      const token = sessionStorage.getItem('jwtToken');

      if(!token || !user.userInfo) return;

      try {
        const payload = token.substring(token.indexOf('.') + 1, token.lastIndexOf('.'));
        const userObj = JSON.parse(base64.decode(payload));

        const response = await hrsOrgsListApi({
          cmd: '1103',
          sid: userObj.aud,
          what: [{ gid: user.userInfo.rootgid}],
          fields: 'FULL',
          include: 'DEPT',
          kind: 'CHILDREN',
          extra: 'ALL',
        });
        setData(response?.data || null);

        const deptMap = new Map<string, string[]>();

        const deptInfoArray = response?.data.data.result.dept?.data.map((info) => {
          return {[info.deptname]: info.deptcode};
        }) || [];

       for(const item of deptInfoArray) {
         const [key, value] = Object.entries(item)[0];
         const existing = deptMap.get(key) || [];
         deptMap.set(key, [...existing, value]);
       }

       setDeptMap(deptMap);

      } catch (err: any) {
        setError(err?.message || 'An error occurred while fetching data');
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, []);

  return { data, isLoading, error };
}