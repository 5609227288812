import React, { useEffect, useState } from 'react';
import Draggable from 'react-draggable';
import { Resizable } from 'react-resizable';
import Button from '../../common/buttons/Button';
import { modifyRuleFuncHubHandler } from '../../../helpers/h-ruleFuncMng';
import {
  closeAllFuncBtnModal,
  closeRuleFuncOptionModal,
} from '../../../helpers/h-modals';
import { ResizeCallbackData } from '../../../interfaces/I-modals';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { IruleFuncHubOption } from '../../../interfaces/api/rule/I-api-admin-rule-func-hub';
import { Field, Form, Formik, FormikProps } from 'formik';
import InputTextFormik from '../../common/inputs/InputTextFormik';

interface Props {
  onClickClose?: () => void;
}
interface ICell {
  title: string;
  children: React.ReactNode;
  required?: boolean;
}

const TextCell: React.FC<ICell> = (props) => {
  return (
    <div className="flex w-full">
      <div className="min-w-[12.625rem] flex items-center text-[0.875rem] leading-[0.86rem]">
        {props.title}
        {props.required && <span className="text-[#d20707]">*</span>}
      </div>
      <div className="grow flex gap-1">{props.children}</div>
    </div>
  );
};

const RuleFuncMng_Option_Modal = (props: Props) => {
  const dispatch = useAppDispatch();
  const { selectedRuleFuncHubOption } = useAppSelector(
    (state) => state.ruleFunctionManagement,
  );

  const [localRuleFuncHubOption, setLocalRuleFuncHubOption] =
    useState<IruleFuncHubOption | null>(null);
  const [title, setTitle] = useState('');
  const [titleReady, setTitleReady] = useState(false);
  const [field1, setField1] = useState('');
  const [field2, setField2] = useState('');
  const [value1, setValue1] = useState('');
  const [value2, setValue2] = useState('');

  const [size, setSize] = useState({
    width: 800,
    height: 260,
  });

  const onResize = (
    e: React.SyntheticEvent<Element, Event>,
    data: ResizeCallbackData,
  ) => {
    setSize({
      width: data.size.width,
      height: data.size.height,
    });
  };

  // field, value 구하기
  useEffect(() => {
    if (localRuleFuncHubOption === null) return;

    setTitle(localRuleFuncHubOption.funcname);
    setField1(localRuleFuncHubOption.funcfield1);
    setField2(localRuleFuncHubOption.funcfield2);
    setValue1(localRuleFuncHubOption.funcvalue1);
    setValue2(localRuleFuncHubOption.funcvalue2);
  }, [localRuleFuncHubOption]);

  // 전역변수 --> 지역변수
  useEffect(() => {
    if (selectedRuleFuncHubOption.funcname.length < 1) return;
    if (titleReady) return;

    setLocalRuleFuncHubOption(selectedRuleFuncHubOption);
    setTitleReady(true);
  }, [selectedRuleFuncHubOption]);

  // 클리어 함수
  useEffect(() => {
    return () => {
      closeAllFuncBtnModal();
    };
  }, []);

  return (
    <Draggable handle="#handle">
      <Resizable
        handle={
          <div
            className="resizable-custom-handler absolute"
            style={{
              left: size.width - 19,
              top: size.height - 19,
            }}
          ></div>
        }
        height={size.height}
        width={size.width}
        onResize={onResize}
      >
        <div className="modal-wrapper text-[#555555]">
          {/* WRAPPER */}
          <div
            className="box drag-box  flex flex-col px-[1.25rem]
            relative border border-modal-subTitle bg-white z-50 shadow-listbox-options
            rounded overflow-hidden
          "
            style={{ width: `${size.width}px`, height: `${size.height}px` }}
          >
            {/* HEADER */}
            <div
              id="handle"
              className="min-h-[3.5rem] cursor-move w-full flex flex-col relative"
            >
              <div className="grow font-bold w-full flex items-center truncate">
                {title}
              </div>
            </div>
            {/* BODY */}
            {localRuleFuncHubOption && (
              <Formik
                initialValues={localRuleFuncHubOption}
                // validationSchema={userAddFormSchema}
                onSubmit={(values) => {
                  // alert(JSON.stringify(values, null, 2));
                  // console.log(values);
                  modifyRuleFuncHubHandler(values);
                }}
              >
                {(formik: FormikProps<IruleFuncHubOption>) => {
                  return (
                    <Form className="grow w-full flex flex-col gap-1.5 overflow-y-auto">
                      <TextCell title={field1}>
                        <Field
                          component={InputTextFormik}
                          name={`funcvalue1`}
                          value={`funcvalue1`}
                          handleClear={() => {
                            formik.setFieldValue('funcvalue1', '');
                          }}
                          grow
                        />
                      </TextCell>
                      <TextCell title={field2}>
                        <Field
                          component={InputTextFormik}
                          name={`funcvalue2`}
                          value={`funcvalue2`}
                          handleClear={() => {
                            formik.setFieldValue('funcvalue2', '');
                          }}
                          grow
                        />
                      </TextCell>
                      <div className="min-h-[6rem] w-full gap-6 jcac">
                        <Button
                          onClick={closeRuleFuncOptionModal}
                          kind="cancel"
                          label="취소"
                        />
                        <Button
                          onClick={() => {
                            formik.handleSubmit();
                            closeRuleFuncOptionModal();
                          }}
                          kind="primary"
                          label="저장"
                        />
                      </div>
                    </Form>
                  );
                }}
              </Formik>
            )}
            {/* FOOTER */}
            {/* 닫기 버튼 */}
            <button
              onClick={closeRuleFuncOptionModal}
              className="absolute right-1 top-1"
            >
              X
            </button>
          </div>
        </div>
      </Resizable>
    </Draggable>
  );
};

export default RuleFuncMng_Option_Modal;
