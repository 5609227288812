import React from 'react';
import Modal from 'react-modal';
import 'rc-tree/assets/index.css'; // Import rc-tree CSS
import { closeClassDepartmentModal } from '../../../helpers/h-react-modals';
import TreeViewV7 from '../../treeView/TreeViewV7';

const customStyles = {
  overlay: {
    zIndex: 99,
  },
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    width: '400px',
    height: '720px',
    padding: 0,
  },
};

interface Props {
  isOpen: boolean;
}

const ClassDepartmentModal: React.FC<Props> = ({ isOpen = false }) => {
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={closeClassDepartmentModal}
      contentLabel="Class Department Modal"
      style={customStyles}
    >
      <TreeViewV7 width={300} />
    </Modal>
  );
};

export default ClassDepartmentModal;
