import { counselCategoryAddApi } from '../api/admin/counseling/api-admin-counsel-category';
import {
  counselChannelAddApi,
  counselChannelModifyApi,
  counselChannelRemoveApi,
} from '../api/admin/counseling/api-admin-counsel-channel';
import { counselCounselorAddApi, counselCounselorRemoveApi } from '../api/admin/counseling/api-admin-counsel-counselor';
import {
  IAdminCounselChannelReq,
  IAdminCounselCounselorReq,
  IChannel,
  IChannelv2,
  IModCategoryDTO,
} from '../interfaces/api/counsel/I-api-admin-counsel-category';
import {
  addCategory,
  modifyCategory,
  modifyCategoryOrder,
  removeCategory,
  removeChannel,
} from '../redux/slice/counselingManagementSlice';
import { modifyDept } from '../redux/slice/organizationUserManagementSlice';
import { store } from '../redux/store';
const dispatch = store.dispatch;

/*
 * @@@@ 카테고리 관련
 */
export const reqAddCategory = (categoryName: string) => {
  if (!categoryName) return;
  const body = {
    cmd: '1502',
    orgcode: 'ORG01',
    name: categoryName,
  };

  dispatch(addCategory(body));
};

export const reqModifyCategory = (data: IModCategoryDTO[]) => {
  if (!data) return;
  const body = {
    cmd: '1503',
    orgcode: 'ORG01',
    what: data,
  };

  dispatch(modifyCategory(body));
};

export const reqModifyCategoryOrder = (data: IModCategoryDTO[]) => {
  if (!data) return;
  const body = {
    cmd: '1503',
    orgcode: 'ORG01',
    what: data,
  };

  dispatch(modifyCategoryOrder(body));
};

export const reqDeleteCategory = (id: string) => {
  if (!id) return;
  const body = {
    cmd: '1504',
    orgcode: 'ORG01',
    id: id,
  };

  dispatch(removeCategory(body));
};

/*
 * @@@@ 채널 관련
 */

export const reqAddChannel = (data: IChannelv2) => {
  const body = {
    ...data,
    cmd: '1506',
  };
  const res = counselChannelAddApi(body);
  return res;
};

export const reqModifyChannel = (data: IAdminCounselChannelReq) => {
  const body = {
    ...data,
    cmd: '1507',
  };
  const res = counselChannelModifyApi(body);
  return res;
};

export const reqDeleteChannel = (id: string) => {
  if (!id) return;
  const body = {
    cmd: '1508',
    orgcode: 'ORG01',
    id: id,
  };
  const res = counselChannelRemoveApi(body);
  return res;
  // dispatch(removeChannel(body));
};

export const reqAddCounselor = (data: IAdminCounselCounselorReq) => {
  const body = {
    ...data,
    cmd: '1510',
  };
  const res = counselCounselorAddApi(body);
  return res;
};

export const reqRemoveCounselor = (data: IAdminCounselCounselorReq) => {
  const body = {
    cmd: '1512',
    orgcode: 'ORG01',
    ...data,
  };

  const res = counselCounselorRemoveApi(body);
  return res;
};

export const reqPresignedUrlIssue = (data: IAdminCounselCounselorReq) => {
  const body = {
    cmd: '1303',
    orgcode: 'ORG01',
    ...data,
  };

  const res = counselCounselorRemoveApi(body);
  return res;
};
