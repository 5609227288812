import React, { useEffect, useState } from 'react';
import Draggable from 'react-draggable';
import { Resizable } from 'react-resizable';
import Button from '../../common/buttons/Button';
import InputText from '../../common/inputs/InputText';
import { modifyCntBtnGroupHandler } from '../../../helpers/h-cntBtnMng';
import { IAdminButtonGroup } from '../../../interfaces/api/button/I-api-admin-btn-group';
import { ResizeCallbackData } from '../../../interfaces/I-modals';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { initSelectedCntBtnGroupModal } from '../../../redux/slice/countButtonManagementSlice';

interface Props {
  onClickClose?: () => void;
}

const CntBtnGroupMngModal_MODIFY = (props: Props) => {
  const [size, setSize] = useState({
    width: 352,
    height: 236,
  });
  const dispatch = useAppDispatch();
  const { selectedCntBtnGroupModal } = useAppSelector(
    (state) => state.countButtonManagement,
  );
  const [localSelectedCntBtnGroup, setLocalSelectedCntBtnGroup] =
    useState<IAdminButtonGroup | null>(null);

  const onResize = (
    e: React.SyntheticEvent<Element, Event>,
    data: ResizeCallbackData,
  ) => {
    setSize({
      width: data.size.width,
      height: data.size.height,
    });
  };

  // 모달 닫기 + 선택했던 버튼그룹 초기화
  const handleCloseModal = () => {
    props.onClickClose && props.onClickClose();
  };

  // 카운트 버튼 그룹 이름 핸들러
  const cntBtnGroupNameHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    localSelectedCntBtnGroup &&
      setLocalSelectedCntBtnGroup({
        ...localSelectedCntBtnGroup,
        groupname: e.target.value,
      });
  };

  // 카운트 버튼 그룹 수정 핸들러
  const handleOnSubmit = () => {
    modifyCntBtnGroupHandler(localSelectedCntBtnGroup);
  };

  // 리덕스 스테이트 로컬 스테이트로 옮기기.
  useEffect(() => {
    if (selectedCntBtnGroupModal === null) return;
    if (localSelectedCntBtnGroup !== null) return;
    setLocalSelectedCntBtnGroup({ ...selectedCntBtnGroupModal });
  }, [selectedCntBtnGroupModal]);

  // 클리어 함수
  useEffect(() => {
    return () => {
      dispatch(initSelectedCntBtnGroupModal());
    };
  }, []);

  return (
    <Draggable handle="#handle">
      <Resizable
        handle={
          <div
            className="resizable-custom-handler absolute"
            style={{
              left: size.width - 19,
              top: size.height - 19,
            }}
          ></div>
        }
        height={size.height}
        width={size.width}
        onResize={onResize}
      >
        <div className="modal-wrapper">
          {/* WRAPPER */}
          <div
            className="box drag-box  flex flex-col px-[1.25rem]
            relative border border-modal-subTitle bg-white z-50 shadow-listbox-options
            rounded
          "
            style={{ width: `${size.width}px`, height: `${size.height}px` }}
          >
            {/* HEADER */}
            <div
              id="handle"
              className="min-h-[3.5rem] cursor-move w-full flex flex-col relative"
            >
              <div className="grow font-bold w-full flex items-center truncate">
                버튼 그룹 수정
              </div>
            </div>
            {/* BODY */}
            <div className="grow w-full flex flex-col gap-3 overflow-y-auto">
              그룹 이름
              <InputText
                value={
                  localSelectedCntBtnGroup
                    ? localSelectedCntBtnGroup.groupname
                    : ''
                }
                handleChangeInput={cntBtnGroupNameHandler}
              />
              {/* 테이블 */}
            </div>
            {/* FOOTER */}
            <div className="min-h-[6rem] w-full  jcac">
              <div className="flex gap-5">
                <Button onClick={handleCloseModal} kind="cancel" label="취소" />
                <Button onClick={handleOnSubmit} kind="primary" label="저장" />
              </div>
            </div>
            {/* 닫기 버튼 */}
            <button
              onClick={handleCloseModal}
              className="absolute right-1 top-1"
            >
              X
            </button>
          </div>
        </div>
      </Resizable>
    </Draggable>
  );
};

export default CntBtnGroupMngModal_MODIFY;
