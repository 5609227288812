import { useEffect, useMemo, useState } from 'react';
import Button from '../../common/buttons/Button';
import { removeCntBtnFromBtnGroupHandler } from '../../../helpers/h-cntBtnMng';
import { openCntBtnGroupMainModal } from '../../../helpers/h-modals';
import { SelectListValue } from '../../../interfaces/TypeSelectListbox';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import {
  initSelectedCntBtn,
  setSelectedCntBtnGroup,
  setSelectedCntBtnGroupsBtn,
} from '../../../redux/slice/countButtonManagementSlice';
import SelectListbox from '../../common/selectList/SelectListbox';

const UpperRightTopBar = () => {
  const dispatch = useAppDispatch();
  const { cntBtnGroupList, selectedCntBtnGroupsBtn, selectedCntBtnGroup } =
    useAppSelector((state) => state.countButtonManagement);
  // LISTBOX
  const deafaultSelectValues: SelectListValue[] = useMemo(() => {
    return [{ id: '1', name: 'empty' }];
  }, []);
  const [selected, setSelected] = useState(deafaultSelectValues[0]);
  const [buttonGroupSelectList, setButtonGroupSelectList] = useState<
    SelectListValue[] | null
  >(null);

  // selectedCntBtnGroup 설정
  const handleSelectCntBtnGroup = (index: number) => {
    if (cntBtnGroupList === null) return;
    dispatch(setSelectedCntBtnGroup(cntBtnGroupList[index]));
  };

  // LISTBOX VALUE 설정
  useEffect(() => {
    if (buttonGroupSelectList === null) return;
    setSelected(buttonGroupSelectList[0]);
  }, [buttonGroupSelectList]);

  // 버튼그룹 SELECT-LISTBOX DATA 설정
  useEffect(() => {
    if (cntBtnGroupList === null) return;
    else if (cntBtnGroupList.length > 0) {
      setButtonGroupSelectList(
        cntBtnGroupList.map((buttonGroup, index) => ({
          id: index.toString(),
          name: buttonGroup.groupname,
        })),
      );
    } else {
      setButtonGroupSelectList(deafaultSelectValues);
    }
  }, [cntBtnGroupList]);

  return (
    <div className="py-[0.563rem] gap-1 flex text-fontColor-default">
      <Button
        kind="cancel"
        label="버튼 그룹 관리"
        onClick={openCntBtnGroupMainModal}
      />
      <SelectListbox
        options={
          buttonGroupSelectList ? buttonGroupSelectList : deafaultSelectValues
        }
        value={selected}
        optionsHeight={12}
        width={'9.62rem'}
        onChange={(value) => {
          setSelected(value);
          if (cntBtnGroupList.length < 1) return;
          handleSelectCntBtnGroup(parseInt(value.id));
        }}
      />
      <Button
        kind="cancel"
        label="버튼 그룹에서 삭제"
        onClick={() => {
          removeCntBtnFromBtnGroupHandler(
            selectedCntBtnGroupsBtn,
            selectedCntBtnGroup,
          );
          dispatch(setSelectedCntBtnGroupsBtn(null));
        }}
      />
      {/* <Button kind="cancel" label="버튼 정보 저장" /> */}
      {/* <Button kind="cancel" label="서버 적용" /> */}
    </div>
  );
};

export default UpperRightTopBar;
