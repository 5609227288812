import { ColDef, GridReadyEvent, IDatasource } from 'ag-grid-community';
import { AgGridReact } from 'ag-grid-react';
import axios from 'axios';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { adminUserListApi } from '../../../api/admin/api-admin-user';
import { IadminUserListApiResponse } from '../../../interfaces/api/I-api-admin-users';
import { IUserInfo } from '../../../interfaces/api/I-api-hrs-users';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { initSelectedUser } from '../../../redux/slice/organizationUserManagementSlice';
import RightTopBar from '../../mainPage/rule-designation/RightTopBar';
import { USER_LIST_TALBLE_COLUMN } from './columns';

const RulDesignationUserList_Table = () => {
  const dispatch = useAppDispatch();
  const { ruleUserStatus } = useAppSelector(
    (state) => state.ruleFunctionManagement,
  );
  const [selectedUsers, setSelectedUsers] = useState<
    IadminUserListApiResponse[]
  >([]);

  // AG_GRID
  const [gridApi, setGridApi] = useState<any>();
  const gridRef = useRef<any>();
  const [columnDefs, setColumnDefs] = useState<ColDef[]>(
    USER_LIST_TALBLE_COLUMN,
  );
  const defaultColDef = useMemo<ColDef>(() => {
    return {
      flex: 1,
      resizable: true,
      minWidth: 100,
    };
  }, []);
  const onSelectionChanged = useCallback(() => {
    const selectedRows = gridRef.current.api.getSelectedRows();
    setSelectedUsers(selectedRows);
  }, []);

  // 테이블 인피니트 스크롤용
  let page = 1;
  const dataSource: IDatasource = {
    rowCount: undefined,
    getRows: (params) => {
      adminUserListApi({ page })
        .then((resp) => ({
          userList: resp?.data.data.result,
          totpage: resp?.data.data.totpage,
          totcount: resp?.data.data.totcount,
        }))
        .then((data) => {
          if (!data) return;
          if (!data.totcount || !data.totpage || !data.userList) return;
          const lastPage =
            page < data.totpage ? 50 * page + 1 : parseInt(data.totcount);
          params.successCallback(data.userList, lastPage);
          page++;
        })
        .catch((error) => {
          if (axios.isAxiosError(error)) {
            console.log('error: ', error);
          } else {
            console.log('unexpected error: ', error);
          }
        });
    },
  };

  const onGridReady = (params: GridReadyEvent<IadminUserListApiResponse>) => {
    setGridApi(params.api);
    params.api.setDatasource(dataSource);
  };

  // 옵저버
  useEffect(() => {
    if (ruleUserStatus !== 'success') return;
    if (gridApi) {
      gridApi.deselectAll();
    }
  }, [ruleUserStatus]);

  // 클리어 함수
  useEffect(() => {
    return () => {
      dispatch(initSelectedUser());
    };
  }, []);

  return (
    <div className="w-full grow flex flex-col overflow-hidden">
      <RightTopBar
        gridApi={gridApi}
        selectedUsers={selectedUsers}
        setSelectedUsers={setSelectedUsers}
      />
      <div className="ag-theme-alpine ag-theme-mycustomtheme grow w-full">
        <AgGridReact
          ref={gridRef}
          columnDefs={columnDefs}
          defaultColDef={defaultColDef}
          rowModelType={'infinite'}
          cacheBlockSize={50}
          onGridReady={onGridReady}
          rowMultiSelectWithClick
          rowSelection={'multiple'}
          // onCellClicked={cellClickedListener}
          onSelectionChanged={onSelectionChanged}
          overlayNoRowsTemplate={'\xa0'}
        ></AgGridReact>
      </div>
    </div>
  );
};

export default RulDesignationUserList_Table;
