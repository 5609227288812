import axios from 'axios';
import Tree from 'rc-tree';
import { EventDataNode } from 'rc-tree/lib/interface';
import { Dispatch, Key, MutableRefObject, SetStateAction } from 'react';
import { toast } from 'react-toastify';
import { hrsOrgsListApi } from '../api/hrs/api-hrs-orgs';
import { hrsUserSearchApi } from '../api/hrs/api-hrs-users';
import { TreeData } from '../components/treeView/TreeViewV6';
// import { TreeData } from '../components/treeView/TreeViewV4';
import {
  IAdminUserAddApiUserList,
  IadminUserModifyApiBodyForOrgDragNDrop,
  IadminUserModifyApiUserList,
} from '../interfaces/api/I-api-admin-users';
import { IhrsOrgDeptFull, IhrsOrgUserAll } from '../interfaces/api/I-api-hrs-orgs';
import { IAdminOrgModifyApiBody } from '../interfaces/api/org/i-api-admin-org';
import { modifyDept, modifyUserAndScroll } from '../redux/slice/organizationUserManagementSlice';
import { store } from '../redux/store';
import { getMyOrgCode, getMyRootDeptCode, getMyTenantId } from './h-userInfo';

const dispatch = store.dispatch;

interface IonLoadData {
  selectedNode: EventDataNode<TreeData>;
  treeData: TreeData[] | null;
  setTreeData: Dispatch<SetStateAction<TreeData[] | null>>;
}
interface IgetFullTreeByValue {
  // setTreeData: Dispatch<SetStateAction<TreeData[] | null>>;
  // setAutoExpandParent: Dispatch<SetStateAction<boolean>>;
  // setDidSearch: Dispatch<SetStateAction<boolean>>;
  // setExpandedKeys: Dispatch<SetStateAction<Key[]>>;
  // setSelectedKeys: Dispatch<SetStateAction<Key[]>>;
  // treeRef: MutableRefObject<Tree<TreeData> | null>;
  searchInputValue: string;
  currentIndex: number;
  setCurrentIndex: (index: number) => void;
}

interface IloopDetp {
  nodes: TreeData[];
  deptPathList: string[][];
  rootDeptChildrenDept: IhrsOrgDeptFull[];
}

interface IloopUser {
  nodes: TreeData[];
  userPathList: string[];
  rootDeptChildrenUser: IhrsOrgUserAll[];
}

type User = IhrsOrgUserAll;
type Dept = IhrsOrgDeptFull;

type LoopNodes = (
  nodes: TreeData[],
  key: string,
  callback: (node: TreeData, index: number, arr: TreeData[]) => void,
) => void;

type SetUsersOrderCase = 'dragIsSmallerThanDrop' | 'dragIsLargerThanDrop';
interface IsetUsersOrderByKey {
  userArr: IhrsOrgUserAll[];
  bid: string;
  dragIndex: number;
  dropIndex: number;
  key: SetUsersOrderCase;
}

// 부서 정보 구하기 관련 함수
export const getChildDeptV2 = async ({ selectedNode, treeData }: IonLoadData): Promise<TreeData[] | null> => {
  const {
    user: { usersid },
  } = store.getState();

  if (treeData === null) return null;

  const curKey = selectedNode.key;
  const gid = selectedNode.data.gid;

  const res = await hrsOrgsListApi({
    cmd: '1103',
    sid: usersid ? usersid : '',
    kind: 'CHILD',
    fields: 'FULL',
    include: 'DEPT',
    extra: 'ALL',
    what: [{ gid }],
  });

  if (!res || !res.data.data.result.dept) return null;

  const deptList = res.data.data.result.dept.data;

  const deptChildren: TreeData[] = deptList.map((child) => ({
    key: `${child.deptpath}`,
    title: child.deptname,
    data: { ...child },
    isLeaf: false,
  }));

  const updatedTreeData = [...treeData];

  const loop = (nodes: TreeData[]) => {
    nodes.forEach((node) => {
      if (curKey === node.key) {
        if (node.children) {
          loop(node.children);
        } else {
          node.children = deptChildren;
        }
      }
    });
  };

  loop(updatedTreeData);
  return updatedTreeData;
};

// ! 부서 정보 구하기 관련 함수
// 키를 변경해야 받아온 deptcode로 부서를 선택되게 할수있음, deptpath는 유저정보에 없이떄문
export const getChildDeptV3 = async ({ selectedNode, treeData, setTreeData }: IonLoadData) => {
  const {
    user: { usersid },
  } = store.getState();

  if (treeData === null) return;

  const TreeData = [...treeData];
  const curKey = selectedNode.key;
  const gid = selectedNode.data.gid;
  const res = await hrsOrgsListApi({
    cmd: '1103',
    sid: usersid ? usersid : '',
    kind: 'CHILD',
    fields: 'FULL',
    include: 'DEPT',
    extra: 'ALL',
    what: [{ gid }],
  });
  if (!res) return;
  if (!res.data.data.result.dept) return;

  const deptList = res.data.data.result.dept.data;

  const deptChildren = deptList.map((child) => ({
    key: `${child.deptcode}`,
    title: child.deptname,
    data: { ...child },
    isLeaf: false,
  }));

  // 테스트 : curKey.indexOf(node.key) === 0 대신 curKey === node.key로 변경
  // 선택된 노드에 자식 데이터 추가하고, 자식 노드가 있으면 재귀적으로 탐색하도록 처라
  const updateTreeData = (nodes: TreeData[]): TreeData[] => {
    return nodes.map((node) => {
      if (node.key === curKey) {
        return { ...node, children: deptChildren };
      }
      if (node.children) {
        return { ...node, children: updateTreeData(node.children) };
      }
      return node;
    });
  };

  const updatedTree = updateTreeData(treeData);
  setTreeData(updatedTree);
};

// ! 부서 정보 구하기 관련 함수
// 1dept 하위 부서 정보 불러온후 setTreeData
export const getChildDept = async ({ selectedNode, treeData, setTreeData }: IonLoadData) => {
  const {
    user: { usersid },
  } = store.getState();

  if (treeData === null) return;

  const TreeData = [...treeData];
  const curKey = selectedNode.key;
  const gid = selectedNode.data.gid;
  const res = await hrsOrgsListApi({
    cmd: '1103',
    sid: usersid ? usersid : '',
    kind: 'CHILD',
    fields: 'FULL',
    include: 'DEPT',
    extra: 'ALL',
    what: [{ gid }],
  });
  if (!res) return;
  if (!res.data.data.result.dept) return;

  const deptList = res.data.data.result.dept.data;

  const deptChildren = deptList.map((child) => ({
    key: `${child.deptpath}`,
    title: child.deptname,
    data: { ...child },
    isLeaf: false,
  }));
  const children = deptChildren;
  console.log(children);
  const loop = (TreeData: TreeData[]) => {
    TreeData.forEach((node) => {
      if (curKey.indexOf(node.key) === 0) {
        if (node.children) {
          loop(node.children);
        } else {
          node.children = children;
        }
      }
    });
  };
  loop(TreeData);
  setTreeData(TreeData);
};

// ! 부서/사용자 정보 구하기 관련 함수
// 1dept 하위 부서/사용자 정보 불러온후 setTreeData
export const getChildDeptUser = async ({ selectedNode, treeData, setTreeData }: IonLoadData) => {
  const {
    user: { usersid },
  } = store.getState();

  if (treeData === null) return;

  const TreeData = [...treeData];
  const curKey = selectedNode.key;
  const gid = selectedNode.data.gid;
  const res = await hrsOrgsListApi({
    cmd: '1103',
    sid: usersid ? usersid : '',
    kind: 'CHILD',
    fields: 'FULL',
    include: 'DEPTUSER',
    extra: 'ALL',
    what: [{ gid }],
  });
  if (!res) return;
  if (!res.data.data.result.dept) return;
  if (!res.data.data.result.user) return;

  const deptList = res.data.data.result.dept.data;
  const userList = res.data.data.result.user.data;

  const deptChildren = deptList.map((child) => ({
    key: `${child.deptpath}`,
    title: child.deptname,
    data: { ...child },
    isLeaf: false,
  }));
  const userChildren = userList.map((child) => ({
    key: `${curKey}-${child.bid}`,
    title: child.username,
    data: { ...child },
    isLeaf: true,
  }));
  // const children = [...deptChildren, ...userChildren];
  const children = [...userChildren, ...deptChildren];
  console.log(children);
  const loop = (TreeData: TreeData[]) => {
    TreeData.forEach((node) => {
      if (curKey.indexOf(node.key) === 0) {
        if (node.children) {
          loop(node.children);
        } else {
          node.children = children;
        }
      }
    });
  };
  loop(TreeData);
  setTreeData(TreeData);
  console.log(TreeData);
};

export const getFullTree = async () => {
  const rootDeptCode = getMyRootDeptCode();

  if (!rootDeptCode) return console.log('error from fetchAllDept');

  const rootDeptRes = await getDeptByDeptCode(rootDeptCode);

  if (!rootDeptRes) return;
  if (!rootDeptRes.data.data.result.dept) return;
  if (rootDeptRes.data.data.result.dept.data.length < 1) return;

  const { rootDeptChildrenDept, rootDeptChildrenUser } = await getDeptChildrenDeptUser(rootDeptCode);

  if (rootDeptChildrenDept.length < 1 && rootDeptChildrenUser.length < 1) return null;

  const deptPathList = rootDeptChildrenDept.map((el) => el.deptpath.split(':').slice(0, -1));
  const userPathList = rootDeptChildrenUser.map((el) => el.deptpath);
  // 트리 구조 만들기 시작
  const rootDept = rootDeptRes.data.data.result.dept.data[0];
  const treeData = [
    {
      title: rootDept.deptname,
      key: rootDept.deptcode,
      data: {
        ...rootDept,
      },
    },
  ];
  loopDept({
    nodes: treeData,
    deptPathList,
    rootDeptChildrenDept,
  });
  loopUser({
    nodes: treeData,
    userPathList,
    rootDeptChildrenUser,
  });
  loopSort(treeData);
  return treeData;

  // setTreeData(treeData);
  // setAutoExpandParent(true);
  // setDidSearch(true);
};

export const getFullTreeByValue = async ({ searchInputValue, currentIndex, setCurrentIndex }: IgetFullTreeByValue) => {
  try {
    const searchUserRes = await getUserByName(searchInputValue);
    if (searchUserRes?.status !== 200) {
      return alert('error from searching user');
    }

    setCurrentIndex((currentIndex + 1) % searchUserRes.data.data.result.length);

    const sortedResults = searchUserRes.data.data.result.sort((a, b) => {
      if (a.deptlevel === b.deptlevel) {
        return a.userorder - b.userorder;
      }
      return a.deptlevel - b.deptlevel;
    });

    const user = sortedResults[currentIndex % sortedResults.length];

    if (!user || !user.deptpath) {
      setCurrentIndex(0);
      return;
    }

    // 사용자가 속한 부서 정보
    const userDeptRes = await getDeptByDeptCode(user.deptcode);

    if (!userDeptRes) return;
    if (!userDeptRes.data.data.result.dept) return;
    if (userDeptRes.data.data.result.dept.data.length < 1) return;

    const userDept = userDeptRes.data.data.result.dept.data[0];
    const keyForSelect = [userDept.deptpath, user.bid].join('-');
    const keyForExpand = userDept.deptpath;

    const userRootDeptCode = userDept.deptpath.split(':')[0];

    const rootDeptRes = await getDeptByDeptCode(userRootDeptCode);
    if (!rootDeptRes) return;
    if (!rootDeptRes.data.data.result.dept) return;
    if (rootDeptRes.data.data.result.dept.data.length < 1) return;

    const rootDeptChildrenRes = await getDeptUserChildrenByDeptCode(userRootDeptCode);

    if (!rootDeptChildrenRes) return;
    if (!rootDeptChildrenRes.data.data.result.dept) return;
    if (!rootDeptChildrenRes.data.data.result.user) return;

    // 루트 부서의 모든 하위 부서, 사용자 정보
    const rootDeptChildrenDept = rootDeptChildrenRes.data.data.result.dept.data;
    const rootDeptChildrenUser = rootDeptChildrenRes.data.data.result.user.data;

    if (rootDeptChildrenDept.length < 1 && rootDeptChildrenUser.length < 1) return null;

    const deptPathList = rootDeptChildrenDept.map((el) => el.deptpath.split(':').slice(0, -1));
    const userPathList = rootDeptChildrenUser.map((el) => el.deptpath);

    // 트리 구조 만들기 시작
    const rootDept = rootDeptRes.data.data.result.dept.data[0];
    const treeData = [
      {
        title: rootDept.deptname,
        key: rootDept.deptcode,
        data: {
          ...rootDept,
        },
      },
    ];

    loopDept({
      nodes: treeData,
      deptPathList,
      rootDeptChildrenDept,
    });
    loopUser({
      nodes: treeData,
      userPathList,
      rootDeptChildrenUser,
    });
    loopSort(treeData);

    return {
      treeData,
      keyForSelect,
      keyForExpand,
    };

    // setTreeData(treeData);
    // setAutoExpandParent(true);
    // setSelectedKeys([keyForSelect]);
    // setTimeout(() => {
    //   if (treeRef.current) {
    //     treeRef.current.scrollTo({
    //       key: keyForSelect,
    //       align: 'auto',
    //     });
    //   }
    // }, 100);
    // setExpandedKeys([keyForExpand]);
    // setDidSearch(true);
  } catch (error) {
    if (axios.isAxiosError(error)) {
      console.log('error: ', error);
    } else {
      console.log('unexpected error: ', error);
    }
  }
};

// 루트 -> 하위 모든 부서/사용자 조회후 {부서[],사용자[]} 반환
export const getDeptChildrenDeptUser = async (deptcode: string) => {
  console.log(deptcode);
  const rootDeptChildrenRes = await getDeptUserChildrenByDeptCode(deptcode);
  console.log(rootDeptChildrenRes);
  let rootDeptChildrenDept: IhrsOrgDeptFull[] = [];
  let rootDeptChildrenUser: IhrsOrgUserAll[] = [];

  if (!rootDeptChildrenRes) {
    alert('error from h-treeView.ts getDeptChildrenDeptUser 1');
    return { rootDeptChildrenDept, rootDeptChildrenUser };
  }

  if (!rootDeptChildrenRes.data.data.result.dept || !rootDeptChildrenRes.data.data.result.user) {
    alert('error from h-treeView.ts getDeptChildrenDeptUser 2');
    return { rootDeptChildrenDept, rootDeptChildrenUser };
  }

  rootDeptChildrenDept = rootDeptChildrenRes.data.data.result.dept.data;
  rootDeptChildrenUser = rootDeptChildrenRes.data.data.result.user.data;
  // console.log(rootDeptChildrenDept);
  // console.log(rootDeptChildrenUser);
  return { rootDeptChildrenDept, rootDeptChildrenUser };
};

// 사용자 검색으로 부서 정보 구하기
export const getUserByName = async (searchInputValue: string) => {
  const state = store.getState();
  return hrsUserSearchApi({
    cmd: '1102',
    sid: state.user.usersid ? state.user.usersid : '',
    reqpage: 1,
    reqcount: 100,
    kind: 'ALL',
    keyword: searchInputValue,
    fields: 'FULL',
    language: 'ko',
  });
};

// 부서 코드로 해당 부서의 단일 정보 구하기
export const getDeptByDeptCode = async (deptCode: string) => {
  const {
    user: { usersid },
  } = store.getState();
  const tenantid = getMyTenantId();
  const orgcode = getMyOrgCode();

  return await hrsOrgsListApi({
    cmd: '1103',
    sid: usersid ? usersid : '',
    kind: 'DEPT',
    fields: 'FULL',
    include: 'DEPT',
    extra: 'ALL',
    what: [{ gid: `${tenantid}.${orgcode}.${deptCode}` }],
  });
};

// 부서 코드로 해당 부서의 모든 하위 부서/사용자 정보 구하기.
export const getDeptUserChildrenByDeptCode = async (deptCode: string) => {
  const {
    user: { usersid },
  } = store.getState();
  const tenantid = getMyTenantId();
  const orgcode = getMyOrgCode();
  console.log(usersid);
  console.log(tenantid);
  console.log(orgcode);
  return await hrsOrgsListApi({
    cmd: '1103',
    sid: usersid ? usersid : '',
    what: [{ gid: `${tenantid}.${orgcode}.${deptCode}` }],
    kind: 'CHILDREN',
    fields: 'FULL',
    include: 'DEPTUSER',
    extra: 'ALL',
  });
};

// 부서 코드로 해당 부서의 하위 부서 정보 구하기
export const getChildDeptByDeptCode = async (deptCode: string) => {
  const {
    user: { usersid },
  } = store.getState();
  const tenantid = getMyTenantId();
  const orgcode = getMyOrgCode();

  const deptChildRes = await hrsOrgsListApi({
    cmd: '1103',
    sid: usersid ? usersid : '',
    what: [{ gid: `${tenantid}.${orgcode}.${deptCode}` }],
    kind: 'CHILD',
    fields: 'FULL',
    include: 'DEPT',
    extra: 'ALL',
  });

  if (!deptChildRes) return;

  const deptChild = deptChildRes.data.data.result.dept;
  if (!deptChild) return;

  return deptChild;
};

// 부서 코드로 해당 부서의 하위 사용자 정보 구하기
export const getChildUserByDeptCode = async (deptCode: string) => {
  const {
    user: { usersid },
  } = store.getState();
  const tenantid = getMyTenantId();
  const orgcode = getMyOrgCode();

  const userChildRes = await hrsOrgsListApi({
    cmd: '1103',
    sid: usersid ? usersid : '',
    what: [{ gid: `${tenantid}.${orgcode}.${deptCode}` }],
    kind: 'CHILD',
    fields: 'FULL',
    include: 'USER',
    extra: 'ALL',
  });

  if (!userChildRes) return;

  const userChild = userChildRes.data.data.result.user;
  if (!userChild) return;

  return userChild;
};

// 부서 코드로 해당 부서의 하위 사용자 / 부서 정보 구하기
export const getChildDeptUserByDeptCode = async (deptCode: string) => {
  const {
    user: { usersid },
  } = store.getState();
  const tenantid = getMyTenantId();
  const orgcode = getMyOrgCode();
  const deptUserChildRes = await hrsOrgsListApi({
    cmd: '1103',
    sid: usersid ? usersid : '',
    what: [{ gid: `${tenantid}.${orgcode}.${deptCode}` }],
    kind: 'CHILD',
    fields: 'FULL',
    include: 'DEPTUSER',
    extra: 'ALL',
  });

  if (!deptUserChildRes) return;

  const deptChild = deptUserChildRes.data.data.result.dept;
  const userChild = deptUserChildRes.data.data.result.user;

  if (!deptChild || !userChild) return;

  return { deptChild, userChild };
};

// 부서 코드로 해당 부서의 하위 부서 갯수 구하기
export const getChildDeptCountByDeptCode = async (deptCode: string) => {
  const childDept = await getChildDeptByDeptCode(deptCode);

  if (!childDept) return;

  const { count } = childDept;

  return count;
};

// 부서 코드로 해당 부서의 하위 사용자 갯수 구하기
export const getChildUserCountByDeptCode = async (deptCode: string) => {
  const childUser = await getChildUserByDeptCode(deptCode);

  if (!childUser) return;

  const { count } = childUser;

  return count;
};

// ! 재귀 함수
// 부서 재귀 함수
export const loopDept = ({ nodes, deptPathList, rootDeptChildrenDept }: IloopDetp) => {
  if (!nodes) return;

  for (const node of nodes) {
    for (const [index, deptPath] of deptPathList.entries()) {
      if (deptPath.length === 0) continue;

      const stringDeptPath = deptPath.join(':');

      if (stringDeptPath.indexOf(node.key) === 0) {
        if (stringDeptPath === node.key) {
          deptPath.splice(0, deptPath.length);
          const newChild = {
            key: rootDeptChildrenDept[index].deptpath,
            title: rootDeptChildrenDept[index].deptname,
            isLeaf: false,
            data: { ...rootDeptChildrenDept[index] },
          };
          if (node.children) {
            node.children.push(newChild);
          } else if (!node.children) {
            node.children = [newChild];
          }
        } else if (node.children) {
          loopDept({
            nodes: node.children,
            deptPathList,
            rootDeptChildrenDept,
          });
        }
      }
    }
  }
};

// 사용자 재귀 함수
export const loopUser = ({ nodes, userPathList, rootDeptChildrenUser }: IloopUser) => {
  //console.log(rootDeptChildrenUser);
  //console.log(userPathList);
  // if (!nodes) return;
  for (const node of nodes) {
    if (node.isLeaf) continue;
    for (const [index, deptPath] of userPathList.entries()) {
      if (deptPath === '') continue;

      if (deptPath.indexOf(node.key) === 0) {
        if (deptPath === node.key) {
          userPathList[index] = '';
          const newChild = {
            key: `${rootDeptChildrenUser[index].deptpath}-${rootDeptChildrenUser[index].bid}`,
            title: rootDeptChildrenUser[index].username,
            isLeaf: true,
            data: rootDeptChildrenUser[index],
          };
          if (node.children) {
            node.children.push(newChild);
          } else {
            node.children = [newChild];
          }
        } else if (node.children) {
          loopUser({
            nodes: node.children,
            userPathList,
            rootDeptChildrenUser,
          });
        }
      }
    }
  }
};

// 공통 콜백 재귀 함수

export const loopNodes: LoopNodes = (nodes, key, callback) => {
  nodes.forEach((node, index, arr) => {
    if (node.key === key) {
      callback(node, index, arr);
      return;
    }
    if (node.children) {
      loopNodes(node.children, key, callback);
    }
  });
};

// ! 순서 / 정렬 관련 함수
const sortDeptUserByLeaf = (a: TreeData) => {
  if (a.isLeaf) {
    return -1;
  }
  if (!a.isLeaf) {
    return 1;
  }
  return 0;
};

// userorder 오름차순 정렬
const sortDeptUserByUserorder = (a: TreeData, b: TreeData) => {
  if (a.data.type === 'hrsOrgDept') {
    return 0;
  }
  if (b.data.type === 'hrsOrgDept') {
    return 0;
  }
  if (a.data.userorder > b.data.userorder) {
    return 1;
  }
  if (a.data.userorder < b.data.userorder) {
    return -1;
  }

  return 0;
};

// 내부에서 정의한 정렬 함수롤통해 정렬된 트리노드를 반환
export const loopSort = (nodes: TreeData[]) => {
  nodes.sort(sortDeptUserByLeaf);
  nodes.sort(sortDeptUserByUserorder);
  for (const node of nodes) {
    if (node.children) {
      loopSort(node.children);
    }
  }
};

// 전달받은 두 사용자의 userorder를 바꾼후 반환
export const changeUserOrder = (dragUser: User, dropUser: User) => {
  const dragUser_userOrder = dragUser.userorder;
  const dropUser_userOrder = dropUser.userorder;

  dragUser = {
    ...dragUser,
    userorder: dropUser_userOrder,
  };
  dropUser = {
    ...dropUser,
    userorder: dragUser_userOrder,
  };

  return { dragUser, dropUser };
};

// treeData[] 에서 사용자 정보[]를 반환
export const getUsersArr = (treeDatas: TreeData[]) => {
  const usersArr = treeDatas.reduce((acc, cur) => {
    if (cur.data.type === 'hrsOrgUser') {
      acc.push(cur.data);
    }
    return acc;
  }, [] as IhrsOrgUserAll[]);
  return usersArr;
};

// treeData[] 에서 사용자 부서정보[]를 반환
export const getDeptArr = (treeDatas: TreeData[]) => {
  const usersArr = treeDatas.reduce((acc, cur) => {
    if (cur.data.type === 'hrsOrgDept') {
      acc.push(cur.data);
    }
    return acc;
  }, [] as Dept[]);
  return usersArr;
};
export const getDept = (treeData: TreeData) => {
  return treeData.data as Dept;
};

// 부서의 마지막 사용자로 지정
export const setDragUserToLastIndex = (userArr: IhrsOrgUserAll[], bid: string, index: number) => {
  const users = userArr.map((user, i, arr) => {
    if (user.bid === bid) {
      return { ...user, userorder: arr.length - 1 };
    }
    if (i === arr.length - 1) {
      return { ...user, userorder: i - 1 };
    }
    if (index < i && arr.length - 1 > i) {
      return { ...user, userorder: i - 1 };
    }
    return user;
  });
  return users;
};

// 부서의 첫번째 사용자로 지정
export const setDragUserToFirstIndex = (userArr: IhrsOrgUserAll[], bid: string, index: number) => {
  const users = userArr.map((user, i, arr) => {
    if (user.bid === bid) {
      return { ...user, userorder: 0 };
    }
    // if (i === 0) {
    //   return { ...user, userorder: i + 1 };
    // }
    if (index > i && 0 <= i) {
      return { ...user, userorder: i + 1 };
    }
    return user;
  });
  return users;
};

// 드래그 노드 인덱스 < 드랍 노드 인덱스인 경우의 사용자 지정 (드래그.부서 == 드랍.부서)
export const setUsersOrderByKey_sameDept = ({ userArr, bid, dragIndex, dropIndex, key }: IsetUsersOrderByKey) => {
  if (key === 'dragIsLargerThanDrop') {
    const users = userArr.map((user, i) => {
      if (user.bid === bid) {
        return { ...user, userorder: dropIndex + 1 };
      }
      if (dropIndex < i && dragIndex > i) {
        return { ...user, userorder: i + 1 };
      }
      return user;
    });
    return users;
  }

  if (key === 'dragIsSmallerThanDrop') {
    const users = userArr.map((user, i) => {
      if (user.bid === bid) {
        return { ...user, userorder: dropIndex };
      }
      if (dragIndex < i && dropIndex >= i) {
        return { ...user, userorder: i - 1 };
      }
      return { ...user, userorder: i };
    });
    return users;
  }
  return userArr;
};

// treeData[] 전달 받아 사용자에 해당하는 data를 갖는 treeData만 남기는 함수
export const leaveOnlyUser = (nodes: TreeData[]) => {
  const NODES = nodes.filter((v) => v.data.type === 'hrsOrgUser');
  return NODES;
};

// treeData[] 전달 받아 부서에 해당하는 data를 갖는 treeData만 남기는 함수
export const leaveOnlyDept = (nodes: TreeData[]) => {
  const NODES = nodes.filter((v) => v.data.type === 'hrsOrgDept');
  return NODES;
};

// ! API 관련 함수
// 전달받은 사용자의 정보를 user/modify api format에 맞게 변환
export const changeUserDataToFormat = (user: User) => {
  const format = {
    languagelist: [
      { language: 'ko', name: user.multiname[0].name },
      { language: 'en', name: user.multiname[1].name },
    ],
    userid: user.userid,
    userpwd: '',
    position: user.position,
    duty: user.duty,
    grade: user.grade,
    deptcode: user.deptcode,
    deptname: user.deptname,
    empno: user.empno,
    telhome: user.telhome,
    teloffice: user.teloffice,
    telextension: user.telextension,
    telmobile: user.telmobile,
    email: user.email,
    picurl: user.picurl,
    zipno: user.zipno,
    address: user.address,
    birthtype: user.birthtype,
    birthday: user.birthday,
    gender: user.gender,
    duties: user.duties,
    officehour: user.officehour,
    workstatus: user.workstatus,
    compcode: user.compcode,
    compname: user.compname,
    userorder: user.userorder,
    userfield1: user.userfield1,
    userfield2: user.userfield2,
    userfield3: user.userfield3,
    userfield4: user.userfield4,
    userfield5: user.userfield5,
    updatetype: user.updatetype,
  };

  return format;
};

// 사용자 정보[]의 사용자 정보를 user/modify api format에 맞게 변환후 format[] 반환
export const usersToUserModifyFormList = (users: User[]) => {
  return users.map((user) => changeUserDataToFormat(user));
};

// 부서의 정보를 받아 org/modify api format에 맞게 변환후 format 반환
export const deptToOrgModifyForm = (dept: Dept) => {
  const BODY = {
    orgcode: dept.orgcode,
    language: dept.language,
    deptcode: dept.deptcode,
    deptname: dept.deptname,
    parentdeptcode: dept.parentdeptcode,
    deptorder: dept.deptorder,
  };

  return BODY;
};

//

// 전달받은 사용자 정보[]를 사용하여 user/modify
interface ImodifyUserByArray {
  userlist: IadminUserModifyApiUserList[];
  keyToScroll: string;
  keyToExpand: string;
}
export const modifyUserByArray = ({ userlist, keyToScroll, keyToExpand }: ImodifyUserByArray) => {
  const orgcode = getMyOrgCode();
  if (!orgcode) return;

  const BODY = {
    orgcode,
    userlist,
  };
  console.log(BODY);
  dispatch(modifyUserAndScroll({ users: BODY, keyToScroll, keyToExpand }));
};

// 전달받은 부서 form을 사용하여 org/modify
export const modifyDeptByForm = (deptList: IAdminOrgModifyApiBody[]) => {
  const request = deptList.map((dept) => dispatch(modifyDept(dept)));
  // console.log({ request });
};
