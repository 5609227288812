import { useState } from 'react';
import Button from '../../common/buttons/Button';
import InputWithSearch from '../../common/InputWithSearch';

const DownTopBar = () => {
  const [userNameInputValue, setUserNameInputValue] = useState('');
  const onClickSearchUser = () => {
    // console.log('search!!');
  };
  return (
    <div className="flex gap-1 overflow-hidden">
      <InputWithSearch
        value={userNameInputValue}
        placeholder="검색..."
        onClickSearch={onClickSearchUser}
        handleChangeInput={(e) => setUserNameInputValue(e.target.value)}
        handleClear={() => setUserNameInputValue('')}
      />
      <div className="flex">
        <div className="flex justify-center items-center shrink-0 w-[5rem]">
          글자색
        </div>
      </div>
      <div className="flex mr-3">
        <div className="flex justify-center items-center shrink-0 w-[5rem]">
          배경색
        </div>
      </div>
      <div className="flex">
        <div className="flex justify-center items-center shrink-0 mr-3">
          최근 수정 날짜
        </div>
        <div className="flex justify-center items-center shrink-0">
          2022-05-25 13:41
        </div>
      </div>
    </div>
  );
};

export default DownTopBar;
