import { useEffect, useMemo, useState } from 'react';
import Arrow from '../../../assets/svg/arrows/Arrow';
import Button from '../../common/buttons/Button';
import { removeRuleFuncHubHandler } from '../../../helpers/h-ruleFuncMng';
import { openRuleFuncRuleModal } from '../../../helpers/h-modals';
import { SelectListValue } from '../../../interfaces/TypeSelectListbox';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import {
  initSelectedRuleCode,
  initSelectedRuleFuncHub,
  setSelectedRuleCode,
} from '../../../redux/slice/ruleFunctionManagementSlice';
import { toast } from 'react-toastify';
import SelectListbox from '../../common/selectList/SelectListbox';

interface Props {
  onClick: () => void;
}
const UpperRightTopBar = ({ onClick }: Props) => {
  const dispatch = useAppDispatch();
  const { ruleCodeList, selectedRuleFuncHub } = useAppSelector(
    (state) => state.ruleFunctionManagement,
  );
  // LISTBOX
  const deafaultSelectValues: SelectListValue[] = useMemo(() => {
    return [{ id: '1', name: 'empty' }];
  }, []);
  const [selected, setSelected] = useState(deafaultSelectValues[0]);
  const [localRuleCodeList, setLocalRuleCodeList] = useState<
    SelectListValue[] | null
  >(null);

  // rule/func/hub/remove
  const handleRemoveRuleFuncHub = () => {
    if (selectedRuleFuncHub.funccode.length < 1) {
      toast.error('규칙에 있는 기능을 선택해주세요.');
      return;
    }

    removeRuleFuncHubHandler(selectedRuleFuncHub?.rulefunckey);
    dispatch(initSelectedRuleFuncHub());
  };

  // selectedRuleCode 설정
  const handleSelectedRuleCode = (index: number) => {
    if (ruleCodeList.length < 1) return;
    dispatch(setSelectedRuleCode(ruleCodeList[index]));
  };

  // SelectListbox value 설정
  useEffect(() => {
    if (localRuleCodeList === null) {
      setLocalRuleCodeList(deafaultSelectValues);
      setSelected(deafaultSelectValues[0]);
      return;
    }

    setSelected(localRuleCodeList[0]);
  }, [localRuleCodeList]);

  // SelectListBox options를 위한 localRuleCodeList 설정
  useEffect(() => {
    // if (ruleCodeList.length < 1) return;
    if (ruleCodeList.length > 0) {
      setLocalRuleCodeList(
        ruleCodeList.map((rule, index) => ({
          id: index.toString(),
          name: rule.rulename,
        })),
      );
    } else {
      setLocalRuleCodeList(deafaultSelectValues);
      dispatch(initSelectedRuleCode());
    }
  }, [ruleCodeList]);

  return (
    <div className="py-[0.563rem] flex justify-between text-fontColor-default">
      <div className="flex gap-1">
        <Button
          kind="cancel"
          label="규칙 관리"
          onClick={openRuleFuncRuleModal}
        />
        <SelectListbox
          options={localRuleCodeList ? localRuleCodeList : deafaultSelectValues}
          value={selected}
          optionsHeight={12}
          width={'10rem'}
          onChange={(value) => {
            setSelected(value);
            handleSelectedRuleCode(parseInt(value.id));
          }}
        />
        {/* <div className="w-[12rem]">
          <InputWithSearch
            value={testInputValue}
            placeholder="검색..."
            onClickSearch={() => console.log('search!!')}
            handleChangeInput={(e) => setTestInputValue(e.target.value)}
            handleClear={(e) => setTestInputValue('')}
          />
        </div> */}
        <Button kind="cancel" label="삭제" onClick={handleRemoveRuleFuncHub} />
        {/* <Button kind="cancel" label="서버 적용" /> */}
        {/* <Button kind="cancel" label="엑셀 다운로드" /> */}
      </div>
      <Arrow onClick={onClick} />
    </div>
  );
};

export default UpperRightTopBar;
