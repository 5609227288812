/**
 * @param deptcode
   @note deptcode 조건 앞에 영문자 + 숫자 길이 2 이상
 */
export function deptcodeValidPattern(deptcode: string | null | undefined): boolean {
  if (typeof deptcode !== 'string') return false;

  const trimmedCode = deptcode.trim();
  if (trimmedCode.length < 2) return false;

  const pattern = /^[A-Za-z].*[0-9]$/;
  return pattern.test(trimmedCode);
}