import { ButtonProps } from '../../../interfaces/ButtonProps';

const AddPersonButton = ({
  onClick,
  width = 36,
  height = 36,
  ...props
}: ButtonProps) => {
  return (
    <button
      {...props}
      onClick={onClick}
      className="rounded bg-C-1f62b9 hover:bg-C-599aee active:bg-C-32418e"
    >
      <svg
        className="stroke-white stroke-2"
        xmlns="http://www.w3.org/2000/svg"
        width={width}
        height={height}
        viewBox="0 0 36 36"
      >
        <g transform="translate(-2 2)">
          <g>
            <path
              transform="translate(11 16)"
              fill="none"
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M0 0h18"
            />
            <path
              transform="translate(20 7)"
              fill="none"
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M0 0v18"
            />
          </g>
        </g>
      </svg>
    </button>
  );
};

export default AddPersonButton;
