import React from 'react';

const SchoolHeaderLogo = () => {
  return (
    <svg width="244" height="32" viewBox="0 0 244 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M47.8222 11.8458C47.667 12.2839 47.2481 12.4146 46.8446 12.2225C45.6654 11.6536 44.2922 11.2539 42.6552 11.277C41.1035 11.3001 40.0795 11.8919 39.9708 13.0372C39.8855 13.8904 40.4596 14.4208 41.3208 14.7744C42.3836 15.2125 43.6948 15.5661 45.0214 15.9197C47.7523 16.6422 49.2109 18.0719 49.1023 20.4702C48.9704 23.2143 46.3714 25.0437 42.5388 24.9975C40.2113 24.9745 37.9925 24.2058 36.2624 22.7992C35.9521 22.5609 35.9288 22.2688 36.1305 21.9614L37.0848 20.5316C37.3098 20.2011 37.6589 20.0935 38.0158 20.3318C39.2338 21.2081 40.5915 21.8076 42.5155 21.8691C44.3154 21.9383 45.3085 21.3003 45.3318 20.4394C45.355 19.494 44.6025 19.1019 43.5163 18.7945C41.988 18.3563 40.5915 18.0719 39.1485 17.3648C37.527 16.5731 36.1771 15.2587 36.2003 13.0603C36.2236 9.93954 38.7295 7.91797 42.6552 7.91797C44.874 7.91797 46.6041 8.3561 48.0006 9.07864C48.4196 9.30155 48.6678 9.58596 48.5127 10.001L47.8222 11.8458Z"
        fill="#4D4A52"
      />
      <path
        d="M64.293 20.7717C64.7119 21.0791 64.6498 21.4327 64.4248 21.7786C63.0516 23.8847 60.8949 24.9224 58.1252 24.9224C53.3539 24.9224 50.0333 21.4942 50.0333 16.5287C50.0333 11.5631 53.1289 8.07342 57.9079 7.91201C61.3138 7.79671 63.587 9.43395 64.7817 11.0097C64.9834 11.2941 64.9834 11.6477 64.6964 11.909L62.656 13.4925C62.3689 13.7307 61.95 13.7769 61.6784 13.4233C61.0733 12.6008 59.9716 11.3556 57.8226 11.4863C55.4951 11.6246 53.7805 13.5616 53.7805 16.4672C53.7805 19.3727 55.7046 21.3712 58.0786 21.4634C59.8165 21.5326 61.0267 20.764 61.7638 19.9031C62.0741 19.5264 62.4077 19.5111 62.8034 19.7724L64.293 20.764V20.7717Z"
        fill="#4D4A52"
      />
      <path
        d="M77.0008 24.6586C76.5586 24.6586 76.3336 24.4357 76.3336 23.9975V17.6792H70.6546L70.6313 23.9975C70.6313 24.4357 70.4063 24.6586 69.9641 24.6586H67.4349C66.9927 24.6586 66.7677 24.4357 66.7677 23.9975V8.77042C66.7677 8.33228 66.9694 8.10938 67.4116 8.10938H70.0106C70.4529 8.10938 70.6778 8.33228 70.6546 8.77042V14.3201H76.3258V8.77042C76.3258 8.33228 76.5276 8.10938 76.9698 8.10938H79.7627C80.205 8.10938 80.43 8.33228 80.4067 8.77042V23.9975C80.4067 24.4357 80.1817 24.6586 79.7395 24.6586H76.9931H77.0008Z"
        fill="#4D4A52"
      />
      <path
        d="M82.4395 16.4807C82.4395 11.5152 85.6591 7.91016 90.4227 7.91016C95.1862 7.91016 98.3593 11.5152 98.3593 16.4807C98.3593 21.4462 95.163 24.9206 90.4227 24.9206C85.6824 24.9206 82.4395 21.4462 82.4395 16.4807ZM94.5966 16.7728C94.5966 13.7904 93.4018 11.5152 90.4537 11.5152C87.5056 11.5152 86.3108 13.7904 86.3108 16.7728C86.3108 19.7552 88.2193 21.4693 90.4537 21.4693C92.6881 21.4693 94.5966 19.7629 94.5966 16.7728Z"
        fill="#4D4A52"
      />
      <path
        d="M129.222 23.8977C129.222 24.4051 128.989 24.6741 128.624 24.6741C125.893 24.6741 122.744 24.6741 119.819 24.6741C118.818 24.6741 118.29 24.1283 118.267 23.1368V8.77823C118.267 8.3401 118.469 8.11719 118.911 8.11719H121.704C122.146 8.11719 122.371 8.3401 122.348 8.77823V21.2843H128.399C128.702 21.2843 129.222 21.2843 129.222 21.8608V23.8901V23.8977Z"
        fill="#4D4A52"
      />
      <path
        d="M147.035 9.25217C147.035 7.99157 148.035 7 149.308 7C150.58 7 151.581 7.99157 151.581 9.25217C151.581 10.5128 150.58 11.5043 149.308 11.5043C148.035 11.5043 147.035 10.5128 147.035 9.25217Z"
        fill="#4D4A52"
      />
      <path
        d="M147.981 24.6703C147.539 24.6703 147.314 24.4473 147.314 24.0092V13.0712C147.314 12.6331 147.508 12.4102 147.95 12.4102H150.673C151.123 12.4102 151.34 12.6331 151.317 13.0712V24.0092C151.317 24.455 151.092 24.6703 150.65 24.6703H147.981Z"
        fill="#4D4A52"
      />
      <path
        d="M162.52 24.6766C162.07 24.6766 161.892 24.4767 161.892 24.0155V17.4204C161.892 16.0368 160.968 15.5295 159.812 15.5295C158.563 15.5295 157.943 16.3674 157.919 17.2744V24.0155C157.919 24.4613 157.694 24.6766 157.252 24.6766H154.591C154.149 24.6766 153.924 24.4536 153.924 24.0155V13.0544C153.901 12.6086 154.126 12.3934 154.576 12.3934H157.299C157.656 12.3934 157.857 12.5471 157.927 12.8315V13.9384C158.571 12.6086 160.247 12.0859 161.364 12.0859C163.552 12.0859 165.429 13.1544 165.685 15.5372C165.879 17.3282 165.856 21.6634 165.763 24.0155C165.716 24.4767 165.538 24.6766 165.096 24.6766H162.528H162.52Z"
        fill="#4D4A52"
      />
      <path
        d="M178.913 22.6763C179.084 22.9453 179.091 23.2835 178.719 23.5756C177.385 24.5826 175.825 25.0053 173.793 24.9208C169.937 24.7594 167.252 22.1921 167.423 18.2642C167.586 14.3364 170.418 11.8843 174.002 12.0381C177.835 12.1995 180.046 14.7438 179.999 18.1028C179.976 19.1866 179.332 20.0859 178.067 20.0552L171.194 19.9476C171.388 21.2159 172.598 22.0383 174.181 22.0844C175.383 22.1152 176.33 21.8846 177.175 21.4311C177.633 21.2082 177.99 21.262 178.176 21.5618L178.913 22.6763ZM176.756 17.2112C176.632 16.012 175.461 15.082 173.987 15.082C172.404 15.082 171.38 16.012 171.194 17.2035H176.748L176.756 17.2112Z"
        fill="#4D4A52"
      />
      <path
        d="M145.429 23.8977C145.429 24.4051 145.196 24.6741 144.831 24.6741C142.1 24.6741 138.951 24.6741 136.026 24.6741C135.025 24.6741 134.497 24.1283 134.474 23.1368V8.77823C134.474 8.3401 134.676 8.11719 135.118 8.11719H137.911C138.353 8.11719 138.578 8.3401 138.555 8.77823V21.2843H144.606C144.909 21.2843 145.429 21.2843 145.429 21.8608V23.8901V23.8977Z"
        fill="#4D4A52"
      />
      <path
        d="M100.12 16.4807C100.12 11.5152 103.34 7.91016 108.104 7.91016C112.867 7.91016 116.04 11.5152 116.04 16.4807C116.04 21.4462 112.844 24.9206 108.104 24.9206C103.363 24.9206 100.12 21.4462 100.12 16.4807ZM112.278 16.7728C112.278 13.7904 111.083 11.5152 108.135 11.5152C105.187 11.5152 103.992 13.7904 103.992 16.7728C103.992 19.7552 105.9 21.4693 108.135 21.4693C110.369 21.4693 112.278 19.7629 112.278 16.7728Z"
        fill="#4D4A52"
      />
      <g>
        <path
          d="M16.8392 20.5586C17.0303 19.4061 17.3662 18.3327 17.8371 17.391C17.8371 17.391 17.8371 17.3877 17.8371 17.3844C17.8338 17.3844 17.8305 17.3844 17.8239 17.3844C14.9555 17.2592 12.9762 15.8006 11.9619 13.5352C11.6029 13.5352 11.2308 13.5681 10.8488 13.6274C4.77599 14.5855 3.4488 18.5138 4.1832 22.9853C4.9176 27.4569 9.25813 30.8319 13.513 29.8178C15.5483 29.3304 17.0105 28.2669 17.9194 26.8181C16.803 25.1618 16.4473 22.982 16.8458 20.5586H16.8392ZM14.5208 26.3505C14.0992 26.6271 13.5394 26.93 12.2715 27.0354H12.2517C11.6952 27.0782 10.9608 26.9498 10.503 26.7357C10.0156 26.5053 9.63027 26.3176 9.76859 25.962C9.85421 25.7348 9.98594 25.5076 10.256 25.3726C10.4338 25.2837 10.7203 25.5635 10.9608 25.6656C12.0014 26.1101 13.2529 25.7776 13.8061 25.3989C14.3825 25.0038 14.5076 24.5263 14.6657 24.4078C14.9061 24.2267 15.6076 24.4275 15.6207 24.7041C15.6438 25.1882 15.2519 25.8698 14.5175 26.3538L14.5208 26.3505Z"
          fill="#E62F87"
        />
      </g>
      <g>
        <path
          d="M17.8339 17.3851C17.8339 17.3851 17.8372 17.3851 17.8405 17.3851C19.082 14.8991 21.2655 13.3054 24.2163 13.4799C24.865 12.2714 25.2207 10.8786 25.1713 9.43969C25.0231 5.19206 23.0702 1.81371 17.2872 2.00798C13.0685 2.14957 11.03 5.66621 11.1782 9.91055C11.2243 11.2573 11.491 12.4855 11.962 13.5392C14.6427 13.5161 16.6516 15.0472 17.8372 17.3884L17.8339 17.3851ZM14.2804 10.4242C15.2355 9.63396 16.1411 10.7535 17.9887 10.635C20.2808 10.4868 21.1469 9.10712 22.0493 10.174C22.8693 11.1453 21.2885 12.5875 19.9251 13.0518C18.9569 13.3811 17.2674 13.4931 16.2926 13.1506C15.4166 12.8444 14.949 12.4954 14.5176 12.0442C14.0861 11.5931 13.7996 10.8193 14.2804 10.4242Z"
          fill="#FABE00"
        />
      </g>
      <g>
        <path
          d="M24.9966 13.5635C24.7299 13.5207 24.4697 13.4943 24.2128 13.4779C21.2653 13.3034 19.0819 14.897 17.837 17.3831C17.837 17.3831 17.837 17.3864 17.837 17.3896C18.3277 18.361 18.6768 19.4674 18.8744 20.6659C19.2663 23.04 18.9436 25.177 17.9128 26.82C18.8481 28.2096 20.3169 29.2336 22.3389 29.7144C26.6762 30.7417 31.0859 27.3864 31.8203 22.928C32.5547 18.4697 31.1847 14.5447 24.9966 13.5668V13.5635ZM26.8507 26.4414C26.2316 27.067 25.2568 27.4621 24.0778 27.4621C23.2479 27.4621 22.2072 27.2415 21.4662 26.8793C19.2828 25.8026 19.9184 23.0532 20.3992 22.6317C20.4387 22.5955 20.4947 22.5823 20.5507 22.5889C20.801 22.6218 21.104 22.7601 21.351 22.984C21.4135 23.0433 21.43 23.1388 21.3905 23.2145C21.1435 23.6755 20.9624 24.3834 21.2225 25.042C21.4498 25.6248 22.2928 26.0759 22.8856 26.2208C23.6003 26.3986 24.3248 26.4414 25.0658 26.2306C26.3534 25.8619 26.6597 24.5151 26.7157 24.1332C26.7881 23.6524 27.236 23.9488 27.7695 24.0904C27.8683 24.1167 27.9309 24.2122 27.9177 24.3077C27.8222 25.009 27.6806 25.605 26.854 26.4414H26.8507Z"
          fill="#6EB92C"
        />
      </g>
      <path
        d="M17.8206 17.3846C17.8206 17.3846 17.8272 17.3846 17.8338 17.3846C16.6482 15.0434 14.6393 13.5156 11.9586 13.5353C12.9729 15.7975 14.9555 17.2561 17.8206 17.3846Z"
        fill="#B92D22"
      />
      <path
        d="M18.8745 20.663C18.6769 19.4677 18.3279 18.3581 17.8372 17.3867C17.3662 18.3317 17.0303 19.4019 16.8393 20.5543C16.4408 22.9778 16.7998 25.1576 17.9129 26.8138C18.9437 25.1708 19.2631 23.0338 18.8745 20.6597V20.663Z"
        fill="#993843"
      />
      <path
        d="M17.8405 17.3831C20.5541 17.495 22.9483 15.8388 24.2162 13.4779C21.2688 13.3034 19.0853 14.897 17.8405 17.3831Z"
        fill="#7B882E"
      />
      <path
        d="M233.564 19.1674L232.629 24.8424H229.942L231.56 15.0263H234.121L233.842 16.7581H233.95C234.258 16.183 234.692 15.7292 235.252 15.3969C235.817 15.0646 236.46 14.8984 237.18 14.8984C237.855 14.8984 238.417 15.0476 238.868 15.3458C239.324 15.644 239.644 16.0679 239.829 16.6175C240.019 17.1671 240.051 17.8254 239.924 18.5923L238.887 24.8424H236.194L237.149 19.078C237.246 18.4772 237.17 18.0086 236.921 17.672C236.677 17.3312 236.283 17.1608 235.739 17.1608C235.377 17.1608 235.044 17.2396 234.74 17.3972C234.437 17.5548 234.182 17.7849 233.975 18.0874C233.773 18.3856 233.636 18.7457 233.564 19.1674Z"
        fill="#040404"
      />
      <path
        d="M225.072 24.8423L226.691 15.0262H229.384L227.765 24.8423H225.072ZM228.315 13.748C227.915 13.748 227.584 13.6138 227.323 13.3454C227.066 13.0727 226.96 12.7489 227.007 12.374C227.053 11.9906 227.234 11.6668 227.55 11.4026C227.867 11.1342 228.225 11 228.625 11C229.026 11 229.352 11.1342 229.605 11.4026C229.858 11.6668 229.963 11.9906 229.921 12.374C229.879 12.7489 229.7 13.0727 229.384 13.3454C229.072 13.6138 228.716 13.748 228.315 13.748Z"
        fill="#040404"
      />
      <path
        d="M208.853 24.8424L210.472 15.0263H213.032L212.754 16.7581H212.861C213.156 16.183 213.567 15.7292 214.094 15.3969C214.625 15.0646 215.222 14.8984 215.883 14.8984C216.541 14.8984 217.076 15.0667 217.489 15.4033C217.906 15.7356 218.147 16.1872 218.21 16.7581H218.311C218.598 16.1958 219.03 15.7463 219.607 15.4097C220.184 15.0689 220.829 14.8984 221.541 14.8984C222.439 14.8984 223.126 15.1903 223.602 15.774C224.079 16.3577 224.228 17.1799 224.051 18.2408L222.964 24.8424H220.271L221.282 18.7776C221.362 18.228 221.282 17.819 221.042 17.5506C220.802 17.2779 220.467 17.1416 220.037 17.1416C219.548 17.1416 219.141 17.2992 218.817 17.6145C218.492 17.9255 218.292 18.3366 218.216 18.8479L217.211 24.8424H214.6L215.624 18.7201C215.696 18.2344 215.618 17.851 215.39 17.5698C215.167 17.2843 214.832 17.1416 214.385 17.1416C214.09 17.1416 213.81 17.2183 213.544 17.3716C213.283 17.5208 213.062 17.7317 212.88 18.0043C212.699 18.2727 212.581 18.588 212.526 18.9502L211.54 24.8424H208.853Z"
        fill="#040404"
      />
      <path
        d="M201.082 25.0019C200.34 25.0019 199.702 24.8101 199.166 24.4267C198.635 24.039 198.256 23.4702 198.028 22.7204C197.805 21.9663 197.784 21.0418 197.965 19.9468C198.151 18.822 198.484 17.8869 198.964 17.1413C199.445 16.3914 200.014 15.8312 200.671 15.4605C201.328 15.0856 202.013 14.8981 202.726 14.8981C203.265 14.8981 203.701 14.9919 204.034 15.1793C204.371 15.3625 204.631 15.5926 204.812 15.8695C204.993 16.1422 205.12 16.4106 205.191 16.6748H205.273L206.089 11.7539H208.776L206.626 24.8421H203.971L204.23 23.27H204.116C203.948 23.5427 203.725 23.8132 203.446 24.0816C203.172 24.3458 202.839 24.5652 202.447 24.7399C202.06 24.9145 201.605 25.0019 201.082 25.0019ZM202.27 22.8354C202.7 22.8354 203.088 22.7161 203.434 22.4775C203.779 22.2347 204.07 21.896 204.306 21.4614C204.542 21.0268 204.707 20.5177 204.799 19.934C204.896 19.3503 204.898 18.8433 204.806 18.413C204.713 17.9827 204.532 17.6504 204.262 17.4161C203.996 17.1818 203.644 17.0646 203.206 17.0646C202.764 17.0646 202.372 17.186 202.03 17.4289C201.693 17.6717 201.413 18.0083 201.189 18.4386C200.97 18.8689 200.814 19.3674 200.722 19.934C200.629 20.5049 200.623 21.0098 200.703 21.4486C200.783 21.8832 200.953 22.224 201.215 22.4711C201.476 22.714 201.828 22.8354 202.27 22.8354Z"
        fill="#040404"
      />
      <path
        d="M186.933 24.8421H184L190.619 11.7539H194.147L196.461 24.8421H193.527L191.953 14.7448H191.852L186.933 24.8421ZM187.604 19.6976H194.532L194.166 21.8576H187.237L187.604 19.6976Z"
        fill="#040404"
      />
    </svg>
  );
};

export default SchoolHeaderLogo;
