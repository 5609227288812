import { t } from 'i18next';
import MainPageTitleHeader from '../../common/MainPageTitleHeader';
import MainPageTitleHeaderDesc from '../../common/MainPageTitleHeaderDesc';
import ClassMain from './ClassMain';

const ClassManagement = () => {
  return (
    <div className="w-full h-full px-[1.5rem] flex flex-col border border-solid border-[#dfe2e8] bg-white rounded">
      <MainPageTitleHeader text={t('ClassManagement.classManagement')} textOnly />
      <MainPageTitleHeaderDesc
        text={
          <>
            {t('ClassManagement.classDesc1')} <br />
            {t('ClassManagement.classDesc2')}
          </>
        }
      />
      <ClassMain />
    </div>
  );
};

export default ClassManagement;
