import { t } from 'i18next';
import Draggable from 'react-draggable';
import Button from '../../common/buttons/Button';

interface Props {
  show: boolean;
  setShow: () => void;
  data: [string, string][];
  title: string;
}
const InformaionModal = ({ show, setShow, data, title }: Props) => {
  if (show) {
    return (
      <Draggable handle="#handle" positionOffset={{ x: '-50%', y: '-50%' }}>
        <div className="z-[999] fixed bg-white left-[50%] top-[50%] flex flex-col shadow-listbox-options rounded border border-solid border-borderLight">
          {/* HEADER */}
          <div
            id="handle"
            className="cursor-move py-defaultY px-defaultX font-bold"
          >
            <div className="text-center py-defaultY">{title}</div>
          </div>
          {/* BODY */}
          <div className="flex flex-col py-defaultY px-defaultX">
            {data.map((d) => (
              <div>
                <b>{d[0]}</b> : {d[1]}
              </div>
            ))}
          </div>
          {/* 버튼 */}
          <div className="jcac py-defaultY">
            <Button label={t('ServiceMornitoring.ok')} onClick={setShow} kind="primary" />
          </div>
        </div>
      </Draggable>
    );
  }

  return null;
};

export default InformaionModal;
