import { t } from 'i18next';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../redux/store';
import icon_pin from '../../../assets/image/etc/icon_pin.png';
import icon_trash from '../../../assets/image/etc/icon_trash.png';
import { setChannelReloadStatus, setSelectedChannel } from '../../../redux/slice/counselingManagementSlice';
import { counselChannelListApi } from '../../../api/admin/counseling/api-admin-counsel-channel';
import ConfirmModal from '../../react-modal/ConfirmModal';
import { reqDeleteChannel } from '../../../helpers/h-counselingMng';
import { openCounselingChannelModal } from '../../../helpers/h-react-modals';
import { IChannelDTO } from '../../../interfaces/api/counsel/I-api-admin-counsel-channel';

const CounselingChannels = () => {
  const dispatch = useDispatch();
  const selectedCategory = useSelector((state: RootState) => state.counselingManagement.selectedCategoryId);
  const channelReloadStatus = useSelector((state: RootState) => state.counselingManagement.channelReloadStatus);
  const [channels, setChannels] = useState<IChannelDTO[]>([]);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [hoveredChannelId, setHoveredChannelId] = useState<string | null>(null);
  const [removeChannelId, setRemoveChannelId] = useState<string | null>(null);
  const categoryRemoveStatus = useSelector((state: RootState) => state.counselingManagement.categoryRemoveStatus);

  useEffect(() => {
    if (selectedCategory) {
      fetchData(selectedCategory);
    }
  }, [selectedCategory]);

  useEffect(() => {
    if (categoryRemoveStatus === 'success') {
      setChannels([]);
    }
  }, [categoryRemoveStatus]);

  useEffect(() => {
    if (channelReloadStatus && selectedCategory) {
      fetchData(selectedCategory);
      dispatch(setChannelReloadStatus(false)); // channelReloadStatus를 false로 설정
    }
  }, [channelReloadStatus, selectedCategory, dispatch]);

  const fetchData = async (id: string) => {
    try {
      const res = await counselChannelListApi(id);
      console.log(res.data.data.result);
      const sortedChannels = res.data.data.result;
      setChannels(sortedChannels);
    } catch (error) {
      console.error('Failed to fetch channels:', error);
    }
  };

  const handleConfirmDelete = async () => {
    if (!removeChannelId) return;
    try {
      const res = await reqDeleteChannel(removeChannelId);
      if (res?.data.data.result === true) {
        toast.success(String(t('CounselingManagement.toast10')));
        fetchData(selectedCategory!);
      } else {
        toast.success(String(t('CounselingManagement.toast11')));
      }
    } catch (error) {
      toast.success(String(t('CounselingManagement.toast11')));
      console.error('Failed to delete the channel:', error);
    } finally {
      setShowConfirmModal(false);
      setRemoveChannelId(null);
    }
  };

  const handleCancelDelete = () => {
    setShowConfirmModal(false);
  };

  const handleRowDoubleClick = (channel: IChannelDTO) => {
    dispatch(setSelectedChannel(channel));
    openCounselingChannelModal();
  };

  const handleDelete = (channel: IChannelDTO) => {
    setShowConfirmModal(true);
    setRemoveChannelId(channel.id);
  };

  return (
    <div className="p-2 category-list-container h-full">
      {channels.length === 0 ? (
        <div className="mt-2 ml-2 text-sm text-gray-400">{t('CounselingManagement.channelNoData') as string}</div>
      ) : (
        <ul>
          {channels.map((channel) => (
            <li
              key={channel.id}
              className="flex items-center py-2 px-3 text-sm text-gray-800 cursor-pointer hover:bg-gray-100"
              onDoubleClick={() => handleRowDoubleClick(channel)}
              onMouseEnter={() => setHoveredChannelId(channel.id)}
              onMouseLeave={() => setHoveredChannelId(null)}
            >
              <span>{channel.name}</span>
              {channel.fixed === 1 && <img src={icon_pin} className="w-5 h-5 min-w-5 ml-1" />}
              <div className="flex-grow"></div>
              <img
                src={icon_trash}
                className="w-5 h-5 min-w-5 ml-1"
                onClick={() => handleDelete(channel)}
                style={{
                  opacity: hoveredChannelId === channel.id ? 1 : 0,
                  transition: 'opacity 0.3s ease',
                  cursor: 'pointer',
                }}
              />
            </li>
          ))}
        </ul>
      )}
      <ConfirmModal
        label={t('CounselingManagement.channelDelMsg') as string}
        isOpen={showConfirmModal}
        onConfirm={handleConfirmDelete}
        onClose={handleCancelDelete}
      />
    </div>
  );
};

export default CounselingChannels;
