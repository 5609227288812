import { useTranslation } from 'react-i18next';
import {
  ColDef,
  GridApi,
  GridReadyEvent,
  RowSelectedEvent,
} from 'ag-grid-community';
import { AgGridReact } from 'ag-grid-react';
import { useEffect, useMemo, useState } from 'react';
import { toast } from 'react-toastify';
import { spAutoScaleListApi } from '../../../../api/admin/service-provider/api-autoScale';
import Button from '../../../common/buttons/Button';
import { IspTenantListReturnValue_desc_F } from '../../../../interfaces/api/service-provider/I-api-admin-serviceProvider-fed';
import {
  IautoScaleDeleteApiBody,
  IautosScale,
} from '../../../../interfaces/api/service-provider/I-api-autoscale';
import { useAppDispatch, useAppSelector } from '../../../../redux/hooks';
import { autoScaleDelete } from '../../../../redux/slice/sp/autoScaleManagementSlice';
import AutoScaleMng_apply_modal from '../../../modals/sp/AutoScaleMng_apply_modal';
import ConfirmModal from '../../../react-modal/ConfirmModal';
import { AutoScale_LIST_TABLE_COL } from './columns';
import axios from 'axios';

interface Props {
  selectedTenant: IspTenantListReturnValue_desc_F | null;
}
const AutoScaleManagement_list_table = ({ selectedTenant }: Props) => {
  const dispatch = useAppDispatch();
  const { autoScaleApllyStatus, autoScaleDeleteStatus } = useAppSelector(
    (state) => state.autoScaleManagement,
  );
  // const [modalData, setModalData] = useState<[string, string][] | null>(null);
  // const [show, setShow] = useState(false);
  const [showApplyModal, setShowApplyModal] = useState(false);
  const [showDeleteConfirmModal, setShowDeleteConfirmModal] = useState(false);
  const { t } = useTranslation();

  // AG_GRID
  const [gridApi, setGridApi] = useState<GridApi<IautosScale[]>>();
  const [rowData, setRowData] = useState<IautosScale[]>([]);
  const [selectedRowData, setSelectedRowData] = useState<IautosScale | null>(
    null,
  );
  const [columnDefs, setColumnDefs] = useState<ColDef[]>([]);
  // const [columnDefs, setColumnDefs] = useState<ColDef[]>(
  //   AutoScale_LIST_TABLE_COL,
  // );
  const defaultColDef = useMemo<ColDef>(() => {
    return {
      flex: 1,
      resizable: true,
      minWidth: 100,
      // sortable: true,
      // filter: true,
      // editable: true,
    };
  }, []);

  // const togleModal = () => {
  //   setShow((state) => !state);
  // };
  const toggleApplyModal = () => {
    setShowApplyModal((state) => !state);
  };
  const toggleDeleteModal = () => {
    if (!selectedRowData) {
      toast.error(String(t('AutoScaleManagement.toastSelect')));
      return;
    }
    setShowDeleteConfirmModal((state) => !state);
  };

  const deleteHandler = () => {
    if (!selectedRowData) return;

    const BODY: IautoScaleDeleteApiBody = {
      name: selectedRowData.meta.name,
      namespace: selectedRowData.meta.namespace,
    };
    dispatch(autoScaleDelete(BODY));
    setShowDeleteConfirmModal(false);
  };

  const onGridReady = (params: GridReadyEvent<IautosScale[]>) => {
    setGridApi(params.api);
  };

  const onRowSelected = (event: RowSelectedEvent<IautosScale>) => {
    if (event.node.isSelected() && event.data) {
      setSelectedRowData(event.data);
    }
  };

  const fetchRowData = async (tenantid: string) => {
    try {
      const autoScaleList = await spAutoScaleListApi({
        namespace: `ucworks-${tenantid}`,
      });
      if (!autoScaleList) return;

      const data = autoScaleList.data.data.result;
      setRowData(data);
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.log('error: ', error);
      } else {
        console.log('unexpected error: ', error);
      }
    }
  };

  useEffect(() => {
    if (!gridApi || !selectedTenant) return;
  }, [selectedTenant, gridApi]);

  useEffect(() => {
    if (!selectedTenant) return;

    fetchRowData(selectedTenant.tenantid);
  }, [selectedTenant]);

  useEffect(() => {
    if (!selectedTenant) return;

    if (
      autoScaleApllyStatus === 'success' ||
      autoScaleDeleteStatus === 'success'
    ) {
      fetchRowData(selectedTenant.tenantid);
    }
  }, [autoScaleApllyStatus, autoScaleDeleteStatus]);

  useEffect(() => {
    setSelectedRowData(null);
    return () => {
      setSelectedRowData(null);
    };
  }, [selectedTenant]);

  useEffect(() => {
    setColumnDefs(AutoScale_LIST_TABLE_COL(t));
  }, [t]);

  return (
    <div className="w-full grow flex flex-col pb-defaultY overflow-hidden">
      {/* 탑 바 */}
      {selectedTenant && (
        <div className="py-[0.563rem] gap-1 flex justify-end text-fontColor-default">
          <Button kind="cancel" label={t('AutoScaleManagement.add')} onClick={toggleApplyModal} />
          <Button kind="cancel" label={t('AutoScaleManagement.delete')} onClick={toggleDeleteModal} />
        </div>
      )}
      {/* 테이블 */}
      <div className="ag-theme-alpine ag-theme-mycustomtheme grow w-full">
        <AgGridReact
          columnDefs={columnDefs}
          rowData={rowData}
          defaultColDef={defaultColDef}
          onGridReady={onGridReady}
          rowSelection={'single'}
          onRowSelected={onRowSelected}
          // onRowDoubleClicked={onRowDoubleClicked}
          overlayNoRowsTemplate={'\xa0'}
        ></AgGridReact>
        <ConfirmModal
          label={t('AutoScaleManagement.toastDelete')}
          isOpen={showDeleteConfirmModal}
          onConfirm={deleteHandler}
          onClose={toggleDeleteModal}
        />
        {selectedTenant && (
          <AutoScaleMng_apply_modal
            show={showApplyModal}
            setShow={toggleApplyModal}
            tenantid={selectedTenant.tenantid}
            title={t('AutoScaleManagement.addAutoScale')}
          />
        )}
      </div>
    </div>
  );
};

export default AutoScaleManagement_list_table;
