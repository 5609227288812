import { closeFederation_delete_Modal } from '../../../helpers/h-modals';
import FederationMng_ask_common from './FederationMng_ask_common';

const FederationMng_delete_Modal = () => {
  return (
    <FederationMng_ask_common
      label="선택된 항목을 삭제하시겠습니까?"
      onClickClose={closeFederation_delete_Modal}
    />
  );
};

export default FederationMng_delete_Modal;
