import { useCallback, useEffect, useMemo, useState } from 'react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import { AgGridReact } from 'ag-grid-react';
import { CellClickedEvent, ColDef } from 'ag-grid-community';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
// import { SERVER_LIST_TABLE_COLUMN } from './column';
import { IspUksGetsApiResponse } from '../../../interfaces/api/service-provider/I-api-admin-sp-uks';
import { getsUksHandler } from '../../../helpers/h-main';
import { SERVER_LIST_TABLE_COLUMN } from './column';
// import { getsUksHandler } from '../../../helpers/h-main';

const MainServerList_Table = () => {
  const dispatch = useAppDispatch();
  const { funcBtnList } = useAppSelector((state) => state.funcButtonManagement);

  const [columnDefs] = useState(SERVER_LIST_TABLE_COLUMN);
  const [rowData, setRowData] = useState<IspUksGetsApiResponse[]>([]);
  const defaultColDef = useMemo<ColDef>(() => {
    return {
      flex: 1,
      resizable: true,
      minWidth: 100,
    };
  }, []);

  const cellClickedListener = useCallback(
    (event: CellClickedEvent<IspUksGetsApiResponse>) => {
      // console.log('cellClicked', event);
    },
    [],
  );

  // 테이블 데이터 설정
  useEffect(() => {
    const fetchRowData = async () => {
      const serverList = await getsUksHandler('pods');
      if (!serverList) return;
      setRowData(serverList);
      // console.log(serverList);
    };
    fetchRowData();
  }, []);

  return (
    <div className="w-full grow pb-2 flex flex-col overflow-hidden">
      <div className="ag-theme-alpine ag-theme-mycustomtheme w-full grow">
        <AgGridReact
          rowData={rowData}
          columnDefs={columnDefs}
          defaultColDef={defaultColDef}
          onCellClicked={cellClickedListener}
          // onFirstDataRendered={(params) => console.log(params.api)}
          rowSelection={'single'}
        ></AgGridReact>
      </div>
    </div>
  );
};

export default MainServerList_Table;
