import '../../styles/treeView-default.css';
import { useCallback, useEffect, useRef, useState } from 'react';
import Tree from 'rc-tree';
import { t } from 'i18next';
// API
import { hrsOrgsListApi } from '../../api/hrs/api-hrs-orgs';
// REDUX
import {
  initDeptStatus,
  initUserModifyStatus,
  modifyDepts,
  setSelectedUser,
  setContextDeptInfo,
  setKeyToExpand,
  setKeyToScroll,
  setContextUserInfo,
} from '../../redux/slice/organizationUserManagementSlice';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
// COMP
import LeftTopBar from '../mainPage/organization_user_management/LeftTopBar';
import { IhrsOrgDeptFull, IhrsOrgUserAll } from '../../interfaces/api/I-api-hrs-orgs';
import { EventDataNode, Key } from 'rc-tree/lib/interface';
import {
  getChildDeptUser,
  modifyUserByArray,
  loopNodes,
  setDragUserToLastIndex,
  setDragUserToFirstIndex,
  usersToUserModifyFormList,
  getUsersArr,
  setUsersOrderByKey_sameDept,
  leaveOnlyUser,
  leaveOnlyDept,
  getDeptArr,
  getDept,
  getFullTree,
  getFullTreeByValue,
} from '../../helpers/h-treeView';
import TreeContextMenu from '../context-menu/TreeContextMenu';
import { getMyOrgCode, getMyRootDeptCode, getMyTenantId } from '../../helpers/h-userInfo';
import { toast } from 'react-toastify';
import { AllowDrop } from 'rc-tree/lib/Tree';
import _ from 'lodash';
import 'rc-tree/assets/index.css';
import { clondeNodeWrapper, customTitle, dropIndicatorRender, switcherIconHandler } from '../../helpers/h-treeView-ui';
import {
  ContextHandler,
  OnDragEvent,
  OnDragEventWithKeys,
  OnDrop,
  OnExpand,
  OnRightClick,
  OnSelect,
} from '../../interfaces/I-treeView';
import { useResizeDetector } from 'react-resize-detector';
import { IAdminOrgModifyApiBody } from '../../interfaces/api/org/i-api-admin-org';
import Loader from '../common/Loader';
import TreeUserContextMenu from '../context-menu/TreeUserContextMenu';

export interface TreeData {
  title: string;
  key: string;
  isLeaf?: boolean;
  // 부서정보 / 사용자 정보
  children?: TreeData[];
  data: IhrsOrgDeptFull | IhrsOrgUserAll;
}

interface Props {
  width: number | undefined;
}

const prefixCls = 'ucTenantTree';

interface IdpObj {
  [key: number]: string;
  0: string;
  1: string;
}
const dpObj: IdpObj = {
  0: 'drop to content',
  1: 'drop to gap',
};

const initContextInfo = {
  show: false,
  points: {
    x: 0,
    y: 0,
  },
};

const initUserContextInfo = {
  show: false,
  points: {
    x: 0,
    y: 0,
  },
};

const initDraggingImageCoordinate = {
  x: -9999,
  y: -9999,
};

// MAIN
const TreeViewV6 = ({ width }: Props) => {
  const { deptStatus, removeDeptStatus, contextDeptInfo, removedDepParent, keyToScroll, keyToExpand, addDeptStatus } =
    useAppSelector(
      (state: { organizationUserManagement: { orgInfo: any } }) => state.organizationUserManagement.orgInfo,
    );
  const { userModifyStatus, userAddStatus, userRemoveStatus } = useAppSelector(
    (state: { organizationUserManagement: { userInfo: any } }) => state.organizationUserManagement.userInfo,
  );
  const { usersid } = useAppSelector((state) => state.user);

  const dispatch = useAppDispatch();

  // context-menu-states
  const [contextInfo, setContextInfo] = useState(initContextInfo);
  const [userContextInfo, setUserContextInfo] = useState(initUserContextInfo);
  // tree-view-states
  const treeRef = useRef<Tree<TreeData> | null>(null);
  const { width: treeWrapperWidth, height: treeWrapperHeight, ref: treeWrapperRef } = useResizeDetector();
  const treeTitleRef = useRef<HTMLElement | null>(null);

  const [treeData, setTreeData] = useState<TreeData[] | null>(null);
  const [selectedKeys, setSelectedKeys] = useState<Key[]>([]);
  const [expandedKeys, setExpandedKeys] = useState<Key[]>([]);
  const [autoExpandParent, setAutoExpandParent] = useState(true);
  const [searchInputValue, setSearchInputValue] = useState<string>('');
  const [currentIndex, setCurrentIndex] = useState(0);
  const [lastSearch, setLastSearch] = useState('');
  const [didSearch, setDidSearch] = useState(false);
  const [coordinate, setCoordinate] = useState(initDraggingImageCoordinate);
  const [cloneNode, setCloneNode] = useState<IhrsOrgDeptFull | IhrsOrgUserAll | null>(null);

  // 스크론 테스트 스테이트
  const [_keyToScroll, _setKeyToScroll] = useState<string | null>(null);

  // 초기 ROOT 부서 불러오기
  useEffect(() => {
    const fetchData = async () => {
      const tenantid = getMyTenantId();
      const orgcode = getMyOrgCode();
      const rootDept = getMyRootDeptCode();

      // console.log(tenantid);
      // console.log(orgcode);
      // console.log(rootDept);

      if (!(tenantid && orgcode && rootDept)) return alert('error from treeView.fetchData');

      const res = await hrsOrgsListApi({
        cmd: '1103',
        sid: usersid ? usersid : '',
        what: [{ gid: `${tenantid}.${orgcode}.${rootDept}` }],
        fields: 'FULL',
        include: 'DEPT',
        kind: 'DEPT',
        extra: 'ALL',
      });
      if (!res) return;

      if (!res.data.data.result.dept || res.data.data.result.dept.data.length < 1) return;

      const deptData = res.data.data.result.dept.data[0];
      const deptCode: string = res?.data.data.result.dept.data[0].deptcode;

      setTreeData([
        {
          title: deptData.deptname,
          key: deptCode,
          data: {
            ...deptData,
          },
        },
      ]);
    };

    setTimeout(() => {
      fetchData();
      // fetchFullTree();
    }, 30);
  }, []);

  useEffect(() => {
    const handleDragOver = (event: any) => {
      event.preventDefault(); // 드랍 이벤트를 위해 필수
    };

    const handleDrop = (event: any) => {
      console.log('d1');
      event.preventDefault();
      const data = event.dataTransfer.getData('application/json');
      const item = JSON.parse(data);
      console.log('Dropped data:', item);
    };

    const dropArea = treeWrapperRef.current;
    console.log('d2');
    if (dropArea) {
      dropArea.addEventListener('dragover', handleDragOver);
      dropArea.addEventListener('drop', handleDrop);
    }

    return () => {
      console.log('d3');
      if (dropArea) {
        dropArea.removeEventListener('dragover', handleDragOver);
        dropArea.removeEventListener('drop', handleDrop);
      }
    };
  }, []);

  // 부서 컨텍스트 메뉴
  const contextHandler: ContextHandler = ({ e }) => {
    e.preventDefault();
    //부서가 활성화되면 사용자는 비활성화
    setContextInfo({
      ...contextInfo,
      points: {
        x: e.clientX + 150 > window.innerWidth ? window.innerWidth - 180 : e.clientX,
        y: e.clientY + 200 > window.innerHeight ? window.innerHeight - 200 : e.clientY,
      },
      show: true,
    });

    setUserContextInfo({
      ...userContextInfo,
      points: {
        x: e.clientX + 150 > window.innerWidth ? window.innerWidth - 180 : e.clientX,
        y: e.clientY + 200 > window.innerHeight ? window.innerHeight - 200 : e.clientY,
      },
      show: false,
    });
  };

  // 사용자 컨텍스트 메뉴
  const userContextHandler: ContextHandler = ({ e }) => {
    e.preventDefault();
    //사용자가 활성화되면 부서는 비활성화
    setUserContextInfo({
      ...userContextInfo,
      points: {
        x: e.clientX + 150 > window.innerWidth ? window.innerWidth - 180 : e.clientX,
        y: e.clientY + 200 > window.innerHeight ? window.innerHeight - 200 : e.clientY,
      },
      show: true,
    });
    setContextInfo({
      ...contextInfo,
      points: {
        x: e.clientX + 150 > window.innerWidth ? window.innerWidth - 180 : e.clientX,
        y: e.clientY + 200 > window.innerHeight ? window.innerHeight - 200 : e.clientY,
      },
      show: false,
    });
  };

  // TREE-EVENT-LISTNER

  // 노드 클릭시..
  const onSelect: OnSelect = (selectedKeys, info) => {
    if (info.node.expanded) return;
    setSelectedKeys(selectedKeys);
  };

  // const cellClickedListener = useCallback((event: Cell<any>) => {
  //   const userInfo = event.data;
  //   dispatch(setSelectedUser(userInfo));
  // }, []);

  // 노드 우클릭시
  const onRightClick: OnRightClick = ({ event, node }) => {
    /* 원본 (부서만) 
    if (node.isLeaf) return;
    if (node.data.type === 'hrsOrgDept') {
      const dept = node.data;
      dispatch(setContextDeptInfo(dept));
      contextHandler({ e: event });
    }
    */

    //사용자의 경우
    if (node.isLeaf) {
      const userInfo = node.data;
      if (userInfo && 'nickname' in userInfo) {
        // 'id' 속성의 존재 여부로 IhrsOrgUserAll 타입인지 판단
        dispatch(setContextUserInfo(userInfo));
      }
      userContextHandler({ e: event });

      //부서의 경우
    } else if (node.data.type === 'hrsOrgDept') {
      const dept = node.data;
      console.log(node.data);
      dispatch(setContextDeptInfo(dept));
      contextHandler({ e: event });
    } else {
      return;
    }
  };

  // 노드 확장시...
  const onExpand: OnExpand = (expandedKeys, info) => {
    if (info.nativeEvent.type === 'dragenter') {
      return;
    }
    setAutoExpandParent(false);
    setExpandedKeys(expandedKeys);
  };

  // 드랍 허용 조건
  const allowDrop: AllowDrop<TreeData> = ({ dragNode, dropNode, dropPosition }) => {
    // 0 = drop to content
    // 1 = dropt to gap
    // console.log(dpObj[dropPosition]);
    // console.log(dropNode);
    // 드래그 = 사용자
    if (dropNode.isLeaf) {
      if (dropPosition === 0) return false;
      if (!dragNode.isLeaf) return false;
    }

    return true;
  };

  // 드래그 시작시
  const onDragStart: OnDragEvent = (info) => {
    const nodeTitle = info.event.currentTarget.lastChild?.lastChild as HTMLElement;
    if (nodeTitle) {
      treeTitleRef.current = nodeTitle;
      treeTitleRef.current.classList.add(`${prefixCls}-title-dragging`);
    }
    info.event.dataTransfer.setDragImage(new Image(), 0, 0);
    setCloneNode(info.node.data);
  };

  // 드래그오버시...
  const onDragOver: OnDragEvent = (info) => {
    // console.log('onDragOver', info.node.title);
    info.event.preventDefault();
    info.event.stopPropagation();
    // console.log(info.event.target);
  };

  // 드래그 진입시
  const onDragEnter: OnDragEventWithKeys = (info) => {
    // console.log('dratEnter', info);
    console.log('on drag enter');
  };

  // 드래그 끝날시
  const onDragEnd: OnDragEvent = (info) => {
    console.log('on drag end');
    const nodeTitle = info.event.currentTarget.lastChild?.lastChild as HTMLElement;
    if (nodeTitle) {
      treeTitleRef.current = nodeTitle;
      treeTitleRef.current.classList.remove(`${prefixCls}-title-dragging`);
    }
    setCoordinate({ x: -9999, y: -9999 });
    setCloneNode(null);
  };

  // 드랍...
  const onDrop: OnDrop = (info) => {
    console.log('on drop');
    if (!treeData) return;
    const { dragNode, node: dropNode, dropToGap } = info;
    const dragNodeKeys = info.dragNodesKeys as string[];
    const { key: dragNodeKey, data: dragNodeData } = dragNode;
    const { key: dropNodeKey, data: dropNodeData } = dropNode;
    let belongArr_drag: TreeData[] = [];
    let belongArr_drop: TreeData[] = [];

    loopNodes(treeData, dragNodeKey, (node, index, arr) => {
      belongArr_drag = arr;
    });
    loopNodes(treeData, dropNodeKey, (node, index, arr) => {
      belongArr_drop = arr;
    });
    let dragIndex = belongArr_drag.findIndex((v) => v.key === dragNodeKey);
    let dropIndex = belongArr_drop.findIndex((v) => v.key === dropNodeKey);

    //! 드랍==부서
    if (dropNodeData.type === 'hrsOrgDept') {
      //! 드랍==부서&&드래그==사용자
      if (dragNodeData.type === 'hrsOrgUser') {
        const userNodeParentNodeKey = dropNodeKey;
        //! dropToGap
        if (dropToGap) {
          // 드래그 부서 !== 드랍 부서
          if (dragNodeData.deptcode !== dropNodeData.deptcode) {
            const keyToScroll = dropNodeKey + '-' + dragNodeKey.split('-')[1];
            let belongArr_drop_children: TreeData[] = [];
            loopNodes(treeData, dropNodeKey, (node, index, arr) => {
              if (node.children) {
                belongArr_drop_children = leaveOnlyUser(node.children);
              }
            });
            const dragUserArr = getUsersArr(belongArr_drag);
            const dropUserArr = getUsersArr(belongArr_drop_children);

            const dragUsers = dragUserArr.map((user, i) => {
              if (i === dragIndex) {
                return {
                  ...user,
                  userorder: 0,
                  deptcode: dropNodeData.deptcode,
                  deptname: dropNodeData.deptname,
                };
              }
              if (dragIndex < i) {
                return { ...user, userorder: i - 1 };
              }
              return user;
            });
            const dropUsers = dropUserArr.map((user, i) => {
              return { ...user, userorder: i + 1 };
            });
            const dragUserFormList = usersToUserModifyFormList(dragUsers);
            const dropUserFormList = usersToUserModifyFormList(dropUsers);
            const userFormList = [...dragUserFormList, ...dropUserFormList];
            modifyUserByArray({
              userlist: userFormList,
              keyToScroll,
              keyToExpand: userNodeParentNodeKey,
            });
            return;
          }
          return;
        }

        // ! dropToContent
        // 드래그 사용자.부서 === 드랍 부서
        if (dragNodeData.deptcode === dropNodeData.deptcode) {
          belongArr_drag = leaveOnlyUser(belongArr_drag);

          if (dragIndex < 0 || dropIndex < 0) return;

          const usersArr = getUsersArr(belongArr_drag);
          const users = setDragUserToFirstIndex(usersArr, dragNodeData.bid, dragIndex);
          const userFormList = usersToUserModifyFormList(users);
          modifyUserByArray({
            userlist: userFormList,
            keyToScroll: dragNodeKey,
            keyToExpand: userNodeParentNodeKey,
          });
          return;
        }

        // 드래그 사용자.부서 !== 드랍 부서
        if (dragNodeData.deptcode !== dropNodeData.deptcode) {
          const keyToScroll = dropNodeKey + '-' + dragNodeKey.split('-')[1];
          let belongArr_drop_children: TreeData[] = [];
          loopNodes(treeData, dropNodeKey, (node, index, arr) => {
            if (node.children) {
              belongArr_drop_children = leaveOnlyUser(node.children);
            }
          });
          const dragUserArr = getUsersArr(belongArr_drag);
          const dropUserArr = getUsersArr(belongArr_drop_children);

          const dragUsers = dragUserArr.map((user, i) => {
            if (i === dragIndex) {
              return {
                ...user,
                userorder: 0,
                deptcode: dropNodeData.deptcode,
                deptname: dropNodeData.deptname,
              };
            }
            if (dragIndex < i) {
              return { ...user, userorder: i - 1 };
            }
            return user;
          });
          const dropUsers = dropUserArr.map((user, i) => {
            return { ...user, userorder: i + 1 };
          });
          const dragUserFormList = usersToUserModifyFormList(dragUsers);
          const dropUserFormList = usersToUserModifyFormList(dropUsers);
          const userFormList = [...dragUserFormList, ...dropUserFormList];
          modifyUserByArray({
            userlist: userFormList,
            keyToScroll,
            keyToExpand: userNodeParentNodeKey,
          });
          return;
        }
        return;
      }

      // ! 드랍==부서&&드래그==부서
      if (dragNodeData.type === 'hrsOrgDept') {
        belongArr_drag = leaveOnlyDept(belongArr_drag);
        belongArr_drop = leaveOnlyDept(belongArr_drop);
        const belongArr_drag_dept = getDeptArr(belongArr_drag);
        const belongArr_drop_dept = getDeptArr(belongArr_drop);
        dragIndex = belongArr_drag.findIndex((v) => v.key === dragNodeKey);
        dropIndex = belongArr_drop.findIndex((v) => v.key === dropNodeKey);
        const dropDeptChildrenTreeData = belongArr_drop[dropIndex].children ?? [];
        const dropDeptChildrenDept = getDeptArr(dropDeptChildrenTreeData);
        const willChangeDeptKeys = dragNodeKeys.reduce((acc, cur) => {
          if (!cur.includes('-')) {
            acc.push(cur);
          }
          return acc;
        }, [] as string[]);
        const willChangeDeptKeysParentDeptCode = [
          dropToGap ? getDept(dropNode).parentdeptcode : dropNodeKey.split(':')[dropNodeKey.split(':').length - 1],
        ];
        const willChangeDeptTreeDatas: TreeData[] = [];
        const keyToScroll = dropToGap
          ? dropNodeKey.split(':').slice(0, -1).join(':') + ':' + dragNodeData.deptcode
          : dropNodeKey + ':' + dragNodeData.deptcode;

        for (const key of willChangeDeptKeys) {
          loopNodes(treeData, key, (node, index, arr) => {
            willChangeDeptTreeDatas.push(node);
            if (key !== willChangeDeptKeys[0]) {
              const deptData = getDept(node);
              willChangeDeptKeysParentDeptCode.push(deptData.parentdeptcode);
            }
          });
        }

        const willChangeDepts = getDeptArr(willChangeDeptTreeDatas);
        // ! dropToGap
        if (dropToGap) {
          // ! 상위 부서가 같은 부서의 다음 순서로 이동
          if (dropNodeData.parentdeptcode === dragNodeData.parentdeptcode) {
            if (dragIndex > dropIndex) {
              const belongArr_drag_Form = belongArr_drag_dept.reduce((acc, cur, i) => {
                if (i <= dropIndex) return acc;
                if (i >= dragIndex) return acc;

                acc.push({
                  orgcode: dropNodeData.orgcode,
                  language: cur.language,
                  deptcode: cur.deptcode,
                  deptname: cur.deptname,
                  parentdeptcode: cur.parentdeptcode,
                  deptorder: i + 1,
                });
                return acc;
              }, [] as IAdminOrgModifyApiBody[]);
              const dragDeptAndChildrenForm = willChangeDepts.map((dept, i) => {
                return {
                  orgcode: dropNodeData.orgcode,
                  language: dept.language,
                  deptcode: dept.deptcode,
                  deptname: dept.deptname,
                  parentdeptcode: willChangeDeptKeysParentDeptCode[i],
                  deptorder: i === 0 ? dropIndex + 1 : dept.deptorder,
                };
              });

              const deptOrgModifyForms = [...belongArr_drag_Form, ...dragDeptAndChildrenForm];
              dispatch(
                modifyDepts({
                  depts: deptOrgModifyForms,
                  keyToScroll,
                  keyToExpand: keyToScroll,
                }),
              );
              return;
            }

            if (dragIndex < dropIndex) {
              const belongArr_drag_Form = belongArr_drag_dept.reduce((acc, cur, i) => {
                if (i > dropIndex) return acc;
                if (i <= dragIndex) return acc;

                acc.push({
                  orgcode: dropNodeData.orgcode,
                  language: cur.language,
                  deptcode: cur.deptcode,
                  deptname: cur.deptname,
                  parentdeptcode: cur.parentdeptcode,
                  deptorder: i - 1,
                });
                return acc;
              }, [] as IAdminOrgModifyApiBody[]);
              const dragDeptAndChildrenForm = willChangeDepts.map((dept, i) => {
                return {
                  orgcode: dropNodeData.orgcode,
                  language: dept.language,
                  deptcode: dept.deptcode,
                  deptname: dept.deptname,
                  parentdeptcode: willChangeDeptKeysParentDeptCode[i],
                  deptorder: i === 0 ? dropIndex : dept.deptorder,
                };
              });

              const deptOrgModifyForms = [...belongArr_drag_Form, ...dragDeptAndChildrenForm];
              dispatch(
                modifyDepts({
                  depts: deptOrgModifyForms,
                  keyToScroll,
                  keyToExpand: keyToScroll,
                }),
              );
              return;
            }
            return;
          }

          // ! 상위 부서가 다른 부서의 다음 순서로 이동
          if (dropNodeData.parentdeptcode !== dragNodeData.parentdeptcode) {
            const belongArr_drag_Form = belongArr_drag_dept.reduce((acc, cur, i) => {
              if (i <= dragIndex) return acc;

              acc.push({
                orgcode: dropNodeData.orgcode,
                language: cur.language,
                deptcode: cur.deptcode,
                deptname: cur.deptname,
                parentdeptcode: cur.parentdeptcode,
                deptorder: i - 1,
              });
              return acc;
            }, [] as IAdminOrgModifyApiBody[]);
            const belongArr_drop_Form = belongArr_drop_dept.reduce((acc, cur, i) => {
              if (i <= dropIndex) return acc;

              acc.push({
                orgcode: dropNodeData.orgcode,
                language: cur.language,
                deptcode: cur.deptcode,
                deptname: cur.deptname,
                parentdeptcode: cur.parentdeptcode,
                deptorder: i + 1,
              });
              return acc;
            }, [] as IAdminOrgModifyApiBody[]);

            const dragDeptAndChildrenForm = willChangeDepts.map((dept, i) => {
              return {
                orgcode: dropNodeData.orgcode,
                language: dept.language,
                deptcode: dept.deptcode,
                deptname: dept.deptname,
                parentdeptcode: willChangeDeptKeysParentDeptCode[i],
                deptorder: i === 0 ? dropIndex + 1 : dept.deptorder,
              };
            });

            const deptOrgModifyForms = [...belongArr_drag_Form, ...belongArr_drop_Form, ...dragDeptAndChildrenForm];
            dispatch(
              modifyDepts({
                depts: deptOrgModifyForms,
                keyToScroll,
                keyToExpand: keyToScroll,
              }),
            );
            return;
          }
          return;
        }

        // ! dropToContent
        if (!dropToGap) {
          // 상위 부서가 같아 드래그한 부서를 현재 위치한 부서의 첫번째로 이동
          if (dropNodeData.deptcode === dragNodeData.parentdeptcode) {
            const dropDeptChildren_Form = dropDeptChildrenDept.reduce((acc, cur, i) => {
              if (i === dragIndex) return acc;
              acc.push({
                orgcode: dropNodeData.orgcode,
                language: cur.language,
                deptcode: cur.deptcode,
                deptname: cur.deptname,
                parentdeptcode: cur.parentdeptcode,
                deptorder: i + 1,
              });
              return acc;
            }, [] as IAdminOrgModifyApiBody[]);
            const dragDeptAndChildrenForm = willChangeDepts.map((dept, i) => {
              return {
                orgcode: dropNodeData.orgcode,
                language: dept.language,
                deptcode: dept.deptcode,
                deptname: dept.deptname,
                parentdeptcode: willChangeDeptKeysParentDeptCode[i],
                deptorder: i === 0 ? 0 : dept.deptorder,
              };
            });
            const deptOrgModifyForms = [...dropDeptChildren_Form, ...dragDeptAndChildrenForm];
            dispatch(
              modifyDepts({
                depts: deptOrgModifyForms,
                keyToScroll,
                keyToExpand: keyToScroll,
              }),
            );
            return;
          }

          // 상위 부서가 달라 드래그한 부서를 드랍한 부서의 첫번째로 이동
          if (dropNodeData.deptcode !== dragNodeData.parentdeptcode) {
            const belongArr_drag_Form = belongArr_drag_dept.reduce((acc, cur, i) => {
              if (i <= dragIndex) return acc;

              acc.push({
                orgcode: dropNodeData.orgcode,
                language: cur.language,
                deptcode: cur.deptcode,
                deptname: cur.deptname,
                parentdeptcode: cur.parentdeptcode,
                deptorder: i - 1,
              });
              return acc;
            }, [] as IAdminOrgModifyApiBody[]);
            const dropDeptChildren_Form = dropDeptChildrenDept.reduce((acc, cur, i) => {
              acc.push({
                orgcode: dropNodeData.orgcode,
                language: cur.language,
                deptcode: cur.deptcode,
                deptname: cur.deptname,
                parentdeptcode: cur.parentdeptcode,
                deptorder: i + 1,
              });
              return acc;
            }, [] as IAdminOrgModifyApiBody[]);
            const dragDeptAndChildrenForm = willChangeDepts.map((dept, i) => {
              return {
                orgcode: dropNodeData.orgcode,
                language: dept.language,
                deptcode: dept.deptcode,
                deptname: dept.deptname,
                parentdeptcode: willChangeDeptKeysParentDeptCode[i],
                deptorder: i === 0 ? 0 : dept.deptorder,
              };
            });
            const deptOrgModifyForms = [...belongArr_drag_Form, ...dropDeptChildren_Form, ...dragDeptAndChildrenForm];
            dispatch(
              modifyDepts({
                depts: deptOrgModifyForms,
                keyToScroll,
                keyToExpand: keyToScroll,
              }),
            );
            return;
          }
          return;
        }
        return;
      }
      return;
    }

    //! 드랍==사용자
    if (dropNodeData.type === 'hrsOrgUser') {
      if (!dropToGap) return;

      const userNodeParentNodeKey = dropNodeKey.split('-')[0];
      //! dropToContent
      belongArr_drag = leaveOnlyUser(belongArr_drag);
      belongArr_drop = leaveOnlyUser(belongArr_drop);
      const dragUserArr = getUsersArr(belongArr_drag);
      dragIndex = belongArr_drag.findIndex((v) => v.key === dragNodeKey);
      dropIndex = belongArr_drop.findIndex((v) => v.key === dropNodeKey);
      if (dragIndex < 0 || dropIndex < 0) return;
      //! 드랍==사용자&&드래그==사용자
      if (dragNodeData.type === 'hrsOrgUser') {
        // 같은 부서간 사용자 순서 이동
        if (dragNodeData.deptcode === dropNodeData.deptcode) {
          // 드래그 노드 인덱스 > 드랍 노드 인덱스
          if (dragIndex > dropIndex) {
            // 드랍된 노드의 순서가 배열의 마지막이라면
            if (dropIndex === belongArr_drag.length - 1) {
              const users = setDragUserToLastIndex(dragUserArr, dragNodeData.bid, dragIndex);
              const userFormList = usersToUserModifyFormList(users);
              modifyUserByArray({
                userlist: userFormList,
                keyToScroll: dragNodeKey,
                keyToExpand: userNodeParentNodeKey,
              });
              return;
            }
            // 드랍된 노드의 순서가 배열의 마지막이 아니라면
            const users = setUsersOrderByKey_sameDept({
              userArr: dragUserArr,
              bid: dragNodeData.bid,
              dragIndex,
              dropIndex,
              key: 'dragIsLargerThanDrop',
            });
            const userFormList = usersToUserModifyFormList(users);
            modifyUserByArray({
              userlist: userFormList,
              keyToScroll: dragNodeKey,
              keyToExpand: userNodeParentNodeKey,
            });
            return;
          }

          // 드래그 노드 인덱스 < 드랍 노드 인덱스
          if (dragIndex < dropIndex) {
            const users = setUsersOrderByKey_sameDept({
              userArr: dragUserArr,
              bid: dragNodeData.bid,
              dragIndex,
              dropIndex,
              key: 'dragIsSmallerThanDrop',
            });
            const userFormList = usersToUserModifyFormList(users);
            modifyUserByArray({
              userlist: userFormList,
              keyToScroll: dragNodeKey,
              keyToExpand: userNodeParentNodeKey,
            });
            return;
          }
          return;
        }

        // 드래그 사용자.부서 !== 드랍 사용자.부서
        if (dragNodeData.deptcode !== dropNodeData.deptcode) {
          const keyToScroll = dropNodeKey.split('-')[0] + '-' + dragNodeKey.split('-')[1];
          // const dragUserArr = getUsersArr(belongArr_drag);
          const dropUserArr = getUsersArr(belongArr_drop);

          const dragUsers = dragUserArr.map((user, i) => {
            if (i === dragIndex) {
              return {
                ...user,
                userorder: dropIndex + 1,
                deptcode: dropNodeData.deptcode,
                deptname: dropNodeData.deptname,
              };
            }
            if (dragIndex < i) {
              return { ...user, userorder: i - 1 };
            }
            return user;
          });
          const dropUsers = dropUserArr.map((user, i) => {
            if (i > dropIndex) {
              return { ...user, userorder: i + 1 };
            }
            return user;
          });
          const dragUserFormList = usersToUserModifyFormList(dragUsers);
          const dropUserFormList = usersToUserModifyFormList(dropUsers);
          const userFormList = [...dragUserFormList, ...dropUserFormList];
          modifyUserByArray({
            userlist: userFormList,
            keyToScroll: keyToScroll,
            keyToExpand: userNodeParentNodeKey,
          });

          return;
        }
        return;
      }
      return;
    }
  };

  // 하위 노드 1depth씩 불러오기
  const onLoadData = async (selectedNode: EventDataNode<TreeData>) => {
    if (didSearch) return;
    getChildDeptUser({ selectedNode, treeData, setTreeData });
  };

  // 업데이트시.. treeData 재설정
  const fetchFullTree = async () => {
    const treeData = await getFullTree();
    if (treeData) {
      setAutoExpandParent(true);
      setTreeData(treeData);
      console.log(treeData);
      return;
    }
    setTreeData(null);
  };

  // searchInputValue가 변경되면 currentIndex를 초기화
  useEffect(() => {
    if (searchInputValue !== lastSearch) {
      setCurrentIndex(0);
      setLastSearch(searchInputValue);
    }
  }, [searchInputValue, lastSearch]);

  //사용자 검색시 treeData 설정 함수
  const fetchTreeByValue = async () => {
    if (!searchInputValue) {
      return;
    }

    const res = await getFullTreeByValue({
      searchInputValue,
      currentIndex,
      setCurrentIndex,
    });

    if (!res) {
      toast.info(t('OrganizationUserManagement.toastNoResult') as string);
      return;
    }
    const { treeData, keyForSelect, keyForExpand } = res;
    setAutoExpandParent(true);
    setTreeData(treeData);
    setSelectedKeys([keyForSelect]);
    setExpandedKeys([keyForExpand]);
    _setKeyToScroll(keyForSelect);
    setDidSearch(true);
  };

  // 컨텍스트 메뉴 활성화 이후 다른 화면 클릭시 비활성화 하는 이벤트
  useEffect(() => {
    const handleClick = () => setContextInfo({ ...contextInfo, show: false });
    window.addEventListener('click', handleClick);
    return () => {
      window.removeEventListener('click', handleClick);
      dispatch(initDeptStatus());
    };
  }, []);

  useEffect(() => {
    const handleClick = () => setUserContextInfo({ ...userContextInfo, show: false });
    window.addEventListener('click', handleClick);
    return () => {
      window.removeEventListener('click', handleClick);
      dispatch(initDeptStatus());
    };
  }, []);

  // 드래그앤 드롭 툴팁을 위한 데이터 구하기
  useEffect(() => {
    function logKey(e: globalThis.MouseEvent) {
      setCoordinate({
        x: e.clientX,
        y: e.clientY,
      });
    }
    const t = _.throttle(logKey, 30);

    if (!cloneNode) {
      window.removeEventListener('dragover', t, true);
      return;
    }
    window.addEventListener('dragover', t, true);

    return () => {
      window.removeEventListener('dragover', t, true);
    };
  }, [cloneNode]);

  // 옵저버
  useEffect(() => {
    if (_keyToScroll) {
      const keyToScrollsExpandedKey = _keyToScroll.includes('-') ? _keyToScroll.split('-')[0] : _keyToScroll;
      if (expandedKeys.includes(keyToScrollsExpandedKey)) {
        setTimeout(() => {
          // 0.35초 딜레이 없애고싶다...
          // 아래 메서드가 실행되는 순서에서 필요한 조건(스크롤 하려고 하는 노드키가 expand되있어야함)이 로그로 찍어보면 충족 되있는데 실행되는 시점에서는 충족되있지 않음.
          if (treeRef.current) {
            treeRef.current.scrollTo({ key: _keyToScroll, align: 'top' });
            _setKeyToScroll(null);
            dispatch(setKeyToExpand(null));
            dispatch(setKeyToScroll(null));
          }
        }, 350);
      }
    }
  }, [expandedKeys, _keyToScroll]);

  useEffect(() => {
    if (keyToExpand) {
      setAutoExpandParent(false);
      setExpandedKeys([keyToExpand]);
    }
    if (keyToScroll) {
      setSelectedKeys([keyToScroll]);
      _setKeyToScroll(keyToScroll);
    }
  }, [keyToScroll, keyToExpand]);

  useEffect(() => {
    if (deptStatus === 'pending') {
      setCoordinate(initDraggingImageCoordinate);
    }
    if (deptStatus === 'success') {
      fetchFullTree();
      dispatch(initDeptStatus());
    }
  }, [deptStatus]);

  useEffect(() => {
    if (addDeptStatus === 'success') {
      //toast.success(t('OrganizationUserManagement.toastDeptAddSuccess') as string);
      fetchFullTree();
      const key = contextDeptInfo?.deptpath;
      setExpandedKeys(key ? [key] : ['']);
      dispatch(initDeptStatus());
    }
  }, [addDeptStatus]);

  useEffect(() => {
    if (removeDeptStatus === 'success') {
      toast.success(t('OrganizationUserManagement.toastDeptDeleteSuccess') as string);
      fetchFullTree();
      setExpandedKeys(removedDepParent ? [removedDepParent] : ['']);
      dispatch(initDeptStatus());
    }
  }, [removeDeptStatus]);

  useEffect(() => {
    if (userModifyStatus === 'pending') {
      setCoordinate(initDraggingImageCoordinate);
    }
    if (userModifyStatus === 'success') {
      fetchFullTree();
      dispatch(initUserModifyStatus());
    }
  }, [userModifyStatus]);

  useEffect(() => {
    if (userAddStatus !== 'success') return;

    fetchFullTree();
  }, [userAddStatus]);

  useEffect(() => {
    if (userRemoveStatus !== 'success') return;

    fetchFullTree();
  }, [userRemoveStatus]);

  useEffect(() => {
    return () => {
      dispatch(initUserModifyStatus());
    };
  }, []);

  return (
    <div className="h-full w-full flex flex-col">
      <LeftTopBar
        width={width}
        searchInputValue={searchInputValue}
        setSearchInputValue={setSearchInputValue}
        fetchTree={fetchTreeByValue}
      />
      {/* Tree-View */}
      <div id="treeView" ref={treeWrapperRef} className="grow flex flex-col w-full h-0">
        {treeData && deptStatus !== 'pending' && userModifyStatus !== 'pending' ? (
          <Tree
            ref={treeRef}
            prefixCls={prefixCls}
            className="CUSTOM_TREE bg-white"
            // icon={iconHandler}

            // 컨디션
            expandAction="click"
            // defaultExpandParent={true}
            autoExpandParent={autoExpandParent}
            expandedKeys={expandedKeys}
            selectedKeys={selectedKeys}
            draggable
            // 메서드
            loadData={onLoadData}
            onSelect={onSelect}
            onExpand={onExpand}
            onRightClick={onRightClick}
            allowDrop={allowDrop}
            onDragStart={onDragStart}
            onDrop={onDrop}
            onDragEnd={onDragEnd}
            // showLine
            // onDragOver={onDragOver}
            // onDragEnter={onDragEnter}
            // onDragLeave={onDragLeave}

            // 렌더링
            height={treeWrapperHeight}
            treeData={treeData}
            switcherIcon={switcherIconHandler}
            dropIndicatorRender={dropIndicatorRender}
            titleRender={customTitle}
          />
        ) : (
          <Loader color="#1f62b9" isLoading={true} />
        )}
      </div>
      {/* 컨텍스트 메뉴 */}
      {contextInfo.show && <TreeContextMenu x={contextInfo.points.x} y={contextInfo.points.y} />}
      {userContextInfo.show && <TreeUserContextMenu x={userContextInfo.points.x} y={userContextInfo.points.y} />}
      {/* 트리뷰 드래그 툴팁*/}
      {cloneNode &&
        clondeNodeWrapper({
          x: coordinate.x,
          y: coordinate.y,
          cloneNode,
        })}
    </div>
  );
};

export default TreeViewV6;
