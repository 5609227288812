import React from 'react';
import { removeDeptHandler } from '../../helpers/h-orgUserMng';
import { closeUserOrgMngReactModal_remove_dept } from '../../helpers/h-react-modals';
import { useAppSelector } from '../../redux/hooks';
import BasicReactModal from './BasicReactModal';
import ModifyDeptReactModal from './orgUserMng/ModifyDeptReactModal';
import AddDeptReactModal from './orgUserMng/AddDeptReactModal';
import UserListReactModal from './orgUserMng/UserListReactModal';
import { t } from 'i18next';
import DeptBulkInsertReactModal from './orgUserMng/DeptBulkInsertReactModal';
import UserBulkInsertReactModal from './orgUserMng/UserBulkInsertReactModal';
import ProfileModal from '../modals/userOrgMng/ProfileModal';
import CounselingCategoryModal from './counselingMng/CounselingCategoryModal';
import CounselingChannelModal from './counselingMng/CounselingChannelModal';
import CounselingCounselorModal from './counselingMng/CounselingCounselorModal';
import ClassDepartmentModal from './classMng/ClassDepartmentModal';
import ClassParentInfoModal from './classMng/ClassParentInfoModal';
import ClassParentModal from './classMng/ClassParentModal';
import ClassStudentModal from './classMng/ClassStudentModal';
import ClassTeacherModal from './classMng/ClassTeacherModal';
import AddDepartmentModal from './orgUserMng/AddDepartmentModal';

const ReactModalView = () => {
  const { reactModals } = useAppSelector((state) => state.toggle);
  const {
    orgInfo: { contextDeptInfo },
  } = useAppSelector((state) => state.organizationUserManagement);

  return (
    <>
      {/* 조직 / 사용자 관리 */}
      <ProfileModal isOpen={reactModals.orgUserMng.profileImg} />
      <AddDeptReactModal isOpen={reactModals.orgUserMng.addDept} />
      {/* 부서 관리 (겸직 처리) */}
      <AddDepartmentModal isOpen={reactModals.orgUserMng.addDeptModal} />
      <ModifyDeptReactModal isOpen={reactModals.orgUserMng.modifyDept} />
      <BasicReactModal
        isOpen={reactModals.orgUserMng.removeDept}
        label={t('ContextMenu.treeContextMenu.treeToast3')}
        onClose={() => {
          closeUserOrgMngReactModal_remove_dept();
        }}
        onConfirm={() => {
          removeDeptHandler(contextDeptInfo);
          closeUserOrgMngReactModal_remove_dept();
        }}
      />

      {/* 조직 / 사용자 관리 -> 일괄등록(부서, 사용자) */}
      <DeptBulkInsertReactModal isOpen={reactModals.orgUserMng.bulkDept} />
      <UserBulkInsertReactModal isOpen={reactModals.orgUserMng.bulkUser} />

      {/* 관리자 권한 관리 */}
      <UserListReactModal isOpen={reactModals.orgUserMng.userList} />

      {/* 상담 관리 (카테고리) */}
      <CounselingCategoryModal isOpen={reactModals.orgUserMng.counselingCategory} />

      {/* 상담 관리 (채널) */}
      <CounselingChannelModal isOpen={reactModals.orgUserMng.counselingChannel} />

      {/* 상담 관리 (상담사) */}
      <CounselingCounselorModal isOpen={reactModals.orgUserMng.counselingCounselor} />

      {/* 학급 관리 (부서) */}
      <ClassDepartmentModal isOpen={reactModals.orgUserMng.classDepartment} />

      {/* 학급 관리 (부모정보) */}
      <ClassParentInfoModal isOpen={reactModals.orgUserMng.classParentInfo} />

      {/* 학급 관리 (부모 추가) */}
      <ClassParentModal isOpen={reactModals.orgUserMng.classParent} />

      {/* 학급 관리 (선생 추가) */}
      <ClassStudentModal isOpen={reactModals.orgUserMng.classStudent} />

      {/* 학급 관리 (학생 추가) */}
      <ClassTeacherModal isOpen={reactModals.orgUserMng.classTeacher} />
    </>
  );
};

export default ReactModalView;
