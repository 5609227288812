import React from 'react';
import ListTitle from '../../common/ListTitle';

const dummyData = new Array(30).fill(null).map(() => ({
  sqlDesc: 'null',
  sqlType: '전체사용자 SQL',
  description: 'ALL USER',
  queryCodeValue: '288004229288004229288004229288004229',
}));

const UpperListContainer = () => {
  return (
    <div className="h-[calc(100%_-_6.25rem)]">
      <ListTitle titles={['SQL 설명', 'SQL 종류', '설명', '쿼리 코드값']} />
      <div className="overflow-auto h-full">
        {dummyData.map((data, i) => (
          <List data={data} key={i} index={i} />
        ))}
      </div>
    </div>
  );
};

interface Data {
  sqlDesc: string;
  sqlType: string;
  description: string;
  queryCodeValue: string;
}

type Props = {
  data: Data;
  index: number;
};

const List: React.FC<Props> = ({ data, index }) => {
  return (
    <div
      className={`grid grid-cols-4 border-b border-solid py-[0.813rem] border-[#dfe2e8] ${
        index < 1 ? 'bg-[#f1f3f6]' : ''
      }`}
    >
      <div className="text-center">{data.sqlDesc}</div>
      <div className="text-center">{data.sqlType}</div>
      <div className="text-center">{data.description}</div>
      <div className="text-center">{data.queryCodeValue}</div>
    </div>
  );
};

export default UpperListContainer;
