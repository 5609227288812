import { getMyTenantId, getMyOrgCode } from './h-userInfo';
import { ChangeEvent, MouseEvent } from 'react';
import { v4 as uuidv4 } from 'uuid';
// REDUX
import { adminButtonFuncListApi } from '../api/admin/button/api-admin-btn-func';
import { adminButtonGroupListApi } from '../api/admin/button/api-admin-btn-group';
import { IAdminButtonFunc } from '../interfaces/api/button/I-api-admin-btn-func';
import {
  IAdminButtonGroup,
  IAdminButtonGroupAddApiBody,
} from '../interfaces/api/button/I-api-admin-btn-group';
import {
  addFuncBtn,
  addFuncBtnGroup,
  addFuncBtnToBtnGroup,
  changeSelectedFuncBtn,
  initSelectedFuncBtnGroupModal,
  modifyFuncBtn,
  modifyFuncBtnGroup,
  removeFuncBtn,
  removeFuncBtnFromBtnGroup,
  removeFuncBtnGroup,
  setFuncBtnGroupList,
  setFuncBtnList,
  setSelectedFuncBtnGroup,
} from '../redux/slice/funcButtonManagementSlice';
import { store as rootStore } from '../redux/store';
import { boolToStringNum } from './h-common';
import { addRuleFuncHandler } from './h-ruleFuncMng';
import {
  closeFuncBtnGroupAddModal,
  closeFuncBtnGroupModifyModal,
} from './h-modals';
import { toast } from 'react-toastify';
import axios from 'axios';

const state = rootStore.getState();
const dispatch = rootStore.dispatch;

// FUNC BUTTON
// LIST
export const fetchAndSetFuncBtnList = async () => {
  const tenantid = getMyTenantId();
  const orgcode = getMyOrgCode();
  if (!tenantid || !orgcode) return alert('error from fetchAndSetFuncBtnList');

  try {
    const res = await adminButtonFuncListApi({
      tenantid,
      orgcode,
      pagesize: 100,
      page: 1,
    });
    if (!res) return;

    const funcBtnList = res.data.data.result;
    dispatch(setFuncBtnList(funcBtnList));
  } catch (error) {
    if (axios.isAxiosError(error)) {
      console.log('error: ', error);
      return error;
    } else {
      console.log('unexpected error: ', error);
      return 'An unexpected error occurred';
    }
  }
};
// ADD
export const addFuncBtnHandler = (selectedFuncBtn: IAdminButtonFunc) => {
  // const { tenantid, orgcode, ...others } = selectedFuncBtn;
  // const funcBtn = { ...others, btncode: uuidv4().split('-').join('') };
  // 임시 변수...
  const BODY = {
    btncode: uuidv4().split('-').join(''),
    btnname: selectedFuncBtn.btnname ? selectedFuncBtn.btnname : 'NEW',
    btntype: selectedFuncBtn.btntype ? selectedFuncBtn.btntype : ' ',
    btndelimeter: selectedFuncBtn.btndelimeter
      ? selectedFuncBtn.btndelimeter
      : ' ',
    btnurlexe: selectedFuncBtn.btnurlexe ? selectedFuncBtn.btnurlexe : ' ',
    btnurlexeopt: selectedFuncBtn.btnurlexeopt
      ? selectedFuncBtn.btnurlexeopt
      : ' ',
    btnpopupname: selectedFuncBtn.btnpopupname
      ? selectedFuncBtn.btnpopupname
      : ' ',
    btnpopupurl: selectedFuncBtn.btnpopupurl
      ? selectedFuncBtn.btnpopupurl
      : ' ',
    btnpopupopt: selectedFuncBtn.btnpopupopt
      ? selectedFuncBtn.btnpopupopt
      : ' ',
    btnvisible: selectedFuncBtn.btnvisible ? selectedFuncBtn.btnvisible : ' ',
    btnusepopup: selectedFuncBtn.btnusepopup
      ? selectedFuncBtn.btnusepopup
      : ' ',
    btnselectuser: selectedFuncBtn.btnselectuser
      ? selectedFuncBtn.btnselectuser
      : ' ',
    btninmessenger: selectedFuncBtn.btninmessenger
      ? selectedFuncBtn.btninmessenger
      : ' ',
    btnusesvrfile: selectedFuncBtn.btnusesvrfile
      ? selectedFuncBtn.btnusesvrfile
      : ' ',
    btnsvrfile: selectedFuncBtn.btnsvrfile ? selectedFuncBtn.btnsvrfile : ' ',
    btnsvrrunexe: selectedFuncBtn.btnsvrrunexe
      ? selectedFuncBtn.btnsvrrunexe
      : ' ',
    btnsvrfilesize: selectedFuncBtn.btnsvrfilesize
      ? selectedFuncBtn.btnsvrfilesize
      : 0,
    btnsize: selectedFuncBtn.btnsize ? selectedFuncBtn.btnsize : 0,
    btnsvrver: selectedFuncBtn.btnsvrver ? selectedFuncBtn.btnsvrver : ' ',
    btncomment: selectedFuncBtn.btncomment ? selectedFuncBtn.btncomment : ' ',
    btnimgsrc: selectedFuncBtn.btnimgsrc ? selectedFuncBtn.btnimgsrc : ' ',
  };
  dispatch(addFuncBtn(BODY));
};
// MODIFY
export const modifyFuncBtnHandler = (selectedFuncBtn: IAdminButtonFunc) => {
  const { tenantid, orgcode, ...others } = selectedFuncBtn;
  const funcBtn = { ...others };

  dispatch(modifyFuncBtn(funcBtn));
};
// REMOVE
export const removeFuncBtnHandler = (selectedFuncBtn: IAdminButtonFunc) => {
  const { btncode } = selectedFuncBtn;
  dispatch(removeFuncBtn(btncode));
};

// FUNC BUTTON GROUP
// LIST
export const fetchAndSetFuncBtnGroupList = async () => {
  try {
    const res = await adminButtonGroupListApi({
      groupkind: 'F',
    });
    if (!res) return;

    const cntBtnGroupList = res.data.data.result;
    if (cntBtnGroupList.length > 0) {
      dispatch(setFuncBtnGroupList(cntBtnGroupList));
      dispatch(setSelectedFuncBtnGroup(cntBtnGroupList[0]));
    } else {
      dispatch(setFuncBtnGroupList([]));
      dispatch(
        setSelectedFuncBtnGroup({
          tenantid: '',
          orgcode: '',
          groupcode: '',
          groupkind: 'F',
          groupname: '',
          groupver: 0,
          grouporder: 0,
        }),
      );
    }
  } catch (error) {
    if (axios.isAxiosError(error)) {
      console.log('error: ', error);
    } else {
      console.log('unexpected error: ', error);
    }
  }
};
// ADD
export const addFuncBtnGroupHandler = (
  groupname: string,
  grouporder: number,
) => {
  if (groupname === '') {
    toast.error('한글자 이상 입력해주세요.');
    return;
  }

  const groupcode = uuidv4().split('-').join('');
  const groupkind = 'F';
  const funcBtnGroup: IAdminButtonGroupAddApiBody = {
    groupcode,
    groupkind,
    groupname,
    groupver: 0,
    grouporder,
  };
  dispatch(addFuncBtnGroup(funcBtnGroup));
  // rule/func 추가에 추가
  const ruleFunc = {
    funccode: groupcode,
    funckind: 'BUTTON-FUNCTION(G)',
    funcname: groupname,
    allowdup: 0,
    funcfield1: '버튼그룹 종류',
    funcfield2: 'FUNCTION BUTTON',
    defaultvalue: '0',
  };
  addRuleFuncHandler(ruleFunc);

  closeFuncBtnGroupAddModal();
};
// REMOVE
export const removeFuncBtnGroupHandler = (
  selectedFuncBtnGroupModal: IAdminButtonGroup | null,
) => {
  if (selectedFuncBtnGroupModal === null) {
    toast.error('버튼 그룹을 선택해주세요');
    return;
  }

  const groupcode = selectedFuncBtnGroupModal.groupcode;
  dispatch(removeFuncBtnGroup({ groupcode }));
  dispatch(initSelectedFuncBtnGroupModal());
};
// MODIFY
export const modifyFuncBtnGroupHandler = (
  selectedFuncBtnGroup: IAdminButtonGroup | null,
) => {
  if (selectedFuncBtnGroup === null) return alert('null selectedFuncBtnGroup');
  const { tenantid, orgcode, ...funcBtnGroup } = selectedFuncBtnGroup;

  dispatch(modifyFuncBtnGroup(funcBtnGroup));
  closeFuncBtnGroupModifyModal();
};
// FUNC BUTTON GROUP HUB
// ADD
export const addFuncBtnToBtnGroupHandler = (
  selectedFuncBtn: IAdminButtonFunc,
  selectedFuncBtnGroup: IAdminButtonGroup,
) => {
  if (selectedFuncBtn.btncode.length !== 32) {
    toast.error('function 버튼을 선택해주세요.');
    return;
  }
  if (selectedFuncBtnGroup.groupcode.length !== 32) {
    toast.error('function 버튼 그룹을 선택해주세요.');
    return;
  }

  dispatch(addFuncBtnToBtnGroup({ selectedFuncBtn, selectedFuncBtnGroup }));
};
// REMOVE
export const removeFuncBtnFromBtnGroupHandler = (
  selectedFuncBtnGroupsBtn: IAdminButtonFunc | null,
  selectedFuncBtnGroup: IAdminButtonGroup,
) => {
  if (
    selectedFuncBtnGroupsBtn === null ||
    selectedFuncBtnGroupsBtn.btncode.length !== 32
  ) {
    toast.error('버튼그룹에서 버튼을 선택해주세요.');
    return;
  }
  if (selectedFuncBtnGroup.groupcode.length !== 32) {
    toast.error('버튼그룹을 선택해주세요.');
    return;
  }

  dispatch(
    removeFuncBtnFromBtnGroup({
      selectedFuncBtnGroupsBtn,
      selectedFuncBtnGroup,
    }),
  );
};
// MODAL
// FORM
export const funcCheckBoxHandler = (e: ChangeEvent<HTMLInputElement>) => {
  dispatch(
    changeSelectedFuncBtn({
      name: e.target.name,
      value: boolToStringNum(e.target.checked),
    }),
  );
};
export const funcBtnFormInputHandler = (e: ChangeEvent<HTMLInputElement>) => {
  dispatch(
    changeSelectedFuncBtn({
      name: e.target.name,
      value: e.target.value,
    }),
  );
};
export const funcBtnFormInputClearHandler = (
  e: MouseEvent<HTMLButtonElement>,
) => {
  dispatch(
    changeSelectedFuncBtn({
      name: e.currentTarget.name,
      value: '',
    }),
  );
};
