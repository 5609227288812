import React, { useEffect, useState } from 'react';
import Modal from 'react-modal';
import { closeBulkInsertDept_Table } from '../../../helpers/h-react-modals';
import { AgGridReact } from 'ag-grid-react';
import { useTranslation } from 'react-i18next';
import { useMemo } from 'react';
import { CellEditingStoppedEvent, ColDef } from 'ag-grid-community';
import NumericCellEditor from '../../tables/orgUserMng/NumericCellEditor';
import { UserInfoList_Table_Import_Column } from '../../tables/orgUserMng/columns';
import { toast } from 'react-toastify';
import LeftTopBarImport from '../../mainPage/organization_user_management/LeftTopBarImport';
import { addDeptsHandler } from '../../../helpers/h-orgUserMng';
import ConfirmModal from '../ConfirmModal';
import { deptcodeValidPattern } from '../../../utils/validHandler';
import { useLanguage } from '../../provider/LanguageProvider';

const customStyles = {
  overlay: {
    zIndex: 99,
    // backgroundColor: 'transparent'
  },
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    width: '1000px',
    height: '700px',
    padding: 0,
  },
};

interface Props {
  isOpen: boolean;
}

interface RowDataItem {
  [key: string]: any;
  deptcode: string;
  deptname: string;
  parentdeptcode: string;
  deptorder: number;
}

const DeptBulkInsertReactModal = ({ isOpen = false }: Props) => {
  const { t } = useTranslation();
  const [columnDefs, setColumnDefs] = useState<ColDef[]>([]);
  const [gridApi] = useState<any>();
  const defaultColDef = useMemo<ColDef>(() => {
    return {
      flex: 1,
      resizable: true,
      minWidth: 100,
      editable: true,
      suppressMovableColumns: true,
    };
  }, []);

  const { languages, setLanguages } = useLanguage();

  const [rowData, setRowData] = useState<RowDataItem[]>([]);
  const [rowCount, setRowCount] = useState<number>(0);
  const [registPosCount, setRegistPosCount] = useState(0);
  const [registImposCount, setRegistImposCount] = useState(0);
  const [selectedRowData, setSelectedRowData] = useState<RowDataItem[]>([]);
  const [showUnregisterable, setShowUnregisterable] = useState(false);
  const [filteredData, setFilteredData] = useState<RowDataItem[]>([]);
  const [registConfirmModal, setRegistConfirmModal] = useState(false);
  const updateRowData = (newData: any) => {
    setRowData(newData);
  };

  const handleRegistServer = async () => {
    const depts: RowDataItem[] = [];
    selectedRowData.forEach((deptData) => {
      const dept = {
        deptcode: deptData.deptcode,
        deptname: deptData.deptname,
        deptorder: Number(deptData.deptorder),
        parentdeptcode: deptData.parentdeptcode,
      };
      depts.push(dept);
    });

    const response = await addDeptsHandler(depts, languages);

    // JSON 응답에서 "success" 값만 제외한 나머지 값을 추출하여 rowData로 설정
    if (response && response.code === 200 && response.data && response.data.result) {
      //등록후 모달창 닫음
      setRegistConfirmModal(false);
      const successArray = response.data.result.success || [];
      const filteredDepts = rowData.filter((dept: RowDataItem) => !successArray.includes(dept.deptcode));

      if (filteredDepts.length == 0) {
        toast.success(t('OrganizationUserManagement.toastDeptAddSuccess') as string);
        closeBulkInsertDept_Table();
      } else {
        const totalCnt = rowData.length;
        const failCnt = filteredDepts.length;
        const successCnt = String(totalCnt - failCnt);

        const msg =
          t('OrganizationUserManagement.toastDeptAddSuccess1') +
          successCnt +
          t('OrganizationUserManagement.toastDeptAddSuccess2') +
          failCnt +
          t('OrganizationUserManagement.toastDeptAddSuccess3');
        toast.success(msg);
      }

      setRowData(filteredDepts);
    }
  };

  const handleAddRow = () => {
    if (rowData.length >= 300) {
      toast.error(t('OrganizationUserManagement.toastWarn9') as string);
      return;
    }
    const newItem = { deptcode: '', deptname: '', parentdeptcode: '', deptorder: 1 };
    const newRowData = [...rowData, newItem];
    setRowData(newRowData);

    // 필터링 로직 추가
    if (showUnregisterable) {
      const newFilteredData = newRowData.filter((row) => {
        const isDeptCodeOrNameMissing = !row.deptcode || !row.deptname; // 부서코드, 부서명 누락
        const isDeptCodeDuplicated = newRowData.some((r) => r !== row && r.deptcode === row.deptcode); // 부서코드 중복
        return isDeptCodeOrNameMissing || isDeptCodeDuplicated;
      });
      setFilteredData(newFilteredData);
    }
  };

  //로우 삭제(유저/부서 분기)
  const handleDeleteSelectedRows = () => {
    const newRows = rowData.filter((row) => !selectedRowData.includes(row));
    const newFilteredRows = filteredData.filter((row) => !selectedRowData.includes(row));

    setSelectedRowData([]);
    setRowData(newRows);
    setFilteredData(newFilteredRows); // 필터링된 데이터 상태도 업데이트
  };

  const onSelectionChanged = (event: { api: { getSelectedNodes: () => any } }) => {
    const selectedNodes = event.api.getSelectedNodes();
    const selectedData = selectedNodes.map((node: { data: any }) => node.data);
    setSelectedRowData(selectedData);
  };

  const handleCheckboxChange = () => {
    const newShowUnregisterable = !showUnregisterable;
    setShowUnregisterable(newShowUnregisterable);

    if (newShowUnregisterable) {
      const newFilteredData = rowData.filter((row) => {
        const isDeptCodeOrNameMissing = !row.deptcode || !row.deptname || !deptcodeValidPattern(row.deptcode); // 부서코드, 부서명 누락
        const isDeptCodeDuplicated = rowData.some((r) => r !== row && r.deptcode === row.deptcode); // 부서코드 중복
        return isDeptCodeOrNameMissing || isDeptCodeDuplicated;
      });
      setFilteredData(newFilteredData);
    } else {
      setFilteredData(rowData); // 체크 해제 시 모든 데이터 표시
    }
  };

  //depart cal
  const calculateRegistStatus = () => {
    let registPosCount = 0;
    let registImposCount = 0;

    rowData.forEach((row) => {
      const isDeptCodeOrNameMissing = !row.deptcode || !row.deptname || !deptcodeValidPattern(row.deptcode);
      const isDeptCodeDuplicated = rowData.filter((r) => r.deptcode === row.deptcode).length > 1;

      if (isDeptCodeOrNameMissing || isDeptCodeDuplicated) {
        registImposCount += 1;
      } else {
        registPosCount += 1;
      }
    });

    return { registPosCount, registImposCount };
  };

  const onCellEditingStopped = (event: CellEditingStoppedEvent) => {
    const { registPosCount, registImposCount } = calculateRegistStatus();
    setRegistPosCount(registPosCount);
    setRegistImposCount(registImposCount);

    //부서 중복 or 부서코드 유효성
    if (event.colDef?.field === 'deptcode') {
      const currentCode = event.data.deptcode;
      const duplicateIndices = rowData
        .map((row, index) => (row.deptcode === currentCode ? index : -1))
        .filter((index) => index !== -1);
      const validPattern = /^[a-zA-Z0-9]+$/;

      if (duplicateIndices.length > 1) {
        toast.warn(t('OrganizationUserManagement.toastWarn') as string);

        const lastDuplicateIndex = duplicateIndices[duplicateIndices.length - 1];
        const updatedRows = rowData.map((row, index) => {
          if (index === lastDuplicateIndex) {
            return { ...row, deptcode: '' };
          }
          return row;
        });
        setRowData(updatedRows);
      } else if (!validPattern.test(currentCode)) {
        const updatedRows = rowData.map((row) => (row.deptcode === currentCode ? { ...row, deptcode: '' } : row));
        setRowData(updatedRows);
        toast.warn(t('OrganizationUserManagement.toastWarn3') as string);
      }
    }
  };

  //모달
  const toggleApplyModal = () => {
    if (selectedRowData.length < 1) {
      toast.info(t('Enrolment.moreThanOne'));
      return;
    }
    setRegistConfirmModal((state) => !state);
  };

  const toggleDeleteModal = () => {
    setRegistConfirmModal((state) => !state);
  };

  const modalContent = () => {
    if (registConfirmModal == true) {
      if (rowData.length == 0) {
        toast.error(t('OrganizationUserManagement.toastWarn11') as string);
        setRegistConfirmModal(false);
        return <></>;
      } else if (registPosCount == 0) {
        toast.error(t('OrganizationUserManagement.toastWarn12') as string);
        setRegistConfirmModal(false);
        return <></>;
      } else {
        return (
          <div>
            {selectedRowData.length} {t('OrganizationUserManagement.confirmContent')}
            <br />
            <div className="text-center">
              ( {t('OrganizationUserManagement.registPos2')} : {registPosCount},{' '}
              {t('OrganizationUserManagement.registImpos2')} : {registImposCount} )
            </div>
          </div>
        );
      }
    } else {
      return <></>;
    }
  };

  useEffect(() => {
    setColumnDefs(UserInfoList_Table_Import_Column(t));
    setRowData(rowData);
  }, [t]);

  useEffect(() => {
    setRowCount(rowData.length);
    const { registPosCount, registImposCount } = calculateRegistStatus();
    setRegistPosCount(registPosCount);
    setRegistImposCount(registImposCount);
  }, [rowData]);

  useEffect(() => {
    if (!isOpen) {
      setRowData([]);
      setSelectedRowData([]);
    }
  }, [isOpen]);

  return (
    <Modal isOpen={isOpen} onRequestClose={closeBulkInsertDept_Table} style={customStyles} contentLabel="Example Modal">
      <div className="w-full grow flex flex-col overflow-hidden">
        <div className="mt-4 mb-2 w-92per mx-auto font-extrabold text-lg">
          {t('OrganizationUserManagement.deptImportXls')}
        </div>

        <LeftTopBarImport gridApi={gridApi} updateRowData={updateRowData} />
        <div className="mt-1 mb-2 w-92per mx-auto">
          <span className="ml-1 float-left text-sm font-semibold leading-7 text-neutral-500">
            {t('OrganizationUserManagement.all')}&nbsp;
          </span>
          <span id="registAll" className="float-left text-sm font-semibold leading-7 text-neutral-500">
            {rowCount}
          </span>
          <span className="float-left text-sm leading-7 text-neutral-500">
            &nbsp;{t('OrganizationUserManagement.registPos')}&nbsp;
          </span>
          <span id="registPos" className="float-left text-sm leading-7 text-neutral-500">
            {registPosCount}
          </span>
          <span className="float-left text-sm leading-7 text-neutral-500">
            &nbsp;{t('OrganizationUserManagement.registImpos')}&nbsp;
          </span>
          <span id="registImpos" className="float-left text-sm leading-7 text-rose-600">
            {registImposCount}
          </span>
          <span className="float-left text-sm leading-7 text-neutral-500 mr-1.5">{`)`}</span>

          <label htmlFor="chkImposRows">
            <input
              id="chkImposRows"
              className="float-left text-sm leading-7 text-neutral-500 mt-2 ml-2"
              type="checkbox"
              onChange={handleCheckboxChange}
            />
            <span className="text-sm ml-1 mt-1 leading-7 text-neutral-600">
              {t('OrganizationUserManagement.chkImposLabel')}
            </span>
          </label>

          <input
            className="float-right min-w-[3.25rem] h-7 rounded text-sm px-3 truncate bg-C-1f62b9 hover:bg-C-599aee active:bg-C-32418e disabled:bg-C-ebedf1 text-white disabled:text-C-b4b5b8 disabled:border disabled:border-C-b4b5b8 disabled:border-solid"
            type="button"
            value={t('OrganizationUserManagement.regist')}
            onClick={toggleApplyModal}
          />
          <input
            className="float-right mr-1 min-w-[3.25rem] h-7 rounded text-sm px-3 truncate bg-white hover:bg-C-f1f3f6 active:bg-C-ebedf1 text-C-555555 border border-C-555555 border-solid"
            type="button"
            value={t('OrganizationUserManagement.delete')}
            onClick={handleDeleteSelectedRows}
          />
          <input
            className="float-right mr-1 min-w-[3.25rem] h-7 rounded text-sm px-3 truncate bg-white hover:bg-C-f1f3f6 active:bg-C-ebedf1 text-C-555555 border border-C-555555 border-solid"
            type="button"
            value={t('OrganizationUserManagement.addDeptRow')}
            onClick={handleAddRow}
          />
        </div>

        <ConfirmModal
          label={modalContent()}
          isOpen={registConfirmModal}
          onConfirm={handleRegistServer}
          onClose={toggleDeleteModal}
        />

        <div className="ag-theme-alpine ag-theme-mycustomtheme grow w-92per h-480 mx-auto">
          <AgGridReact
            columnDefs={columnDefs}
            rowData={showUnregisterable ? filteredData : rowData}
            defaultColDef={defaultColDef}
            rowMultiSelectWithClick
            onCellEditingStopped={onCellEditingStopped}
            rowSelection={'multiple'}
            frameworkComponents={{ numericCellEditor: NumericCellEditor }}
            onSelectionChanged={onSelectionChanged}
            overlayNoRowsTemplate={'\xa0'}
          ></AgGridReact>
        </div>
      </div>
    </Modal>
  );
};

export default DeptBulkInsertReactModal;
