import { Field, Form, Formik } from 'formik';
import AddPersonButton from '../../common/buttons/AddPersonButton';
import Button from '../../common/buttons/Button';
import InputText from '../../common/inputs/InputText';

interface MyFormValues {
  fileSeperator: string;
  file: string;
}
// formik initial values
const initialValues: MyFormValues = { fileSeperator: '', file: '' };

const UpperFormContainer = () => {
  return (
    <div className="w-full overflow-hidden">
      <Formik
        initialValues={initialValues}
        onSubmit={(values, actions) => {
          // console.log({ values, actions });
          // alert(JSON.stringify(values, null, 2));
          actions.setSubmitting(false);
        }}
      >
        <Form>
          {/* 파일구분 */}
          <div className="flex py-defaultY">
            <label
              htmlFor="fileSeperator"
              className="min-w-[7.25rem] flex items-center flex-nowrap truncate"
            >
              파일 구분
            </label>
            <Field
              className="w-[19.5rem] py-defaultY px-3 border border-solid border-[#c8cace]"
              as="select"
              name="fileSeperator"
            >
              <option value="환경파일">환경파일</option>
              <option value="환경파일2">환경파일2</option>
              <option value="환경파일3">환경파일3</option>
            </Field>
          </div>
          {/* 파일첨부 */}
          <div className="py-defaultY flex flex-col">
            <div className="flex">
              <label
                className="min-w-[7.25rem] flex items-center"
                htmlFor="fileSeperator"
              >
                파일 첨부
              </label>
              <span className="truncate">
                환경파일 : xml, PC 업그레이드 파일 : ugr 확장자만 업로드 가능
              </span>
            </div>
            <div className="flex py-defaultY">
              <div className="min-w-[7.25rem]"></div>
              {/* <Field
                        id="file"
                        name="file"
                        placeholder="우측 아이콘으로 첨부파일 추가"
                        className="basis-full py-defaultY px-3 border border-solid border-[#c8cace]"
                      /> */}
              <InputText
                // handleChangeInput={() => {}}
                // handleClear={function noRefCheck() {}}
                // onClick={function noRefCheck() {}}
                placeholder="우측 아이콘으로 첨부파일 추가"
                // addClass="w-full"
                // value=""
              />
              <AddPersonButton />
            </div>
          </div>
          {/* 제출 버튼 */}
          <div className="py-defaultY w-full flex justify-center items-center"></div>
        </Form>
      </Formik>
    </div>
  );
};

export default UpperFormContainer;
