import {
  IadminUserListApiResponse,
  IUserDeptRemove,
  IUserFormData,
  multinameObj,
} from './../interfaces/api/I-api-admin-users';
import { hrsOrgsListApi } from '../api/hrs/api-hrs-orgs';
import { IAdminUserAddApiBody } from '../interfaces/api/I-api-admin-users';
import { IhrsOrgDeptFull } from '../interfaces/api/I-api-hrs-orgs';
import {
  addDept,
  addDepts,
  addUser,
  modifyDept,
  modifyUser,
  modifyUserV2,
  removeDept,
  removeUser,
} from '../redux/slice/organizationUserManagementSlice';
import { store } from '../redux/store';
import { getMyOrgCode, getMyRootDeptCode, getMySid, getMyTenantId } from './h-userInfo';
import { IhrsUserListApiResponseFull, IUserInfo } from '../interfaces/api/I-api-hrs-users';
import { transToSha256String } from '../utils/hashHandler';
import { pisUserProfileAdd } from '../api/pis/pis.api';
import { closeUserOrgMngReactModal_add_dept } from './h-react-modals';
import { ILanguageItem } from '../interfaces/api/I-api-language';
import { mapLanguagesForDept } from '../utils/languagesHandler';
const dispatch = store.dispatch;

interface RowDataItem {
  [key: string]: any;
  deptcode: string;
  deptname: string;
  parentdeptcode: string;
  deptorder: number;
}

// add
export const addDeptHandler = (dept: IhrsOrgDeptFull | null, languages: ILanguageItem[]) => {
  if (dept === null) return;
  const body = {
    orgcode: dept.orgcode,
    languagelist: mapLanguagesForDept(dept.deptname, languages),
    parentdeptcode: dept.deptcode,
    deptorder: dept.deptorder,
  };

  dispatch(addDept(body));
};

// add (부서 일괄 등록 [])
export const addDeptsHandler = async (depts: RowDataItem[] | null, languages: ILanguageItem[]) => {
  if (!depts || depts.length === 0) return;
  const orgcode = 'ORG01';
  //const orgcode = getOrgCode();
  const orglist = depts.map((dept) => ({
    languagelist: mapLanguagesForDept(dept.deptname, languages),
    parentdeptcode: dept.parentdeptcode,
    deptcode: dept.deptcode,
    deptorder: dept.deptorder,
  }));

  const body = {
    orgcode: orgcode,
    orglist: orglist,
  };
  const res = await dispatch(addDepts(body));
  return res.payload;
};

// remove
export const removeDeptHandler = (dept: IhrsOrgDeptFull | null) => {
  if (dept === null) return;

  const body = {
    orgcode: dept.orgcode,
    deptcode: dept.deptcode,
    gid: dept.gid,
    deptpath: dept.deptpath,
    deptlevel: dept.deptlevel,
  };
  dispatch(removeDept(body));
};
//나중에 랭귀지 수정해야함
//modify
export const modifyDeptHandler = (dept: IhrsOrgDeptFull | null, deptname: string) => {
  if (dept === null) return;
  const body = {
    orgcode: dept.orgcode,
    language: 'ko',
    deptcode: dept.deptcode,
    deptname,
    parentdeptcode: dept.parentdeptcode,
    deptorder: dept.deptorder,
  };

  dispatch(modifyDept(body));
};

// admin/user
// add/user
export const addUserHandler = (body: IUserFormData) => {
  const orgcode = getMyOrgCode();
  if (!orgcode) return;

  const user = userFormDataToUser(body);

  const BODY = {
    orgcode,
    userlist: [user],
  };

  dispatch(addUser(BODY));
  // 사용자 추가시 프로필도 추가되야 프로필 수정할수있음
  // 그런데 추가한 사용자의
  // .then((res) => {
  //   if (res.meta.requestStatus === 'fulfilled') {
  //     const tid = getMyTenantId();
  //     if (!tid) return;

  //     pisUserProfileAdd({
  //       cmd: '1203',
  //       sid: tid + '.' + user.userid + '.' + '123451234512345123451234512345',
  //       usage: '',
  //     });
  //   }
  // })
  // .catch((err) => {
  //   console.log(err);
  // });
};

// add (유저 일괄 등록 [])
export const addUsersHandler = async (body: IUserFormData[]) => {
  const orgcode = getMyOrgCode();
  if (!orgcode) return;
  const userlist = body.map((user) => userFormDataToUser(user));
  const BODY = {
    orgcode,
    userlist,
  };

  //dispatch(addUser(BODY));
  const res = await dispatch(addUser(BODY));
  return res.payload;
};

// remove
export const removeUserHandler = (body: { userlist: { bid: string }[] }) => {
  //const orgcode = getMyOrgCode();
  //if (!orgcode) return;
  //console.log(body);
  //const user = userFormDataToUser(body);
  // const BODY = {
  //   userlist: [user],
  // };
  dispatch(removeUser(body));
};

// modify
export const modifyUserHandler = (body: IUserFormData) => {
  const orgcode = getMyOrgCode();
  if (!orgcode) return;
  const user = userFormDataToUser(body);
  const BODY = {
    orgcode,
    userlist: [user],
  };
  dispatch(modifyUser(BODY));
};

// modify
export const modifyUserHandlerV2 = (body: IUserDeptRemove) => {
  const orgcode = getMyOrgCode();
  if (!orgcode) return;
  console.log(body);
  // const user = userFormDataToUserV2(body);
  const BODY = {
    cmd: '1710',
    orgcode,
    userlist: [body],
  };
  dispatch(modifyUserV2(BODY));
};

// DEPT

/**
 * 부서의 객체정보를 deptcode/deptname (key/value) 형식으로 return
 */
export const deptListToKeyValue = (deptList: IhrsOrgDeptFull[]) => {
  const keyValuesArr = deptList.map((dept) =>
    Object.entries(dept).filter(([key, val]) => key === 'deptcode' || key === 'deptname'),
  );

  const keyValObjArr = keyValuesArr.map((keyVal, i) => ({
    deptcode: keyVal[0][1],
    deptname: keyVal[1][1],
  }));

  return keyValObjArr;
};

export const getAllDeptList = async () => {
  const tenantid = getMyTenantId();
  const orgcode = getMyOrgCode();
  const rootDeptCode = getMyRootDeptCode();
  const sid = getMySid();

  if (!(rootDeptCode && orgcode && tenantid && sid)) return console.log('getDeptList error please check...');

  const rootDeptRes = await getDeptByDeptCode(rootDeptCode);
  if (!rootDeptRes) return;
  if (!rootDeptRes.data.data.result.dept) return;

  const rootDept = rootDeptRes.data.data.result.dept.data[0];
  if (!rootDept) return console.log('getDeptList error please check...');
  const rootDeptChildrenDeptRes = await hrsOrgsListApi({
    cmd: '1103',
    sid,
    include: 'DEPT',
    kind: 'CHILDREN',
    fields: 'FULL',
    extra: 'ALL',
    what: [
      {
        gid: `${tenantid}.${orgcode}.${rootDeptCode}`,
      },
    ],
  });
  if (!rootDeptChildrenDeptRes) return;
  if (!rootDeptChildrenDeptRes.data.data.result.dept) return;

  const rootDeptChildrenDept = rootDeptChildrenDeptRes.data.data.result.dept.data;

  const deptList = [rootDept, ...rootDeptChildrenDept];

  return deptList;
};

export const getDeptByDeptCode = async (deptCode: string) => {
  const {
    user: { usersid },
  } = store.getState();
  const tenantid = getMyTenantId();
  const orgcode = getMyOrgCode();

  if (orgcode === null || tenantid === null) return console.log('getDeptByDeptCode error please check...');

  return await hrsOrgsListApi({
    cmd: '1103',
    sid: usersid ? usersid : '',
    kind: 'DEPT',
    fields: 'FULL',

    include: 'DEPT',
    extra: 'ALL',
    what: [{ gid: `${tenantid}.${orgcode}.${deptCode}` }],
  });
};

// USER
/**
 * 사용자 정보를 api에 쓰일 양식에 맞게 바꿔줌
 */
const userFormDataToUser = (user: IUserFormData) => {
  return {
    languagelist: [
      {
        language: 'ko',
        name: user.usernameKo,
      },
      {
        language: 'en',
        name: user.usernameEn,
      },
    ],
    deptlist: user.deptlist,
    userid: user.userid,
    userpwd: user.userpwd.length < 1 ? undefined : transToSha256String(user.userpwd),
    position: user.position,
    duty: user.duty,
    grade: user.grade,
    deptcode: user.deptcode,
    deptname: user.deptname,
    empno: user.empno,
    telhome: user.telhome,
    teloffice: user.teloffice,
    telextension: user.telextension,
    telmobile: user.telmobile,
    email: user.email,
    picurl: user.picurl,
    zipno: user.zipno,
    address: user.address,
    birthtype: user.birthtype,
    birthday: user.birthday,
    gender: user.gender,
    duties: user.duties,
    officehour: user.officehour,
    workstatus: user.workstatus,
    compcode: user.compcode,
    compname: user.compname,
    userorder: user.userorder,
    userfield1: user.userfield1,
    userfield2: user.userfield2,
    userfield3: user.userfield3,
    userfield4: user.userfield4,
    userfield5: user.userfield5,
    updatetype: user.updatetype,
    userpwdhashtype: user.userpwdhashtype,
    isprojectadmin: user.isprojectadmin,
    isreservationoperator: user.isreservationoperator,
  };
};

/**
 * 테이블에서 사용한 사용자의 정보를 폼데이터 형식으로 변환
 */
export const selectedUserToForm = (selectedUser: IadminUserListApiResponse) => {
  const userFormFormat = {
    usernameKo: selectedUser.multiname.find((n) => n.language === 'ko')?.name || '',
    // usernameEn: selectedUser.username,
    // admin/user 리스트 불러올때 language 필드에 넘겨준 국적 이름만 넘겨줌
    // 해당 문제 해결 될떄까지 빈값으로 수정 요청하고 영문 이름 초기값 = test
    usernameEn: selectedUser.multiname.find((n) => n.language === 'en')?.name || '',
    userid: selectedUser.userid,
    userpwd: '',
    position: selectedUser.position,
    duty: selectedUser.duty,
    grade: selectedUser.grade,
    deptlist: selectedUser.deptlist,
    deptcode: '',
    deptname: '',
    empno: selectedUser.empno,
    telhome: selectedUser.telhome,
    teloffice: selectedUser.teloffice,
    telextension: selectedUser.telextension,
    telmobile: selectedUser.telmobile,
    email: selectedUser.email,
    picurl: selectedUser.picurl,
    zipno: selectedUser.zipno,
    address: selectedUser.address,
    birthtype: selectedUser.birthtype,
    birthday: selectedUser.birthday,
    gender: selectedUser.gender,
    duties: selectedUser.duties,
    officehour: selectedUser.officehour,
    workstatus: selectedUser.workstatus,
    compcode: selectedUser.compcode,
    compname: selectedUser.compname,
    userorder: selectedUser.userorder,
    userfield1: selectedUser.userfield1,
    userfield2: selectedUser.userfield2,
    userfield3: selectedUser.userfield3,
    userfield4: selectedUser.userfield4,
    userfield5: selectedUser.userfield5,
    updatetype: selectedUser.updatetype ?? '',
    userpwdhashtype: selectedUser.userpwdhashtype,
    isprojectadmin: selectedUser.isprojectadmin,
    isreservationoperator: selectedUser.isreservationoperator,
  };
  return userFormFormat;
};

export const dateToBirthDayString = (date: Date) => {
  const dateArr = date?.toISOString().split('-');
  const [year, month] = dateArr;
  const day =
    parseInt(dateArr[2].slice(0, 2)) + 1 > 9
      ? (parseInt(dateArr[2].slice(0, 2)) + 1).toString()
      : '0' + (parseInt(dateArr[2].slice(0, 2)) + 1);
  const birthDay = [year, month, day].join('');
  return birthDay;
};

const userFormDataToUserV2 = (user: IUserFormData) => {
  return {
    languagelist: [
      {
        language: 'ko',
        name: user.usernameKo,
      },
      {
        language: 'en',
        name: user.usernameEn,
      },
    ],
    userid: user.userid,
    userpwd: user.userpwd.length < 1 ? undefined : transToSha256String(user.userpwd),
    position: user.position,
    duty: user.duty,
    grade: user.grade,

    deptlist: [
      {
        deptcode: user.deptcode,
      },
    ],
    // deptcode: user.deptcode,
    // deptname: user.deptname,
    empno: user.empno,
    telhome: user.telhome,
    teloffice: user.teloffice,
    telextension: user.telextension,
    telmobile: user.telmobile,
    email: user.email,
    picurl: user.picurl,
    zipno: user.zipno,
    address: user.address,
    birthtype: user.birthtype,
    birthday: user.birthday,
    gender: user.gender,
    duties: user.duties,
    officehour: user.officehour,
    workstatus: user.workstatus,
    compcode: user.compcode,
    compname: user.compname,
    userorder: user.userorder,
    userfield1: user.userfield1,
    userfield2: user.userfield2,
    userfield3: user.userfield3,
    userfield4: user.userfield4,
    userfield5: user.userfield5,
    updatetype: user.updatetype,
    userpwdhashtype: user.userpwdhashtype,
    isprojectadmin: user.isprojectadmin,
  };
};
