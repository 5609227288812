import { useCallback, useEffect, useMemo, useState } from 'react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import { AgGridReact } from 'ag-grid-react';
import { CellClickedEvent, ColDef } from 'ag-grid-community';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { Func_BtnList_Table_Column } from './columns';
import { IAdminButtonFunc } from '../../../interfaces/api/button/I-api-admin-btn-func';
import { setSelectedFuncBtn } from '../../../redux/slice/funcButtonManagementSlice';

const FuncBtnList_Table = () => {
  const dispatch = useAppDispatch();
  const { funcBtnList } = useAppSelector((state) => state.funcButtonManagement);

  const [columnDefs, setColumnDefs] = useState<ColDef[]>(
    Func_BtnList_Table_Column,
  );
  const [rowData, setRowData] = useState<IAdminButtonFunc[] | null>(null);
  const defaultColDef = useMemo<ColDef>(() => {
    return {
      flex: 1,
      resizable: true,
      minWidth: 100,
    };
  }, []);

  const cellClickedListener = useCallback(
    (event: CellClickedEvent<IAdminButtonFunc>) => {
      // console.log('cellClicked', event);
      const buttonInfo = event.data;

      if (!buttonInfo) return;

      dispatch(setSelectedFuncBtn(buttonInfo));
    },
    [],
  );

  // 테이블 데이터 설정
  useEffect(() => {
    setRowData(funcBtnList);
  }, [funcBtnList]);

  return (
    <div className="w-full grow pb-2 flex flex-col overflow-hidden">
      <div className="ag-theme-alpine ag-theme-mycustomtheme w-full grow">
        <AgGridReact
          rowData={rowData}
          columnDefs={columnDefs}
          defaultColDef={defaultColDef}
          onCellClicked={cellClickedListener}
          // onFirstDataRendered={(params) => console.log(params.api)}
          rowSelection={'single'}
          overlayNoRowsTemplate={'\xa0'}
        ></AgGridReact>
      </div>
    </div>
  );
};

export default FuncBtnList_Table;
