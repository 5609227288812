import { useState } from 'react';
import { useResizeDetector } from 'react-resize-detector';
import _ from 'lodash';

// TOAST
import { useAppDispatch } from '../../redux/hooks';

const TestPage = () => {
  const dispatch = useAppDispatch();
  const { width, ref: RootDivRef } = useResizeDetector();
  const [showModal, setShowModal] = useState(false);

  // const notify = () => toast.error('에러!');
  // const notify = () => toast.success('성공');

  // const modalHandler = {
  //   label: '모달 제목입니다',
  //   show() {
  //     setShowModal(true);
  //   },
  //   confirm() {
  //     alert('확인!!!!!!!!!!!!!!');
  //     setShowModal(false);
  //   },
  //   close() {
  //     setShowModal(false);
  //   },
  // };

  // const apiTest = async () => {
  //   try {
  //     const res = await spUksGetsApi({
  //       namespace: 'ucworks-a2002',
  //       kind: 'pods',
  //     });

  //     if (!res) return;

  //     // console.log(res.data.data.result);
  //   } catch (err) {
  //     console.log(err);
  //   }
  // };

  // useEffect(() => {
  //   document.addEventListener('mousemove', _.throttle(logKey, 1000));
  //   return () => {
  //     document.removeEventListener('mousemove', logKey);
  //   };
  // }, []);

  // function logKey(e: MouseEvent) {
  //   console.log('client X: ', e.clientX);
  //   console.log('client Y: ', e.clientY);
  // }

  return (
    <div ref={RootDivRef} className="relative h-full w-full flex flex-col">
      {/* <button onClick={modalHandler.show}>모달 열기</button>
      <div>
        <button onClick={notify}>Notify!</button>
      </div>
      <div onClick={apiTest}>api테스트</div>
      <BasicReactModal
        isOpen={showModal}
        label={modalHandler.label}
        onClose={modalHandler.close}
        onConfirm={modalHandler.confirm}
      /> */}
    </div>
  );
};

export default TestPage;
