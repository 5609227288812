import React, { createContext, ReactNode, useContext, useEffect, useState } from 'react';
import { languageAddApi, languageInfoListApi } from '../../api/admin/api-admin-language';
import { useLocation } from 'react-router-dom';
import { ILanguageContextProps, ILanguageItem } from '../../interfaces/api/I-api-language';

const LanguageContext = createContext<ILanguageContextProps | undefined>(undefined);

/**
 * note 현재 테넌트가 사용하는 디폴트 언어 체크함
 */
const LanguageProvider = ({ children }: { children: ReactNode }) => {
  const location = useLocation();
  const [languages, setLanguages] = useState<ILanguageItem[]>([]);
  // languageAddApi([
  //   {
  //     langcode: 'en',
  //     langname: '영어',
  //     isdefault: true,
  //     sortorder: 0,
  //   },
  // ]);

  useEffect(() => {
    // if (location.pathname !== '/login' && !location.pathname.startsWith('/host')) {
    // const response = languageInfoListApi();

    // response.then((data) => {
    // if (!data.data.data.result?.length) {
    //만약 빈 값이 넘어오면 한국어 디폴트 값으로 추가
    setLanguages([
      {
        langcode: 'ko',
        langname: '한국어',
        isdefault: true,
        sortorder: 0,
      },
      {
        langcode: 'en',
        langname: '영어',
        isdefault: false,
        sortorder: 0,
      },
    ]);
    // } else {
    //   setLanguages(data.data.data.result);
    // }
    // });
    // }
  }, [location.pathname]);

  return <LanguageContext.Provider value={{ languages, setLanguages }}>{children}</LanguageContext.Provider>;
};

export const useLanguage = () => {
  const context = useContext(LanguageContext);
  if (!context) {
    throw new Error('useLanguage must be used within a LanguageProvider');
  }
  return context;
};

export default LanguageProvider;
