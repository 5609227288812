import Button from '../../common/buttons/Button';

const DownTopBar = () => {
  return (
    <div className="py-[0.563rem] gap-6 flex text-fontColor-default">
      <div className="flex gap-1">
        <Button kind="cancel" label="추가" />
        <Button kind="cancel" label="수정" />
        <Button kind="cancel" label="삭제" />
      </div>
      <div className="flex gap-1">
        <Button kind="cancel" label="전체 사용자 및 조직 적용" />
        <Button kind="cancel" label="동보발송 적용" />
        <Button kind="cancel" label="카운트 적용" />
      </div>
      <div className="flex gap-1">
        <Button kind="cancel" label="이벤트 상태 새로고침" />
        <Button kind="cancel" label="이벤트 상태 강제변경" disabled />
      </div>
    </div>
  );
};

export default DownTopBar;
