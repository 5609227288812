import { IpluralAxiosResponse } from './../../../interfaces/api/I-axios';
import {
  IadminRulefuncHub,
  IadminRuleFuncHubAddApiBody,
  IadminRuleFuncHubInfoApiBody,
  IadminRuleFuncHubListApiBody,
  IadminRuleFuncHubModifyApiBody,
  IadminRuleFuncHubRemoveApiBody,
} from '../../../interfaces/api/rule/I-api-admin-rule-func-hub';
import { axiosErrorHandler, axiosInstance } from '../../axiosInstance';

const adminRuleFuncInfo = {
  url: {
    list: '/api/v1/admin-mg-tp/rule/func/hub/list',
    add: '/api/v1/admin-mg-tp/rule/func/hub/add',
    info: '/api/v1/admin-mg-tp/rule/func/hub/info',
    modify: '/api/v1/admin-mg-tp/rule/func/hub/modify',
    remove: '/api/v1/admin-mg-tp/rule/func/hub/remove',
  },
};

// list
export const adminRuleFuncHubListApi = (
  props?: IadminRuleFuncHubListApiBody,
) => {
  const { url } = adminRuleFuncInfo;
  return axiosInstance.post<IpluralAxiosResponse<IadminRulefuncHub>>(url.list, {
    pagesize: 50,
    page: 1,
    ...props,
  });
};

// add
export const adminRuleFuncHubAddtApi = (props: IadminRuleFuncHubAddApiBody) => {
  const { url } = adminRuleFuncInfo;
  return axiosInstance.post(url.add, props);
};

// remove
export const adminRuleFuncHubRemovetApi = (
  props: IadminRuleFuncHubRemoveApiBody,
) => {
  const { url } = adminRuleFuncInfo;
  return axiosInstance.post(url.remove, props);
};

// modify
export const adminRuleFuncHubModifyApi = (
  props: IadminRuleFuncHubModifyApiBody,
) => {
  const { url } = adminRuleFuncInfo;
  return axiosInstance.post(url.modify, props);
};

// info
export const adminRuleFuncHubInfoApi = (
  props: IadminRuleFuncHubInfoApiBody,
) => {
  const { url } = adminRuleFuncInfo;
  return axiosInstance.post(url.info, props);
};
