// style
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import '../../../styles/ag-grid.css';

import { CellClickedEvent, ColDef } from 'ag-grid-community';
import { AgGridReact } from 'ag-grid-react';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { IAdminButtonGroup } from '../../../interfaces/api/button/I-api-admin-btn-group';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { Func_BtnGroupList_Table_Column } from './columns';
import { setSelectedFuncBtnGroupModal } from '../../../redux/slice/funcButtonManagementSlice';

const FuncBtnGroupList_Table = () => {
  const gridStyle = useMemo(() => ({ height: '100%', width: '100%' }), []);
  const dispatch = useAppDispatch();
  const { funcBtnGroupList } = useAppSelector(
    (state) => state.funcButtonManagement,
  );

  const [columnDefs, setColumnDefs] = useState<ColDef[]>(
    Func_BtnGroupList_Table_Column,
  );
  const [rowData, setRowData] = useState<IAdminButtonGroup[]>([]);
  const defaultColDef = useMemo<ColDef>(() => {
    return {
      flex: 1,
      resizable: true,
      minWidth: 100,
    };
  }, []);

  const cellClickedListener = useCallback(
    (event: CellClickedEvent<any>) => {
      // console.log('cellClicked', event);
      const buttonInfo: IAdminButtonGroup = event.data;
      dispatch(setSelectedFuncBtnGroupModal(buttonInfo));
    },
    [funcBtnGroupList],
  );

  // 테이블 데이터 설정
  useEffect(() => {
    if (funcBtnGroupList.length < 1) {
      setRowData([]);
      return;
    }
    setRowData(funcBtnGroupList);
  }, [funcBtnGroupList]);

  return (
    <div className="w-full grow flex flex-col overflow-hidden">
      <div style={gridStyle} className="ag-theme-alpine ag-theme-mycustomtheme">
        <AgGridReact
          rowData={rowData}
          columnDefs={columnDefs}
          defaultColDef={defaultColDef}
          onCellClicked={cellClickedListener}
          rowSelection={'single'}
          overlayNoRowsTemplate={'\xa0'}
        ></AgGridReact>
      </div>
    </div>
  );
};

export default FuncBtnGroupList_Table;
