import Button from '../../common/buttons/Button';

const DownTopBar = () => {
  return (
    <div className="py-[0.563rem] gap-1 flex text-fontColor-default">
      <Button kind="cancel" label="조직 추가" />
      <Button kind="cancel" label="조직 수정" />
      <Button kind="cancel" label="조직 삭제" />
    </div>
  );
};

export default DownTopBar;
