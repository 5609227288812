import React, { useState } from 'react';
import { SelectListValue } from '../../../interfaces/TypeSelectListbox';
import InputWithSearch from '../../common/InputWithSearch';
import SelectListbox from '../../common/selectList/SelectListbox';

const selectValues: SelectListValue[] = [
  { id: '1', name: '전체 사용자' },
  { id: '2', name: '유씨웨어2' },
  { id: '3', name: '유씨웨어3' },
];

const UpperTopBar = () => {
  const [selected, setSelected] = useState(selectValues[0]);
  const [userNameInputValue, setUserNameInputValue] = useState('');
  const onClickSearchUser = () => {
    // console.log('search!!');
  };

  return (
    <div className="flex gap-2 py-defaultY">
      <SelectListbox
        options={selectValues}
        value={selected}
        optionsHeight={12}
        width={'12.5rem'}
        onChange={(value) => {
          setSelected(value);
        }}
      />
      <InputWithSearch
        value={userNameInputValue}
        placeholder="검색..."
        onClickSearch={onClickSearchUser}
        handleChangeInput={(e) => setUserNameInputValue(e.target.value)}
        handleClear={() => setUserNameInputValue('')}
      />
    </div>
  );
};

export default UpperTopBar;
