import React from 'react';

const LeftArrow = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
    >
      <g data-name="btn_back_page">
        <path
          data-name="사각형 428"
          style={{ fill: 'none' }}
          d="M0 0h16v16H0z"
          transform="rotate(-90 8 8)"
        />
        <path
          data-name="패스 1079"
          d="m0 5 5-5 5 5"
          transform="rotate(-90 9 4)"
          style={{
            stroke: '#636465',
            strokeLinecap: 'round',
            strokeLinejoin: 'round',
            strokeWidth: '2px',
            fill: 'none',
          }}
        />
      </g>
    </svg>
  );
};

export default LeftArrow;
