import { useEffect, useState } from 'react';

// 라이브러리
import { SplitPane } from 'react-multi-split-pane';
import { useResizeDetector } from 'react-resize-detector';

// 컴포넌트
import DownTopBar from './DownTopBar';
import UpperLeftTopBar from './UpperLeftTopBar';
import UpperRightTopBar from './UpperRightTopBar';

import {
  handleViewPortSize,
  NestedPane,
  PaneProps,
  splitPaneViewportCase,
} from '../../../helpers/h-splitPane';
import {
  fetchAndSetRuleCodeList,
  fetchAndSetRuleFuncList,
} from '../../../helpers/h-ruleFuncMng';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import RuleFuncMngFunList_Table from '../../tables/ruleFuncMng/RuleFuncMngFunList_Table';
import RuleFuncMngFuncHubList_Table from '../../tables/ruleFuncMng/RuleFuncMngFuncHubList_Table';
import { initModals } from '../../../redux/slice/toggleSlice';
import {
  initRuleFuncHubOption,
  initRuleFuncMngSlice,
} from '../../../redux/slice/ruleFunctionManagementSlice';
import RuleFuncMngOption_Table from '../../tables/ruleFuncMng/RuleFuncMngOption_Table';
import observeStatus from '../../../hooks/observeStatus';
import MainPageTitleHeader from '../../common/MainPageTitleHeader';

const RuleFunctionManagement = () => {
  const dispatch = useAppDispatch();
  const {
    ruleCodeStatus,
    ruleFuncStatus,
    // status
    //    ruleCode
    // ruleCodeAddStatus,
    // ruleCodeModifyStatus,
    // ruleCodeRemoveStatus,
    //    ruleFunc
    ruleFuncAddStatus,
    ruleFuncModifyStatus,
    ruleFuncRemoveStatus,
    //    ruleFuncHub
    ruleFuncHubAddStatus,
    ruleFuncHubModifyStatus,
    ruleFuncHubRemoveStatus,
  } = useAppSelector((state) => state.ruleFunctionManagement);
  const [splitPaneViewportKey, setSplitPaneViewportKey] = useState(
    splitPaneViewportCase.default,
  );

  // 성공/실패 알람
  //    ruleCode
  // observeStatus(ruleCodeAddStatus, '규칙 추가 성공', '규칙 추가 실패');
  // observeStatus(ruleCodeModifyStatus, '규칙 수정 성공', '규칙 수정 실패');
  // observeStatus(ruleCodeRemoveStatus, '규칙 삭제 성공', '규칙 삭제 실패');
  //    ruleFunc
  observeStatus(ruleFuncAddStatus, '기능 추가 성공', '기능 추가 실패');
  observeStatus(ruleFuncModifyStatus, '기능 수정 성공', '기능 수정 실패');
  observeStatus(ruleFuncRemoveStatus, '기능 삭제 성공', '기능 삭제 실패');
  //    ruleFuncHub
  observeStatus(
    ruleFuncHubAddStatus,
    '규칙에 기능 추가 성공',
    '규칙에 기능 추가 실패',
  );
  observeStatus(ruleFuncHubModifyStatus, '수정 성공', '수정 실패');
  observeStatus(
    ruleFuncHubRemoveStatus,
    '규칙에 기능 삭제 성공',
    '규칙에 기능 삭제 실패',
  );

  // rule/code/list / rule/func/list 초기화
  useEffect(() => {
    fetchAndSetRuleCodeList();
    fetchAndSetRuleFuncList();
  }, []);

  // rule/code 추가/수정/삭제 성공시 rule/code/list 갱신
  useEffect(() => {
    if (ruleCodeStatus !== 'success') return;
    fetchAndSetRuleCodeList();
  }, [ruleCodeStatus]);

  // rule/func 추가/수정/삭제 성공시 rule/func/list 갱신
  useEffect(() => {
    if (ruleFuncStatus !== 'success') return;
    fetchAndSetRuleFuncList();
  }, [ruleFuncStatus]);

  // rule/func/hub 추가/수정/삭제 성공시

  // 클리어 함수
  useEffect(() => {
    return () => {
      dispatch(initModals());
      dispatch(initRuleFuncMngSlice());
    };
  }, []);

  return (
    <div className="h-full w-full">
      <SplitPane
        className="custom"
        split="horizontal"
        minSize={[100, 200]}
        defaultSizes={[348, 628]}
      >
        <Upper
          setSplitPaneViewportKey={setSplitPaneViewportKey}
          splitPaneUpdatedState={splitPaneViewportKey}
        />
        <Down
          setSplitPaneViewportKey={setSplitPaneViewportKey}
          splitPaneUpdatedState={splitPaneViewportKey}
        />
      </SplitPane>
    </div>
  );
};

const Upper = ({
  setSplitPaneViewportKey,
  splitPaneUpdatedState,
}: PaneProps) => {
  const splitPaneHandler = () =>
    handleViewPortSize([setSplitPaneViewportKey, 'clickedUpper']);
  return (
    <SplitPane
      className="custom"
      split="vertical"
      minSize={[100, 200]}
      // defaultSizes={[1,1]}
    >
      <UpperLeft
        onClick={splitPaneHandler}
        splitPaneUpdatedState={splitPaneUpdatedState}
      />
      <UpperRight
        onClick={splitPaneHandler}
        splitPaneUpdatedState={splitPaneUpdatedState}
      />
    </SplitPane>
  );
};

const UpperLeft = ({ onClick, splitPaneUpdatedState }: NestedPane) => {
  const { ref } = useResizeDetector();
  useEffect(() => {
    ref.current.parentElement.parentElement.style.flex =
      splitPaneUpdatedState.upper;
  }, [splitPaneUpdatedState]);

  return (
    <div
      ref={ref}
      className="w-full h-full flex flex-col px-[1.5rem] border border-solid border-[#dfe2e8] bg-white rounded"
    >
      <MainPageTitleHeader text="rule 추가 가능한 기능목록" onClick={onClick} />
      <UpperLeftTopBar />
      <RuleFuncMngFunList_Table />
    </div>
  );
};
const UpperRight = ({ onClick, splitPaneUpdatedState }: NestedPane) => {
  const { ref } = useResizeDetector();
  useEffect(() => {
    ref.current.parentElement.parentElement.style.flex =
      splitPaneUpdatedState.upper;
  }, [splitPaneUpdatedState]);

  return (
    <div
      ref={ref}
      className="w-full h-full flex flex-col px-[1.5rem] border border-solid border-[#dfe2e8] bg-white rounded"
    >
      <div className="w-full flex flex-col grow overflow-hidden">
        <UpperRightTopBar onClick={onClick} />
        <RuleFuncMngFuncHubList_Table />
      </div>
    </div>
  );
};

const Down = ({
  setSplitPaneViewportKey,
  splitPaneUpdatedState,
}: PaneProps) => {
  const { selectedRuleFuncHub } = useAppSelector(
    (state) => state.ruleFunctionManagement,
  );
  const dispatch = useAppDispatch();
  const { ref } = useResizeDetector();

  useEffect(() => {
    ref.current.parentElement.style.flex = splitPaneUpdatedState.down;
  }, [splitPaneUpdatedState]);

  useEffect(() => {
    if (selectedRuleFuncHub.funccode.length < 1) {
      dispatch(initRuleFuncHubOption());
      return;
    }
  }, [selectedRuleFuncHub]);

  return (
    <div
      ref={ref}
      className="w-full h-full flex flex-col px-[1.5rem] border border-solid border-[#dfe2e8] rounded bg-white"
    >
      <DownTopBar
        onClick={() =>
          handleViewPortSize([setSplitPaneViewportKey, 'clickedDown'])
        }
      />
      <RuleFuncMngOption_Table />
    </div>
  );
};

export default RuleFunctionManagement;
