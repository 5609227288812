import { useTranslation } from 'react-i18next';
import React, { useEffect, useMemo, useState } from 'react';
import { CellEditingStoppedEvent, ColDef } from 'ag-grid-community';
import { toast } from 'react-toastify';
import { IUserFormData } from '../../../interfaces/api/I-api-admin-users';
import { userFormDataInitial } from '../../../helpers/h-formInitial';
import { addUsersHandler } from '../../../helpers/h-orgUserMng';
import { closeBulkInsertUser_Table } from '../../../helpers/h-react-modals';
import { UserInfoList_Table_Import_User_Column } from '../../tables/orgUserMng/columns';
import Modal from 'react-modal';
import RightTopBarImport from './RightTopBarImport';
import ConfirmModal from '../../react-modal/ConfirmModal';
import { AgGridReact } from 'ag-grid-react';
import NumericCellEditor from '../../tables/orgUserMng/NumericCellEditor';
import "./EnrolmentOfUsersInBatches.css";
import { filterInvalidUserData } from '../../../utils/uniqueHandler';
import LeftArrow from '../../../assets/svg/arrows/LeftArrow';
import { toggleOrgAddBatchPage } from '../../../redux/slice/orgBatchAddPageSlice';
import { useDispatch } from 'react-redux';
import useDeptInfoList from '../../../hooks/useDeptInfoList';
import DeptCell from './DeptCell';
import { useAppSelector } from '../../../redux/hooks';
import { RootState } from '../../../redux/store';
import { useDeptContext } from '../../../providers/DeptProvider';
interface Props {
  isOpen: boolean;
}

interface UserRowDataItem {
  [key: string]: any;
  deptcode: string;
  userid: string;
  userpwd: string;
  usernameKo: string;
  usernameEn: string;
  position: string;
  duty:string,
  grade: string,
  telmobile: string,
  teloffice: string,
  telextension: string,
  email: string
}

const customStyles = {
  overlay: {
    zIndex: 99,
    //backgroundColor: 'transparent'
  },
  content: {
    width: '100%',
    height: '100%',
    padding: 0,
  },
};


const EnrolmentOfUsersInBatches = () => {

  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [columnDefs, setColumnDefs] = useState<ColDef[]>([]);
  const [gridApi, setGridApi] = useState<any>();

  const {deptMap, setDeptMap} = useDeptContext();
  const { data, isLoading, error } = useDeptInfoList();
  const defaultColDef = useMemo<ColDef>(() => {
    return {
      flex: 1,
      resizable: true,
      minWidth: 100,
      editable: true,
      suppressMovableColumns: true,
    };
  }, []);

  const [userRowData, setUserRowData] = useState<UserRowDataItem[]>([]);
  const [userRowCount, setUserRowCount] = useState<number>(0);
  const [userRegistPosCount, setUserRegistPosCount] = useState(0);
  const [userRegistImposCount, setUserRegistImposCount] = useState(0);
  const [userSelectedRowData, setUserSelectedRowData] = useState<
    UserRowDataItem[]
  >([]);
  const [userFilteredData, setUserFilteredData] = useState<UserRowDataItem[]>(
    [],
  );
  const [showUnregisterable, setShowUnregisterable] = useState(false);
  const [userRegistConfirmModal, setUserRegistConfirmModal] = useState(false);

  const updateUserRowData = (newData: any) => {
    setUserRowData(newData);
  };

  const handleRegistServer = async () => {
      const users: IUserFormData[] = [];
      userSelectedRowData.forEach((userData) => {
        const user = {
          ...userFormDataInitial(),
          isprojectadmin: 0,
          isreservationoperator: 0,
        };
        Object.keys(userData).forEach((key) => {
          if (userData[key] !== undefined && userData[key] !== null) {
            (user as any)[key] = String(userData[key]);
          }
        });

        if (
          userData.userpwd === undefined ||
          userData.userpwd === null ||
          userData.userpwd === ''
        ) {
          user.userpwd = '1234';
        }

        if (
          userData.usernameEn === undefined ||
          userData.usernameEn === null ||
          userData.usernameEn === ''
        ) {
          user.usernameEn = userData.usernameKo;
        }

        // const findDeptCode = deptMap.get(user.deptname.trim()) || "";
        //
        // user.deptcode = findDeptCode[0] ?  findDeptCode[0] : "";
        users.push(user);
      });

      // TODO 찾았다 서버로 여기서 요청보냄
      const response: any = await addUsersHandler(users);

      if (
        response &&
        response.code === 200 &&
        response.data &&
        response.data.result
      ) {
        setUserRegistConfirmModal(false);
        const successArray = response.data.result.success || [];
        const filteredUsers = userRowData.filter(
          (user: UserRowDataItem) => !successArray.includes(user.userid),
        );
        const failArray = response.data.result.fail || [];

        if (filteredUsers.length == 0) {
          toast.success(
            t('OrganizationUserManagement.toastUserAddSuccess') as string,
          );
          closeBulkInsertUser_Table();
        } else {
          const totalCnt = userRowData.length;
          const failCnt = filteredUsers.length;
          const successCnt = String(totalCnt - failCnt);

          const msg =
            t('OrganizationUserManagement.toastUserAddSuccess1') +
            successCnt +
            t('OrganizationUserManagement.toastUserAddSuccess2') +
            failArray.length +
            t('OrganizationUserManagement.toastUserAddSuccess3');
          toast.success(msg);
        }

        setUserRowData(filteredUsers);
      }
  };

  const handleAddRow = () => {
    if (userRowData.length >= 3000) {
      toast.error(t('OrganizationUserManagement.toastWarn6') as string);
      return;
    }

    const newItem = {
      deptcode: '',
      userid: '',
      userpwd: '',
      usernameKo: '',
      usernameEn: '',
      position: '',
      duty:"",
      grade: "",
      telmobile: "",
      teloffice: "",
      telextension: "",
      email: ""
    };
    const newUserRowData = [...userRowData, newItem];
    setUserRowData(newUserRowData);

    // 필터링 로직 추가
    if (showUnregisterable) {
      const newUserFilteredData = newUserRowData.filter((row) => {
        const isDeptCodeOrNameMissing = !row.userid || !row.usernameKo; // 유저아이디, 부서 누락
        const isDeptCodeDuplicated = newUserRowData.some(
          (r) => r !== row && r.userid === row.userid,
        ); // 유저아이디 중복
        return isDeptCodeOrNameMissing || isDeptCodeDuplicated;
      });
      setUserFilteredData(newUserFilteredData);
    }
  };

  //로우 삭제(유저/부서 분기)
  const handleDeleteSelectedRows = () => {
    const newRows = userRowData.filter(
      (row) => !userSelectedRowData.includes(row),
    );
    const newFilteredRows = userFilteredData.filter(
      (row) => !userSelectedRowData.includes(row),
    );
    setUserRowData(newRows);
    setUserFilteredData(newFilteredRows); // 필터링된 데이터 상태도 업데이트
  };

  const onSelectionChanged = (event: any) => {
    const selectedNodes = event.api.getSelectedNodes();

    const selectedData = selectedNodes.map((node: { data: any }) => node.data);
    setUserSelectedRowData(selectedData);
  };

  const handleCheckboxChange = () => {
    setUserSelectedRowData([]);

    const newShowUnregisterable = !showUnregisterable;
    setShowUnregisterable(newShowUnregisterable);

    if (newShowUnregisterable) {
      const newUserFilteredData = filterInvalidUserData(userRowData);
      setUserFilteredData(newUserFilteredData);
    } else {
      setUserFilteredData(userRowData); // 체크 해제 시 모든 데이터 표시
    }
  };

  //user cal
  const calculateUserRegistStatus = () => {
    let userRegistPosCount = 0;
    let userRegistImposCount = 0;

    userRowData.forEach((row) => {
      const isDeptCodeOrNameMissing = !row.userid || !row.usernameKo;
      const isDeptCodeDuplicated =
        userRowData.filter((r) => r.userid === row.userid).length > 1;

      if (isDeptCodeOrNameMissing || isDeptCodeDuplicated) {
        userRegistImposCount += 1; //불가능
      } else {
        userRegistPosCount += 1; // 가능
      }
    });
    return { userRegistPosCount, userRegistImposCount };
  };

  const onCellEditingStopped = (event: CellEditingStoppedEvent) => {
    const { userRegistPosCount, userRegistImposCount } =
      calculateUserRegistStatus();
    setUserRegistPosCount(userRegistPosCount);
    setUserRegistImposCount(userRegistImposCount);

    if (event.colDef?.field === 'userid') {
      const userid = event.data.userid;
      const duplicateIndices = userRowData
        .map((row, index) => (row.userid === userid ? index : -1))
        .filter((index) => index !== -1);
      const validPattern = /^[a-zA-Z][a-zA-Z0-9]{4,}$/;

      if (duplicateIndices.length > 1) {
        toast.warn(t('OrganizationUserManagement.toastWarn2') as string);

        const lastDuplicateIndex =
          duplicateIndices[duplicateIndices.length - 1];
        const updatedRows = userRowData.map((row, index) => {
          if (index === lastDuplicateIndex) {
            return { ...row, userid: '' };
          }
          return row;
        });
        setUserRowData(updatedRows);
      } else if (!validPattern.test(userid)) {
        const updatedRows = userRowData.map((row) =>
          row.userid === userid ? { ...row, userid: '' } : row,
        );
        setUserRowData(updatedRows);
        toast.warn(t('OrganizationUserManagement.toastWarn4') as string);
      }
    }
  };

  //모달
  const toggleApplyModal = () => {
    const isValid = userSelectedRowData.every((userData) => userData.userid && userData.usernameKo);
    if(!isValid){
      toast.warn(t("Enrolment.warning4"));
      return;
    }

    if(userSelectedRowData.length === 0 ) {
      toast.info(t("Enrolment.moreThanOne"))
      return;
    }
    setUserRegistConfirmModal((state) => !state);
  };

  const toggleDeleteModal = () => {
    setUserRegistConfirmModal((state) => !state);
  };

  const modalContent = () => {
    if (userRegistConfirmModal == true) {
      if (userRowData.length == 0) {
        toast.error(t('OrganizationUserManagement.toastWarn13') as string);
        setUserRegistConfirmModal(false);
        return <></>;
      } else if (userRegistPosCount == 0) {
        toast.error(t('OrganizationUserManagement.toastWarn14') as string);
        setUserRegistConfirmModal(false);
        return <></>;
      } else {
        return (
          <div>
            <div className={"flex items-center gap-1"}>
              <div>
                {userSelectedRowData.length}
              </div>
              <div>
                {t('OrganizationUserManagement.confirmContent')}
              </div>
            </div>
            <br />
            {/*<div className="text-center">*/}
            {/*  ( {t('OrganizationUserManagement.registPos2')} :{' '}*/}
            {/*  {userRegistPosCount},{' '}*/}
            {/*  {t('OrganizationUserManagement.registImpos2')} :{' '}*/}
            {/*  {userRegistImposCount} )*/}
            {/*</div>*/}
          </div>
        );
      }
    } else {
      return <></>;
    }
  };

  useEffect(() => {
    setColumnDefs(UserInfoList_Table_Import_User_Column(t));
    setUserRowData(userRowData);
  }, [t]);

  useEffect(() => {
    setUserRowCount(userRowData.length);
    const { userRegistPosCount, userRegistImposCount } =
      calculateUserRegistStatus();
    setUserRegistPosCount(userRegistPosCount);
    setUserRegistImposCount(userRegistImposCount);
  }, [userRowData]);

  return (
    <section className={"flex flex-col w-full h-full bg-white border-[1px] border-sold border-[#DFE2E8] rounded-[8px]"}>
      <div className="w-full px-5 flex flex-col h-full pt-5">
        <div className="flex flex-row gap-1 items-center text-[#808080] cursor-pointer"
          onClick={() => {
            dispatch(toggleOrgAddBatchPage());
          }}
        >
          <LeftArrow/>
          <span className="font-semibold">
            {t("Enrolment.prev")}
          </span>
        </div>

        <div className="mt-4 mb-2 w-full mx-auto font-extrabold text-lg">
          {t('OrganizationUserManagement.userImportXls')}
        </div>

        <RightTopBarImport
          gridApi={gridApi}
          updateUserRowData={updateUserRowData}
        />
        <div className="mt-1 mb-2 w-full mx-auto">
          <span className="ml-1 float-left text-sm font-semibold leading-7 text-neutral-500">
            {t('OrganizationUserManagement.all')}&nbsp;
          </span>
          <span
            id="registAll"
            className="float-left text-sm font-semibold leading-7 text-neutral-500"
          >
            {userRowCount}
          </span>
          <span className="float-left text-sm leading-7 text-neutral-500">
            &nbsp;{t('OrganizationUserManagement.registPos')}&nbsp;
          </span>
          <span
            id="registPos"
            className="float-left text-sm leading-7 text-neutral-500"
          >
            {userRegistPosCount}
          </span>
          <span className="float-left text-sm leading-7 text-neutral-500">
            &nbsp;{t('OrganizationUserManagement.registImpos')}&nbsp;
          </span>
          <span
            id="registImpos"
            className="float-left text-sm leading-7 text-rose-600"
          >
            {userRegistImposCount}
          </span>
          <span className="float-left text-sm leading-7 text-neutral-500 mr-1.5">{`)`}</span>

          <label htmlFor="chkImposRows">
            <input
              id="chkImposRows"
              className="float-left text-sm leading-7 text-neutral-500 mt-2 ml-2"
              type="checkbox"
              onChange={handleCheckboxChange}
            />
            <span className="text-sm ml-1 mt-1 leading-7 text-neutral-600">
              {t('OrganizationUserManagement.chkImposLabel')}
            </span>
          </label>

          <input
            className="float-right min-w-[3.25rem] h-7 rounded text-sm px-3 truncate bg-C-1f62b9 hover:bg-C-599aee active:bg-C-32418e disabled:bg-C-ebedf1 text-white disabled:text-C-b4b5b8 disabled:border disabled:border-C-b4b5b8 disabled:border-solid"
            type="button"
            value={t('OrganizationUserManagement.regist')}
            onClick={toggleApplyModal}
          />
          <input
            className="float-right mr-1 min-w-[3.25rem] h-7 rounded text-sm px-3 truncate bg-white hover:bg-C-f1f3f6 active:bg-C-ebedf1 text-C-555555 border border-C-555555 border-solid"
            type="button"
            value={t('OrganizationUserManagement.delete')}
            onClick={handleDeleteSelectedRows}
          />
          <input
            className="float-right mr-1 min-w-[3.25rem] h-7 rounded text-sm px-3 truncate bg-white hover:bg-C-f1f3f6 active:bg-C-ebedf1 text-C-555555 border border-C-555555 border-solid"
            type="button"
            value={t('OrganizationUserManagement.addUserRow')}
            onClick={handleAddRow}
          />
        </div>

        <ConfirmModal
          label={modalContent()}
          isOpen={userRegistConfirmModal}
          onConfirm={handleRegistServer}
          onClose={toggleDeleteModal}
        />

        <div
          style={{
            border:"none",
          }}
          className={`ag-theme-alpine ag-theme-mycustomtheme border-none flex-grow w-full min-h-1 mx-auto pb-5 custom-grid`}
        >
          <AgGridReact
            columnDefs={columnDefs}
            rowData={showUnregisterable ? userFilteredData : userRowData}
            defaultColDef={defaultColDef}
            rowMultiSelectWithClick
            onCellEditingStopped={onCellEditingStopped}
            rowSelection={'multiple'}
            frameworkComponents={{ numericCellEditor: NumericCellEditor, DeptCell: DeptCell}}
            onSelectionChanged={onSelectionChanged}
            overlayNoRowsTemplate={'\xa0'}
          ></AgGridReact>
        </div>
      </div>
    </section>
  );
}



export default EnrolmentOfUsersInBatches;
