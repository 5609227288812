import { IruleFuncHubOption } from './../interfaces/api/rule/I-api-admin-rule-func-hub';
import { toast } from 'react-toastify';
import { v4 as uuidv4 } from 'uuid';
import { adminRuleCodeListApi } from '../api/admin/rule/api-admin-rule-code';
import { adminRuleFuncListApi } from '../api/admin/rule/api-admin-rule-func';
import { adminRuleFuncHubListApi } from '../api/admin/rule/api-admin-rule-func-hub';
import {
  IadminRuleCode,
  IadminRuleCodeModifyApiBody,
} from '../interfaces/api/rule/I-api-admin-rule-code';
import {
  IadminRuleFunc,
  IadminRuleFuncAddApiBody,
  IadminRuleFuncModifyApiBody,
} from '../interfaces/api/rule/I-api-admin-rule-func';
import {
  addRuleCode,
  addRuleFunc,
  addRuleFuncHub,
  modifyRuleCode,
  modifyRuleFunc,
  modifyRuleFuncHub,
  removeRuleCode,
  removeRuleFunc,
  removeRuleFuncHub,
  setRuleCodeList,
  setRuleFuncList,
  setSelectedRuleCode,
} from '../redux/slice/ruleFunctionManagementSlice';
import { store as rootStore } from '../redux/store';
import axios from 'axios';
const dispatch = rootStore.dispatch;

// rule/code
// list
export const fetchAndSetRuleCodeList = async () => {
  try {
    const res = await adminRuleCodeListApi();
    const ruleCodeList = res?.data.data.result;
    if (!ruleCodeList) return;

    dispatch(setRuleCodeList(ruleCodeList));
    if (ruleCodeList.length < 1) return;
    dispatch(setSelectedRuleCode(ruleCodeList[0]));
  } catch (error) {
    if (axios.isAxiosError(error)) {
      console.log('error: ', error);
      return error;
    } else {
      console.log('unexpected error: ', error);
      return 'An unexpected error occurred';
    }
  }
};
// add
export const addRuleCodeHandler = (rulename: string, ruleorder: number) => {
  const rulecode = uuidv4().split('-').join('');
  dispatch(
    addRuleCode({
      rulecode,
      rulename,
      ruleorder,
    }),
  );
};
// remove
export const removeRuleCodeHandler = (rulecode: string) => {
  dispatch(removeRuleCode({ rulecode }));
};
// modify
export const modifyRuleCodeHandler = (
  ruleCode: IadminRuleCodeModifyApiBody,
) => {
  dispatch(modifyRuleCode(ruleCode));
};

// rule/func
// list
export const fetchAndSetRuleFuncList = async () => {
  try {
    const res = await adminRuleFuncListApi();
    if (!res) return;
    const ruleFuncList = res?.data.data.result;
    dispatch(setRuleFuncList(ruleFuncList));
  } catch (error) {
    if (axios.isAxiosError(error)) {
      console.log('error: ', error);
      return error;
    } else {
      console.log('unexpected error: ', error);
      return 'An unexpected error occurred';
    }
  }
};
// add
export const addRuleFuncHandler = (ruleFunc: IadminRuleFuncAddApiBody) => {
  dispatch(addRuleFunc(ruleFunc));
};
// remove
export const removeRuleFuncHandler = (funccode: string) => {
  dispatch(removeRuleFunc({ funccode }));
};
// modify
export const modifyRuleFuncHandler = (
  ruleFunc: IadminRuleFuncModifyApiBody,
) => {
  dispatch(modifyRuleFunc(ruleFunc));
};

// rule/func/hub
// list
export const fetchRuleFuncHubList = async (rulecode: string) => {
  try {
    const res = await adminRuleFuncHubListApi({ rulecode });
    const ruleFuncHubList = res?.data.data.result;
    return ruleFuncHubList;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      console.log('error: ', error);
    } else {
      console.log('unexpected error: ', error);
    }
  }
};
// add
export const addRuleFuncHubHandler = (
  selectedRuleFunc: IadminRuleFunc,
  selectedRuleCode: IadminRuleCode,
) => {
  if (selectedRuleFunc.funccode.length < 1) {
    toast.error('기능을 선택해주세요');
    return;
  }
  if (selectedRuleCode.rulecode.length < 1) {
    toast.error('규칙을 선택해주세요');
    return;
  }

  const rulefunckey = uuidv4().split('-').join('');
  const ruleFuncHubObj = {
    rulefunckey,
    rulecode: selectedRuleCode.rulecode,
    funccode: selectedRuleFunc.funccode,
    funcvalue1: ' ',
    funcvalue2: ' ',
    funcorder: 0,
  };

  dispatch(addRuleFuncHub(ruleFuncHubObj));
};
// remove
export const removeRuleFuncHubHandler = (rulefunckey: string) => {
  dispatch(removeRuleFuncHub({ rulefunckey }));
};
// modify
export const modifyRuleFuncHubHandler = (ruleFuncHub: IruleFuncHubOption) => {
  const { funckind, funcname, funcfield1, funcfield2, defaultvalue, ...BODY } =
    ruleFuncHub;

  dispatch(modifyRuleFuncHub(BODY));
};
