import { t } from 'i18next';
import React, { useEffect } from 'react';
import Button from '../../common/buttons/Button';
import { fedCancelHandler } from '../../../helpers/h-federationMng';
import {
  openFederation_accept_Modal,
  openFederation_reject_Modal,
  openFederation_cancel_Modal,
} from '../../../helpers/h-modals';
import { IserviceProviderFedCancelApiBody } from '../../../interfaces/api/service-provider/I-api-admin-serviceProvider-fed';
import { useAppSelector } from '../../../redux/hooks';

const DownTopBar = () => {
  const { selectedOtherFederation } = useAppSelector(
    (state) => state.federatinManagement,
  );
  return (
    <div className="py-[0.563rem] flex gap-2 text-fontColor-default">
      {/* <Button
        kind="cancel"
        label="삭제"
        onClick={openFederation_delete_Modal}
      /> */}
      {selectedOtherFederation &&
        (selectedOtherFederation.status === 1 ||
          selectedOtherFederation.status === 5) && (
          <Button
            kind="cancel"
            label={t('FederationManagement.cancelApplication')}
            onClick={openFederation_cancel_Modal}
          />
        )}
      {selectedOtherFederation &&
        (selectedOtherFederation.status === 2 ||
          selectedOtherFederation.status === 6) && (
          <Button
            kind="cancel"
            label={t('FederationManagement.accept')}
            onClick={openFederation_accept_Modal}
          />
        )}
      {selectedOtherFederation &&
        (selectedOtherFederation.status === 2 ||
          selectedOtherFederation.status === 6) && (
          <Button
            kind="cancel"
            label={t('FederationManagement.reject')}
            onClick={openFederation_reject_Modal}
          />
        )}
    </div>
  );
};

export default DownTopBar;
