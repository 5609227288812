import { PayloadAction, createSlice } from '@reduxjs/toolkit';

export const dragDropSlice = createSlice({
  name: 'dragDrop',
  initialState: {
    draggedItems: [] as Array<{ bid: string; deptcode: string; deptname: string; position: string; username: string; }>,
  },
  reducers: {
    setDraggedItem: (state, action: PayloadAction<Array<{ bid: string; deptcode: string; deptname: string; position: string; username: string; }>>) => {
      // 받은 배열을 상태에 할당
      state.draggedItems = action.payload;
      console.log(state.draggedItems);
    },
    clearDraggedItem: (state) => {
      state.draggedItems = [];
    },
  },
});

export const { setDraggedItem, clearDraggedItem } = dragDropSlice.actions;
export default dragDropSlice.reducer;