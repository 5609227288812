import React, { useState } from 'react';
import Button from '../../common/buttons/Button';
import InputDatePicker from '../../common/inputs/InputDatePicker';
import { SelectListValue } from '../../../interfaces/TypeSelectListbox';
import SelectListbox from '../../common/selectList/SelectListbox';

const selectValues: SelectListValue[] = [
  { id: '1', name: '10' },
  { id: '2', name: '60' },
  { id: '3', name: '180' },
];
const UpperTopBar = () => {
  const [selected, setSelected] = useState(selectValues[0]);

  const [pickedDate, setPickedDate] = useState<Date | null>(null);

  return (
    <div className="flex justify-between gap-8 py-defaultY">
      <div className="flex gap-2">
        <div className="flex items-center gap-2">
          <span>날짜</span>
          <div className="w-32">
            <InputDatePicker
              pickedDate={pickedDate}
              setPickedDate={(date) => setPickedDate(date)}
              placeholder="시작일"
            />
          </div>
        </div>
        <div className="flex items-center gap-2">
          <span>일별 통계</span>
          <div className="w-32">
            <InputDatePicker
              pickedDate={pickedDate}
              setPickedDate={(date) => setPickedDate(date)}
              placeholder="시작일"
            />
          </div>
        </div>
        <div className="flex items-center gap-2">
          <span>월별 통계</span>
          <div className="w-32 h-9">
            <SelectListbox
              options={selectValues}
              value={selected}
              optionsHeight={12}
              // width={'5rem'}
              onChange={(value) => {
                setSelected(value);
              }}
            />
          </div>
        </div>
        <div className="flex items-center gap-2">
          <span>서버별 접속 통계</span>
          <div className="w-32">
            <InputDatePicker
              pickedDate={pickedDate}
              setPickedDate={(date) => setPickedDate(date)}
              placeholder="시작일"
            />
          </div>
        </div>
      </div>
      <div>
        <Button kind="cancel" label="엑셀 다운로드" />
      </div>
    </div>
  );
};

export default UpperTopBar;
