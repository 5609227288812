import React from 'react';

const DownFormContainer = () => {
  return (
    <div className="w-full h-[calc(100%-7rem)] border border-solid rounded border-[#707070] overflow-auto">
      Lorem ipsum dolor sit amet consectetur adipisicing elit. Explicabo,
      repellat deserunt saepe unde maxime beatae illum voluptatibus! Molestias
      facilis, itaque velit reiciendis possimus suscipit quod dolor praesentium!
      Veritatis, deserunt maxime fugit delectus autem deleniti hic facere, amet
      quidem laboriosam iste earum iure repellat officia consectetur rem libero
      quos doloribus? Quas ipsa accusantium cum expedita hic quos dolorem
      praesentium eligendi ex quo id iure consequuntur iste laborum officiis
      deserunt vero voluptatem, voluptates impedit non nobis aliquid molestiae
      dolores odit! Minima, magnam! Officia sit dolorum reiciendis distinctio
      vero in eligendi, ea molestias eaque, magnam sed corrupti animi numquam
      quos asperiores illo id quas, accusantium aperiam natus nobis enim
      officiis. Praesentium rem possimus molestiae perspiciatis odit fugiat
      explicabo veritatis sequi delectus error temporibus, omnis cupiditate
      doloribus debitis amet quisquam, rerum quasi illum hic placeat impedit
      aperiam quo! Ab nostrum tempore corrupti sunt, molestias inventore, error
      dignissimos excepturi dicta fugit dolores odio perspiciatis velit nemo est
      distinctio quisquam? Non recusandae et consectetur, qui unde accusantium
      fugiat doloribus deleniti, libero aliquid harum blanditiis quod? Nobis
      molestias tempore nisi dolores neque quam porro quisquam odio, deleniti
      suscipit quia harum hic tempora doloribus rem dolorum voluptatem veritatis
      voluptatum id facere! Sunt corrupti reprehenderit provident obcaecati
      beatae sed esse voluptatum? Voluptas vitae, in unde aliquam neque facere,
      optio eligendi odit natus corporis ex tenetur impedit blanditiis.
      Asperiores aspernatur aliquid sint. Omnis tempore consequatur quis quaerat
      culpa iste ipsa accusamus similique quam beatae nesciunt, soluta magnam
      quod iure hic fuga ipsam optio accusantium laborum quae cum dolorum
      praesentium, atque dolorem. Corporis fuga id illum. Quisquam id
      repellendus exercitationem omnis, provident fuga possimus quod molestias,
      laudantium molestiae laborum, quae quam neque obcaecati necessitatibus ut
      incidunt pariatur at. Minus sed nihil, eveniet quis accusamus et quisquam
      saepe! Ipsum ex ipsa dolores ipsam? Eum, veniam laborum exercitationem nam
      quia tempore pariatur quibusdam corporis aliquam libero porro enim sunt,
      possimus earum nesciunt impedit consectetur. Voluptatem rerum optio quas
      ullam perspiciatis itaque odio culpa dicta laudantium, neque commodi
      blanditiis odit sed. Animi, nam! Ea recusandae fugiat quia quas non
      expedita accusantium quaerat amet error? Rem, quas. Aut, accusamus itaque
      perspiciatis natus recusandae tempore voluptates odit delectus tempora
      temporibus, rem, culpa corrupti voluptatum ipsam? Maiores deserunt quas,
      corporis incidunt repudiandae placeat ducimus doloribus aliquam vel
      debitis fugit enim accusamus eius minus voluptates natus voluptatibus, est
      rem. At quas quaerat incidunt dolor repudiandae, culpa fugit ea dolorum
      fugiat expedita saepe obcaecati? Exercitationem repudiandae hic fugiat
      quae natus, consequatur ratione, doloribus architecto aut veniam nobis,
      vero velit placeat unde voluptas pariatur minima voluptatibus atque porro
      laudantium labore illum? Exercitationem iusto distinctio expedita error
      odio debitis nihil fuga, sed, rem reprehenderit aliquam laudantium odit
      eveniet consequuntur quod! Debitis voluptates itaque, sapiente quas illo
      sit, harum qui, magnam et eum aliquid reiciendis ullam facere! Repellat
      illo, pariatur ex aspernatur itaque alias hic perspiciatis praesentium
      eius facere maxime odio labore consequatur provident similique debitis
      animi, fugiat quaerat nostrum quod ratione quia laborum nesciunt earum?
      Eos sit recusandae nisi necessitatibus, non numquam? Eum alias ab cumque
      ad amet quidem pariatur nemo nostrum vitae facere mollitia quisquam,
      aliquid sed beatae porro consectetur. Iste doloribus quisquam ex corporis
      maiores quasi odio cum dolore porro asperiores eius sunt nihil,
      accusantium minus ducimus neque sapiente ipsa dicta numquam error, saepe,
      libero cupiditate doloremque? Incidunt corporis amet libero excepturi rem
      consectetur nihil perferendis dolor, nobis in inventore illum!
      Perspiciatis, voluptatum et. Dolor dolore, pariatur fugiat explicabo
      doloribus placeat vitae eum voluptatum illo officiis, eaque repellat hic
      quisquam quidem, assumenda atque? Quae eveniet suscipit beatae deserunt
      accusantium explicabo eaque velit. Alias obcaecati consequuntur fuga?
      Veniam fuga quibusdam quaerat optio natus commodi sint voluptate quis
      dolor. Placeat perferendis dicta iusto sunt tempore magnam deleniti
      consequatur, quisquam unde cupiditate est itaque! Non, facere illo? Atque
      labore voluptatum quisquam nesciunt deleniti nemo nostrum, cumque incidunt
      consequatur? Porro ipsam facere accusamus eius vel, voluptas et
      consectetur. Vel, sint! Quaerat ullam, voluptatem cum ipsa aliquid porro
      vitae sunt fuga officia obcaecati iste, inventore perspiciatis iusto totam
      molestias aspernatur quam ipsam, nobis fugiat! Quae possimus maxime
      assumenda tenetur minima non provident, impedit dignissimos a error dolor
      nostrum dicta corrupti exercitationem ipsa tempore mollitia excepturi id
      autem obcaecati cupiditate tempora. Rem suscipit pariatur itaque
      cupiditate deleniti voluptatum aspernatur, eveniet quia fuga libero velit,
      autem architecto molestiae corrupti! Officiis aperiam dolor animi nihil
      autem suscipit rem natus voluptas excepturi cupiditate libero et nostrum
      consectetur est, laborum quos nisi tempore corrupti modi quis explicabo
      optio corporis assumenda asperiores. Ea commodi iusto cum cupiditate,
      obcaecati ad recusandae tempore quibusdam, rerum quis illum quidem
      repudiandae maxime laboriosam eos voluptatibus dolores, atque adipisci
      officia. Sint magni perspiciatis voluptatem in fugit eligendi temporibus
      asperiores ad, quod dolor ipsa neque harum repellat eos ullam minima.
      Atque necessitatibus voluptas vitae illum voluptatem fugit mollitia natus
      culpa impedit omnis eveniet nemo officiis ipsum magni ipsa nesciunt
      voluptatum laborum aut ex, perferendis dignissimos? Officia ea, beatae
      libero soluta voluptas error laborum tempora accusamus tenetur molestiae
      architecto quasi autem, harum similique voluptate illum unde quia,
      aspernatur id nam? Molestias sapiente deleniti pariatur, maiores ab quia
      tenetur nostrum dicta veritatis nesciunt quasi modi voluptatibus, eius
      accusamus porro at, sint repellendus. Temporibus veniam doloremque nulla
      maxime. Odio quo repellat alias excepturi tenetur nemo cumque blanditiis
      commodi consequuntur, culpa cum soluta debitis quis vitae, sequi omnis
      eveniet corrupti? Quasi repellat, omnis vero, ipsa sapiente culpa sed nemo
      ad molestiae earum debitis neque tenetur dolore magnam maxime eos dicta
      illum aut iure facere eligendi, accusantium commodi necessitatibus atque.
      Non nesciunt earum explicabo recusandae itaque nihil quibusdam
      reprehenderit exercitationem id dignissimos voluptas adipisci ratione
      facere eos beatae nisi, repellendus, voluptates tenetur, aliquid maiores?
      Ut laudantium illum architecto? Corporis velit doloremque minus veritatis
      molestiae? Ducimus est esse sunt ipsum, id quisquam ex culpa amet dicta
      reprehenderit doloribus numquam? Ipsam praesentium nisi libero architecto
      reiciendis fugit quo autem, porro alias, quisquam id, dicta tempora
      voluptatem dolore consectetur facere? Asperiores obcaecati pariatur
      similique maxime culpa voluptas ipsum quae omnis, temporibus laudantium
      voluptatum exercitationem in distinctio veritatis tenetur autem ex
      delectus, natus aliquam, maiores earum! Sint blanditiis dolore sunt nisi
      dolor, quae aperiam accusantium totam, cum molestiae maxime odit at
      pariatur. Consequatur iure quis mollitia aut ea facere perferendis cum
      quam distinctio voluptas. Aut aperiam nesciunt vel facere esse repellat
      fugit dolorem distinctio hic, facilis porro id, illo a nobis dolores animi
      tenetur sequi culpa quod tempora consequuntur eaque ullam? Blanditiis
      alias obcaecati quaerat, provident eveniet explicabo labore eius atque rem
      officiis nam voluptates libero voluptatibus sit hic ratione sint
      voluptatem possimus ad! Repellendus labore, quas minus sapiente quidem
      corrupti provident, dolorem nostrum aspernatur, suscipit saepe quasi
      assumenda dolore? Consectetur autem quibusdam modi neque quia ut,
      obcaecati suscipit at quidem odit quas vitae doloremque distinctio sunt
      non voluptatibus. Aliquid atque fuga suscipit reiciendis. Ab deleniti at
      inventore doloribus, officiis iusto maxime suscipit quas deserunt
      perspiciatis consectetur ex consequatur quidem autem! Accusantium fugit
      blanditiis, praesentium maxime dolorem natus animi enim quo, corrupti
      incidunt nihil. Aut explicabo, praesentium quos, aliquam animi hic nisi,
      dignissimos unde distinctio aspernatur laudantium modi voluptas facere
      voluptatibus magni repellendus. Adipisci exercitationem sequi odit alias
      fugiat vitae consequuntur doloremque error incidunt. Optio, impedit
      perferendis officiis ad quis neque fugit minus deserunt recusandae aliquid
      ab voluptatem a saepe nobis. Voluptatem excepturi molestias est quaerat
      quidem asperiores, illo perferendis! Saepe nobis dolor quas assumenda!
    </div>
  );
};

export default DownFormContainer;
