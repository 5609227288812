import Arrow from '../../../assets/svg/arrows/Arrow';
import Button from '../../common/buttons/Button';

interface Props {
  onClick: () => void;
}

const DownTopBar = ({ onClick }: Props) => {
  return (
    <div className="flex justify-between w-full py-[0.688rem]">
      <div className="flex gap-1">
        <Button label="신규 공지" kind="cancel" />
        <Button label="공지 수정" kind="cancel" />
        <Button label="공지 삭제" kind="cancel" />
      </div>
      <div>
        <Arrow direction="down" onClick={onClick} />
      </div>
    </div>
  );
};

export default DownTopBar;
