import { ButtonProps } from '../../../interfaces/ButtonProps';

const SearchIcon = ({ width = 18, height = 18 }: ButtonProps) => {
  return (
    <svg
      className="fill-C-555555"
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 18 18"
    >
      <path transform="translate(0 .002)" fill="none" d="M0 0h18v18H0z" />
      <path
        d="m101.415 330.344-3.458-3.526a7.957 7.957 0 1 0-1.4 1.425l3.434 3.5a1 1 0 0 0 1.428-1.4zM85.7 322a5.95 5.95 0 1 1 5.95 5.952A5.959 5.959 0 0 1 85.7 322z"
        transform="translate(-83.699 -314.043)"
      />
    </svg>
  );
};

export default SearchIcon;
