import { ColDef } from 'ag-grid-community';
import { IAdminButtonFunc } from '../../../interfaces/api/button/I-api-admin-btn-func';
import { IAdminButtonGroup } from '../../../interfaces/api/button/I-api-admin-btn-group';
import { AgCellTextCenter } from '../../common/AgCustomCell';
import { AgHeaderTextCenter } from '../../common/AgCustomHeader';

export const Func_BtnList_Table_Column: ColDef<IAdminButtonFunc>[] = [
  {
    field: 'btnimgsrc',
    headerName: '버튼 이미지',
    headerComponent: AgHeaderTextCenter,
    cellRenderer: AgCellTextCenter<IAdminButtonFunc>,
  },
  {
    field: 'btnname',
    headerName: '버튼 이름',
    headerComponent: AgHeaderTextCenter,
    cellRenderer: AgCellTextCenter<IAdminButtonFunc>,
  },
  {
    field: 'btncomment',
    headerName: '버튼 설명',
    headerComponent: AgHeaderTextCenter,
    cellRenderer: AgCellTextCenter<IAdminButtonFunc>,
  },
];

export const Func_BtnGroupHubList_Table_Column: ColDef<IAdminButtonFunc>[] = [
  {
    field: 'btnimgsrc',
    headerName: '버튼 이미지',
    headerComponent: AgHeaderTextCenter,
    cellRenderer: AgCellTextCenter<IAdminButtonFunc>,
  },
  {
    field: 'btntype',
    headerName: '버튼 종류',
    headerComponent: AgHeaderTextCenter,
    cellRenderer: AgCellTextCenter<IAdminButtonFunc>,
  },
  {
    field: 'btnname',
    headerName: '버튼 이름',
    headerComponent: AgHeaderTextCenter,
    cellRenderer: AgCellTextCenter<IAdminButtonFunc>,
  },
  {
    field: 'btncomment',
    headerName: '버튼 설명',
    headerComponent: AgHeaderTextCenter,
    cellRenderer: AgCellTextCenter<IAdminButtonFunc>,
  },
  // {
  //   field: 'groupver',
  //   headerName: '버튼 버전',
  // },
];

export const Func_BtnGroupList_Table_Column: ColDef<IAdminButtonGroup>[] = [
  {
    field: 'groupname',
    headerName: '버튼 그룹 이름',
    headerComponent: AgHeaderTextCenter,
    cellRenderer: AgCellTextCenter<IAdminButtonGroup>,
  },
  {
    field: 'groupver',
    headerName: '버튼 그룹 버전',
    headerComponent: AgHeaderTextCenter,
    cellRenderer: AgCellTextCenter<IAdminButtonGroup>,
  },
  {
    field: 'grouporder',
    headerName: '버튼 그룹 순서',
    headerComponent: AgHeaderTextCenter,
    cellRenderer: AgCellTextCenter<IAdminButtonGroup>,
  },
];
