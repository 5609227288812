import React, { forwardRef, useImperativeHandle, useState } from 'react';
import { IworkspaceRowData_col } from '../../../../interfaces/I-ag-column';

export default forwardRef((props: any, ref: any) => {
  const [data, setData] = useState<IworkspaceRowData_col>(
    props.api.getDisplayedRowAtIndex(props.rowIndex).data,
  );

  // console.log({ props, data });

  useImperativeHandle(ref, () => {
    return {
      getReactContainerClasses() {
        return ['custom-tooltip'];
      },
    };
  });

  return (
    <div
      className="custom-tooltip flex flex-col gap-1 rounded py-defaultY border border-slate-300 border-solid px-1"
      style={{ backgroundColor: props.color || 'white' }}
    >
      {/* "m3BjDEPn8s0rwRu307ku8YhM", # 포탈 워크스페이스 rest_code "tenantid":
      "demouc", # 포탈 워크스페이스 team_code / 테넌트 id "name": "demouc", #
      포탈 워크스페이스 이름 / 테넌트 명 "usefederation": "Y", # 페더레이션 사용
      구분 "updatetime": "20221011031902981", # 테넌트 추가 된 시간
      "worksapceidx": "475", # 워크스페이스 idx "workspacerestcode":
      "m3BjDEPn8s0rwRu307ku8YhM", # 워크 스페이스 rest_code "tenantdesc":
      "아이모션 데모", # 테넌트 상세 정보 "planname": "Free", # 테넌트 plan 결제
      정보 "plantype": "L", # 테넌트 plan 정보1 "planservertype": "UCM", #
      테넌트 plan 정보2 "planusercount": 10, # 테넌트 plan 사용자 제한
      "totalprice": "0", # 총 결제 가격 "pricetype": "C", # ??? 결제 구분인데
      무슨 의미인지 모름 "pricecycle": 1, # 결제 간격 단위 : 월 "portaluserid":
      "ucware", # 워크스페이스 생성자 ucworks.io 계정 정보1 "portalusername":
      "ucware", # 워크스페이스 생성자 ucworks.io 계정 정보2 "portaluseremail":
      "audwnsp@ucware.net", # 워크스페이스 생성자 ucworks.io 계정 정보3
      "portaluserphone": "010-7232-0639", # 워크스페이스 생성자 ucworks.io 계정
      정보4 "portaluserphoneoffice": "", # 워크스페이스 생성자 ucworks.io 계정
      정보5 "portaluserfaxoffice": "" # 워크스페이스 생성자 ucworks.io 계정
      정보6 */}
      <div>
        페더레이션 사용 구분 : {data.useFederation ? '사용' : '사용안함'}
      </div>
      <div>워크스페이스_rest_code : {data.workspaceRestcode}</div>
      <div>총 결제 가격 : {data.totalPrice}</div>
      <div>관리자 이메일 : {data.portalUserEmail}</div>
      <div>관리자 휴대폰 번호 : {data.portalUserPhone}</div>
    </div>
  );
});
