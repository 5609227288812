import { createSlice } from '@reduxjs/toolkit';
import { IaccessMenu } from '../../interfaces/api/I-api-admin-access';
import { IauthResponse, IauthUser } from '../../interfaces/api/I-api-auth';

interface InitialState {
  strid: {
    profile: string;
    user: string;
  } | null;
  userInfo: IauthUser | null;
  userexp: number | null;
  userjwt: string | null;
  usersid: string | null;
  tenantid: string | null;
  type: string | null;
  menugid: string | null;
  userRoleStatus: number | null;
  menuList: IaccessMenu[];
}

const initialState: InitialState = {
  strid: null,
  userInfo: null,
  userexp: null,
  userjwt: null,
  usersid: null,
  tenantid: null,
  type: null,
  menugid: null,
  userRoleStatus: null,
  menuList: [],
};

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    initUserSlice: () => initialState,
    setUserSlice: (state, { payload: user }: IauthResponse) => {
      state.strid = user.strid;
      state.userInfo = user.userInfo;
      state.userexp = user.userexp;
      state.userjwt = user.userjwt;
      state.usersid = user.usersid;
    },
    setTenantId: (state, { payload: tenantid }: { payload: string | null }) => {
      state.tenantid = tenantid;
    },
    setType: (state, { payload: type }: { payload: string | null }) => {
      state.type = type;
    },
    setMenuGid: (state, { payload: menugid }: { payload: string }) => {
      state.menugid = menugid;
    },
    setUserRolestatus: (
      state,
      { payload: userRoleStatus }: { payload: number },
    ) => {
      state.userRoleStatus = userRoleStatus;
    },
    setMenuList: (state, { payload: menuList }: { payload: IaccessMenu[] }) => {
      state.menuList = menuList;
    },
  },
});

export const {
  initUserSlice,
  setUserSlice,
  setTenantId,
  setType,
  setMenuGid,
  setUserRolestatus,
  setMenuList,
} = userSlice.actions;

export default userSlice.reducer;
