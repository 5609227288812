import {
  IautoScaleApiResponse,
  IautoScaleApplysApiBody,
  IautoScaleDeleteApiBody,
  IautoScaleListApiBody,
} from '../../../interfaces/api/service-provider/I-api-autoscale';
import { SP_SERVER } from '../../../const/server';
import { spAxiosInstance } from '../../axiosInstance';

const adminAutoScaleInfo = {
  url: {
    list: `${SP_SERVER}/api/v1/admin-mg-sp/uks/autoscale/list`,
    applys: `${SP_SERVER}/api/v1/admin-mg-sp/uks/autoscale/applys`,
    delete: `${SP_SERVER}/api/v1/admin-mg-sp/uks/autoscale/deletes`,
  },
};

// list
export const spAutoScaleListApi = (props: IautoScaleListApiBody) => {
  const { url } = adminAutoScaleInfo;
  return spAxiosInstance.post<IautoScaleApiResponse>(url.list, props);
};

// applys
export const spAutoScaleApplyApi = (props: IautoScaleApplysApiBody) => {
  const { url } = adminAutoScaleInfo;
  return spAxiosInstance.post(url.applys, props);
};

// deletes
export const spAutoScaleDeleteApi = (props: IautoScaleDeleteApiBody) => {
  const { url } = adminAutoScaleInfo;
  return spAxiosInstance.post(url.delete, props);
};
