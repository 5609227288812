import { useEffect, useState } from 'react';
// 라이브러리
import { SplitPane } from 'react-multi-split-pane';
import { useResizeDetector } from 'react-resize-detector';

// 컴포넌트

import {
  handleViewPortSize,
  PaneProps,
  splitPaneViewportCase,
} from '../../../helpers/h-splitPane';
import RulDesignationRuleUserList_Table from '../../tables/rule-deisgnation/RulDesignationRuleUserList_Table';
import RulDesignationUserList_Table from '../../tables/rule-deisgnation/RulDesignationUserList_Table';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import observeStatus from '../../../hooks/observeStatus';
import { fetchAndSetRuleCodeList } from '../../../helpers/h-ruleFuncMng';
import { initRuleFuncMngSlice } from '../../../redux/slice/ruleFunctionManagementSlice';
import { closeAllRuleFuncModal } from '../../../helpers/h-modals';
import MainPageTitleHeader from '../../common/MainPageTitleHeader';

// 조직/사용자 관리
const RuleDeignation = () => {
  const dispatch = useAppDispatch();
  const {
    ruleCodeStatus,
    // ruleCodeAddStatus,
    // ruleCodeModifyStatus,
    // ruleCodeRemoveStatus,
    ruleUserAddStatus,
    ruleUserRemoveStatus,
  } = useAppSelector((state) => state.ruleFunctionManagement);
  const [splitPaneViewportKey, setSplitPaneViewportKey] = useState(
    splitPaneViewportCase.default,
  );

  // 성공/실패 알람
  //    ruleCode
  // observeStatus(ruleCodeAddStatus, '규칙 추가 성공', '규칙 추가 실패');
  // observeStatus(ruleCodeModifyStatus, '규칙 수정 성공', '규칙 수정 실패');
  // observeStatus(ruleCodeRemoveStatus, '규칙 삭제 성공', '규칙 삭제 실패');
  //    rule/user
  observeStatus(ruleUserAddStatus, '사용자 추가 성공', '사용자 추가 실패');
  observeStatus(ruleUserRemoveStatus, '사용자 삭제 성공', '사용자 삭제 실패');

  // rule/code/list
  useEffect(() => {
    if (ruleCodeStatus !== 'success') return;
    fetchAndSetRuleCodeList();
  }, [ruleCodeStatus]);

  useEffect(() => {
    fetchAndSetRuleCodeList();
  }, []);

  // 초기화
  useEffect(() => {
    return () => {
      closeAllRuleFuncModal();
      dispatch(initRuleFuncMngSlice());
    };
  }, []);

  return (
    <div className="h-full w-full">
      <SplitPane
        className="custom"
        split="vertical"
        defaultSizes={[1, 1]}
        minSize={[100, 200]}
      >
        <Left
          setSplitPaneViewportKey={setSplitPaneViewportKey}
          splitPaneUpdatedState={splitPaneViewportKey}
        />
        <Right
          setSplitPaneViewportKey={setSplitPaneViewportKey}
          splitPaneUpdatedState={splitPaneViewportKey}
        />
      </SplitPane>
    </div>
  );
};

const Left = ({
  setSplitPaneViewportKey,
  splitPaneUpdatedState,
}: PaneProps) => {
  const { ref } = useResizeDetector();

  useEffect(() => {
    ref.current.parentElement.style.flex = splitPaneUpdatedState.left;
  }, [splitPaneUpdatedState]);

  return (
    <div
      ref={ref}
      className="w-full h-full flex flex-col px-6 border border-solid bg-white border-[#dfe2e8] rounded"
    >
      <MainPageTitleHeader
        text="규칙 적용 사용자"
        onClick={() =>
          handleViewPortSize([setSplitPaneViewportKey, 'clickedLeft'])
        }
        direction="left"
      />
      <RulDesignationRuleUserList_Table />
    </div>
  );
};

const Right = ({
  setSplitPaneViewportKey,
  splitPaneUpdatedState,
}: PaneProps) => {
  const { ref } = useResizeDetector();

  useEffect(() => {
    ref.current.parentElement.style.flex = splitPaneUpdatedState.right;
  }, [splitPaneUpdatedState]);

  return (
    <div
      ref={ref}
      className="w-full flex flex-col h-full px-6 border border-solid border-[#dfe2e8] rounded bg-white"
    >
      <MainPageTitleHeader
        text="사용자 검색"
        onClick={() =>
          handleViewPortSize([setSplitPaneViewportKey, 'clickedRight'])
        }
        direction="right"
      />
      <RulDesignationUserList_Table />
    </div>
  );
};

export default RuleDeignation;
