import { axiosInstance } from './../axiosInstance';
import { v4 as uuidv4 } from 'uuid';
import { getMyTenantId } from '../../helpers/h-userInfo';
import {
  IauthApiResponse,
  IhelloServerApiResponse,
} from '../../interfaces/api/I-api-auth';

export interface IaccountValue {
  id: string;
  hashedPwd: string;
}

export interface HelloServerResponse {
  signed: string;
  nonce: string;
}

const authInfo = {
  url: {
    helloServer: '/api/v1/crs-id-auth/hello',
    auth: '/api/v1/crs-id-auth/auth',
  },
};

export const helloServer = () => {
  const { url } = authInfo;

  return axiosInstance.post<IhelloServerApiResponse>(url.helloServer, {
    cmd: '1001',
    nonce: uuidv4().split('-').join('').slice(0, 24),
  });
};

export const authApi = async (
  accountValue: IaccountValue,
  nonceFromServer: string,
) => {
  const { url } = authInfo;

  const tenantid = getMyTenantId();
  let bid: string;
  if (tenantid) {
    bid = tenantid + '.' + accountValue.id;
  } else {
    bid = '';
    throw new Error('cant identify bid from authApi');
  }

  return axiosInstance.post<IauthApiResponse>(url.auth, {
    cmd: '1003',
    nonce: nonceFromServer,
    userid: accountValue.id,
    userpwd: accountValue.hashedPwd,
    bid,
    product: 'M',
    dev: 'D',
    subdev: 'W',
    country: 'KR',
    ver: '0.0.001',
    ismyseryn: 'Y',
    fields: 'FULL',
  });
};
