import { t } from 'i18next';
import { useTranslation } from 'react-i18next';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import '../../../styles/ag-grid.css';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import { AgGridReact } from 'ag-grid-react';
import { ColDef, SelectionChangedEvent } from 'ag-grid-community';
import { FEDERATION_CONNECTED_TABLE_COLUMN } from './columns';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { IfedListItem } from '../../../interfaces/api/service-provider/I-api-admin-serviceProvider-fed';
import { setSelectedConnectedFederation } from '../../../redux/slice/federatinManagementSlice';

const FederationConnected_Table = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { connectedFedList } = useAppSelector(
    (state) => state.federatinManagement,
  );

  // AG_TABLE
  const [columnDefs, setColumnDefs] = useState<ColDef[]>([]);
  // const [columnDefs, setColumnDefs] = useState<ColDef[]>(
  //   FEDERATION_CONNECTED_TABLE_COLUMN,
  // );


  const [rowData, setRowData] = useState<IfedListItem[]>([]);
  const gridRef = useRef<any>();
  const defaultColDef = useMemo<ColDef>(() => {
    return {
      flex: 1,
      resizable: true,
      minWidth: 100,
    };
  }, []);

  const onSelectionChanged = useCallback(
    (props: SelectionChangedEvent<IfedListItem>) => {
      if (!gridRef) return;

      // const selectedRows: IfedListItem[] = gridRef.current.api.getSelectedRows();
      const selectedRows = props.api.getSelectedRows();

      // console.log('selectedRows', selectedRows);

      if (selectedRows.length < 1) {
        dispatch(setSelectedConnectedFederation(null));
      } else {
        dispatch(setSelectedConnectedFederation(selectedRows[0]));
      }
    },
    [],
  );

  // set-ROWDATA
  useEffect(() => {
    if (connectedFedList.length < 1) {
      setRowData([]);
      return;
    }

    setRowData(connectedFedList);
  }, [connectedFedList]);

  useEffect(() => {
    setColumnDefs(FEDERATION_CONNECTED_TABLE_COLUMN(t));
  }, [t]);

  return (
    <div className="w-full grow pb-2 flex flex-col overflow-hidden">
      <div className="ag-theme-alpine ag-theme-mycustomtheme w-full grow">
        <AgGridReact
          ref={gridRef}
          rowData={rowData}
          columnDefs={columnDefs}
          defaultColDef={defaultColDef}
          rowMultiSelectWithClick
          rowSelection={'single'}
          onSelectionChanged={onSelectionChanged}
          overlayNoRowsTemplate={'\xa0'}
          // onCellContextMenu={(e) => {
          //   console.log(e);
          // }}
        ></AgGridReact>
      </div>
    </div>
  );
};

export default FederationConnected_Table;
