import { t } from 'i18next';
import React, { useMemo } from 'react';
import Button from '../../common/buttons/Button';
import {
  openFederation_connectApply_Modal,
  openFederation_disConnectApply_Modal,
} from '../../../helpers/h-modals';
import { useAppSelector } from '../../../redux/hooks';

const UpperTopBar = () => {
  const { selectedConnectedFederation } = useAppSelector(
    (state) => state.federatinManagement,
  );

  const flexDirection = useMemo(() => {
    const value = selectedConnectedFederation
      ? 'justify-between'
      : 'justify-end';
    return value;
  }, [selectedConnectedFederation]);

  return (
    <div
      className={`py-[0.563rem] flex ${flexDirection} text-fontColor-default`}
    >
      {selectedConnectedFederation &&
        selectedConnectedFederation.status === 8 && (
          <Button
            kind="cancel"
            label={t('FederationManagement.disconnectRequest')}
            onClick={openFederation_disConnectApply_Modal}
          />
        )}
      <Button
        kind="cancel"
        label={t('FederationManagement.apply')}
        onClick={openFederation_connectApply_Modal}
      />
    </div>
  );
};

export default UpperTopBar;
