import axios from 'axios';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
  servicepProvider_fed_accept_api,
  servicepProvider_fed_cancel_api,
  servicepProvider_fed_manage_api,
  servicepProvider_fed_reject_api,
  servicepProvider_fed_request_api,
} from '../../api/admin/service-provider/api-admin-serviceProvider-fed';
import {
  IfedListItem,
  IserviceProviderFedAcceptApiBody,
  IserviceProviderFedManageApiBody,
  IserviceProviderFedRejectApiBody,
  IserviceProviderFedRequestApiBody,
  IserviceProviderFedCancelApiBody,
  IserviceProviderFedTenant,
} from '../../interfaces/api/service-provider/I-api-admin-serviceProvider-fed';

interface InitialState {
  tenantList: IserviceProviderFedTenant[];
  connectedFedList: IfedListItem[];
  selectedConnectedFederation: IfedListItem | null;
  otherFedList: IfedListItem[];
  selectedOtherFederation: IfedListItem | null;
  fedActionStatus: 'idle' | 'pending' | 'success' | 'fail';
}

const initialState: InitialState = {
  tenantList: [],
  connectedFedList: [],
  selectedConnectedFederation: null,
  otherFedList: [],
  selectedOtherFederation: null,
  fedActionStatus: 'idle',
};

// accept 테넌트 연결 요청/해제 수락
export const acceptFed = createAsyncThunk(
  'fedMng/accept',
  async (body: IserviceProviderFedAcceptApiBody, { rejectWithValue }) => {
    try {
      const response = await servicepProvider_fed_accept_api(body);
      if (!response) return alert('check federationManagementSlice acceptFed');

      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.log('error: ', error);
        return rejectWithValue(error.message);
      } else {
        console.log('unexpected error: ', error);
        return rejectWithValue('unexpected error');
      }
    }
  },
);
// manage 페더레이션 신청 허용/허용 안함
export const manageFed = createAsyncThunk(
  'fedMng/manage',
  async (body: IserviceProviderFedManageApiBody, { rejectWithValue }) => {
    try {
      const response = await servicepProvider_fed_manage_api(body);
      if (!response?.data.data.result)
        return alert('check federationManagementSlice manageFed');

      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.log('error: ', error);
        return rejectWithValue(error.message);
      } else {
        console.log('unexpected error: ', error);
        return rejectWithValue('unexpected error');
      }
    }
  },
);
// reject 요청 거부
export const rejectFed = createAsyncThunk(
  'fedMng/reject',
  async (body: IserviceProviderFedRejectApiBody, { rejectWithValue }) => {
    try {
      const response = await servicepProvider_fed_reject_api(body);
      if (!response) return alert('check federationManagementSlice rejectFed');

      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.log('error: ', error);
        return rejectWithValue(error.message);
      } else {
        console.log('unexpected error: ', error);
        return rejectWithValue('unexpected error');
      }
    }
  },
);
// request 테넌트 연결/연결 해제 요청
export const requestFed = createAsyncThunk(
  'fedMng/request',
  async (body: IserviceProviderFedRequestApiBody, { rejectWithValue }) => {
    try {
      const response = await servicepProvider_fed_request_api(body);
      if (!response) return alert('check federationManagementSlice reqeustFed');

      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.log('error: ', error);
        return rejectWithValue(error.message);
      } else {
        console.log('unexpected error: ', error);
        return rejectWithValue('unexpected error');
      }
    }
  },
);
//cancel 테넌트 연결신청 취소
export const cancelFed = createAsyncThunk(
  'fedMng/cancel',
  async (body: IserviceProviderFedCancelApiBody, { rejectWithValue }) => {
    try {
      const response = await servicepProvider_fed_cancel_api(body);
      if (!response) return alert('check federationManagementSlice cancelFed');

      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.log('error: ', error);
        return rejectWithValue(error.message);
      } else {
        console.log('unexpected error: ', error);
        return rejectWithValue('unexpected error');
      }
    }
  },
);

export const federatinManagementSlice = createSlice({
  name: 'federatinManagement',
  initialState,
  reducers: {
    initFederatinManagementSlice: () => initialState,
    setTenantList: (
      state,
      { payload: tenantList }: { payload: IserviceProviderFedTenant[] },
    ) => {
      state.tenantList = tenantList;
    },
    setConnectedFedList: (
      state,
      { payload: connectedFedList }: { payload: IfedListItem[] },
    ) => {
      state.connectedFedList = connectedFedList;
    },
    setSelectedConnectedFederation: (
      state,
      { payload: connectedFed }: { payload: IfedListItem | null },
    ) => {
      state.selectedConnectedFederation = connectedFed;
    },
    setOtherFedList: (
      state,
      { payload: otherFedList }: { payload: IfedListItem[] },
    ) => {
      state.otherFedList = otherFedList;
    },
    setSelectedOtherFederation: (
      state,
      { payload: otherFed }: { payload: IfedListItem | null },
    ) => {
      state.selectedOtherFederation = otherFed;
    },
  },
  extraReducers(builder) {
    builder
      // accept 테넌트 연결 요청/해제 수락
      .addCase(acceptFed.pending, (state) => {
        state.fedActionStatus = 'pending';
      })
      .addCase(acceptFed.fulfilled, (state) => {
        state.fedActionStatus = 'success';
      })
      .addCase(acceptFed.rejected, (state, action) => {
        // console.log(action);
        alert(JSON.stringify(action.payload));
        state.fedActionStatus = 'fail';
      })
      // manage 페더레이션 신청 허용/허용 안함
      .addCase(manageFed.pending, (state) => {
        state.fedActionStatus = 'pending';
      })
      .addCase(manageFed.fulfilled, (state) => {
        state.fedActionStatus = 'success';
      })
      .addCase(manageFed.rejected, (state, action) => {
        // console.log(action);
        alert(JSON.stringify(action.payload));
        state.fedActionStatus = 'fail';
      })
      // reject 요청 거부
      .addCase(rejectFed.pending, (state) => {
        state.fedActionStatus = 'pending';
      })
      .addCase(rejectFed.fulfilled, (state) => {
        state.fedActionStatus = 'success';
      })
      .addCase(rejectFed.rejected, (state, action) => {
        // console.log(action);
        alert(JSON.stringify(action.payload));
        state.fedActionStatus = 'fail';
      })
      // request 테넌트 연결/연결 해제 요청
      .addCase(requestFed.pending, (state) => {
        state.fedActionStatus = 'pending';
      })
      .addCase(requestFed.fulfilled, (state) => {
        state.fedActionStatus = 'success';
      })
      .addCase(requestFed.rejected, (state, action) => {
        // console.log(action);
        alert(JSON.stringify(action.payload));
        state.fedActionStatus = 'fail';
      })
      // cancel 페더레이션 연결중 취소
      .addCase(cancelFed.pending, (state) => {
        state.fedActionStatus = 'pending';
      })
      .addCase(cancelFed.fulfilled, (state) => {
        state.fedActionStatus = 'success';
      })
      .addCase(cancelFed.rejected, (state, action) => {
        // console.log(action);
        alert(JSON.stringify(action.payload));
        state.fedActionStatus = 'fail';
      });
  },
});

export const {
  initFederatinManagementSlice,
  setTenantList,
  setConnectedFedList,
  setSelectedConnectedFederation,
  setOtherFedList,
  setSelectedOtherFederation,
} = federatinManagementSlice.actions;

export default federatinManagementSlice.reducer;
