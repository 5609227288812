import { t } from 'i18next';
import { ChangeEvent, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import Modal from 'react-modal';
import InputText from '../../common/inputs/InputText';
import { closeCounselingCategoryModal } from '../../../helpers/h-react-modals';
import Button from '../../common/buttons/Button';
import { toast } from 'react-toastify';
import { RootState } from '../../../redux/store';
import { reqAddCategory, reqModifyCategory } from '../../../helpers/h-counselingMng';

const customStyles = {
  overlay: {
    zIndex: 99,
  },
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    minWidth: '21.875rem',
    padding: 0,
  },
};

interface Props {
  isOpen: boolean;
}

const CounselingCategoryModal = ({ isOpen }: Props) => {
  const subTitle = useRef<HTMLHeadingElement | null>(null);
  const { selectedCategoryId, selectedCategoryName } = useSelector((state: RootState) => state.counselingManagement);
  const [categoryName, setCategoryName] = useState(selectedCategoryName || '');

  const inputHandler = (event: ChangeEvent<HTMLInputElement>) => {
    setCategoryName(event.target.value);
  };

  const handleClear = () => {
    setCategoryName('');
  };

  function afterOpenModal() {
    if (subTitle.current === null) return;
    subTitle.current.style.color = 'red';
  }

  const onConfirm = () => {
    if (!categoryName) return toast.error(t('CounselingManagement.categoryToast1') as string);

    if (selectedCategoryId) {
      const data = [
        {
          id: selectedCategoryId,
          name: categoryName,
        },
      ];
      reqModifyCategory(data);
    } else {
      reqAddCategory(categoryName);
    }

    setCategoryName('');
    closeCounselingCategoryModal();
  };

  useEffect(() => {
    if (!isOpen) {
      setCategoryName('');
    } else {
      setCategoryName(selectedCategoryName || '');
    }
  }, [isOpen, selectedCategoryName]);

  return (
    <div>
      <Modal
        isOpen={isOpen}
        onAfterOpen={afterOpenModal}
        onRequestClose={closeCounselingCategoryModal}
        style={customStyles}
        contentLabel="Example Modal"
      >
        <div
          className="flex flex-col h-full px-3
            relative border border-modal-subTitle bg-white z-50 shadow-listbox-options
            rounded
          "
        >
          <div className="min-h-[3.5rem] w-full flex flex-col relative text-center font-extrabold mt-5">
            {selectedCategoryId
              ? (t('CounselingManagement.categoryMod') as string)
              : (t('CounselingManagement.categoryAdd') as string)}
          </div>
          <InputText
            value={categoryName}
            handleChangeInput={inputHandler}
            handleClear={handleClear}
            placeholder={t('CounselingManagement.categoryToast1') as string}
            // className={`w-56`}
          />
          <div className="min-h-[6rem] w-full jcac gap-2">
            <Button
              kind="cancel"
              label={t('ContextMenu.treeContextMenu.cancel')}
              onClick={closeCounselingCategoryModal}
            />
            <Button kind="primary" label={t('ContextMenu.treeContextMenu.ok')} onClick={onConfirm} />
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default CounselingCategoryModal;
