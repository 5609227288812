import {
  IHrsOrgsListApiBody,
  IHrsOrgsListApiResponse,
  IHrsOrgsSearchUserApiBody,
} from '../../interfaces/api/I-api-hrs-orgs';
import { axiosErrorHandler, axiosInstance } from '../axiosInstance';

const hrsOrgsInfo = {
  url: {
    list: '/api/v1/crs-id-hrs/orgs/list',
    search: '/api/v1/crs-id-hrs/orgs/search',
    searchuser: '/api/v1/crs-id-hrs/orgs/searchuser',
  },
};

export const hrsOrgsListApi = async (props: IHrsOrgsListApiBody) => {
  const { url } = hrsOrgsInfo;

  try {
    const response = await axiosInstance.post<IHrsOrgsListApiResponse>(url.list, props);
    // 부서 타입 정하기
    if (response.data.data.result.dept) {
      response.data.data.result.dept.data = response.data.data.result.dept.data.map((v) => ({
        ...v,
        type: 'hrsOrgDept',
      }));
    }

    // 사용자 타입 정하기
    if (response.data.data.result.user) {
      response.data.data.result.user.data = response.data.data.result.user.data.map((v) => ({
        ...v,
        type: 'hrsOrgUser',
      }));
    }

    return response;
  } catch (error) {
    console.log(error);
    axiosErrorHandler(error);
  }
};

export const hrsOrgsSearchUserApi = async (props: IHrsOrgsSearchUserApiBody) => {
  const { url } = hrsOrgsInfo;

  try {
    const response = await axiosInstance.post(url.searchuser, props);
    return response;
  } catch (error) {
    console.log(error);
    axiosErrorHandler(error);
  }
};
