import {
  IpluralAxiosResponse,
  IsingularAxiosResponse,
} from './../../../interfaces/api/I-axios';
import { getMyTenantId, getOrgCode } from '../../../helpers/h-userInfo';
import {
  IadminRuleFunc,
  IadminRuleFuncAddApiBody,
  IadminRuleFuncInfoApiBody,
  IadminRuleFuncListApiBody,
  IadminRuleFuncModifyApiBody,
  IadminRuleFuncRemoveApiBody,
} from '../../../interfaces/api/rule/I-api-admin-rule-func';
import { axiosInstance } from '../../axiosInstance';

const adminRuleFuncInfo = {
  url: {
    list: '/api/v1/admin-mg-tp/rule/func/list',
    add: '/api/v1/admin-mg-tp/rule/func/add',
    info: '/api/v1/admin-mg-tp/rule/func/info',
    modify: '/api/v1/admin-mg-tp/rule/func/modify',
    remove: '/api/v1/admin-mg-tp/rule/func/remove',
  },
};

// list
export const adminRuleFuncListApi = (props?: IadminRuleFuncListApiBody) => {
  const { url } = adminRuleFuncInfo;
  return axiosInstance.post<IpluralAxiosResponse<IadminRuleFunc>>(url.list, {
    tenantid: getMyTenantId(),
    orgcode: getOrgCode(),
    pagesize: 50,
    page: 1,
    ...props,
  });
};

// add
export const adminRuleFuncAddApi = (props: IadminRuleFuncAddApiBody) => {
  const { url } = adminRuleFuncInfo;
  return axiosInstance.post(url.add, {
    tenantid: getMyTenantId(),
    orgcode: getOrgCode(),
    ...props,
  });
};

// remove
export const adminRuleFuncRemoveApi = (props: IadminRuleFuncRemoveApiBody) => {
  const { url } = adminRuleFuncInfo;
  return axiosInstance.post(url.remove, props);
};

// modify
export const adminRuleFuncModifyApi = (props: IadminRuleFuncModifyApiBody) => {
  const { url } = adminRuleFuncInfo;
  return axiosInstance.post(url.modify, props);
};

// info
export const adminRuleFuncInfoApi = (props: IadminRuleFuncInfoApiBody) => {
  const { url } = adminRuleFuncInfo;
  return axiosInstance.post<IsingularAxiosResponse<IadminRuleFunc>>(
    url.info,
    props,
  );
};
