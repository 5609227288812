import React from 'react';
import {
  closeCntBtnGroupAddModal,
  closeCntBtnGroupMainModal,
  closeCntBtnGroupModifyModal,
  closeModifyUserModal,
  closeUserOrgMngModal,
} from '../../helpers/h-modals';
import { useAppSelector } from '../../redux/hooks';
import CntBtnGroupMngModal from './cntBtnMng/CntBtnGroupMngModal';
import CntBtnGroupMngModal_ADD from './cntBtnMng/CntBtnGroupMngModal_ADD';
import CntBtnGroupMngModal_MODIFY from './cntBtnMng/CntBtnGroupMngModal_MODIFY';
import FederationMng_accept_Modal from './federationMng/FederationMng_accept_Modal';
import FederationMng_cancel_Modal from './federationMng/FederationMng_cancel_Modal';
import FederationMng_connectApply_Modal from './federationMng/FederationMng_connectApply_Modal';
import FederationMng_delete_Modal from './federationMng/FederationMng_delete_Modal';
import FederationMng_disConnect_Modal from './federationMng/FederationMng_disConnect_Modal';
import FederationMng_reject_Modal from './federationMng/FederationMng_reject_Modal';
import FuncBtnGroupMngModal from './funcBtnMng/FuncBtnGroupMngModal';
import FuncBtnGroupMngModal_ADD from './funcBtnMng/FuncBtnGroupMngModal_ADD';
import FuncBtnGroupMngModal_MODIFY from './funcBtnMng/FuncBtnGroupMngModal_MODIFY';
//import RuleFuncMng_Func_Modal from './ruleFuncMng/RuleFuncMng_Func_Modal ';
import RuleFuncMng_Option_Modal from './ruleFuncMng/RuleFuncMng_Option_Modal';
import RuleFuncMng_Rule_Modal from './ruleFuncMng/RuleFuncMng_Rule_Modal';
import RuleFuncMng_Rule_Modal_ADD from './ruleFuncMng/RuleFuncMng_Rule_Modal_ADD';
import RuleFuncMng_Rule_Modal_MODIFY from './ruleFuncMng/RuleFuncMng_Rule_Modal_MODIFY';
import ModifyUserModal from './userOrgMng/ModifyUserModal';
import UserOrgAddUserModal from './userOrgMng/UserOrgAddUserModal';
import { closeProfileImg } from '../../helpers/h-react-modals';

const ModalView = () => {
  const { modals } = useAppSelector((state) => state.toggle);
  return (
    <>
      {/* 조직 / 사용자 관리 */}
      {modals.orgUserMng.addUser && (
        <UserOrgAddUserModal onClickClose={closeUserOrgMngModal} />
      )}
      {modals.orgUserMng.modifyUser && (
        <ModifyUserModal onClickClose={closeModifyUserModal} />
      )}

      {/* FUNC BTN MNG */}
      {modals.funcBtnMng.main && <FuncBtnGroupMngModal />}
      {modals.funcBtnMng.add && <FuncBtnGroupMngModal_ADD />}
      {modals.funcBtnMng.modify && <FuncBtnGroupMngModal_MODIFY />}
      {/* COUNT BTN MNG */}
      {modals.cntBtnMng.main && (
        <CntBtnGroupMngModal onClickClose={closeCntBtnGroupMainModal} />
      )}
      {modals.cntBtnMng.add && (
        <CntBtnGroupMngModal_ADD onClickClose={closeCntBtnGroupAddModal} />
      )}
      {modals.cntBtnMng.modify && (
        <CntBtnGroupMngModal_MODIFY
          onClickClose={closeCntBtnGroupModifyModal}
        />
      )}
      {/* RULE/FUNC MNG */}
      {/* {modals.ruleFuncMng.func && <RuleFuncMng_Func_Modal />} */}
      {modals.ruleFuncMng.rule && <RuleFuncMng_Rule_Modal />}
      {modals.ruleFuncMng.ruleAdd && <RuleFuncMng_Rule_Modal_ADD />}
      {modals.ruleFuncMng.ruleModify && <RuleFuncMng_Rule_Modal_MODIFY />}
      {modals.ruleFuncMng.ruleOption && <RuleFuncMng_Option_Modal />}
      {/* FEDERATION/MNG */}
      {modals.federationMng.cancel && <FederationMng_cancel_Modal />}
      {modals.federationMng.connectApply && (
        <FederationMng_connectApply_Modal />
      )}
      {modals.federationMng.disConnectApply && (
        <FederationMng_disConnect_Modal />
      )}
      {modals.federationMng.delete && <FederationMng_delete_Modal />}
      {modals.federationMng.accept && <FederationMng_accept_Modal />}
      {modals.federationMng.reject && <FederationMng_reject_Modal />}
    </>
  );
};

export default ModalView;
