import { ColDef } from 'ag-grid-community';
import { IUserInfo } from '../../../interfaces/api/I-api-hrs-users';
import { AgCellTextCenter } from '../../common/AgCustomCell';
import { AgHeaderTextCenter } from '../../common/AgCustomHeader';
export const RULE_USER_LIST_TALBLE_COLUMN: ColDef<IUserInfo>[] = [
  {
    field: 'checkbox',
    headerName: ' ',
    // headerCheckboxSelection: true,
    checkboxSelection: true,
    maxWidth: 50,
    resizable: false,
    cellRenderer: AgCellTextCenter<IUserInfo>,
    headerComponent: AgHeaderTextCenter,
  },
  {
    field: 'userid',
    headerName: '사용자 ID',
    cellRenderer: AgCellTextCenter<IUserInfo>,
    headerComponent: AgHeaderTextCenter,
  },
  {
    field: 'username',
    headerName: '사용자명',
    cellRenderer: AgCellTextCenter<IUserInfo>,
    headerComponent: AgHeaderTextCenter,
  },
];

export const USER_LIST_TALBLE_COLUMN: ColDef<any>[] = [
  {
    field: 'checkbox',
    headerName: ' ',
    // headerCheckboxSelection: true,
    checkboxSelection: true,
    maxWidth: 50,
    resizable: false,
    cellRenderer: AgCellTextCenter<any>,
    headerComponent: AgHeaderTextCenter,
  },
  {
    field: 'userid',
    headerName: '사용자 ID',
    cellRenderer: AgCellTextCenter<any>,
    headerComponent: AgHeaderTextCenter,
  },
  {
    field: 'username',
    headerName: '사용자명',
    cellRenderer: AgCellTextCenter<any>,
    headerComponent: AgHeaderTextCenter,
  },
  {
    field: 'deptname',
    headerName: '소속부서',
    cellRenderer: AgCellTextCenter<any>,
    headerComponent: AgHeaderTextCenter,
  },
];
