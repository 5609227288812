/**
 * @param items userid 프로퍼티를 포함하는 배열
 * 배열중 userid 가 중복된게 있다면 false (유니크하지 않음)
 * 배열중 userid 중복된 값이 없다면 true (유니크함)
 */
export function hasUniqueUserIds<T extends { userid: string }>(items: T[]) {

  const useridSet = new Set<string>();

  for(const item of items) {
    if(useridSet.has(item.userid)) {
      return false;
    }
    useridSet.add(item.userid);
  }

  return true;
}

export function filterInvalidUserData<T extends { userid: string, usernameKo?: string }>(items: T[]) {

  const userIdCounts = new Map<string, number>();

  items.forEach((row) => {
    if (row.userid) {
      userIdCounts.set(row.userid, (userIdCounts.get(row.userid) || 0) + 1);
    }
  });

  return items.filter((row) => {
    const isMissingFields = !row.userid || !row.usernameKo; // Check for missing fields
    const isDuplicated = row.userid && userIdCounts.get(row.userid)! > 1; // Check for duplicate `userid`

    return isMissingFields || isDuplicated;
  });
}