import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  profileUrl: '',
};

export const profileSlice = createSlice({
  name: 'profile',
  initialState,
  reducers: {
    initProfileSlice: () => initialState,
    setProfileUrl: (state, { payload: url }: { payload: string }) => {
      state.profileUrl = url;
    },
  },
});

export const { initProfileSlice, setProfileUrl } = profileSlice.actions;

export default profileSlice.reducer;
