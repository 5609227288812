import React from 'react';
import Modal from 'react-modal';
import 'rc-tree/assets/index.css'; // Import rc-tree CSS
import { closeAddDepartmentModal } from '../../../helpers/h-react-modals';
import TreeViewConcurrentDept from '../../treeView/TreeViewConcurrentDept';
import { RootState } from '../../../redux/store';
import { useSelector } from 'react-redux';

const customStyles = {
  overlay: {
    zIndex: 9999,
    backgroundColor: 'transparent',
  },
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    width: '400px',
    height: '720px',
    padding: 0,
  },
};

interface Props {
  isOpen: boolean;
}

const AddDepartmentModal: React.FC<Props> = ({ isOpen = false }) => {
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={closeAddDepartmentModal}
      contentLabel="Add Department Modal"
      style={customStyles}
    >
      <TreeViewConcurrentDept width={300} />
    </Modal>
  );
};

export default AddDepartmentModal;
