import { ButtonProps } from '../../../interfaces/ButtonProps';

const SelectDown = ({ width = 16, height = 16, className }: ButtonProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 16 16"
    className={className}
  >
    <path d="M0 0h16v16H0z" className="fill-transparent" />
    <path
      d="m3.4 3.753 5 5 5-5"
      transform="translate(-.404 2.247)"
      className="stroke-C-636465 stroke-2 fill-transparent"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default SelectDown;
