import { ColDef } from 'ag-grid-community';
import { IspUksGetsApiResponse } from '../../../interfaces/api/service-provider/I-api-admin-sp-uks';
import { AgCellTextCenter } from '../../common/AgCustomCell';
import { AgHeaderTextCenter } from '../../common/AgCustomHeader';

// const CustomCell2 = (props: ICellRendererParams<IspUksGetsApiResponse>) => {
//   return <div className="text-center truncate">{props.value}</div>;
// };
export const SERVER_LIST_TABLE_COLUMN: ColDef<IspUksGetsApiResponse>[] = [
  {
    field: 'name',
    headerName: '서버명',
    headerComponent: AgHeaderTextCenter,
    cellRenderer: AgCellTextCenter<IspUksGetsApiResponse>,
  },
  {
    field: 'uid',
    headerName: 'ID',
    cellRenderer: AgCellTextCenter<IspUksGetsApiResponse>,
    headerComponent: AgHeaderTextCenter,
    // cellStyle: {
    //   width: '30px',
    // },
  },
  {
    field: 'status',
    headerName: '상태',
    cellRenderer: AgCellTextCenter<IspUksGetsApiResponse>,
    headerComponent: AgHeaderTextCenter,
    valueGetter: (params) => {
      if (params.data?.status === 'Running') {
        return '실행중';
      } else if (params.data?.status === 'Pending') {
        return '대기중';
      }
    },
    cellClassRules: {
      'ag-green': (params) => params.value === '실행중',
      'ag-red': (params) => params.value === '대기중',
    },
  },
  {
    field: 'creationTimestamp',
    headerName: '업데이트 시간',
    cellRenderer: AgCellTextCenter<IspUksGetsApiResponse>,
    headerComponent: AgHeaderTextCenter,
  },
  {
    field: 'resourceVersion',
    headerName: '버전 정보',
    cellRenderer: AgCellTextCenter<IspUksGetsApiResponse>,
    headerComponent: AgHeaderTextCenter,
  },
];
