import { useEffect, useState } from 'react';

// 컴포넌트
import ServiceList from './ServiceList';
import DownTopBar from './DownTopBar';

// 라이브러리
import { SplitPane } from 'react-multi-split-pane';
import { useResizeDetector } from 'react-resize-detector';
import {
  handleViewPortSize,
  PaneProps,
  splitPaneViewportCase,
} from '../../../helpers/h-splitPane';
import MainServerList_Table from '../../tables/main/MainServerList_Table';
import MainPageTitleHeader from '../../common/MainPageTitleHeader';

// const splitPaneViewportCase = {
//   clickedUpper: {
//     upper: '1 1 auto',
//     down: '9999 9999 auto',
//     count: 0,
//   },
//   clickedDown: {
//     upper: '9999 9999 auto',
//     down: '1 1 auto',
//     count: 0,
//   },
//   default: {
//     upper: '1 1 auto',
//     down: '1 1 auto',
//     count: 0,
//   },
// };

/*
  1. 위 화면이 더 큰 경우
    a. 위 버튼 클릭 --> 1 1 / 9999 9999
    b. 아래 버튼 클릭 --> 9999 9999 / 1 1
  2. 위 화면이 더 작은 경우
    a. 위 버튼 클릭 --> 9999 9999 / 1 1
    b. 아래 버튼 클릭 --> 1 1 / 9999 9999
  3. 위 아래 화면이 같은 경우
    a. 위 클릭시 --> 1 1 / 9999 9999
    b. 아래 클릭시 --> 9999 9999 / 1 1

  **필요한 변수**
    1. 각 화면의 크기를 추적하는 변수
    2. 화면의 크기를 조절할 키값을 갖는 변수

  - 1번의 크기를 감지하면서 2번의 변수에서 다음에 해야할 값을 정함

*/

// const [paneViewport, setPaneViewport] = useState({
//   upper: 1,
//   down: 1
// })
// const [paneViewPortKey, setPaneViewPortKey] = useState({
//   upperLargerThanDown: {
//     clickUpper: {
//       upper: 'flex 1 1 auto',
//       down: 'flex 9999 9999 auto'
//     },
//     clickDown: {
//       upper: 'flex 9999 9999 auto',
//       down: 'flex 1 1 auto'
//     }
//   },
//   downLargerThanUpper: {
//     clickUpper: {
//       upper:  'flex 9999 9999 auto',
//       down: 'flex 1 1 auto'
//     },
//     clickDown: {
//       upper: 'flex 1 1 auto',
//       down: 'flex 9999 9999 auto'
//     },

//   },
//   c: {
//     upper: 'flex 1 1 auto',
//     down: 'flex 9999 9999 auto'
//   }
// })

/*
  -- 중간 값이 없는 경우--

  1. 위에 화살표는 항상 위
    - 클릭시
      위 :    flex 1    1
      아래 :  flex 9999 9999

  2. 아래 화살표는 항상 아래
    - 클릭시
      위 :    flex 9999 9999
      아래 :  flex 1    1

*/

const Main = () => {
  const [splitPaneViewportKey, setSplitPaneViewportKey] = useState(
    splitPaneViewportCase.default,
  );

  return (
    <div className="h-full w-full">
      <SplitPane
        className="custom"
        defaultSizes={[2, 1]}
        split="horizontal"
        minSize={[40, 40]}
      >
        <Upper
          setSplitPaneViewportKey={setSplitPaneViewportKey}
          splitPaneUpdatedState={splitPaneViewportKey}
        />
        <Down
          setSplitPaneViewportKey={setSplitPaneViewportKey}
          splitPaneUpdatedState={splitPaneViewportKey}
        />
      </SplitPane>
    </div>
  );
};

const Upper = ({
  setSplitPaneViewportKey,
  splitPaneUpdatedState,
}: PaneProps) => {
  const { ref } = useResizeDetector();

  useEffect(() => {
    ref.current.parentElement.style.flex = splitPaneUpdatedState.upper;
  }, [splitPaneUpdatedState]);

  return (
    <div
      ref={ref}
      className="w-full h-full border border-solid border-[#dfe2e8] rounded"
    >
      <div className="w-full h-full flex flex-col px-[1.5rem] bg-white">
        <MainPageTitleHeader
          onClick={() =>
            handleViewPortSize([setSplitPaneViewportKey, 'clickedUpper'])
          }
          text="서버 목록"
        />
        <MainServerList_Table />
      </div>
    </div>
  );
};

const Down = ({
  setSplitPaneViewportKey,
  splitPaneUpdatedState,
}: PaneProps) => {
  const { ref, width: topBarWidth } = useResizeDetector();

  useEffect(() => {
    ref.current.parentElement.style.flex = splitPaneUpdatedState.down;
  }, [splitPaneUpdatedState]);

  return (
    <div
      ref={ref}
      className="w-full h-full px-[1.5rem] border border-solid flex flex-col border-[#dfe2e8] rounded bg-white"
    >
      <MainPageTitleHeader
        direction="down"
        text="서비스 목록"
        onClick={() =>
          handleViewPortSize([setSplitPaneViewportKey, 'clickedDown'])
        }
      />
      <DownTopBar width={topBarWidth} />
      <ServiceList />
    </div>
  );
};

export default Main;
