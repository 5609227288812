


const WarningIcon = () => {

  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
      <path fillRule="evenodd" clipRule="evenodd" d="M11 15H9V13H11V15Z" fill="#FA3E5E" />
      <path fillRule="evenodd" clipRule="evenodd" d="M9 12L8.5 6H11.5L11 12H9Z" fill="#FA3E5E" />
      <circle cx="10" cy="10" r="8" stroke="#FA3E5E" strokeWidth="2" />
    </svg>
  )
}

export default WarningIcon;
