import { t } from 'i18next';
import React, { useEffect, useState } from 'react';
import Draggable from 'react-draggable';
import { ResizeCallbackData, Resizable } from 'react-resizable';
import Button from '../../common/buttons/Button';

interface Props {
  label: string;
  onClickHandler?: () => void;
  onClickClose: () => void;
}
const FederationMng_ask_common = (props: Props) => {
  const [size, setSize] = useState({
    width: 350,
    height: 196,
  });

  const onResize = (
    e: React.SyntheticEvent<Element, Event>,
    data: ResizeCallbackData,
  ) => {
    setSize({
      width: data.size.width,
      height: data.size.height,
    });
  };

  // FUNC 버튼 그룹 수정 모달 핸들러
  const handleModifyFuncBtnGroup = () => {
    // console.log('d');
  };

  // FUNC 버튼 그룹 삭제 핸들러
  const handleRemoveFuncBtnGroup = () => {
    // console.log('d');
  };

  // 클리어 함수
  useEffect(() => {
    // console.log('dd');
  }, []);

  return (
    <Draggable handle="#handle">
      <Resizable
        handle={
          <div
            className="resizable-custom-handler absolute"
            style={{
              left: size.width - 19,
              top: size.height - 19,
            }}
          ></div>
        }
        height={size.height}
        width={size.width}
        onResize={onResize}
      >
        <div
          className="modal-wrapper"
          style={{
            top: 'calc(50% - 127px)',
            left: 'calc(50% - 93px)',
          }}
        >
          {/* WRAPPER */}
          <div
            className="box drag-box  flex flex-col px-[1.25rem]
            relative border border-modal-subTitle bg-white z-50 shadow-listbox-options
            rounded
          "
            style={{ width: `${size.width}px`, height: `${size.height}px` }}
          >
            {/* HEADER */}
            <div
              id="handle"
              className="min-h-[3.5rem] cursor-move w-full flex flex-col relative"
            >
              {/* <div className="grow font-bold w-full flex items-center truncate">
                페더레이션 신청
              </div> */}
            </div>
            {/* BODY */}
            <div className="grow w-full jcac gap-3 overflow-y-auto text-sm">
              {props.label}
            </div>
            {/* FOOTER */}
            <div className="min-h-[6rem] w-full jcac gap-2">
              <Button kind="cancel" label={t('FederationManagement.cancel')} onClick={props.onClickClose} />
              <Button
                kind="primary"
                label={t('FederationManagement.confirm')}
                onClick={props.onClickHandler}
              />
            </div>
          </div>
        </div>
      </Resizable>
    </Draggable>
  );
};

export default FederationMng_ask_common;
