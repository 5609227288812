import {
  IspUksGetsApiBody,
  IspUksGetsKinds,
} from './../interfaces/api/service-provider/I-api-admin-sp-uks';
import { spUksGetsApi } from '../api/admin/service-provider/api-admin-sp-uks';
import { store } from '../redux/store';
import axios from 'axios';

// uks
// gets
export const getsUksHandler = async (kind: IspUksGetsKinds) => {
  const {
    conf: { confData },
  } = store.getState();

  if (!confData) return;

  const tenantid = confData.tenantid;

  try {
    const res = await spUksGetsApi({
      namespace: `ucworks-${tenantid}`,
      kind,
    });

    if (!res) return;
    if (res.data.data.result.length < 1) {
      return [];
    }

    const serverDataList = res.data.data.result;

    return serverDataList;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      console.log('error: ', error);
    } else {
      console.log('unexpected error: ', error);
    }
  }
};
