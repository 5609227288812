import React from 'react';
import ListTitle from '../../common/ListTitle';
import AnnounceLists from './AnnounceLists';
import { announcementDummy } from './data';

const AnnounceListContainer = () => {
  return (
    <div className="h-[calc(100%_-_9.25rem)]">
      <ListTitle
        titles={[
          '구분',
          '제목',
          '소속',
          '작성자명',
          'ID',
          '공지기간',
          '작성일',
        ]}
      />
      <div className="overflow-auto h-full">
        {announcementDummy.map((data, i) => (
          <AnnounceLists data={data} key={i} index={i} />
        ))}
      </div>
    </div>
  );
};

export default AnnounceListContainer;
