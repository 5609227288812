import { IpluralAxiosResponse } from './../../../interfaces/api/I-axios';
import axios from 'axios';
import { axiosErrorHandler, axiosInstance } from '../../axiosInstance';
import {
  IclientPatchListApiBody,
  IclientPatchListApiResponse,
  IclientPatchUploadApiBody,
} from '../../../interfaces/api/client/I-api-admin-client';
import { SERVER_SUFFIX } from '../../../const/server';


const adminClientInfo = {
  url: {
    patch_list: '/api/v1/admin-mg-tp/client/patch/list',
    patch_upload: '/storage/v2/crs-file-storage/patch/upload',
  },
};

// list
export const adminClientPatchListApi = (props: IclientPatchListApiBody) => {
  const { url } = adminClientInfo;
  const { tenantid, ...body } = props;

  return axiosInstance.post<IpluralAxiosResponse<IclientPatchListApiResponse>>(
    `https://${tenantid}${SERVER_SUFFIX}${url.patch_list}`,
    body,
  );
};

// patch
export const adminClientPatchUploadApi = (props: IclientPatchUploadApiBody) => {
  const { url } = adminClientInfo;
  const { tenantid, ver, product, dev, subdev, envFile } = props;
  return axiosInstance.put(
    `https://kr.${tenantid}${SERVER_SUFFIX}${url.patch_upload}?ver=${ver}&product=${product}&dev=${dev}&subdev=${subdev}`,
    envFile,
  );
};
