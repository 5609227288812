import axios from 'axios';
import { t } from 'i18next';
import { toast } from 'react-toastify';
import { adminAccessMenuListApi } from '../api/admin/api-admin-access';
import { adminConfListApi } from '../api/admin/api-admin-conf';
import { adminRoleInfoApi } from '../api/admin/api-amin-role';
import { authApi, helloServer, IaccountValue } from '../api/auth/api-auth';
import { axiosInstance, searchParam, setAxiosBaseUrl, setAxiosDefaultAuth } from '../api/axiosInstance';
import { IauthUser } from '../interfaces/api/I-api-auth';
import { initAuthSlice, setUser } from '../redux/slice/authSlice';
import { setConfData } from '../redux/slice/confSlice';
import {
  initUserSlice,
  setMenuGid,
  setMenuList,
  setType,
  setUserRolestatus,
  setUserSlice,
} from '../redux/slice/userSlice';
import { store } from '../redux/store';
import { IHrsUsersListApiBody } from '../interfaces/api/I-api-hrs-users';
import { hrsUserListApi, userIdDup } from '../api/hrs/api-hrs-users';
import base64 from 'base-64';
import { axiosEduInstance, setAxiosEduDefaultAuth, setAxiosEduUrl } from '../api/axiosEduInstance';

const dispatch = store.dispatch;

interface IauthauthHandlerProps {
  accountValue?: IaccountValue;
  fromPortal?: string;
}

interface fromPortalProps {
  jwt?: string;
  refjwt?: string;
  lang?: string;
}
// auth/auth 유저데이터 가공
export const directAuthHandler = async ({ jwt, refjwt }: fromPortalProps) => {
  if (!axiosInstance.defaults.baseURL) {
    setAxiosBaseUrl();
  }
  if (!axiosEduInstance.defaults.baseURL) {
    setAxiosEduUrl();
  }
  const jwtStr = JSON.stringify(jwt);

  const authentication = `Bearer ${jwt}`;
  sessionStorage.setItem('Auth', authentication);

  const payload = jwtStr.substring(jwtStr.indexOf('.') + 1, jwtStr.lastIndexOf('.'));
  const decodedJwt = JSON.parse(base64.decode(payload));
  const bidOriginal = decodedJwt.aud;
  const secondDotIndex = bidOriginal.indexOf('.M.D.W', bidOriginal.indexOf('.') + 1);
  const bid = bidOriginal.substring(0, secondDotIndex);
  const usersid = decodedJwt.aud;
  const res = await userIdDup(bid);
  const rs = res.data.data.result;
  const userData = {
    v: 1,
    cmd: '1003',
    usersid: usersid,
    strid: {
      user: '',
      profile: '',
    },
    userjwt: jwtStr,
    userexp: 1,
    user: rs as unknown as IauthUser,
    //user: {} as IauthUser,
  };
  console.log(userData);
  return userData;
};
/*
 */
/* 위의 함수 원본
 */
// auth/auth
export const authauthHandler = async ({ accountValue, fromPortal }: IauthauthHandlerProps) => {
  if (!axiosInstance.defaults.baseURL) {
    setAxiosBaseUrl();
  }
  if (!axiosEduInstance.defaults.baseURL) {
    setAxiosEduUrl();
  }
  try {
    const {
      data: { data: respHelloServer },
    } = await helloServer();
    const accountValueFromPortal = {
      id: fromPortal ? fromPortal : '',
      hashedPwd: '1234',
    };

    const ACCOUNTVALUE = fromPortal ? accountValueFromPortal : accountValue ? accountValue : { id: '', hashedPwd: '' };

    if (!respHelloServer) return;

    const { data: userData } = await authApi(ACCOUNTVALUE, respHelloServer.nonce);

    if (!userData) return;

    // // 로그인 실패
    // if (userData.code !== 200) {
    //   toast.error('사용자 계정 혹은 비밀번호가 틀립니다.');
    //   return;
    // }

    // 로그인 성공
    sessionStorage.setItem('jwtToken', userData.data.userjwt ?? '');
    sessionStorage.setItem('refToken', userData.data.refjwt ?? '');
    console.log(userData);

    return userData.data;
  } catch (e) {
    //toast.error(String(e));
    toast.error(t('LoginPage.loginFailDesc1') as string);
    console.log(e);
  }
};

interface IvalidateUserHandler {
  userData: {
    v: number;
    cmd: string;
    usersid: string;
    strid: {
      user: string;
      profile: string;
    };
    userjwt: string;
    userexp: number;
    user: IauthUser;
  };
}
//포탈에서 온 유저 검증
export const validateDirectUserHandler = async ({ userData }: IvalidateUserHandler) => {
  try {
    const type = sessionStorage.getItem('type');
    const { data: userRoleInfo } = await adminRoleInfoApi({
      bid: userData.user.bid,
      role: 'ADMIN',
    });
    if (!userRoleInfo) return;

    if (!userRoleInfo.data.result || userRoleInfo.data.result.rolestatus !== 0) {
      toast.error(t('LoginPage.loginFailDesc2') as string);
      return;
    }
    console.log(type);
    dispatch(setType(type));
    dispatch(
      setUserSlice({
        strid: userData.strid,
        userInfo: userData.user,
        userexp: userData.userexp,
        userjwt: userData.userjwt,
        usersid: userData.usersid,
      }),
    );
    dispatch(setMenuGid(userRoleInfo.data.result.menugid));
    dispatch(setUserRolestatus(userRoleInfo.data.result.rolestatus));
    const menuList = await adminAccessMenuListApi({ bid: userData.user.bid });
    const { data: confData } = await adminConfListApi({
      tenantid: userData.user.bid.split('.')[0],
      conftype: 0,
      pagesize: 10,
      page: 1,
    });
    if (menuList?.data.data.result) {
      dispatch(setMenuList(menuList.data.data.result));
    }

    if (confData && confData.data.result.length > 0) {
      const CONFDATA = confData.data.result[0].confdata;
      dispatch(setConfData(CONFDATA));
    }

    const jwt = userData.userjwt;
    const authentication = `Bearer ${jwt}`;
    sessionStorage.setItem('Auth', authentication);
    setAxiosDefaultAuth();
    setAxiosEduDefaultAuth();
    dispatch(setUser(true));
  } catch (error) {
    if (axios.isAxiosError(error)) {
      console.log('error: ', error);
    } else {
      console.log('unexpected error: ', error);
    }
  }
};
//일반 로그인
export const validateUserHandler = async ({ userData }: IvalidateUserHandler) => {
  try {
    const type = sessionStorage.getItem('type');
    const { data: userRoleInfo } = await adminRoleInfoApi({
      bid: userData.user.bid,
      role: 'ADMIN',
    });

    if (!userRoleInfo) return;
    console.log(userRoleInfo);
    if (!userRoleInfo.data.result || userRoleInfo.data.result.rolestatus !== 0) {
      toast.error(t('LoginPage.loginFailDesc2') as string);
      return;
    }
    console.log(type);
    dispatch(setType(type));
    dispatch(
      setUserSlice({
        strid: userData.strid,
        userInfo: userData.user,
        userexp: userData.userexp,
        userjwt: userData.userjwt,
        usersid: userData.usersid,
      }),
    );
    dispatch(setMenuGid(userRoleInfo.data.result.menugid));
    dispatch(setUserRolestatus(userRoleInfo.data.result.rolestatus));
    const menuList = await adminAccessMenuListApi({ bid: userData.user.bid });
    const { data: confData } = await adminConfListApi({
      tenantid: userData.user.bid.split('.')[0],
      conftype: 0,
      pagesize: 10,
      page: 1,
    });
    console.log(confData);
    if (menuList?.data.data.result) {
      dispatch(setMenuList(menuList.data.data.result));
    }
    if (confData && confData.data.result.length > 0) {
      const CONFDATA = confData.data.result[0].confdata;
      dispatch(setConfData(CONFDATA));
    }

    const jwt = userData.userjwt;
    const authentication = `Bearer ${jwt}`;
    sessionStorage.setItem('Auth', authentication);
    setAxiosDefaultAuth();
    setAxiosEduDefaultAuth();
    dispatch(setUser(true));
  } catch (error) {
    if (axios.isAxiosError(error)) {
      console.log('error: ', error);
    } else {
      console.log('unexpected error: ', error);
    }
  }
};

/**
 * 인증을 위해 사용한 로컬 스토리지값 초기화
 */
export const clearAuthStorage = () => {
  sessionStorage.removeItem('tenantid');
};

export const logoutHandler = () => {
  sessionStorage.removeItem('Auth');
  sessionStorage.removeItem('persist:root');
  dispatch(initAuthSlice());
  dispatch(initUserSlice());
};
