import React from 'react';
import ListTitle from '../../common/ListTitle';
// import Lists from './Lists';
import { serviceListDummy } from './data';

interface Data {
  division: string;
  serviceName: string;
  pid: string;
  state: string;
  updatedTime: string;
  cpuUsage: string;
  memoryUsage: string;
  master: string;
}

type Props = {
  data: Data;
  index: number;
};

const Lists: React.FC<Props> = ({ data, index }) => {
  return (
    <div
      className={`grid grid-cols-8 border-b border-solid py-[0.813rem] border-[#dfe2e8] ${
        index < 1 ? 'bg-[#f1f3f6]' : ''
      }`}
    >
      <div className="text-center">{data.division}</div>
      <div className="text-center">{data.serviceName}</div>
      <div className="text-center">{data.pid}</div>
      <div className="text-center">{data.state}</div>
      <div className="text-center">{data.updatedTime}</div>
      <div className="text-center">{data.cpuUsage}</div>
      <div className="text-center">{data.memoryUsage}</div>
      <div className="text-center">{data.master}</div>
    </div>
  );
};

const ServiceList = () => {
  return (
    <div className="flex flex-col h-[calc(100%_-_6.25rem)]">
      <ListTitle
        titles={[
          '구분',
          '서비스명',
          'PID',
          '상태',
          '업데이트 시간',
          'CPU 사용량',
          '메모리 사용량',
          'Master',
        ]}
      />
      <div className="overflow-auto h-full">
        {serviceListDummy.map((d, i) => (
          <Lists key={i} data={d} index={i} />
        ))}
      </div>
    </div>
  );
};

export default ServiceList;
