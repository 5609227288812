import { IDeptCodeV2 } from '../interfaces/api/I-api-admin-users';

// 사용자 추가
export const userFormDataInitial = () => {
  return {
    usernameKo: '',
    usernameEn: '',
    userid: '',
    userpwd: '',
    position: '',
    duty: '',
    grade: '',
    deptlist: [] as IDeptCodeV2[],
    deptcode: '',
    deptname: '',
    empno: '',
    telhome: '',
    teloffice: '',
    telextension: '',
    telmobile: '',
    email: '',
    picurl: '',
    zipno: '',
    address: '',
    birthtype: '',
    birthday: '',
    gender: '',
    duties: '',
    officehour: '',
    workstatus: '',
    compcode: '',
    compname: '',
    userorder: 1,
    userfield1: '',
    userfield2: '',
    userfield3: '',
    userfield4: '',
    userfield5: '',
    updatetype: '',
    userpwdhashtype: 'SHA256',
  };
};

export const deptFormDataInitial = () => {
  return {
    usernameKo: '',
    usernameEn: '',
    userid: '',
    userpwd: '',
    position: '',
    duty: '',
    grade: '',
    deptcode: '',
    deptname: '',
    empno: '',
    telhome: '',
    teloffice: '',
    telextension: '',
    telmobile: '',
    email: '',
    picurl: '',
    zipno: '',
    address: '',
    birthtype: '',
    birthday: '',
    gender: '',
    duties: '',
    officehour: '',
    workstatus: '',
    compcode: '',
    compname: '',
    userorder: 1,
    userfield1: '',
    userfield2: '',
    userfield3: '',
    userfield4: '',
    userfield5: '',
    updatetype: '',
    userpwdhashtype: 'SHA256',
  };
};

// 기능 추가
export const ruleFuncFormDataInitial = () => {
  return {
    funccode: '',
    funckind: '',
    funcname: '',
    allowdup: 0,
    funcfield1: '',
    funcfield2: '',
    defaultvalue: '',
  };
};
