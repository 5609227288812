import React, { useEffect, useState } from 'react';
import Draggable from 'react-draggable';
import { Resizable } from 'react-resizable';
import Button from '../../common/buttons/Button';
import InputText from '../../common/inputs/InputText';
import { modifyFuncBtnGroupHandler } from '../../../helpers/h-funcBtnMng';
import { closeFuncBtnGroupModifyModal } from '../../../helpers/h-modals';
import { IAdminButtonGroup } from '../../../interfaces/api/button/I-api-admin-btn-group';
import { ResizeCallbackData } from '../../../interfaces/I-modals';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { initSelectedFuncBtnGroupModal } from '../../../redux/slice/funcButtonManagementSlice';

interface Props {
  onClickClose?: () => void;
}

const FuncBtnGroupMngModal_MODIFY = (props: Props) => {
  const [size, setSize] = useState({
    width: 352,
    height: 236,
  });
  const dispatch = useAppDispatch();
  const { selectedFuncBtnGroupModal } = useAppSelector(
    (state) => state.funcButtonManagement,
  );
  const [localSelectedFuncBtnGroup, setLocalSelectedFuncBtnGroup] =
    useState<IAdminButtonGroup | null>(null);

  const onResize = (
    e: React.SyntheticEvent<Element, Event>,
    data: ResizeCallbackData,
  ) => {
    setSize({
      width: data.size.width,
      height: data.size.height,
    });
  };

  // FUNC 버튼 그룹 이름 핸들러
  const funcBtnGroupNameHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    localSelectedFuncBtnGroup &&
      setLocalSelectedFuncBtnGroup({
        ...localSelectedFuncBtnGroup,
        groupname: e.target.value,
      });
  };

  // FUNC 버튼 그룹 수정 핸들러
  const handleOnSubmit = () => {
    modifyFuncBtnGroupHandler(localSelectedFuncBtnGroup);
  };

  // 리덕스 스테이트 로컬 스테이트로 옮기기.
  useEffect(() => {
    if (selectedFuncBtnGroupModal === null) return;
    if (localSelectedFuncBtnGroup !== null) return;
    setLocalSelectedFuncBtnGroup({ ...selectedFuncBtnGroupModal });
  }, [selectedFuncBtnGroupModal]);

  // 클리어 함수
  useEffect(() => {
    return () => {
      dispatch(initSelectedFuncBtnGroupModal());
    };
  });

  return (
    <Draggable handle="#handle">
      <Resizable
        handle={
          <div
            className="resizable-custom-handler absolute"
            style={{
              left: size.width - 19,
              top: size.height - 19,
            }}
          ></div>
        }
        height={size.height}
        width={size.width}
        onResize={onResize}
      >
        <div className="modal-wrapper">
          {/* WRAPPER */}
          <div
            className="box drag-box  flex flex-col px-[1.25rem]
            relative border border-modal-subTitle bg-white z-50 shadow-listbox-options
            rounded
          "
            style={{ width: `${size.width}px`, height: `${size.height}px` }}
          >
            {/* HEADER */}
            <div
              id="handle"
              className="min-h-[3.5rem] cursor-move w-full flex flex-col relative"
            >
              <div className="grow font-bold w-full flex items-center truncate">
                버튼 그룹 수정
              </div>
            </div>
            {/* BODY */}
            <div className="grow w-full flex flex-col gap-3 overflow-y-auto">
              그룹 이름
              <InputText
                value={
                  localSelectedFuncBtnGroup
                    ? localSelectedFuncBtnGroup.groupname
                    : ''
                }
                handleChangeInput={funcBtnGroupNameHandler}
              />
              {/* 테이블 */}
            </div>
            {/* FOOTER */}
            <div className="min-h-[6rem] w-full  jcac">
              <div className="flex gap-5">
                <Button
                  onClick={closeFuncBtnGroupModifyModal}
                  kind="cancel"
                  label="취소"
                />
                <Button onClick={handleOnSubmit} kind="primary" label="저장" />
              </div>
            </div>
            {/* 닫기 버튼 */}
            <button
              onClick={closeFuncBtnGroupModifyModal}
              className="absolute right-1 top-1"
            >
              X
            </button>
          </div>
        </div>
      </Resizable>
    </Draggable>
  );
};

export default FuncBtnGroupMngModal_MODIFY;
