import { t } from 'i18next';
import { ChangeEvent } from 'react';
import { toast } from 'react-toastify';
import InputRadioCheckbox from '../../common/inputs/InputRadioCheckbox';
import { fedManageHandler } from '../../../helpers/h-federationMng';
import { useAppSelector } from '../../../redux/hooks';

const FederationRadioCheck = () => {
  const { myTenantInfo } = useAppSelector((state) => state.auth);
  const { userInfo } = useAppSelector((state) => state.user);

  const radioOnChangeHandler = (e: ChangeEvent<HTMLInputElement>) => {
    if (!userInfo) return alert('check radioOnChangeHandler in federatiionRadioCheck.ts');

    let confirmText =
      e.target.value === 'N'
        ? (t('FederationManagement.disallow') as string)
        : (t('FederationManagement.allow') as string);
    confirmText = ((t('FederationManagement.federationStatusTo') as string) +
      confirmText +
      t('FederationManagement.changeConfirm')) as string;

    if (confirm(`${confirmText}`)) {
      const body = {
        tenantid: userInfo.bid.split('.')[0],
        isdisable: e.target.value === 'N' ? true : false,
      };
      // console.log(body);
      fedManageHandler(body);
    }
  };

  return (
    <div className="flex pt-defaultY gap-32 shrink-0">
      <div className="shrink-0">{t('FederationManagement.federationApplication') as string}</div>
      <div className="flex gap-20">
        <form className="flex gap-20">
          <div className="flex items-center gap-2 shrink-0">
            <InputRadioCheckbox
              kind="radio"
              id="accept"
              value="Y"
              name="federation"
              onChange={radioOnChangeHandler}
              checked={myTenantInfo?.usefederation === 'Y'}
            />
            <label htmlFor={'accept'}>{t('FederationManagement.allow') as string}</label>
          </div>
          <div className="flex items-center gap-2 shrink-0">
            <InputRadioCheckbox
              kind="radio"
              id="deny"
              value="N"
              name="federation"
              onChange={radioOnChangeHandler}
              checked={myTenantInfo?.usefederation === 'N'}
            />
            <label htmlFor={'deny'}>{t('FederationManagement.disallow') as string}</label>
          </div>
        </form>
      </div>
    </div>
  );
};

export default FederationRadioCheck;
