import { useEffect, useState } from 'react';
// 라이브러리
import { SplitPane } from 'react-multi-split-pane';
import { useResizeDetector } from 'react-resize-detector';
import { toast } from 'react-toastify';

// 컴포넌트
import DownFormContainer from './DownFormContainer';
import DownTopBar from './DownTopBar';
import UpperRightTopBar from './UpperRightTopBar';

import {
  handleViewPortSize,
  NestedPane,
  PaneProps,
  splitPaneViewportCase,
} from '../../../helpers/h-splitPane';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import {
  fetchAndSetFuncBtnGroupList,
  fetchAndSetFuncBtnList,
} from '../../../helpers/h-funcBtnMng';
import { initModals } from '../../../redux/slice/toggleSlice';
import FuncBtnList_Table from '../../tables/funcBtnMng/FuncBtnList_Table';
import FuncBtnGroupHubList_Table from '../../tables/funcBtnMng/FuncBtnGroupHubList_Table';
import { initRuleFuncMngSlice } from '../../../redux/slice/ruleFunctionManagementSlice';
import observeStatus from '../../../hooks/observeStatus';
import { initFuncBtnMngSlice } from '../../../redux/slice/funcButtonManagementSlice';
import MainPageTitleHeader from '../../common/MainPageTitleHeader';

const FunctionButtonManagement = () => {
  const dispatch = useAppDispatch();
  const {
    funcBtnStatus,
    funcBtnGroupStatus,
    funcBtnAddStatus,
    funcBtnModifyStatus,
    funcBtnRemoveStatus,
    funcBtnGroupAddStatus,
    funcBtnGroupModifyStatus,
    funcBtnGroupRemoveStatus,
    funcBtnGroupsBtnAddStatus,
    funcBtnGroupsBtnRemoveStatus,
  } = useAppSelector((state) => state.funcButtonManagement);

  const [splitPaneViewportKey, setSplitPaneViewportKey] = useState(
    splitPaneViewportCase.default,
  );

  // 성공/실패 알람
  //    funcBtn
  observeStatus(funcBtnAddStatus, '버튼 추가 성공', '버튼 추가 실패');
  observeStatus(funcBtnModifyStatus, '버튼 수정 성공', '버튼 수정 실패');
  observeStatus(funcBtnRemoveStatus, '버튼 삭제 성공', '버튼 삭제 실패');
  //    funcBtnGroup
  observeStatus(
    funcBtnGroupAddStatus,
    '버튼 그룹 추가 성공',
    '버튼 그룹 추가 실패',
  );
  observeStatus(
    funcBtnGroupModifyStatus,
    '버튼 그룹 수정 성공',
    '버튼 그룹 수정 실패',
  );
  observeStatus(
    funcBtnGroupRemoveStatus,
    '버튼 그룹 삭제 성공',
    '버튼 그룹 삭제 실패',
  );
  //    funcBtnGroupHub
  observeStatus(
    funcBtnGroupsBtnAddStatus,
    '버튼 그룹에 추가 성공',
    '버튼 그룹에 추가 실패',
  );
  observeStatus(
    funcBtnGroupsBtnRemoveStatus,
    '버튼 그룹에서 삭제 성공',
    '버튼 그룹에서 삭제 실패',
  );

  // FUNC 버튼 / FUNC 버튼 그룹 초기화
  useEffect(() => {
    fetchAndSetFuncBtnList();
    fetchAndSetFuncBtnGroupList();
  }, []);

  // FUNC 버튼 추가/수정/삭제 성공시 FUNC 버튼 리스트 갱신
  useEffect(() => {
    if (funcBtnStatus !== 'success') return;
    fetchAndSetFuncBtnList();
  }, [funcBtnStatus]);

  // FUNC 버튼 그룹 추가/수정/삭제 성공시 FUNC 버튼 그룹 리스트 갱신
  useEffect(() => {
    if (funcBtnGroupStatus !== 'success') return;
    fetchAndSetFuncBtnGroupList();
  }, [funcBtnGroupStatus]);

  // 초기화
  useEffect(() => {
    return () => {
      dispatch(initModals());
      dispatch(initRuleFuncMngSlice());
      dispatch(initFuncBtnMngSlice());
    };
  }, []);

  return (
    <div className="h-full w-full">
      <SplitPane
        className="custom"
        split="horizontal"
        minSize={[100, 200]}
        defaultSizes={[348, 628]}
      >
        <Upper
          setSplitPaneViewportKey={setSplitPaneViewportKey}
          splitPaneUpdatedState={splitPaneViewportKey}
        />
        <Down
          setSplitPaneViewportKey={setSplitPaneViewportKey}
          splitPaneUpdatedState={splitPaneViewportKey}
        />
      </SplitPane>
    </div>
  );
};

const Upper = ({
  setSplitPaneViewportKey,
  splitPaneUpdatedState,
}: PaneProps) => {
  const splitPaneHandler = () =>
    handleViewPortSize([setSplitPaneViewportKey, 'clickedUpper']);

  return (
    <SplitPane
      className="custom"
      split="vertical"
      minSize={[100, 200]}
      // defaultSizes={[1,1]}
    >
      <UpperLeft
        onClick={splitPaneHandler}
        splitPaneUpdatedState={splitPaneUpdatedState}
      />
      <UpperRight
        onClick={splitPaneHandler}
        splitPaneUpdatedState={splitPaneUpdatedState}
      />
    </SplitPane>
  );
};

const UpperLeft = ({ onClick, splitPaneUpdatedState }: NestedPane) => {
  const { ref } = useResizeDetector();
  const [isFirst, setisFirst] = useState(true);

  useEffect(() => {
    if (isFirst) return setisFirst(false);
    ref.current.parentElement.parentElement.style.flex =
      splitPaneUpdatedState.upper;
  }, [splitPaneUpdatedState]);

  return (
    <div
      ref={ref}
      className="w-full h-full flex flex-col px-[1.5rem] border border-solid border-[#dfe2e8] bg-white rounded"
    >
      <MainPageTitleHeader textOnly={true} text="function 버튼 목록" onClick={onClick} />
      <FuncBtnList_Table />
    </div>
  );
};
const UpperRight = ({ onClick, splitPaneUpdatedState }: NestedPane) => {
  const { ref } = useResizeDetector();
  const [isFirst, setisFirst] = useState(true);

  useEffect(() => {
    if (isFirst) return setisFirst(false);
    ref.current.parentElement.parentElement.style.flex =
      splitPaneUpdatedState.upper;
  }, [splitPaneUpdatedState]);

  return (
    <div
      ref={ref}
      className="w-full h-full flex flex-col px-[1.5rem] border border-solid border-[#dfe2e8] bg-white rounded"
    >
      <div className="w-full flex flex-col grow overflow-hidden">
        <MainPageTitleHeader textOnly={true} text="버튼 그룹 목록" onClick={onClick} />
        <UpperRightTopBar />
        <FuncBtnGroupHubList_Table />
      </div>
    </div>
  );
};

const Down = ({
  setSplitPaneViewportKey,
  splitPaneUpdatedState,
}: PaneProps) => {
  const { ref } = useResizeDetector();
  const [isFirst, setisFirst] = useState(true);

  useEffect(() => {
    if (isFirst) return setisFirst(false);
    ref.current.parentElement.style.flex = splitPaneUpdatedState.down;
  }, [splitPaneUpdatedState]);

  return (
    <div
      ref={ref}
      className="w-full h-full flex flex-col px-[1.5rem] border border-solid border-[#dfe2e8] rounded bg-white"
    >
      <DownTopBar
        onClick={() =>
          handleViewPortSize([setSplitPaneViewportKey, 'clickedDown'])
        }
      />
      <DownFormContainer />
    </div>
  );
};

export default FunctionButtonManagement;
