import { t } from 'i18next';
import { useEffect, useState } from 'react';
import CounselingSectionHeader from './CounselingSectionHeader';
import { openCounselingCategoryModal } from '../../../helpers/h-react-modals';
import { IAdminCounselCategoryListRes } from '../../../interfaces/api/counsel/I-api-admin-counsel-category';
import { counselCategoryListApi } from '../../../api/admin/counseling/api-admin-counsel-category';
import { DropResult } from 'react-beautiful-dnd';
import CounselingCategories from './CounselingCategories';
import { useSelector } from 'react-redux';
import { RootState } from '../../../redux/store';
import { reqModifyCategoryOrder } from '../../../helpers/h-counselingMng';
import { clearSelectedCategory } from '../../../redux/slice/counselingManagementSlice';
import { useAppDispatch } from '../../../redux/hooks';

const CounselingMainLeft = () => {
  const dispatch = useAppDispatch();
  const [categories, setCategories] = useState<IAdminCounselCategoryListRes[]>([]);
  const categoryAddStatus = useSelector((state: RootState) => state.counselingManagement.categoryAddStatus);
  const categoryRemoveStatus = useSelector((state: RootState) => state.counselingManagement.categoryRemoveStatus);
  const categoryModifyStatus = useSelector((state: RootState) => state.counselingManagement.categoryModifyStatus);

  const fetchCategories = async () => {
    try {
      const response = await counselCategoryListApi();
      if (response.data.code === 200) {
        setCategories(response.data.data.result);
      } else {
        console.error('Failed to fetch categories:', response.data.message);
      }
    } catch (error) {
      console.error('An error occurred while fetching categories:', error);
    }
  };

  useEffect(() => {
    fetchCategories();
  }, []);

  useEffect(() => {
    if (categoryAddStatus === 'success' || categoryRemoveStatus === 'success' || categoryModifyStatus === 'success') {
      fetchCategories();
    }
  }, [categoryAddStatus, categoryRemoveStatus, categoryModifyStatus]);

  const handleAddCategory = () => {
    dispatch(clearSelectedCategory());
    openCounselingCategoryModal();
  };

  const handleDragEnd = async (result: DropResult) => {
    if (!result.destination) return;

    const reorderedCategories = Array.from(categories);
    const [movedCategory] = reorderedCategories.splice(result.source.index, 1);
    reorderedCategories.splice(result.destination.index, 0, movedCategory);

    const updatedCategories = reorderedCategories.map((category, index) => ({
      ...category,
      sortorder: index + 1,
    }));

    setCategories(updatedCategories);

    console.log('Updated Categories:', updatedCategories);

    try {
      reqModifyCategoryOrder(updatedCategories);
      console.log('카테고리 순서 수정 성공');
    } catch (error) {
      console.error('카테고리 순서 수정 오류:', error);
    }
  };
  return (
    <div className="flex-[1]">
      <div className="w-full h-full border border-solid border-[#dfe2e8] bg-white border-r-0">
        <CounselingSectionHeader
          title={t('CounselingManagement.categoryTitle') as string}
          onButtonClick={handleAddCategory}
        />
        <CounselingCategories categories={categories} onDragEnd={handleDragEnd} />
      </div>
    </div>
  );
};

export default CounselingMainLeft;
