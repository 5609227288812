import { Link } from 'react-router-dom';
import HambergerToggle from '../../assets/svg/toggle/HambergerToggle';

// 리덕스
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { getSideBarToggleValue, toggleSideBar } from '../../redux/slice/toggleSlice';
import TenantAdminLogo from '../../assets/svg/header/TenantAdminLogo';
import SpLogo from '../../assets/svg/header/SpLogo';
import { Dispatch, SetStateAction } from 'react';
import HeaderLogo from '../../assets/svg/header/HeaderLogo';
import SchoolHeaderLogo from '../../assets/svg/header/SchoolHeaderLogo';

interface Props {
  type: 'ta' | 'sp';
  tenantid?: string;
  workspacename?: string;
  setOpenSidebarByClick?: Dispatch<SetStateAction<boolean>>;
}
const Header = ({ type, tenantid, workspacename, setOpenSidebarByClick }: Props) => {
  const dispatch = useAppDispatch();
  const showSideBar = useAppSelector(getSideBarToggleValue);
  const header = sessionStorage.getItem('type');

  const handleToggle = () => {
    dispatch(toggleSideBar());

    if (setOpenSidebarByClick) {
      if (!showSideBar) {
        setOpenSidebarByClick(true);
      } else {
        setOpenSidebarByClick(false);
      }
    }
  };
  function getHeaderLogo() {
    const domain = window.location.hostname;

    if (domain.endsWith('schoolline.jp')) {
      return <SchoolHeaderLogo />; // 🏫 도메인이 'schooll.jp'라면 무조건 SchoolHeaderLogo
    }

    return type === 'ta' ? header === 'edu' ? <SchoolHeaderLogo /> : <HeaderLogo /> : <SpLogo />;
  }
  return (
    <div className="h-[4.25rem] mr-defaultX fixed flex w-full z-49 shadow-lg">
      {/* 토글 버튼 */}
      <div className="flex">
        <div className="flex justify-center items-center w-[4.375rem]">
          <div className="w-[1.125rem] h-4 text-gray-600  flex justify-center items-center hover:scale-110 duration-200">
            <button onClick={handleToggle}>
              <HambergerToggle />
            </button>
          </div>
        </div>
        {/* ucworks 로고 */}
        <div className="flex items-center text-black mr-2">
          <Link to={type === 'ta' ? '/service-mornitoring' : '/sp/tenant-management'}>
            {getHeaderLogo()}
            {/* {type === 'ta' ? <HeaderLogo /> : <SpLogo />} */}
          </Link>
        </div>
      </div>
      {type === 'ta' && tenantid && workspacename && (
        <div className="flex flex-col justify-center items-center font-semibold text-black relative top-[2.2px]">
          {/* {tenantid} - {workspacename} */}
        </div>
      )}
    </div>
  );
};

export default Header;
