import { useTranslation } from 'react-i18next';
import {
  ColDef,
  GridApi,
  GridReadyEvent,
  RowSelectedEvent,
} from 'ag-grid-community';
import { AgGridReact } from 'ag-grid-react';
import { useEffect, useMemo, useState } from 'react';
import { IspTenantListReturnValue_desc_T } from '../../../interfaces/api/service-provider/I-api-admin-serviceProvider-fed';
import { billingHistory } from '../../../interfaces/I-ag-column';
import { WorkspaceBillingHistory_column } from './column';

interface Props {
  selectedTenant: IspTenantListReturnValue_desc_T | null;
  fromPortal: boolean;
}
const WorkspaceBillingHistory_Table = ({ selectedTenant }: Props) => {
  const { t } = useTranslation();
  // AG_GRID
  const [columnDefs, setColumnDefs] = useState<ColDef[]>([]);
  const [rowData, setRowData] = useState<billingHistory[]>([]);
  const [gridApi, setGridApi] = useState<GridApi<billingHistory[]>>();
  const defaultColDef = useMemo<ColDef>(() => {
    return {
      flex: 1,
      resizable: true,
      minWidth: 100,
    };
  }, []);

  const onGridReady = (params: GridReadyEvent<billingHistory[]>) => {
    setGridApi(params.api);
  };

  const onRowSelected = (event: RowSelectedEvent<billingHistory>) => {
    if (event.node.isSelected() && event.data) {
      // dispatch(setSelectedTenant(event.data));
    }
  };

  useEffect(() => {
    if (!selectedTenant) return;
    const fetchData = async () => {
      try {
        const bills: billingHistory[] = [];
        const usersPaymentList = selectedTenant.tenantdesc.paymenthistory;
        for (const payment of usersPaymentList) {
          bills.push({
            licenseType: payment.licensetype,
            price: payment.price,
            //licenseCount: payment.pricecount,
            // orderCycle: `${
            //   payment.ordercycle === 1
            //     ? '1 Month'
            //     : payment.ordercycle === 6
            //     ? '6 Months'
            //     : payment.ordercycle === 12
            //     ? '1 Year'
            //     : ''
            // }`,
            orderDate: payment.paiddate,
          });
        }
        setRowData(bills);
      } catch (err) {
        //console.log(err);
      }
    };
    fetchData();
  }, [selectedTenant]);

  useEffect(() => {
    setColumnDefs(WorkspaceBillingHistory_column(t));
  }, [t]);

  return (
    <div className="w-full grow flex flex-col pb-defaultY overflow-hidden">
      <div className="ag-theme-alpine ag-theme-mycustomtheme grow w-full">
        <AgGridReact
          rowData={rowData}
          columnDefs={columnDefs}
          defaultColDef={defaultColDef}
          onGridReady={onGridReady}
          rowSelection={'single'}
          onRowSelected={onRowSelected}
          overlayNoRowsTemplate={'\xa0'}
        ></AgGridReact>
      </div>
    </div>
  );
};

export default WorkspaceBillingHistory_Table;
