import { createSlice } from '@reduxjs/toolkit';
import { IfedTenantListItem } from '../../interfaces/api/service-provider/I-api-admin-serviceProvider-fed';

interface InitialState {
  user: boolean;
  myTenantInfo: IfedTenantListItem | null;
}

const initialState: InitialState = {
  user: false,
  myTenantInfo: null,
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    initAuthSlice: () => initialState,
    setUser: (state, action) => {
      state.user = action.payload;
    },
    setMyTenantInfo: (
      state,
      { payload: myTenantInfo }: { payload: IfedTenantListItem },
    ) => {
      state.myTenantInfo = myTenantInfo;
    },
  },
});

export const { initAuthSlice, setUser, setMyTenantInfo } = authSlice.actions;

export default authSlice.reducer;
