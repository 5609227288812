import React from 'react';
import ListTitle from '../../common/ListTitle';

const dummyData = new Array(30).fill(null).map(() => ({
  userID: '23320282',
  userName: '최철화',
  locateDepartment: '인사팀',
  state: '오프라인',
  status: '-',
}));

const UpperListContainer = () => {
  return (
    <div className="h-full">
      <ListTitle
        titles={['사용자 ID', '이름', '소속부서', '상태', '접속 형태']}
      />
      <div className="overflow-auto h-full">
        {dummyData.map((data, i) => (
          <List data={data} key={i} index={i} />
        ))}
      </div>
    </div>
  );
};

interface Data {
  userID: string;
  userName: string;
  locateDepartment: string;
  state: string;
  status: string;
}

type Props = {
  data: Data;
  index: number;
};

const List: React.FC<Props> = ({ data, index }) => {
  return (
    <div
      className={`grid grid-cols-5 border-b border-solid py-[0.813rem] border-[#dfe2e8] ${
        index < 1 ? 'bg-[#f1f3f6]' : ''
      }`}
    >
      <div className="text-center">{data.userID}</div>
      <div className="text-center">{data.userName}</div>
      <div className="text-center">{data.locateDepartment}</div>
      <div className="text-center">{data.state}</div>
      <div className="text-center">{data.status}</div>
    </div>
  );
};

export default UpperListContainer;
