import { t } from 'i18next';
import {
  ColDef,
  IDatasource,
  GridApi,
  GridReadyEvent,
  RowSelectedEvent,
} from 'ag-grid-community';
import { AgGridReact } from 'ag-grid-react';
import axios from 'axios';
import { Dispatch, SetStateAction, useMemo, useState, useEffect } from 'react';
import { sp_tenantListApi_desc_F } from '../../../../api/admin/service-provider/api-admin-serviceProvider-fed';
import { IspTenantListReturnValue_desc_T } from '../../../../interfaces/api/service-provider/I-api-admin-serviceProvider-fed';
import { useAppDispatch, useAppSelector } from '../../../../redux/hooks';
import { setSelectedTenant } from '../../../../redux/slice/tenantManagementSlice';
import { TenantManagement_tenantList_column } from './columns';

interface Props {
  setSelectedTenant: Dispatch<
    SetStateAction<IspTenantListReturnValue_desc_T | null>
  >;
}
const TenantManagement_tenantList_Table = ({ setSelectedTenant }: Props) => {
  const dispatch = useAppDispatch();
  // AG_GRID
  const [gridApi, setGridApi] =
    useState<GridApi<IspTenantListReturnValue_desc_T[]>>();

  const [columnDefs, setColumnDefs] = useState<ColDef[]>([]);
  // const [columnDefs, setColumnDefs] = useState<ColDef[]>(
  //   TenantManagement_tenantList_column,
  // );
  const defaultColDef = useMemo<ColDef>(() => {
    return {
      flex: 1,
      resizable: true,
      minWidth: 100,
    };
  }, []);

  // 테이블 인피니트 스크롤용
  let page = 1;
  const dataSource: IDatasource = {
    rowCount: undefined,
    getRows: (params) => {
      sp_tenantListApi_desc_F({
        pagesize: 50,
        page,
      })
        .then((resp) => ({
          tenantList: resp?.data.data.result,
          totpage: resp?.data.data.totpage,
          totcount: resp?.data.data.totcount,
        }))
        .then((data) => {
          const { tenantList, totpage, totcount } = data;
          if (!tenantList || !totcount || !totpage) return;
          const lastPage = page < totpage ? 50 * page + 1 : parseInt(totcount);
          params.successCallback(tenantList, lastPage);
          page++;
        })
        .catch((error) => {
          if (axios.isAxiosError(error)) {
            console.log('error: ', error);
          } else {
            console.log('unexpected error: ', error);
          }
        });
    },
  };

  const onGridReady = (
    params: GridReadyEvent<IspTenantListReturnValue_desc_T[]>,
  ) => {
    setGridApi(params.api);
    params.api.setDatasource(dataSource);
  };

  const onRowSelected = (
    event: RowSelectedEvent<IspTenantListReturnValue_desc_T>,
  ) => {
    if (event.node.isSelected() && event.data) {
      setSelectedTenant(event.data);
    }
  };

  useEffect(() => {
    setColumnDefs(TenantManagement_tenantList_column(t));
  }, [t]);

  return (
    <div className="w-full grow flex flex-col pb-defaultY overflow-hidden">
      <div className="ag-theme-alpine ag-theme-mycustomtheme grow w-full">
        <AgGridReact
          columnDefs={columnDefs}
          defaultColDef={defaultColDef}
          rowModelType={'infinite'}
          cacheBlockSize={50}
          onGridReady={onGridReady}
          rowSelection={'single'}
          onRowSelected={onRowSelected}
          // rowMultiSelectWithClick
          // onCellClicked={cellClickedListener}
          // onSelectionChanged={onSelectionChanged}
          overlayNoRowsTemplate={'\xa0'}
        ></AgGridReact>
      </div>
    </div>
  );
};

export default TenantManagement_tenantList_Table;
