import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Tooltip } from 'react-tooltip';
import WarningIcon from '../../../assets/svg/icons/WarningIcon';
import { useAppSelector } from '../../../redux/hooks';
import { RootState } from '../../../redux/store';
import { useDeptContext } from '../../../providers/DeptProvider';

interface Props {
  value: any;
}

const DeptCell = ({ value }: Props) => {
  const { t } = useTranslation();

  const { deptMap, setDeptMap } = useDeptContext();
  const tooltipId = `tooltip-${Math.random().toString(36).substring(2, 10)}`;
  const [visible, setVisible] = useState<boolean>(false);
  const [tooltipContent, setTooltipContent] = useState<string>("");

  /*
  * @note 렌더링 중 상태 변경시 무한 루프에 빠짐
  */
  const getTooltipContent = (): string => {
    if (!value) return t("Enrolment.warning3");

    const deptValues = deptMap.get(value) || [];
    if (deptValues.length === 0) return t("Enrolment.warning1");
    if (deptValues.length > 1) return t("Enrolment.warning2");

    return "";
  };

  useEffect(() => {
    const tooltipText: string | null= getTooltipContent();

    setVisible(!!tooltipText);
    setTooltipContent(getTooltipContent());
  }, [value, visible]);

  return (
    <div className="relative flex items-center h-full w-full gap-2">
      <div className={"h-4/5 w-full truncate items-center flex justify-center"}>
        {value}
      </div>

      {
        visible && (
          <>
            <span
              data-tooltip-id={tooltipId} // 툴팁 연결 ID
              className="cursor-pointer h-fit w-fit"
              style={{ marginTop: '-1px' }}
            >
              <WarningIcon />
            </span>
            <Tooltip
              id={tooltipId}
              float={false}
              variant={'dark'}
              place="right" // 툴팁 위치
              style={{
                padding: "0px 5px 0px 5px",
                borderRadius: '4px',
                zIndex: 9999,
              }}
            >
              {tooltipContent}
            </Tooltip>
          </>

        )
      }

    </div>

  );
};

export default DeptCell;