
export const fieldLabelsForKO: Record<string, string> = {
  'deptcode': '조직코드',
  'userid': '아이디*',
  'userpwd': '비밀번호',
  'username': '이름*',
  'usernameEn': '이름(영문)',
  'position': '직위',
  'duty': '직책',
  'grade': '직급',
  'telmobile': '핸드폰',
  'teloffice': '전화(회사)',
  'telextension': '회사 내선번호',
  'email': '이메일',
};

export const fieldLabelsForEN: Record<string, string> = {
  "deptcode": "Department code",
  "userid": "ID*",
  "userpwd": "Password",
  "username": "Name*",
  "usernameEn": "Name (English)",
  "position": "Position",
  "duty": "Duty",
  "grade": "Grade",
  "telmobile": "Mobile",
  "teloffice": "Office no",
  "telextension": "Extension Number",
  "email": "Email"
}