import { createSlice } from '@reduxjs/toolkit';

interface InitialState {
  isPageOpen: boolean;
}

const initialState: InitialState = {
  isPageOpen: false
}

export const orgBatchAddPageSlice = createSlice({
  name: "orgBatchPage",
  initialState,
  reducers: {
    toggleOrgAddBatchPage: (state) => {
      state.isPageOpen = !state.isPageOpen;
    }
  }
})

export const { toggleOrgAddBatchPage } = orgBatchAddPageSlice.actions;

export default orgBatchAddPageSlice.reducer;
