import { createContext, PropsWithChildren, useContext, useState } from 'react';

type DeptContextType = {
  deptMap: Map<string, string[]>;
  setDeptMap: (map: Map<string, string[]>) => void;
};

const DeptContext = createContext<DeptContextType | undefined>(undefined);

export const DeptProvider = ({ children }: PropsWithChildren) => {
  const [deptMap, setDeptMap] = useState<Map<string, string[]>>(new Map());
  return (
    <DeptContext.Provider value={{ deptMap, setDeptMap }}>
      {children}
    </DeptContext.Provider>
  )
}

export const useDeptContext = () => {
  const context = useContext(DeptContext);

  if (!context) {
    throw new Error('useDeptContext must be used within a DeptProvider');
  }
  return context;
}

