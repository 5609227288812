import { t } from 'i18next';
import React, { useEffect, useState } from 'react';
import { SplitPane } from 'react-multi-split-pane';
import { useResizeDetector } from 'react-resize-detector';
import {
  getAndSetFedList,
  getAndSetTenantInfo,
  initFedSlice,
} from '../../../helpers/h-federationMng';
import {
  handleViewPortSize,
  PaneProps,
  splitPaneViewportCase,
} from '../../../helpers/h-splitPane';
import { getMyTenantId } from '../../../helpers/h-userInfo';
import { useAppSelector } from '../../../redux/hooks';
import MainPageTitleHeader from '../../common/MainPageTitleHeader';
import FederationConnected_Table from '../../tables/federationMng/FederationConnected_Table';
import FederationWillConnect_Table from '../../tables/federationMng/FederationWillConnect_Table';
import FederationRadioCheck from './FederationRadioCheck';
import UpperTopBar from './UpperTopBar';

const Federation = () => {
  const { myTenantInfo } = useAppSelector((state) => state.auth);

  return (
    <div className="w-full min-h-[6.5rem] px-[1.5rem] border border-solid flex flex-col border-[#dfe2e8] rounded bg-white overflow-hidden">
      <MainPageTitleHeader text={t('FederationManagement.myFederationSettings')} textOnly />
      {myTenantInfo && <FederationRadioCheck />}
    </div>
  );
};

const Upper = ({
  setSplitPaneViewportKey,
  splitPaneUpdatedState,
}: PaneProps) => {
  const { ref } = useResizeDetector();

  useEffect(() => {
    ref.current.parentElement.style.flex = splitPaneUpdatedState.upper;
  }, [splitPaneUpdatedState]);

  return (
    <div
      ref={ref}
      className="w-full grow flex flex-col border px-[1.5rem] border-solid bg-white border-[#dfe2e8] rounded"
    >
      <MainPageTitleHeader
        textOnly={true}
        text={t('FederationManagement.connectedTenantList')}
        onClick={() =>
          handleViewPortSize([setSplitPaneViewportKey, 'clickedUpper'])
        }
      />
      <UpperTopBar />
      <FederationConnected_Table />
    </div>
  );
};

const Down = ({
  setSplitPaneViewportKey,
  splitPaneUpdatedState,
}: PaneProps) => {
  const { ref } = useResizeDetector();

  useEffect(() => {
    ref.current.parentElement.style.flex = splitPaneUpdatedState.down;
  }, [splitPaneUpdatedState]);

  return (
    <div
      ref={ref}
      className="w-full grow px-[1.5rem] border border-solid flex flex-col border-[#dfe2e8] rounded bg-white"
    >
      <MainPageTitleHeader
        textOnly={true}
        text={t('FederationManagement.connectionRequestList')}
        onClick={() =>
          handleViewPortSize([setSplitPaneViewportKey, 'clickedDown'])
        }
      />
      <FederationWillConnect_Table />
    </div>
  );
};

const FederationManagement = () => {
  const { fedActionStatus } = useAppSelector(
    (state) => state.federatinManagement,
  );
  const [splitPaneViewportKey, setSplitPaneViewportKey] = useState(
    splitPaneViewportCase.default,
  );

  // fed/list 패치
  useEffect(() => {
    getAndSetFedList();
    getAndSetTenantInfo(getMyTenantId() as string);
  }, []);

  // 옵저버
  useEffect(() => {
    if (fedActionStatus !== 'success') return;

    getAndSetFedList();
    getAndSetTenantInfo(getMyTenantId() as string);
  }, [fedActionStatus]);

  //초기화 함수
  useEffect(() => {
    return () => {
      initFedSlice();
    };
  });

  return (
    <div className="h-full w-full flex flex-col gap-3">
      <Federation />
      <div className="splitPane-Wrapper grow w-full">
        <SplitPane
          className="custom grow flex flex-col h-0 min-h-0"
          defaultSizes={[2, 1]}
          split="horizontal"
          minSize={[40, 40]}
        >
          <Upper
            setSplitPaneViewportKey={setSplitPaneViewportKey}
            splitPaneUpdatedState={splitPaneViewportKey}
          />
          <Down
            setSplitPaneViewportKey={setSplitPaneViewportKey}
            splitPaneUpdatedState={splitPaneViewportKey}
          />
        </SplitPane>
      </div>
    </div>
  );
};

export default FederationManagement;
