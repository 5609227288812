import React from 'react';

interface Data {
  devision: string;
  title: string;
  department: string;
  creator: string;
  ID: string;
  duration: string;
  createTime: string;
}

type Props = {
  data: Data;
  index: number;
};

const AnnounceLists: React.FC<Props> = ({ data, index }) => {
  return (
    <div
      className={`grid grid-cols-7 border-b border-solid py-[0.813rem] border-[#dfe2e8] ${
        index < 1 ? 'bg-[#f1f3f6]' : ''
      }`}
    >
      <div className="text-center">{data.devision}</div>
      <div className="text-center">{data.title}</div>
      <div className="text-center">{data.department}</div>
      <div className="text-center">{data.creator}</div>
      <div className="text-center">{data.ID}</div>
      <div className="text-center">{data.duration}</div>
      <div className="text-center">{data.createTime}</div>
    </div>
  );
};

export default AnnounceLists;
