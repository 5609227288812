import React, { ChangeEvent, useEffect, useRef, useState } from 'react';
import ReactDOM from 'react-dom';
import Button from '../../common/buttons/Button';
import Modal from 'react-modal';
import InputText from '../../common/inputs/InputText';
import { closeUserOrgMngReactModal_modify_dept } from '../../../helpers/h-react-modals';
import { useAppSelector } from '../../../redux/hooks';
import { modifyDeptHandler } from '../../../helpers/h-orgUserMng';
import { toast } from 'react-toastify';
import { t } from 'i18next';

const customStyles = {
  overlay: {
    zIndex: 99,
  },
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    minWidth: '21.875rem',
    padding: 0,
  },
};

interface Props {
  isOpen: boolean;
}

const ModifyDeptReactModal = ({ isOpen = false }: Props) => {
  const {
    orgInfo: { contextDeptInfo },
  } = useAppSelector((state) => state.organizationUserManagement);

  const subTitle = useRef<HTMLHeadingElement | null>(null);
  const [newDeptName, setNewDeptName] = useState('');

  const inputHandler = (event: ChangeEvent<HTMLInputElement>) => {
    setNewDeptName(event.target.value);
  };

  function afterOpenModal() {
    if (subTitle.current === null) return;
    subTitle.current.style.color = 'red';
  }

  const onConfirm = () => {
    if (!contextDeptInfo) return toast.error(t('ContextMenu.treeContextMenu.treeToast1') as string);
    if (!newDeptName) return toast.error(t('ContextMenu.treeContextMenu.treeToast2') as string);

    modifyDeptHandler(contextDeptInfo, newDeptName);
    setNewDeptName('');
    closeUserOrgMngReactModal_modify_dept();
  };

  useEffect(() => {
    if (!isOpen) setNewDeptName('');
  }, [isOpen]);

  return (
    <div>
      <Modal
        isOpen={isOpen}
        onAfterOpen={afterOpenModal}
        onRequestClose={closeUserOrgMngReactModal_modify_dept}
        style={customStyles}
        contentLabel="Example Modal"
      >
        <div
          className="flex flex-col h-full px-3
            relative border border-modal-subTitle bg-white z-50 shadow-listbox-options
            rounded
          "
        >
          {/* HEADER */}
          <div className="min-h-[3.5rem] w-full flex flex-col relative"></div>
          {/* BODY */}
          <div className="grow w-full jcac gap-3 overflow-y-auto text-sm py-defaultY">
            {t('ContextMenu.treeContextMenu.modify') as string}
          </div>
          <InputText value={newDeptName} handleChangeInput={inputHandler} />
          {/* FOOTER */}
          <div className="min-h-[6rem] w-full jcac gap-2">
            <Button
              kind="cancel"
              label={t('ContextMenu.treeContextMenu.cancel')}
              onClick={closeUserOrgMngReactModal_modify_dept}
            />
            <Button kind="primary" label={t('ContextMenu.treeContextMenu.ok')} onClick={onConfirm} />
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default ModifyDeptReactModal;
