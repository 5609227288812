import { useEffect, useState } from 'react';
// 라이브러리
import { SplitPane } from 'react-multi-split-pane';
import { useResizeDetector } from 'react-resize-detector';

// 컴포넌트
import DownFormContainer from './DownFormContainer';
import DownTopBar from './DownTopBar';
import UpperListContainer from './UpperListContainer';

import {
  handleViewPortSize,
  PaneProps,
  splitPaneViewportCase,
} from '../../../helpers/h-splitPane';
import MainPageTitleHeader from '../../common/MainPageTitleHeader';

const DbManagement = () => {
  const [splitPaneViewportKey, setSplitPaneViewportKey] = useState(
    splitPaneViewportCase.default,
  );
  return (
    <div className="h-full w-full">
      <SplitPane
        className="custom"
        split="horizontal"
        minSize={[100, 200]}
        defaultSizes={[259, 717]}
      >
        <Upper
          setSplitPaneViewportKey={setSplitPaneViewportKey}
          splitPaneUpdatedState={splitPaneViewportKey}
        />
        <Down
          setSplitPaneViewportKey={setSplitPaneViewportKey}
          splitPaneUpdatedState={splitPaneViewportKey}
        />
      </SplitPane>
    </div>
  );
};

const Upper = ({
  setSplitPaneViewportKey,
  splitPaneUpdatedState,
}: PaneProps) => {
  const { ref } = useResizeDetector();

  useEffect(() => {
    ref.current.parentElement.style.flex = splitPaneUpdatedState.upper;
  }, [splitPaneUpdatedState]);
  return (
    <div
      ref={ref}
      className="w-full h-full border border-solid border-[#dfe2e8] rounded"
    >
      <div className="w-full h-full px-[1.5rem] flex flex-col bg-white">
        <MainPageTitleHeader
          text="Databace 관리"
          onClick={() =>
            handleViewPortSize([setSplitPaneViewportKey, 'clickedUpper'])
          }
        />
        <UpperListContainer />
      </div>
    </div>
  );
};

const Down = ({
  setSplitPaneViewportKey,
  splitPaneUpdatedState,
}: PaneProps) => {
  const { ref } = useResizeDetector();

  useEffect(() => {
    ref.current.parentElement.style.flex = splitPaneUpdatedState.down;
  }, [splitPaneUpdatedState]);

  return (
    <div
      ref={ref}
      className="w-full h-full px-[1.5rem] border border-solid border-[#dfe2e8] rounded bg-white "
    >
      <DownTopBar
        onClick={() =>
          handleViewPortSize([setSplitPaneViewportKey, 'clickedDown'])
        }
      />
      <DownFormContainer />
    </div>
  );
};
export default DbManagement;
