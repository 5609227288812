import { useCallback, useEffect, useMemo, useState } from 'react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import { AgGridReact } from 'ag-grid-react';
import { CellClickedEvent, ColDef } from 'ag-grid-community';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { RULE_FUNC_LIST_TALBLE_COLUMN } from './columns';
import { IadminRuleFunc } from '../../../interfaces/api/rule/I-api-admin-rule-func';
import { setSelectedRuleFunc } from '../../../redux/slice/ruleFunctionManagementSlice';

const RuleFuncMngFunList_Table = () => {
  const dispatch = useAppDispatch();
  const { ruleFuncList } = useAppSelector(
    (state) => state.ruleFunctionManagement,
  );

  const [columnDefs, setColumnDefs] = useState<ColDef[]>(
    RULE_FUNC_LIST_TALBLE_COLUMN,
  );
  const [rowData, setRowData] = useState<IadminRuleFunc[]>([]);
  const defaultColDef = useMemo<ColDef>(() => {
    return {
      flex: 1,
      resizable: true,
      minWidth: 100,
    };
  }, []);

  const cellClickedListener = useCallback((event: CellClickedEvent<any>) => {
    // console.log('cellClicked', event);
    const buttonInfo = event.data;
    dispatch(setSelectedRuleFunc(buttonInfo));
  }, []);

  // 테이블 데이터 설정
  useEffect(() => {
    setRowData(ruleFuncList);
  }, [ruleFuncList]);

  return (
    <div className="w-full grow pb-2 flex flex-col overflow-hidden">
      <div className="ag-theme-alpine ag-theme-mycustomtheme w-full grow">
        <AgGridReact
          rowData={rowData}
          columnDefs={columnDefs}
          defaultColDef={defaultColDef}
          onCellClicked={cellClickedListener}
          rowSelection={'single'}
          overlayNoRowsTemplate={'\xa0'}
        ></AgGridReact>
      </div>
    </div>
  );
};

export default RuleFuncMngFunList_Table;
