import { t } from 'i18next';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
// 라이브러리
import { SplitPane } from 'react-multi-split-pane';
import { useResizeDetector } from 'react-resize-detector';

// 컴포넌트

import {
  handleViewPortSize,
  NestedPaneWithTenant,
  PaneProps,
  splitPaneViewportCase,
} from '../../../helpers/h-splitPane';
import { IspTenantListReturnValue_desc_T } from '../../../interfaces/api/service-provider/I-api-admin-serviceProvider-fed';
import MainPageTitleHeader from '../../common/MainPageTitleHeader';
import TenantManagement_tenantList_Table from '../../tables/sp/tenantManagement/TenantManagement_tenantList_Table';
import UpgradeManagement_PatchList_Table from '../../tables/sp/upgradeManagement/UpgradeManagement_PatchList_Table';
import UpgradeManagement_VersionControl_Table from '../../tables/sp/upgradeManagement/UpgradeManagement_VersionControl_Table';

interface IleftPane extends PaneProps {
  setSelectedTenant: Dispatch<
    SetStateAction<IspTenantListReturnValue_desc_T | null>
  >;
}
interface IRightPane extends PaneProps {
  selectedTenant: IspTenantListReturnValue_desc_T | null;
}
// 테넌트 관리
const UpgradeManagement = () => {
  const [splitPaneViewportKey, setSplitPaneViewportKey] = useState(
    splitPaneViewportCase.default,
  );
  const [selectedTenant, setSelectedTenant] =
    useState<IspTenantListReturnValue_desc_T | null>(null);

  return (
    <div className="h-full w-full">
      <SplitPane
        className="custom"
        split="vertical"
        defaultSizes={[3, 5]}
        minSize={[50, 50]}
      >
        <Left
          setSplitPaneViewportKey={setSplitPaneViewportKey}
          splitPaneUpdatedState={splitPaneViewportKey}
          setSelectedTenant={setSelectedTenant}
        />
        <Right
          setSplitPaneViewportKey={setSplitPaneViewportKey}
          splitPaneUpdatedState={splitPaneViewportKey}
          selectedTenant={selectedTenant}
        />
      </SplitPane>
    </div>
  );
};

const Left = ({
  setSplitPaneViewportKey,
  splitPaneUpdatedState,
  setSelectedTenant,
}: IleftPane) => {
  const { width, ref } = useResizeDetector();

  useEffect(() => {
    ref.current.parentElement.style.flex = splitPaneUpdatedState.left;
  }, [splitPaneUpdatedState]);

  const splitPaneHandler = () => {
    handleViewPortSize([setSplitPaneViewportKey, 'clickedLeft']);
  };

  return (
    <div
      ref={ref}
      className="w-full flex flex-col px-6 border border-solid bg-white border-[#dfe2e8] rounded"
    >
      <MainPageTitleHeader
        onClick={splitPaneHandler}
        text={t('UpgradeManagement.tenantList')}
        direction="left"
      />
      <TenantManagement_tenantList_Table
        setSelectedTenant={setSelectedTenant}
      />
    </div>
  );
};

const RightUpper = ({
  onClick,
  splitPaneUpdatedState,
  selectedTenant,
}: NestedPaneWithTenant) => {
  const { ref } = useResizeDetector();
  const [isFirst, setisFirst] = useState(true);

  useEffect(() => {
    if (isFirst) return setisFirst(false);
    ref.current.parentElement.parentElement.style.flex =
      splitPaneUpdatedState.right;
  }, [splitPaneUpdatedState]);

  return (
    <div
      ref={ref}
      className="w-full flex flex-col h-full px-6 border border-solid border-[#dfe2e8] rounded bg-white"
    >
      <MainPageTitleHeader
        onClick={onClick}
        text={t('UpgradeManagement.serviceVerConfig')}
        direction="right"
      />
      <UpgradeManagement_VersionControl_Table selectedTenant={selectedTenant} />
    </div>
  );
};
const RightDown = ({
  onClick,
  splitPaneUpdatedState,
  selectedTenant,
}: NestedPaneWithTenant) => {
  const { ref } = useResizeDetector();
  const [isFirst, setisFirst] = useState(true);

  useEffect(() => {
    if (isFirst) return setisFirst(false);
    ref.current.parentElement.parentElement.style.flex =
      splitPaneUpdatedState.right;
  }, [splitPaneUpdatedState]);

  return (
    <div
      ref={ref}
      className="w-full flex flex-col h-full px-6 border border-solid border-[#dfe2e8] rounded bg-white"
    >
      <MainPageTitleHeader
        onClick={onClick}
        text={t('UpgradeManagement.patchFileList')}
        direction="right"
      />
      <UpgradeManagement_PatchList_Table selectedTenant={selectedTenant} />
    </div>
  );
};

const Right = ({
  setSplitPaneViewportKey,
  splitPaneUpdatedState,
  selectedTenant,
}: IRightPane) => {
  const { width, ref } = useResizeDetector();

  const splitPaneHandler = () =>
    handleViewPortSize([setSplitPaneViewportKey, 'clickedRight']);

  return (
    <SplitPane className="custom" split="horizontal" defaultSizes={[1]}>
      <RightUpper
        onClick={splitPaneHandler}
        splitPaneUpdatedState={splitPaneUpdatedState}
        selectedTenant={selectedTenant}
      />
      <RightDown
        onClick={splitPaneHandler}
        splitPaneUpdatedState={splitPaneUpdatedState}
        selectedTenant={selectedTenant}
      />
    </SplitPane>
  );
};

export default UpgradeManagement;
