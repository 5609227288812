// 컴포넌트
import MainPageTitleHeader from '../../common/MainPageTitleHeader';
import ListContainer from './ListContainer';
import NavBar from './NavBar';
import TopBar from './TopBar';

const AdminLog = () => {
  return (
    <div className="w-full h-full px-[1.5rem] flex flex-col border border-solid border-[#dfe2e8] bg-white  rounded">
      <MainPageTitleHeader text="관리자 로그" />
      <div className="flex justify-between">
        <TopBar />
        <NavBar />
      </div>
      <ListContainer />
    </div>
  );
};

export default AdminLog;
